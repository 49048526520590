import {
  browserLocalStorageAvailable,
  saveJsonItemToSessionStorage,
} from "@js-ecom-mfe/browser-storage";
import { logger } from "@js-ecom-mfe/logger";
import Cookies from "js-cookie";
import { getClosestStore } from "../services/closestStore";
import { DELIVERY_INFORMATION_TIME_OUT } from "../services/const/timeouts";
import { getDeliveryInformation } from "../services/deliveryInformation";
import { getBrandConcept, getConfigById } from "../util/context";
const fileName = "userLocationDetails.js";
import { REPONAME, CURRENT_ZIP_CODE_INFO } from "../util/constants";
import { zipCodeDeliveryCookieCheck } from "../util/cookies";
import { mapState } from "vuex";

export default {
  data() {
    const userLocationDetailsConfigs =
      getConfigById("userLocationDetails", this) || {};
    const storeRadius = userLocationDetailsConfigs.storeRadius || 25;
    const isUserLocationDetailsEnabled =
      userLocationDetailsConfigs.isEnabled || false;
    const deliveryLocationTimeout =
      userLocationDetailsConfigs?.deliveryLocationTimeout ||
      DELIVERY_INFORMATION_TIME_OUT;

    return {
      isUserLocationDetailsEnabled,
      deliveryLocationTimeout,
      storeRadius,
      userPostalCode: "",
      userLatitude: "",
      userLongitude: "",
      brand: getBrandConcept(this),
    };
  },
  computed: {
    ...mapState({
      isPostalCodeDeliveryCookieCheckOnConfig: (state) =>
        state.header?.config?.isZipCodeDeliveryCookieCheckOn || false,
    }),
  },
  methods: {
    async updateUserLocationDetails() {
      let postalCode = this.userPostalCode;
      this.isPostalCodeDeliveryCookieCheckOnConfig &&
        (postalCode = zipCodeDeliveryCookieCheck());
      const userPickupLocationPoints = await this.getPickupLocationPoints();
      const userDeliveryInformation = await getDeliveryInformation(
        postalCode,
        this.deliveryLocationTimeout
      );

      const userLocationData = {
        userPostalCode: this.userPostalCode,
        userPickupLocationPoints,
        userDeliveryInformation,
      };

      if (browserLocalStorageAvailable()) {
        saveJsonItemToSessionStorage("userLocationData", userLocationData);
      }
    },
    async getPickupLocationPoints() {
      try {
        const { data } = await getClosestStore(
          this.brand,
          this.userLatitude,
          this.userLongitude,
          this.storeRadius
        );
        if (data?.storeListResponse?.stores?.length) {
          return data.storeListResponse.stores.map(
            (store) => store.properties["PICKUP_LOCATION_CODE"]
          );
        } else {
          return [];
        }
      } catch (e) {
        logger.error(REPONAME, fileName, "getPickupLocationPoints", e);
        return [];
      }
    },
  },
  async created() {
    if (!this.isUserLocationDetailsEnabled) {
      return;
    }
    const geoLocationInfo = Cookies.get(CURRENT_ZIP_CODE_INFO);
    if (!geoLocationInfo) {
      return;
    }
    const locationDetails = geoLocationInfo.split("|");
    if (locationDetails.length < 4) {
      return;
    }
    this.userPostalCode = locationDetails[2] || "";
    this.userLatitude = locationDetails[3] || "";
    this.userLongitude = locationDetails[4] || "";

    if (this.isPostalCodeOptimizationEnabled) {
      await this.setOptimizedGovernorState();
      this.userPostalCode = this.getMyStoreUserLocation("zipCode");
      const storeDetails = this.getStoreLocation();
      this.userLatitude = storeDetails?.latitude;
      this.userLongitude = storeDetails?.longitude;
    }

    this.updateUserLocationDetails();
  },
};

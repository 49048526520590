<template>
  <header
    data-style="simple-header"
    :id="subBrandContext"
    data-component="Global-Header"
    data-test-id="Global-header"
    :class="className"
  >
    <SeoSchemaTags data-test-id="seo-schema-component" />
    <div class="sticky-header simple-sticky-header-HOD" ref="headerMain">
      <!--Desktop Header-->
      <header class="main-container HOM simple-header" role="presentation">
        <div class="my-store-position">
          <section id="sub-brand-bar">
            <button
              aria-label="search"
              @click="handleSearchFlyout()"
              class="search-icon"
            >
              <span v-html="searchIcon"></span>
            </button>
            <div class="separated-lists">
              <ActionLinkList
                :subBrand="subBrand"
                data-testid="Global-action-link-list"
                :displayUserName="displayUserName"
              />
            </div>
          </section>

          <template v-if="hasLoaded">
            <NavMenu :isResponsive="isResponsive" :navMenuText="navMenuText" />
            <SearchFlyout :flyoutConfig="searchFlyoutConfig" />
          </template>
        </div>
        <BrandLogo v-if="!isResponsive" />
        <section id="header-search-form">
          <span class="nav-menu-title">
            <button
              aria-label="Shop Nav"
              @click="toggleNavFlyout('primary')"
              class="search-icon nav-flyout"
            >
              {{ navMenuName }}
            </button>

            <button
              aria-label="Explore"
              @click="toggleNavFlyout('secondary')"
              class="search-icon explore-flyout"
            >
              {{ exploreMenuName }}
            </button>
          </span>
        </section>
      </header>
      <!--Mobile Header-->
      <slot name="simpleMobileHeader" />
    </div>
    <template>
      <template
        v-if="
          this.isMobile &&
          showMarketingCampaignOverlay &&
          isMobileOverlayV2Enabled
        "
      >
        <MobileMarketingOverlayContainer />
      </template>
      <template
        v-else-if="
          this.isMobile &&
          showMarketingCampaignOverlay &&
          isMobileOverlayEnabled
        "
      >
        <MobileMarketingOverlay />
      </template>
      <template v-else-if="hasLoaded">
        <JoinEmailCampaign
          :isMobileActive="isMobile"
          :showMarketingCampaignOverlay="showMarketingCampaignOverlay"
        />
      </template>
    </template>
  </header>
</template>

<script>
import Cookies from "js-cookie";
import ActionLinkList from "../ActionLinkList/ActionLinkList.vue";
import SearchFlyout from "../SearchFlyout/SearchFlyout.vue";
import NavMenu from "./NavMenu.vue";
import BrandLogo from "../BrandLogo.vue";
import { getConfigById, getBrandConcept } from "../../../util/context";
import JoinEmailCampaign from "../JoinEmailCampaign.vue";
import MobileMarketingOverlayContainer from "../MobileMarketingOverlayContainer.vue";
import MobileMarketingOverlay from "../MobileMarketingOverlay.vue";
import EventBus from "../../../util/event-bus";
import breakPoints from "../../../mixins/breakPoints";
import resize from "../../../mixins/resize";
import SeoSchemaTags from "../SeoSchemaTags.vue";

export default {
  name: "SimpleHeader",
  props: {
    subBrandContext: {
      type: String,
      required: false,
    },
    subBrand: {
      type: String,
      required: false,
    },
    isMobileOverlayV2Enabled: {
      type: Boolean,
      default: false,
    },
    isMobileOverlayEnabled: {
      type: Boolean,
      default: false,
    },
    showMarketingCampaignOverlay: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      required: false,
    },
  },
  components: {
    ActionLinkList,
    SearchFlyout,
    BrandLogo,
    JoinEmailCampaign,
    MobileMarketingOverlayContainer,
    MobileMarketingOverlay,
    NavMenu,
    SeoSchemaTags,
  },
  mixins: [breakPoints, resize],
  data() {
    const cookies = getConfigById("cookies", this) || {};
    const searchFlyoutConfig = getConfigById("searchFlyout", this) ?? {};
    const simpleHeaderMenuConfig =
      getConfigById("simpleHeaderMenu", this) ?? {};
    const navMenuName = simpleHeaderMenuConfig?.navMenuName ?? "Shop";
    const exploreMenuName =
      simpleHeaderMenuConfig?.exploreMenuName ?? "Explore";
    const joinEmailCampaignConfig =
      getConfigById("joinEmailCampaign", this) || {};

    return {
      cookies,
      emailOverlayCookieExpirationDays:
        joinEmailCampaignConfig?.emailOverlayCookieExpirationDays || 60,
      searchIcon:
        "<svg viewBox='0 0 21 20' role='img' version='1.1' aria-labelledby='title' class='icon-search' > <title id='title'>search</title> <desc>Click to search for products.</desc> <g stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' transform='translate(-99.000000, -120.000000)' > <g transform='translate(99.000000, 119.000000)'> <g transform='translate(0.000000, 0.681600)'> <g></g> <path d='M8.4307,1.7187 C4.5537,1.7187 1.4007,4.8717 1.4007,8.7487 C1.4007,12.6247 4.5537,15.7787 8.4307,15.7787 C12.3067,15.7787 15.4597,12.6247 15.4597,8.7487 C15.4597,4.8717 12.3067,1.7187 8.4307,1.7187 M8.4307,17.1787 C3.7827,17.1787 -0.0003,13.3977 -0.0003,8.7487 C-0.0003,4.1007 3.7827,0.3187 8.4307,0.3187 C13.0787,0.3187 16.8607,4.1007 16.8607,8.7487 C16.8607,13.3977 13.0787,17.1787 8.4307,17.1787' class='search-btn-fill-color' fill='#fff' ></path> </g> <polygon class='search-btn-fill-color' fill='#fff' points='19.793 20.6816 13.6 15.0496 14.541 14.0146 20.734 19.6466' ></polygon> </g> </g> </svg>",
      searchFlyoutEnabled: searchFlyoutConfig?.enableld ?? false,
      searchFlyoutConfig,
      displayUserName: false,
      isResponsive: false,
      hasLoaded: false,
      navMenuText: "",
      navMenuName,
      exploreMenuName,
      emailOverlayCookieName:
        cookies?.emailOverlaySuppression ||
        `ad_sess_${getBrandConcept(this).toLowerCase()}_email`,
    };
  },
  methods: {
    // shows search flyout
    handleSearchFlyout() {
      EventBus.$emit("toggleSearchFlyout");
    },
    // shows navigations menu flyout
    toggleNavFlyout(navMenuText) {
      this.navMenuText = navMenuText;
      EventBus.$emit("toggleDesktopNavMenu");
    },
    /**
     * Determine whether email overlay suppression cookie already exists
     * @returns {Boolean} True if cookie exists, false if cookie does not exist
     */
    hasEmailOverlaySuppressionCookie() {
      return !!Cookies.get(this.emailOverlayCookieName);
    },
    /**
     * Updates cookies to set email overlay suppression cookie value to true with specified expiration
     */
    setEmailOverlaySuppressionCookie() {
      if (!this.hasEmailOverlaySuppressionCookie()) {
        Cookies.set(this.emailOverlayCookieName, true, {
          expires: this.emailOverlayCookieExpirationDays,
        });
      }
    },
  },
  mounted() {
    this.hasLoaded = true;
    this.$emit("hasLoaded", this.hasLoaded);

    EventBus.$on("mediumBreakPoint", () => {
      this.isResponsive = true;
    });
    EventBus.$on("largeBreakPoint", () => {
      this.isResponsive = false;
    });
  },
};
</script>

/* eslint-disable camelcase */

import { axiosResponseErrorLogger } from "../util/errorLogger";
import { REPONAME } from "../util/constants";
import { logger } from "@js-ecom-mfe/logger";
import axios from "axios";
import {
  emptyMockData,
  mockData,
} from "./__tests__/pznTriggeredCampaigns-mock";
import { defaultCampaign } from "../helpers/pznTriggeredCampaignsHelper";
import { productGroupEndpoint } from "./const/endpoints";

const fileName = "pznTriggeredCampaigns.js";
let resolvePromise = null;
let rejectPromise = null;
let decisionPromise = null;

/**
 * Returns an array of mapped campaigns depending on the success of the Cheetah API or mock data response
 *
 * @param {Array} campaignTypes keys that represent the different types of campaigns ('sku_noc' | 'sku_bis' | 'sku_nos')
 * @param {Timer | null} decisionEventTimeout timeout that triggers the promise to reject
 * @param {Object} response campaigns response from either Cheetah API or mock data
 * @returns {Promise<Object | String>} resolves to a campaign or rejects with an error
 */
const decisionCampaignsCallback = (
  campaignTypes,
  decisionEventTimeout,
  response
) => {
  if (decisionEventTimeout) {
    clearTimeout(decisionEventTimeout);
  }

  if (response.success) {
    let campaign = defaultCampaign;
    if (response.data.items.length) {
      campaign = mapDecisionCampaigns(response.data.items, campaignTypes);
    }

    return resolvePromise(campaign);
  } else if (response.success === false) {
    return rejectPromise(response.errorMessage);
  } else {
    // if the decision event is unreachable for some reason
    return rejectPromise("Decision Event is not available");
  }
};

/**
 * Emit decision campaign event using data receive from Cheetah API
 * @param {Object} apiConfig the pzn triggered campaigns API configuration
 * @param {String} brandConcept the current brand code (MG|PB|PK|PT|WE|WS)
 * @param {String} deviceToken the user's adobe identifier token
 * @param {String} userToken the user's hashed email
 * @param {Array} campaignTypes list of configured campaign types
 */
const emitCheetahDecision = (
  apiConfig,
  brandConcept,
  deviceToken,
  userToken,
  campaignTypes
) => {
  const decisionEventTimeout = setTimeout(() => {
    return rejectPromise("Decision Event API timed out");
  }, apiConfig.timeoutSeconds * 1000);

  window[decisionCampaignsCallback.name] = decisionCampaignsCallback.bind(
    null,
    campaignTypes,
    decisionEventTimeout
  );

  const apiParams = {
    decision: "rt_decision_sku_triggers",
    business_unit: brandConcept,
  };

  apiParams.ut = userToken
    ? userToken
    : apiConfig.mockUserToken
      ? apiConfig.mockUserToken
      : null;

  if (deviceToken) {
    apiParams["dt"] = deviceToken;
  }

  window["spot_data"].push({
    event: "decision",
    params: apiParams,
    handler: decisionCampaignsCallback.name,
    apiErrorHandler: decisionCampaignsCallback.name,
  });
};

/**
 * Emit decision campaign event using mock data
 * @param {Object} apiConfig the pzn triggered campaigns API configuration
 * @param {Array} campaignTypes list of configured campaign types
 */
const emitMockDataDecision = (apiConfig, campaignTypes) => {
  const campaigns = apiConfig.useEmptyMockData ? emptyMockData : mockData;
  decisionCampaignsCallback(campaignTypes, null, campaigns);
};

/**
 * Returns campaign with product IDs from 3rd party marketing API. The goal is to wrap the 3rd party API call.
 *
 * @param {Object} apiConfig the pzn triggered campaigns API configuration
 * @param {String} brandConcept the current brand code (MG|PB|PK|PT|WE|WS)
 * @param {String} deviceToken the user's adobe identifier token
 * @param {String} userToken the user's hashed email address
 * @param {Array} campaignTypes list of configured campaign types
 * @return {Promise<Object>} product list for each campaign
 */
export const getPznTriggeredCampaigns = async (
  apiConfig,
  brandConcept,
  deviceToken,
  userToken,
  campaignTypes
) => {
  const methodName = "getPznTriggeredCampaigns";

  decisionPromise = new Promise((resolve, reject) => {
    resolvePromise = resolve;
    rejectPromise = reject;
  });

  if (window["spot_data"]) {
    emitCheetahDecision(
      apiConfig,
      brandConcept,
      deviceToken,
      userToken,
      campaignTypes
    );
    return decisionPromise;
  } else if (apiConfig.useMockData) {
    try {
      emitMockDataDecision(apiConfig, campaignTypes);
      return decisionPromise;
    } catch (error) {
      logger.error(REPONAME, fileName, methodName, error);
      return rejectPromise(error);
    }
  }
};

/**
 * Retrieve data for a single product
 *
 * FUTURE: Make a generic util to be used in pznTriggeredCampaigns.js and recentlyViewedItemsService.js
 *
 * @param {String} groupId group ID of the product (eg: calphalon-elite-nonstick-15-piece-cookware-set)
 * @returns {Object} Product group Object
 */
export const getProductGroup = async (groupId) => {
  const methodName = "getProductGroup";

  try {
    const response = await axios.get(productGroupEndpoint(groupId));
    axiosResponseErrorLogger(fileName, methodName, response);
    return response.data;
  } catch (error) {
    logger.warn(
      REPONAME,
      fileName,
      methodName,
      `Error requesting ${productGroupEndpoint(groupId)}:`,
      error
    );
    return Promise.reject(error);
  }
};

/**
 * @typedef productPromiseSettlements
 * @property {String} status fulfilled or rejected
 * @property {Object} [value] a product Object if fulfilled
 * @property {String} [reason] a error reason if rejected
 */

/**
 * Retrieve product data for an array of products
 *
 * @param {Array<String>} groupIds Array of product group-ids
 * @returns {Promise<productPromiseSettlements>} A Promise of a productPromiseSettlements Object
 */
export const getProducts = async (groupIds) => {
  const methodName = "getProducts";

  try {
    const products = groupIds.map(
      async (groupId) => await getProductGroup(groupId)
    );
    return Promise.allSettled(products);
  } catch (error) {
    logger.error(REPONAME, fileName, methodName, error);
    return Promise.reject(error);
  }
};

/**
 * @typedef Campaign
 * @property {String} id campaign type ('sku_noc' | 'sku_bis' | 'sku_nos')
 * @property {Array} products list of product groupIDs
 */
/**
 * Maps the campaigns from the decision event to the expected campaign format
 *
 * @param {Array} campaignProducts list of products for campaigns
 * @param {Array} campaignTypes keys that represent the different types of campaigns ('sku_noc' | 'sku_bis' | 'sku_nos')
 * @returns {Object} Campaign object
 */
export const mapDecisionCampaigns = (campaignProducts, validCampaignTypes) => {
  // ensure the campaign's type is valid
  const campaignType = campaignProducts[0]?.camp_type;
  if (!validCampaignTypes.includes(campaignType)) {
    return defaultCampaign;
  }

  // keep only the products that match the campaign type
  const filteredCampaignProducts = campaignProducts.filter(
    (product) => product.camp_type === campaignType
  );

  const products = getProductsByCampaignType(
    filteredCampaignProducts,
    campaignType
  );

  if (!products.length) {
    return defaultCampaign;
  }

  return {
    id: campaignType,
    products,
  };
};

/**
 * Get products for specified campaign type
 *
 * @param {Array} campaignProducts list of products
 * @param {String} campaignType the campaign type
 * @returns {Array} list of products for the campaign type
 */
export const getProductsByCampaignType = (campaignProducts, campaignType) => {
  return campaignProducts
    .filter(({ camp_type }) => camp_type === campaignType)
    .map(({ product }) => product);
};

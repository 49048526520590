const ConstructorioClient = require("@constructor-io/constructorio-client-javascript");
import { REPONAME } from "../util/constants";
import { logger } from "@js-ecom-mfe/logger";
import { getBrConfig } from "./brConfig";
import { getApplicationUri } from "../util/context";

const fileName = "vtaConstructor.js";

/**
 * Instantiate the constructor end point access object.
 * Note: Use a window object to hold the Constructor
 * object so that other user use the same instantiation.
 * @param {String} key - the id to use for the connection
 * @param {String} hashedEmail - the user's hashed email
 * @returns {Object} constructor io object
 */
const getConstructorInfo = (key, hashedEmail) => {
  if (typeof window?.WSI?.constructorIoClient !== "object") {
    window.WSI = window.WSI || {};
    window.WSI.constructorIoClient = new ConstructorioClient({
      apiKey: key,
      sendTrackingEvents: true,
      ...(hashedEmail && { userId: hashedEmail }),
    });
  }
  return window?.WSI?.constructorIoClient;
};

/**
 * Send a constructor IO call to the search engine.
 * @param {String} query the search term
 * @param {Object} constructorConfig the configuration
 * @param {String} hashedEmail - the user's hashed email
 * @param {Object} scope - the caller's scope
 * @returns {Object} the transformed data.
 */
export const fetchProductsFromConstructor = async (
  query,
  constructorConfig,
  hashedEmail,
  scope
) => {
  const brConfig = await getBrConfig(
    getApplicationUri(scope),
    scope?.catGenerationId,
    scope
  );
  const key = brConfig?.constructorKey ?? constructorConfig?.key;
  const constructorObj = getConstructorInfo(key, hashedEmail);
  const productCount = constructorConfig?.productCount || "6";
  try {
    const response = constructorObj.autocomplete.getAutocompleteResults(query, {
      resultsPerSection: {
        Products: productCount,
        "Search Suggestions": productCount,
      },
    });
    // transform the data
    return response;
  } catch (error) {
    logger.error(REPONAME, fileName, "fetchProductsFromConstructor", error);
    return Promise.reject(error);
  }
};

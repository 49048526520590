<template>
  <div
    data-style="my-store-current-store-hours"
    v-if="hasStore"
    class="my-store-time"
  >
    <span
      v-if="myStoreConfig.showStoreStatus"
      class="my-store-status"
      :class="storeStatus.class"
      >{{ storeStatus.text }}</span
    >
    <span v-if="myStoreConfig.showHours" class="my-store-hours">{{
      currentStoreHours.formattedHours
    }}</span>
  </div>
</template>

<script>
import { getConfigById } from "../../util/context";
import storeHours from "../../mixins/storeHours";

//Labels and CSS Classes for Store status
const STORE_STATUS_ENUM = Object.freeze({
  closed: {
    class: "closed",
    text: "Closed",
  },
  tomorrow: {
    class: "tomorrow",
    text: "Opens Tomorrow",
  },
  today: {
    class: "opens-today",
    text: "Opens Today",
  },
  open: {
    class: "open",
    text: "Open Today",
  },
});

export default {
  name: "my-store-current-store-hours",
  mixins: [storeHours],
  props: {
    isResponsive: {
      type: Boolean,
      default: false,
    },
    store: {
      type: Object,
      default: null,
    },
  },
  computed: {
    /**
     * Determines if a store has been selected.
     * @returns {Boolean} True if a store has been selected
     */
    hasStore() {
      return this.store && this.store !== null;
    },
    /**
     * Builds the currently selected store's hours that are shown to the user.
     * @returns {Object} Currently selected store's hours
     */
    currentStoreHours() {
      return this.hasStore
        ? this.getFormattedCurrentStoreHours(this.store)
        : null;
    },
    /**
     * Builds the wording before the store's hours based on the user's time of day and the store's hours of operation.
     * @returns {Object} Object with the store status class and text.
     */
    storeStatus() {
      let _storeStatus = {
        class: "",
        text: "",
      };
      if (this.currentStoreHours === null) {
        return _storeStatus;
      } else if (this.currentStoreHours.closed) {
        _storeStatus.class = STORE_STATUS_ENUM.closed.class;
        _storeStatus.text =
          this.myStoreConfig.closedText || STORE_STATUS_ENUM.closed.text;
      } else if (this.currentStoreHours.tomorrow) {
        _storeStatus.class = STORE_STATUS_ENUM.tomorrow.class;
        _storeStatus.text =
          this.myStoreConfig.opensTomorrowText ||
          STORE_STATUS_ENUM.tomorrow.text;
      } else if (!this.currentStoreHours.open) {
        _storeStatus.class = STORE_STATUS_ENUM.today.class;
        _storeStatus.text =
          this.myStoreConfig.opensTodayText || STORE_STATUS_ENUM.today.text;
      } else {
        _storeStatus.class = STORE_STATUS_ENUM.open.class;
        _storeStatus.text =
          this.myStoreConfig.openTodayText || STORE_STATUS_ENUM.open.text;
      }

      return _storeStatus;
    },
  },
  data() {
    const myStoreConfig = getConfigById("myStore", this) || {};

    return {
      myStoreConfig,
    };
  },
};
</script>

<template>
  <div
    data-style="email-sms-capture-campaign-container"
    data-test-id="email-sms-capture-campaign-container"
  >
    <EmailSmsCaptureFormScreen
      data-test-id="email-sms-capture-form-screen"
      :currentFormState="currentFormState"
      @submit="handleSubmit"
    />
    <EmailSmsConfirmationScreen
      data-test-id="email-sms-confirmation-screen"
      :currentFormState="currentFormState"
      @confirmationScreenClosed="handleConfirmationScreenClosed"
    />
    <EmailSmsErrorScreen
      data-test-id="email-sms-error-screen"
      :currentFormState="currentFormState"
      @errorScreenClosed="handleErrorScreenClosed"
    />
  </div>
</template>

<script>
import { addEvent } from "@js-ecom-tracking/datalayer";
import EmailSmsCaptureFormScreen from "./EmailSmsCaptureFormScreen.vue";
import EmailSmsConfirmationScreen from "./EmailSmsConfirmationScreen.vue";
import EmailSmsErrorScreen from "./EmailSmsErrorScreen.vue";
import { FORM_STATE } from "../../util/marketingOverlayFormState";
import { logger } from "@js-ecom-mfe/logger";
import { REPONAME } from "../../util/constants";
import { marketingClient } from "../../services/marketingClient";
import { mapGetters } from "vuex";

const fileName = "EmailSmsCaptureFormScreen.vue";

export default {
  name: "email-sms-capture-campaign-container",
  components: {
    EmailSmsCaptureFormScreen,
    EmailSmsConfirmationScreen,
    EmailSmsErrorScreen,
  },
  data() {
    return {
      currentFormState: FORM_STATE.UNSENT,
      analytics: {},
    };
  },
  computed: {
    ...mapGetters({
      brand: "getConcept",
      suppressionCookie: "getSuppressionCookie",
    }),
  },
  methods: {
    /**
     * handleSubmit().
     * Calls to customer-service api and emits 'formSubmitted' event'.
     * @param form data
     */
    handleSubmit(formData) {
      this.currentFormState = FORM_STATE.LOADING;
      this.$emit("formSubmitted");

      marketingClient
        .joinCampaign(formData)
        .then((response) => {
          if (response.status === 200) {
            this.currentFormState = FORM_STATE.SUCCESS;

            const responseData = response.data;
            const hashedEmail =
              responseData.indexOf("metricsEmail") !== -1
                ? responseData.substring(
                    responseData.indexOf("metricsEmail") + 35,
                    responseData.lastIndexOf("metricsEmail") - 8
                  )
                : "";
            this.analytics.addEvent({
              campaign: `${this.brand}MOBILEEMAILSIGNUP`,
              category: "Email Overlay",
              email: hashedEmail,
              item: "EMAIL SIGNUP : EMAIL FORM",
              type: "personalizedContent",
              ...(formData.phoneNumber !== "" && {
                emailNotificationType: "sms_text",
              }),
            });
          } else {
            this.currentFormState = FORM_STATE.FAILURE;
            logger.error(
              REPONAME,
              fileName,
              "handleSubmit",
              `Did not get 200 response for email signup. responseStatus = ${response.status}`
            );
          }
        })
        .catch((error) => {
          this.currentFormState = FORM_STATE.FAILURE;
          logger.error(
            REPONAME,
            fileName,
            "handleSubmit",
            `Error getting response for email signup: ${error.message}`
          );
        });
    },
    /**
     * handleConfirmationScreenClosed().
     * Emits 'confirmationScreenClosed' event.
     */
    handleConfirmationScreenClosed() {
      this.$emit("confirmationScreenClosed");
    },
    /**
     * handleErrorScreenClosed().
     * Emits 'errorScreenClosed' event.
     */
    handleErrorScreenClosed() {
      this.$emit("errorScreenClosed");
    },
  },
  mounted() {
    this.analytics.addEvent = addEvent;
  },
};
</script>

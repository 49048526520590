<template>
  <div
    data-style="my-store-weekly-store-hours"
    v-if="store && store !== null"
    class="weekly-store-hours"
  >
    <ul>
      <li
        v-for="compressedDay in weeklyStoreHours"
        :key="store.PICKUP_LOCATION_CODE + compressedDay.days + '-hours'"
      >
        {{ compressedDay.days
        }}{{ compressedDay.hours === closedValue ? "" : ":" }}
        {{ compressedDay.hours }}
      </li>
    </ul>
  </div>
</template>

<script>
import { STORE_LOCATION_CLOSED_FORMATTED } from "../../util/constants";
import storeHours from "../../mixins/storeHours";

export default {
  name: "my-store-weekly-store-hours",
  mixins: [storeHours],
  computed: {
    /**
     * Builds the weekly hours for the store that are shown to the user.
     * @returns {Object} Current store's weekly hours
     */
    weeklyStoreHours() {
      return this.store && this.store !== null
        ? this.getFormattedWeeklyStoreHours(this.store)
        : {};
    },
  },
  data() {
    return {
      closedValue: STORE_LOCATION_CLOSED_FORMATTED,
    };
  },
  props: {
    store: {
      type: Object,
      default: null,
    },
  },
};
</script>

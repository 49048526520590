import { getSubBrand, getConfigById } from "../util/context";

export default {
  methods: {
    /**
     * Retrieves subbrand query key
     * if on a subbrand, returns current sbkey from router query params
     * or urlParamValue from config
     * else will return an empty string
     */
    getSubBrandSearchQueryKeyValue() {
      let sbkey = this.$route?.query?.sbkey?.toString() ?? "";
      if (sbkey.includes(`,`)) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            sbkey: sbkey.split(`,`)[0],
          },
        });
        sbkey = sbkey.split(`,`)[0];
      }
      const currentBrandId = getSubBrand(this)?.id ?? {};
      const subBrandConfig = getConfigById("subBrands", this)?.brands ?? [];
      const subBrandKeyFromConfig =
        subBrandConfig?.find(
          (brand) => brand.id === currentBrandId && !brand.default
        )?.urlParamValue ?? "";
      return getConfigById("subBrands", this)?.subBrandSearchEnabled
        ? sbkey || subBrandKeyFromConfig || ""
        : "";
    },
  },
};

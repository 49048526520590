import Cookies from "js-cookie";
import { AODD_POSTALCODE_COOKIE, AODD_WSGEO_COOKIE } from "../util/constants";
export const getCookie = function (cookieName = "") {
  const name = cookieName + "=";
  const cookieList = decodeURIComponent(document.cookie).split(";");

  const cookieMatch =
    cookieList.find(
      (cookie) => cookie.trim().indexOf(cookieName) === 0 //Ensure we match the ID, not a substring of the value
    ) || "";

  // Will return empty string if the cookie is not found,
  // name.length + 1 as start position to get rid of leading equals character
  return cookieMatch.substring(name.length + 1, cookieMatch.length);
};

export const setCookie = function (cookieKey, cookieValue, expirationDays) {
  let expiryDate = "";

  if (expirationDays) {
    const date = new Date();

    date.setTime(date.getTime() + expirationDays * 24 * 60 * 60 * 1000);

    expiryDate = `; expiryDate=" ${date.toUTCString()}`;
  }

  document.cookie = `${cookieKey}=${cookieValue || ""}${expiryDate}; path=/`;
};

/**
 * Fetches postal code from either postal code or ws geo cookies.
 * @returns {string} The postal code extracted from the cookie.
 */
export const zipCodeDeliveryCookieCheck = function () {
  let postalCode = "";
  let cookieValue = Cookies.get(AODD_POSTALCODE_COOKIE);
  let cookieZipCode;
  /* regex check for 5 digit zip */
  const regex = new RegExp(
    "^[ABCEGHJ-NPRSTVXY]{1}\\d{1}[ABCEGHJ-NPRSTV-Z]{1}[ -]?\\d{1}[ABCEGHJ-NPRSTV-Z]{1}\\d{1}$",
    "i"
  );
  if (cookieValue) {
    // cookie value contains multiple encoded pipe-delimited items.
    // ASSUMPTION: zipcode is always in the FIRST position
    cookieZipCode = window.decodeURIComponent(cookieValue).split("|")[0];
    if (regex.test(cookieZipCode)) {
      postalCode = cookieZipCode;
    }
  } else {
    // WSGEO Cookie
    // ASSUMPTION: zipcode is always in the THIRD position
    cookieValue = Cookies.get(AODD_WSGEO_COOKIE);
    cookieZipCode = window.decodeURIComponent(cookieValue).split("|")[2];
    if (regex.test(cookieZipCode)) {
      postalCode = cookieZipCode;
    }
  }
  return postalCode;
};

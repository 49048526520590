<template>
  <div
    data-style="email-sms-loading-spinner"
    v-show="show"
    data-test-id="email-sms-loading-spinner"
    class="email-sms-spinner-bg-mask bg-shade-dark"
  >
    <div class="email-sms-spinner-wrapper">
      <div class="spinner"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  name: "email-sms-loading-spinner",
};
</script>

<template>
  <div
    data-style="email-sms-confirmation-screen"
    data-test-id="email-sms-confirmation-screen"
    v-show="currentFormState === FORM_STATE.SUCCESS"
    style="text-align: center"
  >
    <h1 class="heading-primary" data-test-id="confirmation-title">
      {{ getThanksForSingingUpText }}
    </h1>
    <p class="text-xs" data-test-id="confirmation-description">
      {{ getEmailSmsConfirmationText }}
    </p>
    <button
      class="btn-primary"
      data-test-id="confirmation-button"
      @click="handleConfirmationButtonClick"
    >
      {{ getStartShoppingText }}
    </button>
  </div>
</template>

<script>
import { FORM_STATE } from "../../util/marketingOverlayFormState";
import { mapGetters } from "vuex";

export default {
  name: "email-sms-confirmation-screen",
  props: {
    currentFormState: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      FORM_STATE: FORM_STATE,
    };
  },
  computed: {
    ...mapGetters([
      "getEmailSmsConfirmationText",
      "getStartShoppingText",
      "getThanksForSingingUpText",
    ]),
  },
  methods: {
    /**
     * handleConfirmationButtonClick().
     * Emit 'confirmationScreenClosed' event on click of 'start shopping' button
     * @returns {string}
     */
    handleConfirmationButtonClick() {
      this.$emit("confirmationScreenClosed");
    },
  },
};
</script>

import {
  browserLocalStorageAvailable,
  browserSessionStorageAvailable,
  saveItemToSessionStorage,
} from "@js-ecom-mfe/browser-storage";
import { logger } from "@js-ecom-mfe/logger";
import { addEvent } from "@js-ecom-tracking/datalayer";
import { getPopularSearches } from "../services/popularSearch";
import { fetchProductsFromBloomreach } from "../services/vta";
import { fetchProductsFromConstructor } from "../services/vtaConstructor";
import {
  RECENT_SEARCHES_TITLE,
  REPONAME,
  SEARCH_BAR_KEY,
  SEARCH_BAR_PROPERTY,
  RECENTLY_SEARCHED_MAX_SIZE,
  SEARCH_ENGINE_CONSTRUCTOR_AB_VARIANT,
  MIN_QUERY_INPUT_LIMIT,
  POPULAR_SEARCHES_MAX_RETRIES,
} from "../util/constants";
import {
  getApplicationUri,
  getBrandConcept,
  getCatGenerationId,
  getConfigById,
} from "../util/context";
import EventBus from "../util/event-bus";

import { transformConstructorResponse } from "../util/transformConstructor";

import {
  getRecentSearchesFromLocalStorage,
  removeRecentSearchesFromLocalStorage,
  saveRawInput,
  saveTypeAheadSuggestion,
} from "../util/recentSearches";
import { removeTrailingSlash } from "../util/string";
import { checkConstructorEnabled } from "../helpers/checkConstructorEnabled";
const fileName = "typeAhead.js";

export default {
  data() {
    let searchBar = getConfigById("searchBar", this);
    const constructorConfig = getConfigById("constructor", this);
    let isBRQuery = true;
    // abTest check to send request to BR or constructor
    if (searchBar?.constructor?.abTestConfig?.isEnabled) {
      isBRQuery = false;
    }
    return {
      prevQuery: "",
      prevSuggestions: [],
      prevProducts: [],
      activeSuggestion: "",
      currentSuggestion: -1, // keyword entered in the search field
      concept: "",
      hide: true,
      products: [],
      recentSearches: [],
      popularSearches: [],
      isRecentSearchesEnabled: true,
      isPopularSearchesEnabled: false,
      resultsUrl: "",
      searchUrl: "",
      scrollerIndex: 0,
      showScroller: false,
      showingRecentSearches: false,
      suggestions: [],
      topProductsTitle: "",
      catGenerationId: "",
      recentlySearchedMaxSize:
        searchBar?.recentlySearchedMaxSize ?? RECENTLY_SEARCHED_MAX_SIZE,
      searchSuggestionsTitle: "",
      recentSearchesTitle: "",
      sendRequestToBr: isBRQuery,
      constructorConfig: constructorConfig,
    };
  },
  computed: {
    canGoBackInScroller() {
      return this.scrollerIndex > 0;
    },
    canGoNextInScroller() {
      return (
        this.scrollerIndex + this.maxProductsVisible < this.products.length
      );
    },
    productsSubset() {
      return this.products.slice(
        this.scrollerIndex,
        this.scrollerIndex + this.maxProductsVisible
      );
    },
  },
  methods: {
    highlightSuggestion(direction) {
      if (
        direction === "down" &&
        this.currentSuggestion < this.suggestions.length - 1
      ) {
        this.currentSuggestion += 1;
      } else if (direction === "up" && this.currentSuggestion > -1) {
        this.currentSuggestion -= 1;
      }
      this.$nextTick(() => {
        this.activeSuggestion =
          this.suggestions[this.currentSuggestion]?.dq || "";
      });
    },
    clearSearchResults(val) {
      this.scrollerIndex = 0;
      this.hide = true;
      this.products = [];
      this.suggestions = [];
      this.currentSuggestion = -1;
      this.activeSuggestion = "";
      if (val) {
        EventBus.$emit("showBrandsListBannerHeader", true);
      }
    },
    /**
     * Send query request to the search engine to get type ahead info.
     * Using the response to populate the search suggestion and product
     * suggestions.
     * @param {Object} store - the VueX store
     * @return {Object} The suggest response.
     */
    async fetch(store) {
      let res;
      const isSendToConstructor = checkConstructorEnabled(
        store,
        SEARCH_ENGINE_CONSTRUCTOR_AB_VARIANT
      );
      const keyword = this.query.trimLeft();
      try {
        if (isSendToConstructor) {
          const urlParams = new URL(store.state.header.url).searchParams;
          const hashedEmail = urlParams.get("cm_em");
          res = await fetchProductsFromConstructor(
            keyword,
            this.constructorConfig,
            hashedEmail,
            this
          );
          res = transformConstructorResponse(
            res,
            keyword,
            this.constructorConfig
          );
        } else {
          res = await fetchProductsFromBloomreach(
            keyword,
            this.catGenerationId,
            this
          );
        }
      } catch (e) {
        logger.error(REPONAME, fileName, "clearSearchResults", e);
        res = {};
      }
      if (keyword?.length < MIN_QUERY_INPUT_LIMIT) {
        this.showRecentSearches();
        this.showRviItems();
      } else {
        this.scrollerIndex = 0;
        this.showingRecentSearches = false;
        this.setSuggestions(res);
        this.setProducts(res);
      }
    },
    hideSearchResults() {
      this.hide = true;
      this.currentSuggestion = -1;
      this.activeSuggestion = "";
    },
    /**
     * Attempts to load recent searches from local storage.
     */
    loadRecentSearchesFromLocalStorage() {
      this.recentSearches = [...getRecentSearchesFromLocalStorage()];
    },

    /**
     * Attempts to load popular searches from api.
     */
    async loadPopularSearches() {
      const popularSearchesConfig = this.topSearchVta?.popularSearches;
      if (
        this.isTopSearchVtaEnabled &&
        !(this.isRecentSearchesEnabled && this.recentSearches?.length) &&
        this.isPopularSearchesEnabled
      ) {
        this.popularSearches = await getPopularSearches(
          popularSearchesConfig?.url,
          popularSearchesConfig?.maxRetries || POPULAR_SEARCHES_MAX_RETRIES
        );
        this.recentSearchesTitle =
          this.isTopSearchVtaEnabled &&
          this.isPopularSearchesEnabled &&
          this.popularSearches?.length > 0
            ? this.topSearchVta?.popularSearches?.title
            : "";
      }
    },

    loadSearchAndProductSuggestions(newSearchQuery) {
      this.hide = false;
      if (this.prevQuery !== newSearchQuery) {
        this.prevQuery = newSearchQuery;
        this.fetch(this.$store);
      } else {
        this.scrollerIndex = 0;
        this.showingRecentSearches = false;
        this.suggestions = this.prevSuggestions;
        this.products = this.prevProducts;
      }
    },

    /**
     * Attempts to clear recent searches from local storage.
     */
    async clearRecentSearchesFromLocalStorage() {
      const isRemoved = removeRecentSearchesFromLocalStorage();
      if (isRemoved) {
        this.recentSearches = [];
        this.suggestions = [];
        this.recentSearchesTitle = "";
        await this.loadPopularSearches();
        this.showRecentSearches();
      }
    },
    /**
     * Update recent search in local storage when the search is triggered from Blank Results Page.
     * @param {Object} searchData - search information
     */
    async updateRecentSearch(searchData) {
      await saveRawInput(
        searchData.searchedKeyword,
        this.searchUrl,
        this.recentlySearchedMaxSize
      );
      this.loadRecentSearchesFromLocalStorage();
    },
    /**
     * Handler for when the user uses their own search term (raw input).
     * Will attempt to save the raw input to Local Storage, and
     * then run the analytics routine associated with typing in a raw search term.
     * @param {String} searchText - search term
     * @param {Object} event - Dom event
     */
    onRawInputHandler(searchText, event) {
      saveRawInput(searchText, this.searchUrl, this.recentlySearchedMaxSize);
      this.submitSearchAnalytics(searchText, event);
      this.hide = true;
    },
    /**
     * Handler for when the user clicks on a VTA suggested search term.
     * Will attempt to save the suggestion to Local Storage, and
     * then run the analytics routine associated with using a suggestion term.
     * @param {String} searchText - search term
     * @param {Object} event - Dom event
     */
    onSearchSuggestionHandler(searchText, event) {
      saveTypeAheadSuggestion(
        searchText,
        this.searchUrl,
        this.recentlySearchedMaxSize
      );
      this.submitSearchAnalytics(searchText, event);
      this.hide = true;
    },

    nextHandler() {
      // FUTURE: Part out the VTA Scroller functionality to its own component
      if (this.canGoNextInScroller) {
        this.scrollerIndex += 1;
      }
    },
    prevHandler() {
      if (this.canGoBackInScroller) {
        this.scrollerIndex -= 1;
      }
    },

    renderQueryKeyword(suggestion) {
      // Sets query keyword to lower case and highlights keyword matches in bold through span wrapper.
      const cleanSuggestion = suggestion.replace(/<[^>]+>/g, ""); // Remove HTML Tags that shouldn't be in the index
      const lowerCaseQuery = this.showingRecentSearches
        ? ""
        : this.query.toLowerCase();
      return cleanSuggestion.replace(
        lowerCaseQuery,
        `<span data-keyword="${lowerCaseQuery}" class="queryKeyword query-keyword">${lowerCaseQuery}</span>`
      );
    },
    responseContains(res, key) {
      return (
        res &&
        res.data &&
        res.data.response &&
        res.data.response[key] &&
        res.data.response[key].length
      );
    },
    setProducts(res) {
      if (this.responseContains(res, "products")) {
        this.products = res.data.response.products;
      } else {
        this.products = [];
      }
      this.prevProducts = this.products;
    },
    setSuggestions(res) {
      if (this.responseContains(res, "suggestions")) {
        this.suggestions = res.data.response.suggestions.slice(
          0,
          this.maxSuggestionsVisible
        );
      } else {
        this.suggestions = [];
      }
      this.prevSuggestions = this.suggestions;
    },
    /**
     * Displays the type ahead drawer and populates it with recent searches/popular searches,
     * if there are any, based on the data saved in the user's local storage.
     * if there are any, based on the data fetched from popular searches.
     * It should only open the Recent Searches/Popular searches dropdown if the user wasn't
     * already in the middle of a search.
     */
    showRecentSearches() {
      if (
        (this.isRecentSearchesEnabled && this.recentSearches?.length) ||
        this.isPopularSearchesEnabled
      ) {
        const configKey =
          this.isRecentSearchesEnabled && this.recentSearches?.length
            ? "recentSearches"
            : "popularSearches";
        if (this[configKey] && this[configKey].length) {
          let maxVisible =
            this.topSearchVta[configKey]?.maxVisible?.desktop || 6;
          let maxVisibleMobile =
            this.topSearchVta[configKey]?.maxVisible?.mobile || 3;
          const limit = this.isMobile ? maxVisibleMobile : maxVisible;
          this.suggestions = this[configKey]
            .reduce((acc, recSearch) => {
              acc.push({
                q: recSearch.keyword,
                dq: recSearch.keyword,
              });
              return acc;
            }, [])
            .slice(0, limit);
          this.showingRecentSearches = true;
          this.hide = false;
        }
      }
    },
    /**
     * Displays the type ahead drawer and populates it with rvi items,
     * if there are any, based on the data saved in the user's local storage.
     * It should only open the Recent Viewed dropdown if the user wasn't
     * already in the middle of a search.
     */
    showRviItems() {
      if (this.hide && this.showRvi) {
        this.hide = false;
        this.showingRecentSearches = true;
      }
    },
    showQueryResults(newSearchQuery = this.query) {
      this.clearSearchResults();
      newSearchQuery = newSearchQuery?.trimLeft();
      if (newSearchQuery.length === 0) {
        // if the string only has white spaces ignore it, but show RVI info
        this.showRecentSearches();
        this.showRviItems();
        return;
      }
      if (newSearchQuery.length > 2) {
        this.loadSearchAndProductSuggestions(newSearchQuery);
      } else {
        this.showRecentSearches();
        this.showRviItems();
      }
      if (newSearchQuery.length === 3 && !this.searchSubmissionSent) {
        // Search Suggestion Impression event only for the first request
        this.searchSubmissionSent = true;
        const isSendToConstructor = checkConstructorEnabled(
          this.$store,
          SEARCH_ENGINE_CONSTRUCTOR_AB_VARIANT
        );
        this.addEvent({
          category: "searchSubmission",
          item: "searchSuggestionImpression",
          searchEntry: newSearchQuery,
          suggestionAlgorithm: isSendToConstructor
            ? "constructor"
            : "bloomreach",
        });
      }
    },
    submitSearchAnalytics(textValue, event) {
      let searchType = "Search:TA-Suggestion";
      if (
        this.recentSearches.length &&
        this.showingRecentSearches &&
        this.topSearchVta?.recentSearches?.isEnabled
      ) {
        this.recentSearches.forEach((element) => {
          if (element.keyword === textValue) {
            searchType = "Search:TA-Recent";
          }
        });
      } else if (this.isPopularSearchesEnabled) {
        this.popularSearches.forEach((element) => {
          if (element.keyword === textValue) {
            searchType = "Search:TA-Popular";
          }
        });
      }
      const searchFormElements = document.querySelectorAll("#search-field");
      const searchEntry = searchFormElements?.length
        ? searchFormElements[this.isMobile ? 1 : 0].value
        : "";
      this.addEvent({
        item: "searchInitiation",
        category: "searchSubmission",
        searchEntry,
        searchTerm: textValue,
        searchType: searchType,
      });
      if (browserSessionStorageAvailable) {
        const searchInfo = {
          searchType: searchType,
          searchQuery: this.query,
        };
        saveItemToSessionStorage(
          SEARCH_BAR_KEY,
          SEARCH_BAR_PROPERTY,
          searchInfo
        );
      }
    },
    searchResultsUrl(query) {
      const path = this.resultsUrl + encodeURIComponent(query);
      const subBrandQueryKey = this.getSubBrandSearchQueryKeyValue();
      return subBrandQueryKey ? `${path}&sbkey=${subBrandQueryKey}` : path;
    },
    /**
     * @method setThumbImage
     * Gets thumb image for the product
     * @param product - product item
     * @returns {string} of product thumb image
     */
    setThumbImage(product) {
      const config = this.$store.state.header.config.vta;
      return config.allowThumbImageAttrField && product.thumb_image_attr
        ? product.thumb_image_attr
        : product.thumb_image;
    },
  },
  async mounted() {
    this.concept = getBrandConcept(this);
    const config = this.$store.state.header.config.vta;
    this.resultsUrl = config.resultsUrl;
    this.searchUrl = `${removeTrailingSlash(getApplicationUri(this))}${
      this.resultsUrl
    }`;
    this.showScroller = this.isTopSearchVtaEnabled
      ? this.topSearchVta?.productSuggestions?.showScroller || false
      : config.showScroller;
    this.topProductsTitle = this.isTopSearchVtaEnabled
      ? this.topSearchVta?.productSuggestions?.title || ""
      : config.topProductsTitle;
    this.searchSuggestionsTitle = this.isTopSearchVtaEnabled
      ? this.topSearchVta?.searchSuggestions?.title
      : "";
    this.isRecentSearchesEnabled = this.topSearchVta?.recentSearches
      ? this.topSearchVta?.recentSearches?.isEnabled
      : true;
    this.catGenerationId = getCatGenerationId(this);
    if (browserLocalStorageAvailable()) {
      this.loadRecentSearchesFromLocalStorage();
    }

    const activeTests = this.$store?.state?.header?.activeTests ?? {};
    const popularSearchesConfig = this.topSearchVta?.popularSearches;
    const popularSearchesVtaAbTestConfig =
      popularSearchesConfig?.abTestConfig || {};
    this.isPopularSearchesEnabled =
      popularSearchesConfig?.isEnabled &&
      (!popularSearchesVtaAbTestConfig?.isEnabled ||
        activeTests[popularSearchesVtaAbTestConfig?.name]?.variation ===
          popularSearchesVtaAbTestConfig?.activeVariant);
    await this.loadPopularSearches();
    this.recentSearchesTitle = this.isTopSearchVtaEnabled
      ? this.isRecentSearchesEnabled && this.recentSearches?.length
        ? this.topSearchVta?.recentSearches?.title || RECENT_SEARCHES_TITLE
        : this.isPopularSearchesEnabled && this.popularSearches?.length
          ? this.topSearchVta?.popularSearches?.title
          : ""
      : "";

    WSI.state.onChange("updateRecentSearch", (searchData) => {
      if (searchData?.searchedKeyword) {
        this.updateRecentSearch(searchData);
      }
    });
    this.addEvent = addEvent;
  },
  watch: {
    query(newSearchQuery) {
      this.showQueryResults(newSearchQuery);
    },
  },
};

import { render, staticRenderFns } from "./EmailSmsErrorScreen.vue?vue&type=template&id=60bd8125"
import script from "./EmailSmsErrorScreen.vue?vue&type=script&lang=js"
export * from "./EmailSmsErrorScreen.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('60bd8125')) {
      api.createRecord('60bd8125', component.options)
    } else {
      api.reload('60bd8125', component.options)
    }
    module.hot.accept("./EmailSmsErrorScreen.vue?vue&type=template&id=60bd8125", function () {
      api.rerender('60bd8125', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/header/EmailSmsErrorScreen.vue"
export default component.exports
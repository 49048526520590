<template>
  <div
    data-style="primary-nav-pop-out-menu"
    :class="isVerticalMenu ? 'vertical-nav' : 'pop-out-menu'"
    :data-cat-id="catId"
    @keyup.esc="closeDropdownMenu"
  >
    <template v-if="customComponent && customComponent !== ''">
      <component :is="customComponent"></component>
    </template>

    <template v-else-if="sortedCustomData && sortedCustomData.length">
      <div class="col" v-for="(column, index) in sortedCustomData" :key="index">
        <template v-for="section in column">
          <h3 v-if="section.path" :key="section.name + '-header'">
            <a :href="getNavLink(section)" v-html="section.name"></a>
          </h3>
          <h3
            v-else-if="(section.path === '' || !section.path) && section.link"
            class="header-link"
            :key="section.name + '-header'"
          >
            <a
              :href="getSectionLink(section.link)"
              class="no-header-parent"
              v-html="section.name"
            ></a>
          </h3>
          <h3
            v-else
            v-html="section.name"
            :key="section.name + '-header'"
            :class="{
              open:
                navHeaderActive === `${section.name}-header` &&
                navHeaderActiveState,
            }"
            @click="toggleSubNav(`${section.name}-header`)"
            :ref="`navHeaderItem-${section.name}-header`"
            @keyup.enter="toggleSubNav(`${section.name}-header`)"
            :role="isResponsive ? 'button' : ''"
            :tabindex="isResponsive ? '0' : ''"
            :aria-expanded="mobileAccordionMenuExpand(`${section.name}-header`)"
          ></h3>
          <ul v-if="section.items" :key="section.name + '-links'">
            <li v-for="(navItem, navIdx) in section.items" :key="navIdx">
              <a
                v-if="isBrandList || navItem.isExternal"
                :href="navItem.link"
                v-html="navItem.name"
                target="_blank"
                rel="nofollow"
                class="nav-external"
              ></a>
              <a v-else :href="getNavLink(navItem)" v-html="navItem.name"></a>
            </li>
          </ul>
        </template>
      </div>
    </template>
    <template v-else-if="displayLinksubcatPopout">
      <div
        class="col"
        v-if="columns && columns.A && columns.A.length"
        :data-params="linkParams"
        @click.stop="storeLinkClickInfo($event)"
      >
        <ul>
          <li v-for="linksubcat in columns.A" :key="linksubcat.id + '-header'">
            <a :href="getNavLink(linksubcat)" v-html="linksubcat.name" />
          </li>
        </ul>
      </div>
    </template>
    <template v-else-if="Object.keys(columns).length > 0">
      <div
        :key="column.id"
        v-for="(column, key) in columns"
        class="col"
        :class="key"
        :data-params="linkParams"
        @click.stop="storeLinkClickInfo($event)"
      >
        <template v-for="navHeader in column">
          <!-- Support hardcoded sibling links inside Supercat accordions for Mobile. -->
          <!-- FUTURE: This is a *temporary* fix in order to support RM-21627 -->
          <template
            v-if="
              isResponsive &&
              navHeader.path &&
              mobileCatSiblingLinks &&
              mobileCatSiblingLinks[navHeader.path] &&
              mobileCatSiblingLinks[navHeader.path].length
            "
          >
            <a
              v-for="(siblingLink, index) in mobileCatSiblingLinks[
                navHeader.path
              ]"
              class="sibling-link"
              :key="`${siblingLink.text}-${index}`"
              :href="getNavLink(siblingLink)"
              v-html="siblingLink.text"
            />
          </template>
          <h3
            v-if="showClickableCategoryHeader(navHeader)"
            :key="navHeader.id + '-header'"
            :data-path="navHeader.path"
            class="clickable"
            :class="{
              open: navHeaderActive === navHeader.id && navHeaderActiveState,
              'cch-mobile-nav': cchMobileNav && !cchDisplayNewArrow,
            }"
            @click="toggleSubNav(navHeader.id)"
            :ref="`navHeaderItem-${navHeader.id}`"
            @keyup.enter="toggleSubNav(navHeader.id)"
            :role="isResponsive ? 'button' : ''"
            :tabindex="isResponsive ? '0' : ''"
            :aria-expanded="mobileAccordionMenuExpand(navHeader.id)"
          >
            <a
              :href="getNavLink(navHeader)"
              class="clickable-category-header-link"
              @click="clearSelectedFacetsFromSession($event, navHeader)"
              v-html="`${navHeader.name}&nbsp;`"
              :tabindex="isResponsive ? '-1' : ''"
            ></a>
            <a
              v-if="isResponsive && !cchDisplayNewArrow"
              :href="getNavLink(navHeader)"
              :class="`clickable-category-header-link ${
                cchMobileNav && !cchDisplayNewArrow ? 'cch-mobile-nav' : ''
              }`"
              @click.stop
              :tabindex="isResponsive ? '-1' : ''"
            >
            </a>
            <a
              v-if="isResponsive && cchMobileNav && cchDisplayNewArrow"
              @click="openCchHeaderLink(navHeader)"
              @click.prevent="toggleSubNav(navHeader.id)"
              :class="`clickable-category-header-link ${
                navHeaderActive === navHeader.id && navHeaderActiveState
                  ? 'align-new-cch-arrow'
                  : 'cch-new-arrow'
              }`"
            >
              <SVGIcon :icon="'cch-chevron-right'"></SVGIcon>
            </a>
          </h3>
          <h3
            v-else
            :key="navHeader.id + '-header'"
            :data-path="navHeader.path"
            :class="{
              open: navHeaderActive === navHeader.id && navHeaderActiveState,
            }"
            @click="toggleSubNav(navHeader.id)"
            :ref="`navHeaderItem-${navHeader.id}`"
            v-html="navHeader.name"
            @keyup.enter="toggleSubNav(navHeader.id)"
            :tabindex="isResponsive ? '0' : ''"
            :role="isResponsive ? 'button' : ''"
            :aria-expanded="mobileAccordionMenuExpand(navHeader.id)"
          ></h3>
          <!-- Conditionally render visual nav images on Col A based on config -->
          <ul
            :key="navHeader.id + '-links'"
            :class="{ 'visual-nav-category': navHeader.hasVisualNavCategory }"
          >
            <template v-for="navItem in showHideNavlinks(navHeader.navLinks)">
              <li
                :key="navItem.id"
                :data-nav-type="navItem.type"
                v-if="removeCchRefCategoryFromMenu(navItem)"
              ></li>
              <li v-else :data-nav-type="navItem.type" :key="navItem.id">
                <a
                  v-if="
                    navItem.imagePath &&
                    showSubcatImgs &&
                    colsWithHasVisNavCatTrue.includes(navHeader.displayColumn)
                  "
                  @click.prevent="openNavLinks(navItem)"
                  :href="getNavLink(navItem)"
                >
                  <img
                    :src="getWcmImageFullPath(navItem.imagePath)"
                    :alt="navItem.name"
                  />
                  <span v-html="navItem.name" />
                </a>
                <a
                  v-else
                  v-html="navItem.name"
                  @click.prevent="openNavLinks(navItem)"
                  :href="getNavLink(navItem)"
                />
              </li>
            </template>
          </ul>
        </template>
      </div>
    </template>
    <template v-else>
      <div
        class="col"
        :data-params="linkParams"
        @click.stop="storeLinkClickInfo($event)"
      >
        <ul class="non-header-supercat-links">
          <li v-for="(link, idx) in nonHeaderSuperCatLinks" :key="idx">
            <a :href="getNavLink(link)" v-html="getSubCatText(link, idx)" />
          </li>
        </ul>
      </div>
    </template>
    <div class="spotlight" v-if="showSpotlight && hasSpotlight">
      <PersonalizedPlacement
        :placementId="'MMS-' + concept + '-SPOT-' + catId"
      />
      <EcmContentSlot
        v-if="!ecmPromos.length && (catId || spotlightId)"
        :contentLocation="'spotlight-' + (spotlightId || catId)"
      />
      <div class="figures" v-if="ecmPromos.length > 0">
        <!-- spotlight for secondary nav -->
        <!-- eslint-disable-next-line vue/require-v-for-key -->
        <figure v-for="ecmPromo in ecmPromos">
          <EcmContentSlot :contentLocation="'spotlight-' + ecmPromo" />
        </figure>
      </div>
    </div>
  </div>
</template>

<script>
import { getCategoryDataFromPath } from "../../util/categoryData";
import { getBrandConcept, getConfigById } from "../../util/context";
import { convertFirstCharToInt } from "../../util/string";
import breakPoints from "../../mixins/breakPoints";
import PersonalizedPlacement from "../../components/PersonalizedPlacement.vue";
import EcmContentSlot from "../EcmContentSlot.vue";
import {
  browserSessionStorageAvailable,
  saveItemToSessionStorage,
} from "@js-ecom-mfe/browser-storage";
import {
  SELECTED_FACETS,
  MOBILE_NAVIGATION,
  DESKTOP_NAVIGATION,
} from "../../util/constants";
const SVGIcon = () =>
  import(/* webpackChunkName: "header-footer-svg-icon" */ "../SVGIcon.vue");
import dropdownMenu from "../../mixins/dropdownMenu";
import {
  storeLinkClickInfo,
  createDataParamsAttr,
} from "../../common/linkUtilities";

const SHOW_NAV_ITEM_DEFAULT = true;

export default {
  name: "primary-nav-pop-out-menu",
  mixins: [breakPoints, dropdownMenu],
  components: {
    PersonalizedPlacement,
    EcmContentSlot,
    SVGIcon,
  },
  computed: {
    showHideNavlinks() {
      const displayLocation = this.isMobile
        ? MOBILE_NAVIGATION
        : DESKTOP_NAVIGATION;
      return (navlinks) => {
        const filteredNavlinks = navlinks.filter(({ displayLocations }) => {
          return displayLocations
            ? displayLocations.includes(displayLocation)
            : true;
        });
        return filteredNavlinks;
      };
    },
    catId() {
      if (!this.catData || !this.catData.id) {
        return "";
      }
      return this.catData.heroLink
        ? this.catData.heroLink.split("/").slice(-1)[0]
        : this.catData.id;
    },
    linkParams() {
      let paramObj = { cm_type: "gnav" };
      if (this.catData?.id) {
        paramObj.originsc = this.catData.id;
      }
      const tabNavSegments = this.tabNavParam.split("=");
      if (tabNavSegments.length > 1) {
        paramObj.tabnav = tabNavSegments[1];
      }
      return createDataParamsAttr(paramObj, this.linkParametersConfig);
    },
  },
  data() {
    const cchConfig = getConfigById("clickableCategoryHeaders", this) ?? {};
    const { isAbTestEnabled, ABTest, cchVariation, cchRefCatMap } =
      cchConfig?.cchNavigationTest ?? {};
    const cchABTest = this.$store?.state?.header?.activeTests[ABTest] ?? {};
    const isCchVariation = cchABTest && cchABTest.variation === cchVariation;
    const linkParametersConfig = getConfigById("linkParameters", this) ?? {};
    const parametersToRemove = linkParametersConfig?.parametersToRemove || [];

    return {
      concept: "",
      columns: {},
      displayLinksubcatPopout: false,
      sortedCustomData: [],
      navHeaderActive: "",
      navHeaderActiveState: false,
      nonHeaderSuperCatLinks: [],
      wcmImagePath: this.$store?.state?.header?.assetUris?.images || "",
      clickableCategoryHeaderConfig: cchConfig,
      brandHostName: getConfigById("hostName", this) || "",
      navLinksPathInNewTab: getConfigById("navLinksPathInNewTab", this) || "",
      colsWithHasVisNavCatTrue: [],
      showSpotlight: true,
      cchMobileNav: cchConfig?.cchMobileNav ?? false,
      cchDisplayNewArrow: cchConfig?.cchDisplayNewArrow ?? false,
      isAbTestEnabled: isAbTestEnabled ?? false,
      ABTest: ABTest ?? "headerCCHNavigation",
      cchVariation: cchVariation ?? "cchON",
      cchRefCatMap: cchRefCatMap ?? [],
      cchABTest,
      isCchVariation,
      isPrimaryNavSSREnabled:
        this.$store?.state?.header?.config?.isPrimaryNavSSREnabled ?? false,
      isPrimaryNavSSREnabledNonBot:
        this.$store?.state?.header?.config?.isPrimaryNavSSREnabledNonBot ??
        false,
      isSecondaryNavSSREnabled:
        this.$store?.state?.header?.config?.isSecondaryNavSSREnabled ?? false,
      isSecondaryNavSSREnabledNonBot:
        this.$store?.state?.header?.config?.isSecondaryNavSSREnabledNonBot ??
        false,
      isSeoBot: this.$store?.state?.header?.activeProperties?.isSeoBot === "1",
      ssrCategoryData:
        this.$store?.state?.header?.config?.primaryNavBar?.catData
          ?.categories ?? [],
      linkParametersConfig,
      parametersToRemove,
    };
  },
  methods: {
    /**
     * Checks if the clickable categories headers config is enabled.
     * @param {object} navHeader category header object that contains header details
     * @returns {boolean} true or false depending on the config value and the path on the header
     */
    showClickableCategoryHeader(navHeader) {
      let isCch =
        this.clickableCategoryHeaderConfig?.enabled &&
        navHeader?.path &&
        navHeader?.type === "header" &&
        navHeader?.template === "clickableHeader";

      // ignore AB test when configs are disabled
      if (!isCch || !this.isAbTestEnabled) {
        return isCch;
      }
      if (this.isAbTestEnabled && this.isCchVariation) {
        isCch = true;
      } else {
        isCch = false;
      }
      return isCch;
    },
    stopLink(event) {
      event.preventDefault();
    },
    /**
     * Checks if the navItem represents 'refCat' of a CCH header and that it should be hidden.
     * @returns {boolean} true or false depending on the config value and the path on the header. If 'true' menu item should be removed.
     */
    removeCchRefCategoryFromMenu(navItem) {
      const refCategoriesList = this.cchRefCatMap;
      const isShopPathOnRefCategoriesList =
        refCategoriesList.indexOf(`/${navItem?.path}/`) !== -1;

      return (
        this.clickableCategoryHeaderConfig?.enabled &&
        this.isAbTestEnabled &&
        this.isCchVariation &&
        isShopPathOnRefCategoriesList
      );
    },
    /**
     * Checks if all items within a category is of type 'linksubcat'.
     * Special use case for PK Gifting where the popout menu contains only links without any headers.
     */
    isLinksubcatPopout(categories) {
      return categories
        ? categories.every((category) => category.type === "linksubcat")
        : false;
    },
    /**
     * Organize Nav Items into its respective Nav Header, then each Nav Header into its respective displayColumn
     * so it can be rendered iteratively through Vue
     * @param {object} catDataToOrganize Cat data object to organize into an array of Headers
     * @return {object} Object of Headers containing Nav Items organized by displayColumns
     */
    organizeNavLinksByColumns(catDataToOrganize) {
      if (!catDataToOrganize || !catDataToOrganize.categories) {
        return [];
      } else if (this.displayLinksubcatPopout) {
        return {
          A: [...catDataToOrganize.categories],
        };
      } else {
        let currHeader = "";
        let organizedNavHeaders = catDataToOrganize.categories.reduce(
          (acc, navItem) => {
            if (navItem.type === "header" || navItem.type === "leftheader") {
              const headerId = navItem.path.replace("shop/", ""); // remove 'shop/' from path if it exists
              currHeader = headerId;
              acc[currHeader] = {
                id: navItem.id,
                name: navItem.name,
                path: navItem.path,
                navLinks: [],
                displayColumn: navItem.displayColumn,
                type: navItem.type,
                hasVisualNavCategory: !!navItem?.hasVisualNavCategory,
                template: navItem?.template || "",
              };
            } else {
              if (
                acc[currHeader] &&
                acc[currHeader].navLinks &&
                !navItem.secret &&
                this.shouldShowNavItemBasedOnConfig(navItem)
              ) {
                acc[currHeader].navLinks.push(navItem);
              }
            }
            return acc;
          },
          {}
        );

        // Purge any `leftheader` nav headers from organizedNavHeaders nodetree.
        // Should be done by default unless overridden by a config because
        // `leftheader`s were only meant to be shown in the Left Nav for Shop pages.
        if (!this.showLeftNavHeaders) {
          organizedNavHeaders = Object.keys(organizedNavHeaders).reduce(
            (acc, key) => {
              if (organizedNavHeaders[key].type !== "leftheader") {
                acc[key] = organizedNavHeaders[key];
              }
              return acc;
            },
            {}
          );
        }

        // Organize each navigation header into its respective Display Column.
        const organizedDisplayColumns = {};
        // eslint-disable-next-line guard-for-in
        for (const key in organizedNavHeaders) {
          const navHeader = organizedNavHeaders[key];
          const displayColumn = navHeader.displayColumn;
          if (!organizedDisplayColumns[displayColumn]) {
            organizedDisplayColumns[displayColumn] = [];
          }
          organizedDisplayColumns[displayColumn].push(navHeader);
        }

        // Sort Display columns -
        // With ES2015, the insertion order is preserved so an object like
        // {b: "bar", a: "foo", c: "baz"} will not automatically evaluate as
        // {a: "foo", b: "bar", c: "baz"} by interpreters. This can create a sorting issue when
        // displayColumns are not returned in exact order by the WCM categoryTree endpoint.
        // Resort the sortedNavHeaders based on their displayColumn values so the columns
        // are in the right order.
        const sortedNavHeaders = Object.keys(organizedDisplayColumns)
          .sort((a, b) => convertFirstCharToInt(a) - convertFirstCharToInt(b))
          .reduce((acc, navColumn) => {
            if (organizedDisplayColumns[navColumn]) {
              acc[navColumn] = [...organizedDisplayColumns[navColumn]];
            }
            return acc;
          }, {});
        return sortedNavHeaders;
      }
    },
    async organizeCustomCategoriesByColumn(customData) {
      if (
        !customData ||
        !Array.isArray(customData) ||
        customData.length === 0
      ) {
        return [];
      }
      const generateCustomCategoryContainer = (customData) => {
        const numDisplayColumns = customData.reduce((numColumns, cat) => {
          const displayColumn = cat.displayColumn
            ? convertFirstCharToInt(cat.displayColumn)
            : 0;
          return displayColumn > numColumns ? displayColumn : numColumns;
        }, 0);
        return Array(numDisplayColumns + 1)
          .fill()
          .map(() => []);
      };
      let categoryData;

      typeof window !== "undefined"
        ? (categoryData = await window.catInfoLoaded)
        : (categoryData = this.ssrCategoryData);

      const customDataContainer = generateCustomCategoryContainer(customData);
      customData.forEach((cat) => {
        if (!cat.items && cat.path) {
          // No custom categories specified. Pull data from catTree if available
          const catData = getCategoryDataFromPath(categoryData, cat.path);
          if (catData?.categories) {
            cat.items = catData.categories.filter(
              (catDataCategory) =>
                catDataCategory.type === "subcat" && !catDataCategory.secret
            );
          }
        }
        if (cat.displayColumn) {
          const customDataContainerIndex = convertFirstCharToInt(
            cat.displayColumn
          );
          customDataContainer[customDataContainerIndex].push(cat);
        } else {
          customDataContainer.push([cat]);
        }
      });
      return customDataContainer.filter(
        (dataContainer) => dataContainer.length
      );
    },
    toggleSubNav(navId) {
      this.navHeaderActiveState =
        this.navHeaderActive === navId ? !this.navHeaderActiveState : true;
      this.navHeaderActive = navId;
      /**
       * On clicking the navHeader, when it got scrolled beyond the view port,
       * then this will make sure that the opened navitem is at the top of the mobileNavigation
       */
      this.$nextTick(() => {
        let navHeaderItem = this.$refs[`navHeaderItem-${navId}`]?.[0];
        if (navHeaderItem) {
          const nav_item_offset = navHeaderItem.getBoundingClientRect().top;
          const menu_offset = navHeaderItem
            .closest(".mobileNavigation")
            .getBoundingClientRect().top;
          if (nav_item_offset < menu_offset) {
            navHeaderItem.closest(".mobileNavigation").scrollTop =
              navHeaderItem.offsetParent.offsetTop + navHeaderItem.offsetTop;
          }
        }
      });
    },
    /**
     * Get links for supercat which is not type 'header'
     * @param {object} catObj object to filter linkcat, subcat type
     * @return array of links
     */
    getNonHeaderSuperCatMenuLinks(catObj) {
      return catObj && catObj?.categories
        ? catObj.categories.filter(
            (link) =>
              link.type === "linkcat" ||
              (link.type === "subcat" && !link.secret)
          )
        : [];
    },
    /**
     * query param added to the secondary nav link
     * @param {String} navLink secondary nav link
     * @return {String} navlink with query param "originsc"
     */
    getSectionLink(navLink) {
      let origin = this.$store.state.header?.applicationUri;
      const url = new URL(navLink, origin);
      const searchParams = url.searchParams;
      // eslint-disable-next-line eqeqeq
      if (this.navLinkCatId != null) {
        searchParams.append("originsc", `${this.navLinkCatId}`);
      }
      url.search = searchParams.toString();
      const newUrl = url.toString();
      origin = origin.endsWith("/") ? origin.slice(0, -1) : origin;
      return url.origin === origin ? `${url.pathname}${url.search}` : newUrl;
    },
    /**
     * Checks whether the URL is for same site as current page.
     * @param navLink URL to check
     * @returns {*|boolean} Return true if navLink URL is for same site as current page.
     */
    isCrossBrandLink(navLink) {
      return (
        navLink.startsWith("http") &&
        !navLink.includes(this.$store.state.header?.applicationUri)
      );
    },
    getNavLink(link) {
      let navLink = link.heroLink || `/${link.path}`;
      let globalNav = "";
      // Initialize the link by clearing certain params
      const paramsToClear = ["cm_type", "originsc", "tabnav"];
      paramsToClear.forEach((paramName) => {
        const match = navLink.match(new RegExp(paramName + "=[^&]*"));
        if (match) {
          navLink = navLink.replace(match[0] + "&", "");
          navLink = navLink.replace(match[0], "");
        }
      });
      navLink = navLink.endsWith("&") ? navLink.slice(0, -1) : navLink;
      navLink = navLink.endsWith("?") ? navLink.slice(0, -1) : navLink;
      // Now add only needed params
      if (
        !this.parametersToRemove.includes("cm_type") ||
        this.isCrossBrandLink(navLink)
      ) {
        globalNav =
          navLink.includes("#") || navLink.includes("?")
            ? this.navLinkCatId !== null &&
              (!this.parametersToRemove.includes("originsc") ||
                this.isCrossBrandLink(navLink))
              ? `&cm_type=gnav&originsc=${
                  this.navLinkCatId || this.catData?.id
                }`
              : `&cm_type=gnav`
            : this.navLinkCatId !== null &&
                (!this.parametersToRemove.includes("originsc") ||
                  this.isCrossBrandLink(navLink))
              ? `?cm_type=gnav&originsc=${
                  this.navLinkCatId || this.catData?.id
                }`
              : `?cm_type=gnav`;
      } else if (
        !this.parametersToRemove.includes("originsc") ||
        this.isCrossBrandLink(navLink)
      ) {
        if (navLink.includes("#") || navLink.includes("?")) {
          globalNav = this.catData?.id ? `&originsc=${this.catData.id}` : "";
        } else {
          globalNav = this.catData?.id ? `?originsc=${this.catData.id}` : "";
        }
      }
      globalNav =
        navLink.endsWith(".html") ||
        navLink.endsWith("/") ||
        navLink.includes("#") ||
        navLink.includes("?")
          ? globalNav
          : `/${globalNav}`;
      navLink = `${navLink}${globalNav}`;
      navLink =
        !this.parametersToRemove.includes("tabnav") ||
        this.isCrossBrandLink(navLink)
          ? `${navLink}${this.tabNavParam}`
          : navLink;
      return navLink;
    },
    /**
     * Determines if a navItem should be shown based on the hasGlobalLink flag
     * @param {Object} navItem Navigation Item from categoryTree response
     * @returns {Boolean} True if navItem should be displayed
     */
    shouldShowNavItemBasedOnConfig(navItem) {
      if (this.showLinksIfHasGlobalOrNavLink) {
        return navItem.hasGlobalLink || navItem.hasBodyLink;
      }
      return SHOW_NAV_ITEM_DEFAULT;
    },
    /**
     * Conditional logic to display spotlight promos
     */
    shouldShowSpotlight() {
      return (
        !this.isMobile ||
        this.$store.state.header.config.showSpotlightsOnMobile ||
        false
      );
    },
    /**
     * Get imagePath for WCM images
     * @param {String} image path from navItem
     * @returns {String} full imagePath with suffix
     */
    getWcmImageFullPath(path) {
      return `${this.wcmImagePath}${path}t.jpg`;
    },
    /**
     * Determines if a nav link should open in the same window or new
     * @param {Object} navItem Navigation Item from categoryTree response
     */
    openNavLinks(navItem) {
      const heroLink = this.getNavLink(navItem) || "";
      if (
        !heroLink.includes(this.brandHostName) &&
        (heroLink.startsWith("http") ||
          (this.navLinksPathInNewTab &&
            heroLink.includes(this.navLinksPathInNewTab)))
      ) {
        window.open(heroLink, "_blank");
        document.location.reload(true);
      } else {
        window.open(heroLink, "_self");
      }
    },
    /**
     * Get array of columns names with hasVisNavCategory set to true
     * @param {object} column data corresponding to supercat menu data
     * @return array of column names
     */
    getColumnsWithVisualNavCategory(columns) {
      let colsHasVNCatTrue = [];
      Object.values(columns).forEach((column) => {
        column.forEach((navHeader) => {
          if (
            navHeader.hasVisualNavCategory &&
            !colsHasVNCatTrue.includes(navHeader.displayColumn)
          ) {
            colsHasVNCatTrue = [...colsHasVNCatTrue, navHeader.displayColumn];
          }
        });
      });
      return colsHasVNCatTrue;
    },
    /**
     * Clears facets selected on clickable category header page when navigating from gobal nav menu
     */
    clearSelectedFacetsFromSession(event, navHeader) {
      if (this.isResponsive) {
        this.stopLink(event);
      }
      if (browserSessionStorageAvailable()) {
        saveItemToSessionStorage(SELECTED_FACETS, `/${navHeader?.path}/`, {
          selectedFilters: [],
          selections: [],
        });
      }
    },
    /**
     * Open a clickable header nav link in the same window
     * @param {Object} navHeader Navigation Item from categoryTree response
     */
    openCchHeaderLink(navHeader) {
      const headerLink = this.getNavLink(navHeader) || "";
      if (headerLink !== "") {
        window.open(headerLink, "_self");
      }
    },
    /**
     * To check accordion menu expanded state for the accessibility with keyboard users
     * @param {String} menuItemLink Navigation item from categoryTree response
     * @return {String} value with true/false
     */
    mobileAccordionMenuExpand(menuItemLink) {
      const isMenuExpand =
        this.isResponsive &&
        this.navHeaderActive === menuItemLink &&
        this.navHeaderActiveState
          ? "true"
          : "false";
      return isMenuExpand;
    },

    /**
     * To add sequence number before the subcat text
     * @param {String} link subcat item from
     * @param {Int} index of the items
     * @return {String} with subcat link text to display
     */
    getSubCatText(link, index) {
      if (link.id !== "viewall" && this.addNumbering) {
        if (index < 10) {
          return `0${index}. ${link.name}`;
        }
        return `${index}. ${link.name}`;
      }
      return link.name;
    },
    storeLinkClickInfo,
  },
  props: {
    catData: {
      type: Object,
      default() {
        return {};
      },
    },
    navLinkCatId: {
      type: String,
      default: "",
    },
    customComponent: {
      type: String,
      default: "",
    },
    customData: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: Array | Object,
      default() {
        return [];
      },
    },
    hasSpotlight: {
      type: Boolean,
      default: false,
    },
    mobileCatSiblingLinks: {
      // FUTURE: To be deprecated, currently to support RM-21627
      type: Object,
      default() {
        return {};
      },
    },
    spotlightId: {
      type: String,
      default: "",
    },
    isBrandList: {
      type: Boolean,
      default: false,
    },
    isResponsive: {
      type: Boolean,
      default: false,
    },
    ecmPromos: {
      type: Array,
      default: () => [],
    },
    showLeftNavHeaders: {
      type: Boolean,
      default: false,
    },
    showLinksIfHasGlobalOrNavLink: {
      type: Boolean,
      default: true,
    },
    showSubcatImgs: {
      type: Boolean,
      default: false,
    },
    isVerticalMenu: {
      type: Boolean,
      default: false,
      required: false,
    },
    addNumbering: {
      type: Boolean,
      default: false,
      required: false,
    },
    tabNavParam: {
      type: String,
      default: "",
    },
  },
  async serverPrefetch() {
    if (
      (this.isSecondaryNavSSREnabled && this.isSeoBot) ||
      (this.isSecondaryNavSSREnabledNonBot && !this.isSeoBot)
    ) {
      await this.organizeCustomCategoriesByColumn(this.customData).then(
        (res) => {
          this.sortedCustomData = res;
        }
      );
    }
  },
  created() {
    if (
      (this.isPrimaryNavSSREnabled && this.isSeoBot) ||
      (this.isPrimaryNavSSREnabledNonBot && !this.isSeoBot)
    ) {
      this.concept = getBrandConcept(this);
      this.displayLinksubcatPopout = this.isLinksubcatPopout(
        this.catData?.categories || []
      );
      this.columns = this.organizeNavLinksByColumns(this.catData);
      this.colsWithHasVisNavCatTrue = this.getColumnsWithVisualNavCategory(
        this.columns
      );
      this.nonHeaderSuperCatLinks = this.getNonHeaderSuperCatMenuLinks(
        this.catData
      );
    }
  },
  mounted() {
    this.concept = getBrandConcept(this);
    this.displayLinksubcatPopout = this.isLinksubcatPopout(
      this.catData?.categories || []
    );
    this.columns = this.organizeNavLinksByColumns(this.catData);
    this.colsWithHasVisNavCatTrue = this.getColumnsWithVisualNavCategory(
      this.columns
    );
    this.nonHeaderSuperCatLinks = this.getNonHeaderSuperCatMenuLinks(
      this.catData
    );
    this.organizeCustomCategoriesByColumn(this.customData).then((res) => {
      this.sortedCustomData = res;
    });
    window.addEventListener("resize", this.shouldShowSpotlight);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.shouldShowSpotlight);
  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{class:_vm.className,attrs:{"data-style":"simple-header","id":_vm.subBrandContext,"data-component":"Global-Header","data-test-id":"Global-header"}},[_c('SeoSchemaTags',{attrs:{"data-test-id":"seo-schema-component"}}),_vm._v(" "),_c('div',{ref:"headerMain",staticClass:"sticky-header simple-sticky-header-HOD"},[_c('header',{staticClass:"main-container HOM simple-header",attrs:{"role":"presentation"}},[_c('div',{staticClass:"my-store-position"},[_c('section',{attrs:{"id":"sub-brand-bar"}},[_c('button',{staticClass:"search-icon",attrs:{"aria-label":"search"},on:{"click":function($event){return _vm.handleSearchFlyout()}}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.searchIcon)}})]),_vm._v(" "),_c('div',{staticClass:"separated-lists"},[_c('ActionLinkList',{attrs:{"subBrand":_vm.subBrand,"data-testid":"Global-action-link-list","displayUserName":_vm.displayUserName}})],1)]),_vm._v(" "),(_vm.hasLoaded)?[_c('NavMenu',{attrs:{"isResponsive":_vm.isResponsive,"navMenuText":_vm.navMenuText}}),_vm._v(" "),_c('SearchFlyout',{attrs:{"flyoutConfig":_vm.searchFlyoutConfig}})]:_vm._e()],2),_vm._v(" "),(!_vm.isResponsive)?_c('BrandLogo'):_vm._e(),_vm._v(" "),_c('section',{attrs:{"id":"header-search-form"}},[_c('span',{staticClass:"nav-menu-title"},[_c('button',{staticClass:"search-icon nav-flyout",attrs:{"aria-label":"Shop Nav"},on:{"click":function($event){return _vm.toggleNavFlyout('primary')}}},[_vm._v("\n            "+_vm._s(_vm.navMenuName)+"\n          ")]),_vm._v(" "),_c('button',{staticClass:"search-icon explore-flyout",attrs:{"aria-label":"Explore"},on:{"click":function($event){return _vm.toggleNavFlyout('secondary')}}},[_vm._v("\n            "+_vm._s(_vm.exploreMenuName)+"\n          ")])])])],1),_vm._v(" "),_vm._t("simpleMobileHeader")],2),_vm._v(" "),[(
        this.isMobile &&
        _vm.showMarketingCampaignOverlay &&
        _vm.isMobileOverlayV2Enabled
      )?[_c('MobileMarketingOverlayContainer')]:(
        this.isMobile &&
        _vm.showMarketingCampaignOverlay &&
        _vm.isMobileOverlayEnabled
      )?[_c('MobileMarketingOverlay')]:(_vm.hasLoaded)?[_c('JoinEmailCampaign',{attrs:{"isMobileActive":_vm.isMobile,"showMarketingCampaignOverlay":_vm.showMarketingCampaignOverlay}})]:_vm._e()]],2)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
<template>
  <div data-style="mobile-bottom-links">
    <ul
      id="bottomNav"
      v-if="bottomLinks && bottomLinks.length > 0"
      data-component="Global-header-MobileBottomLinks"
    >
      <li
        v-for="(bottomLink, index) in displayedBottomLinks"
        :key="index"
        :class="bottomLink.classname"
      >
        <a
          v-if="bottomLink.text.includes('Key Rewards')"
          :href="updateKeyRewards(bottomLink)"
          @click="bottomLink.isFeedback ? launchOO($event) : null"
          :class="{ 'opinionlab-feedback-link': bottomLink.isFeedback }"
        >
          <span v-if="bottomLink.SVGIcon">
            <SVGIcon :icon="bottomLink.SVGIcon"></SVGIcon>
          </span>
          <span v-if="bottomLink.isFeedback">[+]</span>
          {{ bottomLink.text }}
        </a>
        <a
          v-else
          :href="bottomLink.link"
          @click="bottomLink.isFeedback ? launchOO($event) : null"
          :class="[getBottomClass(bottomLink.isFeedback, bottomLink.linkClass)]"
          :target="bottomLink.target ? bottomLink.target : '_self'"
        >
          <span v-if="bottomLink.SVGIcon">
            <SVGIcon :icon="bottomLink.SVGIcon"></SVGIcon>
          </span>
          <span v-if="bottomLink.isFeedback">[+]</span>
          {{ bottomLink.text }}
        </a>
      </li>
    </ul>
    <div v-if="isLoggedIn" id="nav-main">
      <ul :class="getNavMenuClass">
        <li :class="getClassName">
          <a
            v-if="isLogoutAPIEnabled"
            href="javascript:void(0)"
            @click="signOut"
          >
            {{ signOutText }}
          </a>
          <a
            v-else
            href="/account/not-me.html"
            target="_self"
            class="mobile-brand-title"
            >{{ signOutText }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getConfigById, getKeyRewardsLink } from "../../util/context";
import { loadScript } from "../../util/scriptHandler";
const SVGIcon = () =>
  import(/* webpackChunkName: "header-footer-svg-icon" */ "../SVGIcon.vue");
import onlineOpinions from "../../mixins/onlineOpinions";
import {
  SIGN_OUT,
  PROFILE_SUMMARY_LOCAL_STORAGE_KEY,
  LOGOUT_API_URL,
  LOGOUT_URL,
} from "../../util/constants";
import Cookies from "js-cookie";
import { getItemFromLocalStorage } from "@js-ecom-mfe/browser-storage";
const axios = require("../../services/axios-instance");

export default {
  name: "mobile-bottom-links",
  props: {
    subBrand: {
      type: String,
      required: true,
    },
  },
  data() {
    const profileConfig = getConfigById("profile", this) ?? {};
    const myAccount = getConfigById("myAccount", this) ?? {};
    return {
      bottomLinks: [],
      isUserNameCookieEnabled: profileConfig?.isUiUserNameCookieEnabled ?? true,
      isLogoutAPIEnabled: myAccount?.enableLogoutAPI ?? false,
      error: false,
      errorCode: "",
    };
  },
  components: {
    SVGIcon,
  },
  mixins: [onlineOpinions],
  computed: {
    displayedBottomLinks() {
      return this.bottomLinks.filter(({ onlyOnSubBrands }) =>
        Array.isArray(onlyOnSubBrands)
          ? onlyOnSubBrands.includes(this.subBrand)
          : true
      );
    },
    /**
     * Gets isLoggedIn value based on isUsernameCookieEnabled flag.
     *
     * @returns {boolean} - Returns boolean value".
     */
    isLoggedIn() {
      if (this.isUserNameCookieEnabled) {
        return !!Cookies.get("UI_USERNAME_COOKIE");
      }
      return !!getItemFromLocalStorage(PROFILE_SUMMARY_LOCAL_STORAGE_KEY);
    },
    /**
     * Gets class name for signout button.
     *
     * @returns {string} - Returns signout button class".
     */
    getClassName() {
      return `site-link-title HOD ${this.subBrand}`;
    },
    /**
     * Gets boolean value to append nav-menu class.
     *
     * @returns {boolean} - Returns boolean value".
     */
    isNavMenu() {
      return getConfigById("hideNavMenuClass", this) || false;
    },
    /**
     * Gets nav-menu class name.
     *
     * @returns {string} - Returns string value".
     */
    getNavMenuClass() {
      return this.isNavMenu ? "" : "nav-menu";
    },
    /**
     * Get signout button text.
     *
     * @returns {String} - string value".
     */
    signOutText() {
      return getConfigById("signedOutButtonLabel", this) || SIGN_OUT;
    },
  },
  methods: {
    signOut() {
      axios
        .post(LOGOUT_API_URL)
        .then((response) => {
          if (response) {
            const { status } = response;
            if (status === 204) {
              window.location.href = LOGOUT_URL;
            } else {
              this.error = true;
              this.errorCode = status;
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            this.error = true;
            this.errorCode = error.response.status;
          }
        });
    },
    getBottomLinks() {
      return getConfigById("mobileBottomLinks", this);
    },
    updateKeyRewards(link) {
      return getKeyRewardsLink(link);
    },
    getBottomClass(isFeedback, bottomConfigClass = "") {
      const bottomClass = bottomConfigClass ? bottomConfigClass : "";
      return isFeedback
        ? `${bottomClass} opinionlab-feedback-link`
        : bottomClass;
    },
  },
  created() {
    const bottomLinksData = this.getBottomLinks();
    this.bottomLinks = bottomLinksData.bottomLinks;
  },
  async mounted() {
    if (typeof WSI !== "undefined") {
      const { assetUris, staticPrefix } = WSI.appGlobal;
      await loadScript(
        `${assetUris.scripts}${staticPrefix}/js/thirdParty/oo_engine_min.js`
      );
      await loadScript(
        `${assetUris.scripts}${staticPrefix}/js/thirdParty/oo_config_inline.js`
      );
      await loadScript(
        `${assetUris.scripts}${staticPrefix}/js/thirdParty/oo_config_inline_footer_menu.js`
      );
    }
  },
};
</script>

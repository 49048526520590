import {
  browserSessionStorageAvailable,
  saveItemToSessionStorage,
} from "@js-ecom-mfe/browser-storage";
import { logger } from "@js-ecom-mfe/logger";
import {
  REPONAME,
  SEARCH_BAR_KEY,
  SEARCH_BAR_PROPERTY,
  RECENTLY_SEARCHED_MAX_SIZE,
} from "../util/constants";
import { getConfigById } from "../util/context";
import { saveRawInput } from "../util/recentSearches";
import { removeTrailingSlash } from "../util/string";
import EventBus from "../util/event-bus";

const fileName = "SearchBar.js";

export default {
  data() {
    const searchBar = this.getConfig("searchBar");
    const setSearchFormIsClickedOrFocused = false;
    return {
      recentlySearchedMaxSize:
        searchBar?.recentlySearchedMaxSize ?? RECENTLY_SEARCHED_MAX_SIZE,
      setSearchFormIsClickedOrFocused,
    };
  },
  methods: {
    onKeydownHandler(direction) {
      this.$refs.visualTypeAhead.highlightSuggestion(direction);
    },

    getConfig(type) {
      const config = getConfigById(type, this);

      if (!config) {
        logger.warn(
          REPONAME,
          fileName,
          "getConfig",
          `Config error in searchBar`
        );
        return {};
      }
      return config;
    },
    /**
     * Communicates to the the child Visual Type Ahead component,
     * if it exists, to hide its search suggestions.
     */
    hideSearchSuggestions() {
      if (
        this.$refs[this.vtaRef] &&
        !this.$refs[this.vtaRef].hide &&
        this.$refs[this.vtaRef].hideSearchResults
      ) {
        this.$refs[this.vtaRef].hideSearchResults();
        EventBus.$emit("showBrandsListBannerHeader", true);
        EventBus.$emit("hideMobileSearchBar");
      }
      // if OSSA-2123 RVI test treatment is active,
      // communicate to child RVI component to hide
      if (
        this.rviEnabled &&
        this.isMobile &&
        this.$refs[this.rviTabsRef] &&
        !this.$refs[this.rviTabsRef].hide &&
        this.$refs[this.rviTabsRef].hideRecentlyViewedTabs
      ) {
        this.$refs[this.rviTabsRef].hideRecentlyViewedTabs();
      }
    },
    /**
     * Handler for when user clicks outside of the search area.
     */
    onClickOutside() {
      this.hideSearchSuggestions();
    },
    /**
     * Handler for when user submits a search -
     * 1) Saves the user's input to the recent searches list in Local Storage.
     * 2) Reports to Analytics.
     * ASSUMPTION: Any user input in the search bar is considered a "raw input",
     *             so save the search in the recent searches object exactly as
     *             the user entered the term.
     * 3) Updates `window.location.href` with searchResultsUrl, given that the
     *    user is on a sub brand then the sub brand_key query param value from the
     *    config will be appended to the request URL, extends the sub brand
     *    experience to /search/pages.
     * @param {Object} event - the dom event object
     * @param {String} type - where this event came from
     */
    onSubmit(event, type) {
      const { activeSuggestion = "", searchResultsUrl } =
        this.$refs[this.vtaRef];

      if (activeSuggestion) {
        this.$refs.searchField.value = activeSuggestion;
      }
      // if the string only has white spaces ignore it.
      const searchTerm = this.$refs.searchField.value.trimLeft();
      if (searchTerm) {
        const searchQuery = `${this.formAction}?words=`;
        saveRawInput(
          searchTerm,
          `${removeTrailingSlash(this.applicationUri)}${searchQuery}`,
          this.recentlySearchedMaxSize
        );
        this.submitSearchAnalytics(event);
        if (type === "searchbar") {
          this.hideSearchSuggestions();
        }
        window.location.href = searchResultsUrl(searchTerm);
      } else {
        this.showRecentSearches(type);
        // submit focus analytics event if searchField input value is empty
        this.sendFocusAnalyticsEvent();
      }
    },
    /**
     * Communicates to child VTA component,
     * if it exists, to load recent searches.
     */
    showRecentSearches(type, event) {
      event?.stopPropagation();
      if (this.$refs[this.vtaRef] && this.$refs[this.vtaRef].showQueryResults) {
        this.$refs[this.vtaRef].showQueryResults();
      }
      // Show Rvi Items
      // communicate to child component to show
      if (this.$refs[this.vtaRef] && this.$refs[this.vtaRef].showRviItems) {
        this.$refs[this.vtaRef].showRviItems();
      }
      // if OSSA-2123 RVI test treatment is active,
      // communicate to child RVI component to show
      if (
        this.rviEnabled &&
        this.isMobile &&
        this.$refs[this.rviTabsRef] &&
        this.$refs[this.rviTabsRef].showRecentlyViewedTabs &&
        type === "searchbar"
      ) {
        this.$refs[this.rviTabsRef].showRecentlyViewedTabs();
      }
      EventBus.$emit("showBrandsListBannerHeader", false);
    },
    submitSearchAnalytics(event) {
      this.addEvent({
        item: "searchInitiation",
        category: "searchSubmission",
        searchEntry: "",
        searchTerm: this.$refs.searchField.value,
        searchType: "",
      });
      if (browserSessionStorageAvailable) {
        saveItemToSessionStorage(SEARCH_BAR_KEY, SEARCH_BAR_PROPERTY, {});
      }
    },
    toggleSearchOverlay() {
      // triggers event just once per page load
      this.sendFocusAnalyticsEvent();
      this.isOverlayVisible = !this.isOverlayVisible;
      if (this.isMobile || this.isResponsive) {
        this.toggleNoScroll(this.isOverlayVisible);
      }

      // make sure we have a value if one is not there
      this.searchValue = this.$refs.searchField.value;

      /*
       * need to let the update hook run so we can update the value
       * otherwise the value gets cleared during the update process
       */
      this.$nextTick(() => {
        this.$refs.searchField.value = this.searchValue;
        if (!this.isOverlayVisible && !this.searchValue) {
          EventBus.$emit("hideMobileSearchBar");
        }
      });
    },
    onKeyupHandler() {
      this.searchValue = this.$refs.searchField.value;
    },
    /**
     * Toggles the showSearchBar property true/false to show or hide the searchBar in mobile
     */
    toggleSearchBar() {
      this.showSearchBar = this.showSearchBar ? this.showSearchBar : true;
    },
    /**
     * Extract Search query params from URL
     */
    getURLParamsValue() {
      if (
        typeof window !== "undefined" &&
        window.location?.search &&
        window.location.pathname?.startsWith("/search/results.html")
      ) {
        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams && searchParams.has("words")) {
          const words = window.decodeURIComponent(searchParams.get("words"));
          if (this.$refs.searchField && words) {
            return words;
          }
        }
      }
    },
    sendFocusAnalyticsEvent() {
      if (!this.searchFormIsClickedOrFocused) {
        this.addEvent({
          item: "focus",
          category: "searchsubmission",
        });
        this.searchFormIsClickedOrFocused = !this.searchFormIsClickedOrFocused;
      }
    },
  },
};

<template>
  <div data-style="nav-menu" class="grid-recommendations-wrapper">
    <Drawer
      ref="navDrawerComponent"
      :showDrawer="showDrawer"
      :customClass="'desktopVerticalNavigation'"
      direction="left"
      :lockScreen="true"
      :sitsInlineWith="sitsInlineWith"
      :isResponsive="isResponsive"
      v-on:close="toggleDrawer"
      :showCloseIcon="true"
      v-on:ready="hasLoaded"
    >
      <template v-slot:content>
        <nav id="vertical-nav-main" aria-label="primary">
          <PrimaryNav
            v-if="navMenuText === 'all' || navMenuText === 'primary'"
            :isVerticalMenu="true"
          />
          <SecondaryNav
            v-if="navMenuText === 'all' || navMenuText === 'secondary'"
            :isResponsive="isResponsive"
          />
        </nav>
      </template>
      <template v-slot:footer>
        <div v-if="navMenuText === 'all' || navMenuText === 'secondary'">
          <EcmContentSlot contentLocation="spotlight-common" />
        </div>
      </template>
    </Drawer>
  </div>
</template>
<script>
import Drawer from "../../drawer/Drawer.vue";
import EventBus from "../../../util/event-bus";
import PrimaryNav from "../PrimaryNav.vue";
import SecondaryNav from "../SecondaryNav.vue";
import EcmContentSlot from "../../EcmContentSlot.vue";

export default {
  name: "navmenu-flyout-component",
  components: {
    Drawer,
    PrimaryNav,
    EcmContentSlot,
    SecondaryNav,
  },
  props: {
    isResponsive: {
      type: Boolean,
      required: false,
      default: false,
    },
    navMenuText: {
      type: String,
      required: false,
      default: "all",
    },
  },
  data() {
    return {
      showDrawer: false,
      hasLoaded: false,
      sitsInlineWith: "",
    };
  },
  computed: {},
  methods: {
    // toggles drawer
    toggleDrawer() {
      this.showDrawer = !this.showDrawer;
    },
  },
  mounted() {
    // toggles navigations menu flyout
    EventBus.$on("toggleDesktopNavMenu", () => {
      this.showDrawer = !this.showDrawer;
    });
  },
};
</script>

<!-- eslint-disable-next-line vue/valid-template-root -->
<template data-style="mpulse-tagger"></template>

<script>
import { getConfigById } from "../../util/context";
import { getCategoryDataFromPath } from "../../util/categoryData";
const CUSTOMER_SERVICE_ROUTE = "customer-service";
const CUSTOMER_SERVICE = "customer-service-mfe";
const FAVORTIES_ROUTE = "favorites-gallery";
const HOMEPAGE = "homepage-mfe";
const PAGES_ROUTE = "pages";
const PAGES = "pages-mfe";
const PHYGITAL_APPOINTMENTS_ROUTE = "phygital-appointments";
const PHYGITAL_EVENTS_ROUTE = "phygital-events";
const PRODUCTS_MFE = "product-mfe";
const PRODUCTS_ROUTE = "products";
const REGISTRY_ROUTE = "registry";
const RECIPE_ROUTE = "recipe";
const RECIPE = "recipe-mfe";
const SHOP_CATEGORY = "shop-category";
const TIPS_AND_IDEAS_ROUTE = "tips-and-ideas";
const ORDER_CONFIRMATION = "order-confirmation";
const SHOP_BY_STYLE = "shop-by-style";
const CURATED_BOARDS_ROUTE = "curated-boards";
const INSPIRATION_BOARDS_ROUTE = "inspiration-boards";

export default {
  name: "MPulseTagger",
  methods: {
    createSOASTAObject(routeType) {
      let soastaObject = {};
      if (routeType === FAVORTIES_ROUTE) {
        soastaObject = {
          pg: routeType,
        };
      } else {
        const isOrderConfirmationPage = [
          "order-confirmation",
          "m-order-confirmation",
        ].includes(this.pageName);
        let pg;
        switch (routeType) {
          case "subcat":
            pg = "shop-subcategory";
            break;
          case "search":
            pg = "search-results";
            break;
          case PHYGITAL_APPOINTMENTS_ROUTE:
            pg = PHYGITAL_APPOINTMENTS_ROUTE;
            break;
          case PHYGITAL_EVENTS_ROUTE:
            pg = PHYGITAL_EVENTS_ROUTE;
            break;
          case PRODUCTS_ROUTE:
            pg = PRODUCTS_MFE;
            break;
          case REGISTRY_ROUTE:
            pg = REGISTRY_ROUTE;
            break;
          case ORDER_CONFIRMATION:
            pg = ORDER_CONFIRMATION;
            break;
          case HOMEPAGE:
            pg = HOMEPAGE;
            break;
          case CUSTOMER_SERVICE:
            pg = CUSTOMER_SERVICE;
            break;
          case PAGES:
            pg = PAGES;
            break;
          case SHOP_BY_STYLE:
            pg = SHOP_BY_STYLE;
            break;
          case RECIPE:
            pg = RECIPE;
            break;
          default:
            pg = SHOP_CATEGORY;
        }
        soastaObject = {
          pg: pg,
        };
        if (isOrderConfirmationPage || routeType === ORDER_CONFIRMATION) {
          soastaObject.conv = "1";
        }
      }
      return soastaObject;
    },
    getPath() {
      // remove the first
      let path = window.location.pathname;
      if (path.endsWith("/")) {
        path = path.substring(0, path.length - 1);
      }
      if (path.startsWith("/")) {
        path = path.substring(1);
      }
      return path;
    },
    async initMPulse() {
      const categoryData = await window.catInfoLoaded;
      const path = this.getPath();

      let routeType;
      if (path.startsWith("favorites")) {
        routeType = FAVORTIES_ROUTE;
      } else if (path.startsWith("appointments")) {
        routeType = PHYGITAL_APPOINTMENTS_ROUTE;
      } else if (path.startsWith("events")) {
        routeType = PHYGITAL_EVENTS_ROUTE;
      } else if (path.startsWith(PRODUCTS_ROUTE)) {
        routeType = PRODUCTS_ROUTE;
      } else if (path.startsWith(REGISTRY_ROUTE)) {
        routeType = REGISTRY_ROUTE;
      } else if (path.includes("checkout/thanks")) {
        routeType = ORDER_CONFIRMATION;
      } else if (!path || path === "/") {
        routeType = HOMEPAGE;
      } else if (path.includes(CUSTOMER_SERVICE_ROUTE)) {
        routeType = CUSTOMER_SERVICE;
      } else if (
        path.includes(PAGES_ROUTE) ||
        path.includes(TIPS_AND_IDEAS_ROUTE)
      ) {
        routeType = PAGES;
      } else if (path.includes(RECIPE_ROUTE)) {
        routeType = RECIPE;
      } else if (
        path.includes(CURATED_BOARDS_ROUTE) ||
        path.includes(INSPIRATION_BOARDS_ROUTE)
      ) {
        routeType = SHOP_BY_STYLE;
      } else {
        const routeCategoryData = getCategoryDataFromPath(categoryData, path);
        routeType =
          (routeCategoryData && routeCategoryData.type) || routeCategoryData;
      }

      const MPULSE_KEY = getConfigById("mpulse", this).mpulseKey;
      const soastaObject = this.createSOASTAObject(routeType);

      if (window.mpulseTagger) {
        window.mpulseTagger(MPULSE_KEY, soastaObject);
      }
    },
  },
  props: {
    pageName: {
      type: String,
      default: "",
    },
  },
  mounted() {
    this.initMPulse();
  },
};
</script>

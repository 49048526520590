import axios from "axios";
import { logger } from "@js-ecom-mfe/logger";
import { deliveryInformationEndpoint } from "./const/endpoints";
const fileName = "getDeliveryInformation.js";
const methodName = "getDeliveryInformation";
import { REPONAME } from "../util/constants";

/**
 * Retrieves fulfillment location information based on postal code and a delivery info URL.
 *
 * @param {string} postalCode User's postal code.
 * @param {string} deliveryInfoUrl URL of the API endpoint providing fulfillment location information.
 *
 * @returns {Promise} Promise resolving to the fulfillment location data or an empty object if unsuccessful.
 */
export const getDeliveryInformation = async (postalCode, timeout) => {
  try {
    const response = await axios.get(deliveryInformationEndpoint(postalCode), {
      timeout,
    });
    // Check for successful response before returning data
    if (response.status >= 200 && response.status < 300) {
      return response?.data?.deliveryInformationByPostalCode?.locationInfoList.map(
        (location) => location.deliveryLocation
      );
    } else {
      logger.error(
        REPONAME,
        fileName,
        methodName,
        `API request failed with status code: ${response.status}`
      );
      return {};
    }
  } catch (error) {
    logger.error(REPONAME, fileName, methodName, error);
    return {};
  }
};

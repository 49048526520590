<template>
  <div data-style="mobile-tabbed-navigation" class="design-platform tabbed-nav">
    <div class="tab-menu">
      <ul role="tablist">
        <li
          v-for="(tabItem, tabIndex) in computedTabsForDisplay"
          :id="`tab-${tabItem.id}`"
          :key="tabIndex"
          role="tab"
          :aria-selected="activeNavTab === tabItem.id ? 'true' : 'false'"
          :aria-controls="`tabpanel-${tabItem.id}`"
        >
          <a
            href="javascript:void(0)"
            :tabindex="0"
            :ref="tabItem.id"
            :class="{ active: activeNavTab === tabItem.id }"
            v-html="tabItem.name"
            :key="tabIndex"
            @click.stop.prevent="toggleActiveTab(tabItem.id)"
          ></a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import EventBus from "../../util/event-bus";
import {
  MOBILE_NAV_ACTIVE_TAB,
  MOBILE_NAV_ACTIVE_TAB_DEFAULT_EXPIRATION_DAYS,
} from "./../../util/constants";
import {
  browserLocalStorageAvailable,
  getItemFromLocalStorage,
  saveItemToLocalStorage,
} from "@js-ecom-mfe/browser-storage";

export default {
  name: "mobile-tabbed-navigation",
  components: {},
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
    tabDefinitions: {
      type: Array,
      default: () => [],
    },
    subBrandName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      rememberLastActiveTabConfig: this.config?.rememberLastActiveTab || {},
      activeNavTab:
        // eslint-disable-next-line vue/no-computed-properties-in-data
        this.config?.defaultTab || this.computedTabsForDisplay[0]?.tab || "",
    };
  },
  computed: {
    computedTabsForDisplay() {
      let computedTabsForDisplay = [];
      const configuredTabs = this.config?.tabs || [];
      configuredTabs.forEach((tab) => {
        return this.tabDefinitions.filter((tabDefinition) => {
          if (tabDefinition.id === tab) {
            computedTabsForDisplay.push(tabDefinition);
          }
        });
      });
      return computedTabsForDisplay;
    },
  },
  methods: {
    toggleActiveTab(activeTab) {
      this.activeNavTab = activeTab;
      EventBus.$emit("onTabSelection", activeTab);
      this.rememberLastActiveTabConfig?.isEnabled &&
        this.saveLastActiveTabToStorage();
    },
    /**
     * @property {activeNavTab} activeNavTab
     * The ID of the currently active tab within the mobile tabbed navigation component.
     * This value determines which tab content is displayed and which tab link has the
     * active styling.
     *
     * @default
     * - Configured default tab from `this.config.defaultTab` if available.
     * - First tab's ID from `this.computedTabsForDisplay[0].tab` if no default configured.
     * - Falls back to an empty string ("") otherwise.
     */
    setDefaultActiveTab() {
      this.activeNavTab =
        this.computedTabsForDisplay.find(
          (tab) =>
            tab.name === this.subBrandName ||
            this.subBrandName.indexOf(tab.name) > 0
        )?.id ||
        (this.rememberLastActiveTabConfig?.isEnabled &&
          this.getLastActiveTabFromStorage()) ||
        this.config?.defaultTab ||
        this.computedTabsForDisplay[0]?.tab;
    },
    getLastActiveTabFromStorage() {
      if (browserLocalStorageAvailable()) {
        const mobileNavActiveTab =
          getItemFromLocalStorage(MOBILE_NAV_ACTIVE_TAB) || {};
        if (mobileNavActiveTab.lastActiveTab && mobileNavActiveTab.expiry) {
          const currentDate = new Date();
          const expiryDate = new Date(mobileNavActiveTab.expiry);
          return (
            currentDate.getTime() < expiryDate.getTime() &&
            mobileNavActiveTab.lastActiveTab
          );
        }
      }
    },
    saveLastActiveTabToStorage() {
      const expiryDate = new Date();
      expiryDate.setDate(
        expiryDate.getDate() + MOBILE_NAV_ACTIVE_TAB_DEFAULT_EXPIRATION_DAYS
      );
      saveItemToLocalStorage(MOBILE_NAV_ACTIVE_TAB, {
        lastActiveTab: this.activeNavTab,
        expiry: expiryDate,
      });
    },
  },
  mounted() {
    EventBus.$on("subBrandMobileTabNav", (subBrand) => {
      this.activeSubBrand = subBrand;
    });
    this.setDefaultActiveTab();
    EventBus.$emit("onTabSelection", this.activeNavTab);
  },
};
</script>

import axios from "axios";
import { REPONAME } from "../util/constants";
import { logger } from "@js-ecom-mfe/logger";
import { GENERIC_SERVICE_TIME_OUT } from "./const/timeouts";

const fileName = "formSubmit.js";

export const formSubmit = (formId, url, queryParams = {}) => {
  let formDataEntries = { ...queryParams };
  const inputElements = document.querySelectorAll(`#${formId} input`);
  const phoneElement =
    document.querySelector(`#${formId} input[name='marketingSmsInfo.phone']`) ||
    {};

  inputElements.forEach((input) => {
    const isEmailCatalogCheckbox =
      input.type === "checkbox" &&
      (input.name === "emailMeBean.catalogs" ||
        input.name === "catalogRequestBean.catalogs");

    const isInputMarketingSmsPreference =
      input.type === "checkbox" &&
      input.name === "marketingSmsInfo.marketingSmsPreference";

    if (isInputMarketingSmsPreference && !phoneElement.value) {
      return;
    }

    if (input.value && (!isEmailCatalogCheckbox || input.checked)) {
      formDataEntries[input.name] = input.value;
    }
  });

  if (queryParams.emailAddr) {
    formDataEntries.emailAddr = queryParams.emailAddr;
  }

  const data = new URLSearchParams([...Object.entries(formDataEntries)]);
  const encodedData = url + "?" + data.toString();
  try {
    return axios.get(encodedData, { timeout: GENERIC_SERVICE_TIME_OUT });
  } catch (error) {
    logger.error(REPONAME, fileName, "formSubmit", error);
    return Promise.reject(error);
  }
};

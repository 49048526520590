<template>
  <div
    data-style="secondary-nav"
    @mouseenter="mouseEnterSecondaryNav"
    @mouseleave="mouseLeaveSecondaryNav"
  >
    <section v-if="secondaryNavEnabled" id="nav-secondary">
      <ul v-click-outside="closeMenu">
        <template v-for="(category, index) in secondary_nav_categories">
          <li
            v-bind:key="category.id"
            v-if="!(category.hideIfInternational && isInternational)"
          >
            <a
              :href="category.url || 'javascript:void(0)'"
              v-html="category.name"
              @click="
                isIpad && isIpadDeviceCheckEnabled && !isResponsive
                  ? category.popOut ||
                    (category.categories && category.categories.length)
                    ? toggleTopNav($event, index, category.url)
                    : null
                  : null
              "
            ></a>
            <button
              type="button"
              :aria-label="getNavItemText({ text: category.name })"
              aria-expanded="false"
              class="drop-down-icon"
              ref="menuIcon"
              v-if="
                topNavItemByPath(category.path) ||
                category.custom ||
                (category.customComponent && category.popOut)
              "
              @click="showDropdownMenu($event, '#nav-secondary')"
              @keyup.esc="closeDropdownMenu"
            >
              <SVGIcon :icon="'icon-dropdown'"></SVGIcon>
            </button>
            <component
              v-if="category.customComponent"
              :is="category.customComponent"
              :ecmPromos="category.ecmPromos"
            >
            </component>

            <template
              v-if="
                (category.path && category.type === 'supercat') ||
                category.custom
              "
            >
              <PrimaryNavPopOutMenu
                v-if="topNavItemByPath(category.path) || category.custom"
                :catData="topNavItemByPath(category.path)"
                :navLinkCatId="category.id"
                :customData="category.custom ? category.categories : null"
                :hasSpotlight="category.hasSpotlight"
                :ecmPromos="category.ecmPromos"
                :showLeftNavHeaders="config.showLeftNavHeaders || false"
                :showLinksIfHasGlobalOrNavLink="
                  config.showLinksIfHasGlobalOrNavLink || true
                "
              >
              </PrimaryNavPopOutMenu>
            </template>
          </li>
        </template>
      </ul>
      <EcmContentSlot contentLocation="secondary-nav-banner-1" />
      <EcmContentSlot contentLocation="secondary-nav-banner-b2b" />
      <EcmContentSlot contentLocation="kids-secondary-nav-banner-1" />
    </section>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import RegistryPopOut from "./ActionLinkList/RegistryPopOut.vue";
import FindAStorePopOut from "./ActionLinkList/FindAStorePopOut.vue";
import PrimaryNavPopOutMenu from "./PrimaryNavPopOutMenu.vue";
import EcmContentSlot from "../EcmContentSlot.vue";
import {
  getApplicationUri,
  getBrandConcept,
  getConfigById,
  getSubBrand,
} from "../../util/context";
import {
  getNavBarConfig,
  getSecondaryHeaderConfig,
  getSecondaryHeaderPaths,
} from "../../util/nav";
import { getCategoryDataFromPath } from "../../util/categoryData";
import { removeTrailingSlash } from "../../util/string";
import clickOutside from "../../directives/click-outside";
import { isDeviceIpad } from "../../util/checkDeviceType";
const SVGIcon = () =>
  import(/* webpackChunkName: "header-footer-svg-icon" */ "../SVGIcon.vue");
import dropdownMenu from "../../mixins/dropdownMenu";
import Vue from "vue";
import { mapMutations } from "vuex";

Vue.use(clickOutside);
export default {
  name: "secondary-nav",
  components: {
    EcmContentSlot,
    PrimaryNavPopOutMenu,
    RegistryPopOut,
    FindAStorePopOut,
    SVGIcon,
  },
  props: {
    isResponsive: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [dropdownMenu],
  data() {
    return {
      applicationUri: "",
      concept: "",
      config: getNavBarConfig(this) || {},
      subBrand: "",
      secondary_nav_categories: [],
      topNavItems: [],
      isInternational: false,
      secondaryNavEnabled: true,
      // eslint-disable-next-line no-undefined
      topNavActive: undefined,
      isIpadDeviceCheckEnabled:
        getConfigById("isIpadDeviceCheckEnabled", this)?.enabled || true,
      isIpad: "",
      isRetailStoreSession: false,
      isSecondaryNavSSREnabled:
        this.$store?.state?.header?.config?.isSecondaryNavSSREnabled ?? false,
      isSecondaryNavSSREnabledNonBot:
        this.$store?.state?.header?.config?.isSecondaryNavSSREnabledNonBot ??
        false,
      isSeoBot: this.$store?.state?.header?.activeProperties?.isSeoBot === "1",
      ssrCategoryData:
        this.$store?.state?.header?.config?.primaryNavBar?.catData
          ?.categories ?? [],
      stillInteractingWithSecondaryNav: false,
    };
  },
  computed: {
    retailStoreConfig() {
      return getConfigById("retailStoreConfig", this);
    },
  },
  methods: {
    ...mapMutations(["setDisplaySignInPrompt", "myStoreUpdate"]),
    async getCategoryData() {
      return await window.catInfoLoaded;
    },
    closeMenu(e) {
      if (!this.isIpad) {
        return true;
      }
      // eslint-disable-next-line no-undefined
      this.topNavActive = undefined;
    },
    getCategories() {
      return getSecondaryHeaderConfig(this) || [];
    },
    /**
     * @param {object} e eventHandler
     * @param {number} topNavItem the index of the nav item which is selected
     * @param {string} path redirect/route path of the selected nav item
     */
    toggleTopNav(e, topNavItem, path) {
      // since conditional event binding with modifier is not working have to do traditional way
      e.preventDefault();
      if (
        this.topNavActive === topNavItem &&
        this.isIpad &&
        this.isIpadDeviceCheckEnabled
      ) {
        window.location.href = `${removeTrailingSlash(
          this.applicationUri
        )}${path}`;
        return true;
      }
      this.topNavActive = topNavItem;
    },
    getHeaderPaths() {
      return getSecondaryHeaderPaths(this);
    },
    mouseEnterSecondaryNav() {
      this.stillInteractingWithSecondaryNav = true;
      setTimeout(() => {
        if (this.stillInteractingWithSecondaryNav) {
          this.setDisplaySignInPrompt(false);
        }
      }, 400);
    },
    mouseLeaveSecondaryNav() {
      this.stillInteractingWithSecondaryNav = false;
    },
    async getTopNavItems() {
      const topNavItemPaths = this.getHeaderPaths();
      const categoryData =
        typeof window !== "undefined"
          ? (this.categoryData = await window.catInfoLoaded)
          : (this.categoryData = this.ssrCategoryData);
      // build hash for quick lookup
      return topNavItemPaths.reduce((hash, path) => {
        const catDataForTopNavItem = getCategoryDataFromPath(
          categoryData,
          path
        );
        if (catDataForTopNavItem) {
          hash[path] = catDataForTopNavItem;
        }
        return hash;
      }, {});
    },
    topNavItemByPath(path) {
      return this.topNavItems[path];
    },
  },
  serverPrefetch() {
    if (
      (this.isSecondaryNavSSREnabled && this.isSeoBot) ||
      (this.isSecondaryNavSSREnabledNonBot && !this.isSeoBot)
    ) {
      this.getTopNavItems().then((res) => {
        this.topNavItems = res;
      });
    }
  },
  async created() {
    if (
      (this.isSecondaryNavSSREnabled && this.isSeoBot) ||
      (this.isSecondaryNavSSREnabledNonBot && !this.isSeoBot)
    ) {
      typeof window !== "undefined"
        ? (this.categoryData = await window.catInfoLoaded)
        : (this.categoryData = this.ssrCategoryData);
    }
    const config = getNavBarConfig(this);
    this.secondaryNavEnabled = config.hasOwnProperty("enabled")
      ? config?.enabled
      : true;
    this.concept = getBrandConcept(this);
    this.subBrand = getSubBrand(this).id;
    this.secondary_nav_categories = this.getCategories();
  },
  mounted() {
    this.isRetailStoreSession =
      !!Cookies.get(this.retailStoreConfig?.storeCookieName) ||
      !!Cookies.get(this.retailStoreConfig?.storeSessionEndedCookieName);
    // Disabling registry pop out in Retail Store Session to restrict associate from login
    if (this.isRetailStoreSession) {
      this.secondary_nav_categories = this.secondary_nav_categories.map(
        (category) => {
          if (category.id === "registry") {
            const categoryItem = { ...category, customComponent: "" };
            return categoryItem;
          }
          return category;
        }
      );
    }
    this.isIpad = isDeviceIpad();
    this.applicationUri = getApplicationUri(this);
    this.getTopNavItems().then((res) => {
      this.topNavItems = res;
    });
    //TODO: ensure this works correctly once this event is hooked up
    window.WSI.state.onChange("currencyUpdate", (data) => {
      this.isInternational = data.selectedCountry !== "US";
    });
  },
};
</script>

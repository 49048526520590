<template>
  <span data-style="pt-logo" data-component="Global-brand_logo-PTLogo">
    <svg
      v-if="subBrand === 'PBD' && !isResponsive"
      width="360"
      height="22"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 372.16 22.1"
      :id="`logo-${subBrand.toLowerCase()}`"
    >
      <title>Pottery Barn Dorm</title>
      <g>
        <path
          d="M302.94,17.48c0,0.56,0.09,0.95,0.26,1.17c0.17,0.22,0.47,0.32,0.91,0.32l1.29-0.03v2.62h-6.98v-1.78c-0.67,0.84-1.41,1.44-2.21,1.79c-0.81,0.36-1.77,0.53-2.89,0.53c-1.96,0-3.53-0.73-4.72-2.18c-1.18-1.45-1.78-3.38-1.78-5.77c0-2.43,0.64-4.45,1.91-6.04c1.27-1.59,2.89-2.39,4.85-2.39c0.62,0,1.17,0.07,1.65,0.21c0.47,0.14,0.94,0.37,1.39,0.69c0.24,0.15,0.47,0.33,0.71,0.55c0.24,0.22,0.47,0.46,0.71,0.74V4.1c0-0.54-0.09-0.91-0.26-1.12c-0.17-0.2-0.47-0.31-0.9-0.31h-1.39V0h7.47V17.48z M291.92,14.22c0,1.7,0.26,2.99,0.79,3.86c0.53,0.87,1.3,1.31,2.31,1.31c0.99,0,1.79-0.48,2.41-1.45c0.61-0.97,0.92-2.23,0.92-3.78c0-1.72-0.27-3.04-0.82-3.94c-0.55-0.91-1.33-1.36-2.34-1.36c-1.01,0-1.81,0.48-2.39,1.44c-0.58,0.96-0.87,2.28-0.87,3.96V14.22z"
        ></path>
        <path
          d="M307.37,13.77c0.11-2.54,0.91-4.52,2.41-5.93c1.5-1.41,3.55-2.12,6.16-2.12c2.67,0,4.76,0.73,6.27,2.2s2.26,3.48,2.26,6.04c0,2.48-0.77,4.45-2.31,5.93c-1.54,1.48-3.6,2.21-6.19,2.21c-2.67,0-4.77-0.71-6.3-2.12c-1.53-1.41-2.29-3.47-2.29-6.19V13.77z M312.47,13.73c0,1.9,0.29,3.34,0.87,4.33s1.43,1.49,2.55,1.49c1.12,0,1.98-0.5,2.57-1.49c0.59-0.99,0.89-2.43,0.89-4.33c0-1.66-0.31-2.98-0.92-3.96c-0.61-0.98-1.45-1.47-2.5-1.47c-1.08,0-1.92,0.48-2.54,1.44c-0.61,0.96-0.92,2.3-0.92,4.02V13.73z"
        ></path>
        <path
          d="M333.73,8.43c0.39-0.47,0.73-0.88,1.03-1.21c0.3-0.33,0.6-0.6,0.9-0.79c0.32-0.24,0.66-0.4,1-0.48c0.34-0.09,0.73-0.13,1.16-0.13c0.97,0,1.75,0.33,2.33,0.99c0.58,0.66,0.87,1.53,0.87,2.63c0,0.84-0.24,1.52-0.73,2.04c-0.48,0.52-1.13,0.78-1.92,0.78c-0.67,0-1.22-0.22-1.65-0.66c-0.43-0.44-0.65-0.99-0.65-1.63c0-0.09,0-0.18,0.02-0.27c0.01-0.1,0.03-0.19,0.05-0.27c0.04-0.19,0.12-0.39,0.24-0.6c0.12-0.2,0.29-0.45,0.5-0.73c-0.84,0-1.47,0.34-1.89,1.03c-0.42,0.69-0.72,1.44-0.89,2.26c-0.09,0.41-0.15,0.82-0.18,1.23c-0.03,0.41-0.05,0.77-0.05,1.07v3.81c0,0.56,0.09,0.95,0.26,1.16c0.17,0.22,0.47,0.32,0.91,0.32h1.84v2.59H326.5v-2.59h1.45c0.43,0.02,0.73-0.08,0.9-0.29c0.17-0.22,0.26-0.59,0.26-1.13v-7.33c0-0.52-0.09-0.88-0.26-1.09c-0.17-0.2-0.47-0.31-0.9-0.31h-1.45V6.14h7.4L333.73,8.43z"
        ></path>
        <path
          d="M349.47,8.05c0.47-0.41,0.91-0.76,1.31-1.07c0.4-0.3,0.79-0.54,1.18-0.71c0.41-0.19,0.83-0.33,1.26-0.42c0.43-0.09,0.92-0.13,1.45-0.13c1.1,0,2.02,0.2,2.78,0.6c0.75,0.4,1.39,1.04,1.91,1.92c0.11-0.13,0.21-0.25,0.31-0.36c0.1-0.11,0.2-0.2,0.31-0.29c0.71-0.69,1.44-1.17,2.18-1.45c0.74-0.28,1.63-0.42,2.67-0.42c1.42,0,2.57,0.39,3.46,1.18c0.88,0.79,1.32,1.82,1.32,3.09v7.5c0,0.56,0.09,0.95,0.26,1.16c0.17,0.22,0.48,0.32,0.94,0.32h1.36v2.59h-9.21v-2.59c0.67,0.02,1.15-0.04,1.44-0.18c0.29-0.14,0.44-0.56,0.44-1.25v-6.09c0-1.04-0.17-1.77-0.5-2.19c-0.33-0.42-0.92-0.63-1.76-0.63c-0.95,0-1.66,0.31-2.13,0.92c-0.47,0.61-0.71,1.54-0.71,2.76v5.17c0,0.56,0.09,0.95,0.26,1.16c0.17,0.22,0.48,0.32,0.94,0.32h0.74v2.59h-8.66v-2.59c0.65,0.02,1.13-0.03,1.45-0.15c0.32-0.12,0.48-0.55,0.48-1.28v-6.09c0-0.99-0.17-1.71-0.52-2.16c-0.35-0.44-0.9-0.66-1.68-0.66c-0.97,0-1.69,0.3-2.16,0.91c-0.47,0.6-0.71,1.53-0.71,2.78v5.17c0,0.56,0.09,0.95,0.26,1.16c0.17,0.22,0.47,0.32,0.91,0.32h0.78v2.59h-9.24v-2.59h1.36c0.43,0.02,0.73-0.08,0.9-0.29c0.17-0.22,0.26-0.59,0.26-1.13v-7.32c0-0.52-0.09-0.88-0.26-1.09c-0.17-0.21-0.47-0.31-0.9-0.31l-1.36-0.03V6.14h7.01L349.47,8.05z"
        ></path>
      </g>
      <g>
        <path
          class="st0"
          d="M3.81,11.58v8.08h0.76v0.4H0v-0.4h0.74V2.8H0V2.4h5.36c0.37,0,1.74-0.08,2.51,0.42c0.85,0.54,1.09,1.26,1.09,2.16v4.19c0,0.45-0.11,1.23-0.94,1.79c-0.8,0.52-2.11,0.63-3.18,0.63H3.81z M5.9,3.95c0-0.34,0.02-0.6-0.18-0.85C5.49,2.84,5.18,2.8,4.73,2.8H3.81v8.4h1.11c0.35,0,0.63-0.12,0.81-0.34C5.95,10.6,5.9,10.18,5.9,9.96V3.95z"
        ></path>
        <path
          class="st0"
          d="M32,17.61c0,1.59-1.05,2.62-3.86,2.61c-3.01,0-3.83-1.01-3.83-2.61V6.93c0-1.9,1.33-2.87,3.91-2.88C30.7,4.03,32,5.28,32,6.93V17.61z M29.23,5.57c0-0.89-0.33-1.25-1.02-1.23c-0.73,0-1.15,0.32-1.15,1.23v13.15c0,0.67,0.22,1.18,1.05,1.18c0.82,0,1.12-0.33,1.12-1.18V5.57z"
        ></path>
        <path
          class="st0"
          d="M56.52,4.17v3.2H56.1v-2.5h-2.35V19.7h0.68v0.36h-4.11V19.7h0.67V4.87h-2.33v2.5h-0.42v-3.2H56.52z"
        ></path>
        <path
          class="st0"
          d="M81.04,4.17v3.2h-0.42v-2.5h-2.35V19.7h0.68v0.36h-4.11V19.7h0.67V4.87h-2.33v2.5h-0.42v-3.2H81.04z"
        ></path>
        <path
          class="st0"
          d="M100.66,11.61h3.05v-1.39h0.42v3.49h-0.42v-1.4h-3.05v7.03h3.05v-2.4h0.42v3.11h-6.91V19.7h0.68V4.53h-0.68V4.17h6.91v3.11h-0.42v-2.4h-3.05V11.61z"
        ></path>
        <path
          class="st0"
          d="M126.46,11.92c0,0.1,1.02,0.18,1.71,0.65c0.72,0.48,0.78,1,0.78,1.7v5.42h0.68v0.36h-3.45v-6.35c0-0.5,0.12-0.98-0.36-1.28c-0.3-0.2-0.87-0.15-1.18-0.15h-0.93v7.42h0.67v0.36h-4.11V19.7h0.68V4.53h-0.68V4.17h4.83c0.33,0,1.56-0.07,2.26,0.38c0.77,0.49,0.98,1.14,0.98,1.94v3.38c0,0.17,0.05,0.93-0.57,1.45C127.21,11.82,126.46,11.85,126.46,11.92z M125.58,5.57c0-0.3,0.05-0.53-0.17-0.76c-0.23-0.25-0.58-0.28-0.85-0.28h-0.85v7.41h1c0.32,0,0.57-0.11,0.73-0.31c0.2-0.24,0.13-0.61,0.13-0.8V5.57z"
        ></path>
        <path
          class="st0"
          d="M147.36,14.39l-2.88-9.86h-0.68V4.17h4.11v0.36h-0.67l2.43,8.36l2.15-8.36h-0.68V4.17h2.25v0.36h-0.67l-2.6,9.89v5.28h0.66v0.36h-4.11V19.7h0.68V14.39z"
        ></path>
        <path
          class="st0"
          d="M179.14,13.51v3.98c0,0.68-0.03,1.17-0.52,1.71c-0.66,0.72-2.05,0.86-2.64,0.86h-6.66v-0.4h0.74V2.8h-0.74V2.4h6.34c0.8,0,1.39,0.14,1.98,0.58c0.98,0.74,0.89,1.65,0.89,1.67v4.24c0,0.02,0.04,0.86-0.59,1.39c-0.81,0.68-1.44,0.71-1.42,0.79c0,0.12,1.13,0.11,1.94,0.8C178.94,12.28,179.14,12.71,179.14,13.51z M173.14,19.66h1.64c0.06,0,0.61,0,0.89-0.22c0.3-0.25,0.41-0.51,0.41-0.86v-6.24c0-0.26-0.02-0.48-0.18-0.68c-0.18-0.21-0.5-0.28-0.85-0.28h-1.9V19.66z M173.14,2.8v8.11h1.61c0.06,0,0.39,0.02,0.59-0.2c0.18-0.2,0.13-0.49,0.13-0.65V3.85c0-0.06,0.04-0.65-0.13-0.85c-0.16-0.2-0.87-0.2-0.89-0.2H173.14z"
        ></path>
        <path
          class="st0"
          d="M199.62,4.53h-0.68V4.17h4.25v0.36h-0.77l2.76,15.17h0.68v0.36h-4.16V19.7h0.58l-0.67-4.44h-3.38l-0.92,4.44h0.68v0.36h-2.16V19.7h0.6L199.62,4.53z M198.34,14.65h3.13L200,6.01L198.34,14.65z"
        ></path>
        <path
          class="st0"
          d="M228.19,11.92c0,0.1,1.02,0.18,1.72,0.65c0.71,0.48,0.78,1,0.78,1.7v5.42h0.68v0.36h-3.45v-6.35c0-0.5,0.11-0.98-0.37-1.28c-0.3-0.2-0.86-0.15-1.18-0.15h-0.93v7.42h0.67v0.36H222V19.7h0.68V4.53H222V4.17h4.83c0.33,0,1.57-0.07,2.26,0.38c0.77,0.49,0.98,1.14,0.98,1.94v3.38c0,0.17,0.05,0.93-0.56,1.45C228.94,11.82,228.19,11.85,228.19,11.92z M227.31,5.57c0-0.3,0.05-0.53-0.17-0.76c-0.23-0.25-0.58-0.28-0.85-0.28h-0.85v7.41h1c0.31,0,0.57-0.11,0.73-0.31c0.2-0.24,0.13-0.61,0.13-0.8V5.57z"
        ></path>
        <path
          class="st0"
          d="M252.4,20.06l-3.35-13.2V19.7h0.68v0.36h-2.16V19.7h0.67V4.53h-0.67V4.17h3.44l3.48,13.77V4.53h-0.66V4.17h2.16v0.36h-0.68v15.53H252.4z"
        ></path>
      </g>
      <rect x="272.26" y="0.52" width="1.3" height="21.57"></rect>
    </svg>
    <svg
      v-if="subBrand === 'PBD' && isResponsive"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 222.72 79.85"
      style="enable-background: new 0 0 222.72 79.85"
      xml:space="preserve"
      :id="`logo-mobile-pbd`"
    >
      <g>
        <g>
          <path
            class="st0"
            d="M3.31,7.99v7.03h0.66v0.35H0v-0.35h0.64V0.35H0V0h4.67c0.32,0,1.51-0.07,2.19,0.36
			C7.59,0.84,7.8,1.46,7.8,2.24v3.65c0,0.39-0.1,1.07-0.82,1.56C6.29,7.9,5.15,7.99,4.21,7.99H3.31z M5.13,1.36
			c0-0.3,0.02-0.52-0.16-0.74C4.78,0.39,4.5,0.35,4.12,0.35h-0.8v7.31h0.96c0.31,0,0.55-0.11,0.71-0.29
			c0.19-0.23,0.15-0.59,0.15-0.78V1.36z"
          />
          <path
            class="st0"
            d="M27.84,13.23c0,1.39-0.91,2.28-3.36,2.27c-2.62,0-3.33-0.88-3.33-2.27V3.94c0-1.65,1.16-2.5,3.4-2.51
			c2.16-0.01,3.29,1.07,3.29,2.51V13.23z M25.43,2.76c0-0.77-0.29-1.09-0.88-1.07c-0.64,0-1,0.28-1,1.07V14.2
			c0,0.58,0.19,1.02,0.91,1.02c0.71,0,0.97-0.29,0.97-1.02V2.76z"
          />
          <path
            class="st0"
            d="M49.17,1.54v2.79h-0.36V2.16h-2.04v12.9h0.59v0.31h-3.58v-0.31h0.58V2.16h-2.03v2.17h-0.36V1.54H49.17z"
          />
          <path
            class="st0"
            d="M70.51,1.54v2.79h-0.36V2.16H68.1v12.9h0.59v0.31h-3.58v-0.31h0.58V2.16h-2.03v2.17h-0.36V1.54H70.51z"
          />
          <path
            class="st0"
            d="M87.57,8.02h2.65V6.82h0.36v3.04h-0.36V8.64h-2.65v6.12h2.65v-2.09h0.36v2.7h-6.01v-0.31h0.59V1.86h-0.59
			V1.54h6.01v2.7h-0.36V2.16h-2.65V8.02z"
          />
          <path
            class="st0"
            d="M110.02,8.29c0,0.08,0.88,0.16,1.49,0.57c0.62,0.42,0.68,0.87,0.68,1.48v4.72h0.59v0.31h-3V9.84
			c0-0.44,0.1-0.86-0.32-1.11c-0.26-0.17-0.75-0.13-1.03-0.13h-0.81v6.45h0.58v0.31h-3.58v-0.31h0.59V1.86h-0.59V1.54h4.2
			c0.29,0,1.36-0.06,1.97,0.33c0.67,0.42,0.85,0.99,0.85,1.69V6.5c0,0.14,0.04,0.81-0.49,1.27C110.67,8.2,110.02,8.23,110.02,8.29z
			 M109.26,2.76c0-0.26,0.04-0.46-0.15-0.66c-0.2-0.22-0.51-0.24-0.74-0.24h-0.74V8.3h0.87c0.27,0,0.49-0.1,0.64-0.27
			c0.17-0.21,0.12-0.53,0.12-0.7V2.76z"
          />
          <path
            class="st0"
            d="M128.2,10.43l-2.51-8.58h-0.59V1.54h3.58v0.31h-0.58l2.11,7.28l1.87-7.28h-0.59V1.54h1.95v0.31h-0.58
			l-2.26,8.6v4.6h0.58v0.31h-3.58v-0.31h0.59V10.43z"
          />
          <path
            class="st0"
            d="M155.86,9.67v3.46c0,0.59-0.03,1.02-0.45,1.49c-0.58,0.63-1.79,0.75-2.3,0.75h-5.79v-0.35h0.64V0.35h-0.64V0
			h5.52c0.69,0,1.21,0.12,1.72,0.51c0.85,0.64,0.77,1.43,0.77,1.45v3.69c0,0.01,0.03,0.75-0.52,1.21c-0.71,0.59-1.25,0.62-1.24,0.68
			c0,0.11,0.98,0.09,1.69,0.7C155.68,8.6,155.86,8.97,155.86,9.67z M150.63,15.02h1.43c0.05,0,0.53,0,0.77-0.19
			c0.26-0.22,0.35-0.44,0.35-0.75V8.65c0-0.23-0.02-0.41-0.16-0.59c-0.16-0.19-0.43-0.24-0.74-0.24h-1.66V15.02z M150.63,0.35V7.4
			h1.4c0.05,0,0.34,0.01,0.51-0.17c0.16-0.17,0.11-0.43,0.11-0.56v-5.4c0-0.05,0.03-0.56-0.11-0.74c-0.14-0.17-0.76-0.17-0.77-0.17
			H150.63z"
          />
          <path
            class="st0"
            d="M173.67,1.86h-0.59V1.54h3.69v0.31h-0.67l2.4,13.2h0.59v0.31h-3.62v-0.31h0.51l-0.58-3.86h-2.94l-0.8,3.86
			h0.6v0.31h-1.88v-0.31h0.52L173.67,1.86z M172.56,10.66h2.72l-1.27-7.52L172.56,10.66z"
          />
          <path
            class="st0"
            d="M198.53,8.29c0,0.08,0.88,0.16,1.49,0.57c0.62,0.42,0.68,0.87,0.68,1.48v4.72h0.59v0.31h-3V9.84
			c0-0.44,0.1-0.86-0.32-1.11c-0.26-0.17-0.75-0.13-1.03-0.13h-0.81v6.45h0.58v0.31h-3.58v-0.31h0.59V1.86h-0.59V1.54h4.2
			c0.29,0,1.36-0.06,1.97,0.33c0.67,0.42,0.85,0.99,0.85,1.69V6.5c0,0.14,0.04,0.81-0.49,1.27C199.18,8.2,198.53,8.23,198.53,8.29z
			 M197.76,2.76c0-0.26,0.04-0.46-0.15-0.66c-0.2-0.22-0.51-0.24-0.74-0.24h-0.74V8.3h0.87c0.27,0,0.49-0.1,0.64-0.27
			c0.17-0.21,0.12-0.53,0.12-0.7V2.76z"
          />
          <path
            class="st0"
            d="M219.59,15.37l-2.91-11.49v11.17h0.59v0.31h-1.88v-0.31h0.58V1.86h-0.58V1.54h3l3.03,11.98V1.86h-0.58V1.54
			h1.88v0.31h-0.59v13.51H219.59z"
          />
        </g>
        <rect x="27.07" y="29.16" width="168.57" height="1.29" />
        <g>
          <path
            d="M67.64,72.23c0,0.92,0.14,1.56,0.43,1.92c0.28,0.36,0.78,0.53,1.49,0.53l2.13-0.05v4.32h-11.5v-2.93
			c-1.1,1.38-2.31,2.37-3.65,2.95c-1.33,0.59-2.92,0.88-4.76,0.88c-3.23,0-5.82-1.2-7.77-3.59c-1.95-2.4-2.93-5.56-2.93-9.5
			c0-4.01,1.05-7.33,3.14-9.95c2.09-2.63,4.75-3.94,7.98-3.94c1.03,0,1.93,0.12,2.71,0.35c0.78,0.23,1.54,0.61,2.29,1.14
			c0.39,0.25,0.78,0.55,1.17,0.9c0.39,0.36,0.78,0.76,1.17,1.22V50.2c0-0.89-0.14-1.5-0.43-1.84c-0.28-0.34-0.78-0.51-1.49-0.51
			h-2.29v-4.42h12.29V72.23z M49.49,66.86c0,2.8,0.43,4.92,1.3,6.36c0.87,1.44,2.14,2.16,3.81,2.16c1.63,0,2.95-0.8,3.96-2.4
			c1.01-1.6,1.52-3.67,1.52-6.23c0-2.84-0.45-5-1.36-6.49c-0.91-1.49-2.19-2.24-3.86-2.24c-1.67,0-2.98,0.79-3.94,2.37
			c-0.96,1.58-1.44,3.75-1.44,6.52V66.86z"
          />
          <path
            d="M74.93,66.12c0.18-4.19,1.5-7.44,3.97-9.77c2.47-2.32,5.84-3.49,10.14-3.49c4.4,0,7.84,1.21,10.33,3.62
			c2.48,2.41,3.73,5.73,3.73,9.95c0,4.08-1.27,7.34-3.81,9.77c-2.54,2.43-5.93,3.65-10.19,3.65c-4.4,0-7.86-1.16-10.38-3.49
			c-2.52-2.32-3.78-5.72-3.78-10.19V66.12z M83.34,66.06c0,3.12,0.48,5.5,1.44,7.13s2.36,2.45,4.2,2.45c1.85,0,3.26-0.82,4.23-2.45
			c0.98-1.63,1.46-4.01,1.46-7.13c0-2.73-0.51-4.9-1.52-6.52c-1.01-1.61-2.39-2.42-4.12-2.42c-1.77,0-3.17,0.79-4.18,2.37
			c-1.01,1.58-1.52,3.79-1.52,6.63V66.06z"
          />
          <path
            d="M118.36,57.33c0.64-0.78,1.21-1.45,1.7-2c0.5-0.55,0.99-0.98,1.49-1.3c0.53-0.39,1.08-0.66,1.65-0.8
			c0.57-0.14,1.21-0.21,1.92-0.21c1.6,0,2.87,0.54,3.83,1.62c0.96,1.08,1.44,2.53,1.44,4.34c0,1.38-0.4,2.5-1.2,3.35
			c-0.8,0.85-1.85,1.28-3.17,1.28c-1.1,0-2.01-0.36-2.71-1.09c-0.71-0.73-1.06-1.62-1.06-2.69c0-0.14,0.01-0.29,0.03-0.45
			c0.02-0.16,0.04-0.31,0.08-0.45c0.07-0.32,0.2-0.65,0.4-0.98c0.19-0.34,0.47-0.74,0.83-1.2c-1.38,0-2.42,0.57-3.11,1.7
			c-0.69,1.14-1.18,2.38-1.46,3.73c-0.14,0.67-0.24,1.35-0.29,2.02c-0.05,0.67-0.08,1.26-0.08,1.76v6.28c0,0.92,0.14,1.56,0.43,1.92
			c0.28,0.36,0.78,0.53,1.49,0.53h3.03v4.26h-17.14v-4.26h2.4c0.71,0.04,1.21-0.12,1.49-0.48c0.28-0.36,0.43-0.98,0.43-1.87V60.27
			c0-0.85-0.14-1.45-0.43-1.79c-0.28-0.34-0.78-0.51-1.49-0.51h-2.4v-4.42h12.19L118.36,57.33z"
          />
          <path
            d="M144.28,56.7c0.78-0.67,1.5-1.26,2.16-1.76c0.66-0.5,1.3-0.89,1.94-1.17c0.67-0.32,1.37-0.55,2.08-0.69
			c0.71-0.14,1.51-0.21,2.39-0.21c1.81,0,3.34,0.33,4.58,0.98c1.24,0.66,2.29,1.71,3.14,3.17c0.18-0.21,0.35-0.41,0.51-0.59
			c0.16-0.18,0.33-0.34,0.51-0.48c1.17-1.13,2.37-1.93,3.59-2.4c1.22-0.46,2.69-0.69,4.39-0.69c2.34,0,4.24,0.65,5.69,1.94
			c1.45,1.3,2.18,2.99,2.18,5.08v12.35c0,0.92,0.14,1.56,0.43,1.92c0.28,0.36,0.8,0.53,1.54,0.53h2.23v4.26h-15.17v-4.26
			c1.1,0.04,1.89-0.06,2.37-0.29c0.48-0.23,0.72-0.92,0.72-2.06V62.3c0-1.71-0.28-2.91-0.82-3.6c-0.55-0.69-1.52-1.04-2.9-1.04
			c-1.56,0-2.73,0.51-3.51,1.52c-0.78,1.01-1.17,2.53-1.17,4.55v8.52c0,0.92,0.14,1.56,0.43,1.92c0.28,0.36,0.8,0.53,1.54,0.53h1.22
			v4.26h-14.26v-4.26c1.06,0.04,1.86-0.04,2.39-0.24c0.53-0.2,0.8-0.9,0.8-2.11V62.3c0-1.64-0.28-2.82-0.85-3.55
			c-0.57-0.73-1.49-1.09-2.77-1.09c-1.6,0-2.79,0.5-3.57,1.49c-0.78,0.99-1.17,2.52-1.17,4.58v8.52c0,0.92,0.14,1.56,0.43,1.92
			c0.28,0.36,0.78,0.53,1.49,0.53h1.28v4.26h-15.22v-4.26h2.24c0.71,0.04,1.21-0.12,1.49-0.48c0.28-0.36,0.43-0.98,0.43-1.87V60.28
			c0-0.85-0.14-1.45-0.43-1.79s-0.78-0.51-1.49-0.51l-2.24-0.05v-4.38h11.55L144.28,56.7z"
          />
        </g>
      </g>
    </svg>
    <svg
      v-if="subBrand === 'MT'"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 428.99 83.78"
      :id="`logo-${subBrand.toLowerCase()}`"
    >
      <title>Modern Teen</title>
      <rect
        x="267.09"
        y="23.36"
        width="2.58"
        height="60.42"
        style="fill: #231f20"
      ></rect>
      <path
        d="M2.76,10.16H7.31c3.21,0,5.36-2,5.36-5S10.52.26,7.31.26H1.19V18.33H2.76Zm0-8.41h4.5c2.35,0,3.82,1.33,3.82,3.47S9.65,8.68,7.26,8.68H2.76Z"
        style="fill: #231f20"
      ></path>
      <path
        d="M26.39,18.6a9.15,9.15,0,0,0,9.2-9.3,9.17,9.17,0,1,0-18.34,0A9.13,9.13,0,0,0,26.39,18.6Zm0-17.06A7.47,7.47,0,0,1,34,9.27a7.59,7.59,0,0,1-7.55,7.79,7.5,7.5,0,0,1-7.55-7.79A7.47,7.47,0,0,1,26.42,1.54Z"
        style="fill: #231f20"
      ></path>
      <polygon
        points="54.35 0.26 39.7 0.26 39.7 1.75 46.24 1.75 46.24 18.34 47.81 18.34 47.81 1.75 54.35 1.75 54.35 0.26"
        style="fill: #231f20"
      ></polygon>
      <polygon
        points="64.22 18.34 65.78 18.34 65.78 1.75 72.33 1.75 72.33 0.26 57.67 0.26 57.67 1.75 64.22 1.75 64.22 18.34"
        style="fill: #231f20"
      ></polygon>
      <polygon
        points="79.22 9.26 86.94 9.26 86.94 7.78 79.22 7.78 79.22 1.75 89.05 1.75 89.05 0.26 77.65 0.26 77.65 18.34 89.36 18.34 89.36 16.85 79.22 16.85 79.22 9.26"
        style="fill: #231f20"
      ></polygon>
      <path
        d="M106.85,18.33h1.93L102.55,10a4.73,4.73,0,0,0,4-4.84c0-2.95-2.15-4.94-5.36-4.94H95V18.33h1.57V10.16h4.11ZM96.59,1.75h4.5c2.35,0,3.82,1.33,3.82,3.47s-1.43,3.46-3.82,3.46h-4.5Z"
        style="fill: #231f20"
      ></path>
      <polygon
        points="119.3 9.05 114.04 0.44 113.93 0.26 112.19 0.26 118.52 10.56 118.52 18.34 120.08 18.34 120.08 10.56 126.41 0.26 124.67 0.26 119.3 9.05"
        style="fill: #231f20"
      ></polygon>
      <path
        d="M155,13.06a4.78,4.78,0,0,0-3.44-4.84,4.15,4.15,0,0,0,1.73-3.5c0-2.71-1.9-4.46-4.83-4.46h-6.2V18.33h7C152.78,18.33,155,16.26,155,13.06ZM143.85,1.74l4.55,0c2.11,0,3.32,1.1,3.32,3s-1.21,3-3.32,3h-4.55Zm0,15.11V9.26h5.42c2.52,0,4.16,1.5,4.16,3.82s-1.64,3.77-4.16,3.77Z"
        style="fill: #231f20"
      ></path>
      <path
        d="M167.77,0h-.87l-7.66,18.33h1.65l1.89-4.59h9.09l1.92,4.59h1.65Zm-4.38,12.25,3.93-9.47,3.93,9.47Z"
        style="fill: #231f20"
      ></path>
      <path
        d="M182.61,10.16h4.11l6.15,8.17h1.93L188.58,10a4.73,4.73,0,0,0,3.94-4.84c0-2.95-2.15-4.94-5.35-4.94h-6.12V18.33h1.56Zm0-8.41h4.5c2.36,0,3.82,1.33,3.82,3.47s-1.42,3.46-3.82,3.46h-4.5Z"
        style="fill: #231f20"
      ></path>
      <polygon
        points="211.91 15.2 200.7 0.14 200.59 0 199.66 0 199.66 18.34 201.22 18.34 201.22 3.52 212.53 18.6 213.47 18.6 213.47 0.26 211.91 0.26 211.91 15.2"
        style="fill: #231f20"
      ></polygon>
      <polygon
        points="18.83 51.06 3.72 36.05 0 36.05 0 70.18 6.71 70.18 6.71 48.36 18.83 60.43 30.99 48.36 30.99 70.18 37.65 70.18 37.65 36.05 33.98 36.05 18.83 51.06"
        style="fill: #231f20"
      ></polygon>
      <path
        d="M68,36.63a16.95,16.95,0,1,0,16.9,17A17,17,0,0,0,68,36.63ZM68,63.9a10.33,10.33,0,1,1,0-20.66A10.44,10.44,0,0,1,78.25,53.62,10.32,10.32,0,0,1,68,63.9Z"
        style="fill: #231f20"
      ></path>
      <path
        d="M110,36.39H98.28V70.18H110c10,0,16.36-7.39,16.36-16.85C126.37,43.34,120,36.39,110,36.39Zm0,27.08h-5.07V43.15H110c5.55,0,9.65,4.53,9.65,10.18S115.51,63.47,110,63.47Z"
        style="fill: #231f20"
      ></path>
      <polygon
        points="139.55 70.18 161.27 70.18 161.27 63.47 146.26 63.47 146.26 56.66 157.22 56.66 157.22 49.9 146.26 49.9 146.26 43.1 161.27 43.1 161.27 36.39 139.55 36.39 139.55 70.18"
        style="fill: #231f20"
      ></polygon>
      <path
        d="M198.39,46.81A10.51,10.51,0,0,0,188,36.39H174.3V70.18H181V57.29H185l9.17,12.89h8.21l-10-13.9A10.29,10.29,0,0,0,198.39,46.81Zm-11.68,3.82H181V43.1h5.75c3.38,0,5,1.69,5,3.71S190.09,50.63,186.71,50.63Z"
        style="fill: #231f20"
      ></path>
      <polygon
        points="235.17 56.33 216.92 36.05 213.98 36.05 213.98 70.18 220.64 70.18 220.64 50.29 238.84 70.52 241.83 70.52 241.83 36.39 235.17 36.39 235.17 56.33"
        style="fill: #231f20"
      ></polygon>
      <path
        d="M317.76,43h-9.41V70.52h-6.81V43H292V36.22h25.72Z"
        style="fill: #231f20"
      ></path>
      <path
        d="M337.07,43v6.91h11.12V56.8H337.07v6.91h15.24v6.81H330.26V36.22h22.05V43Z"
        style="fill: #231f20"
      ></path>
      <path
        d="M372.25,43v6.91h11.12V56.8H372.25v6.91h15.24v6.81H365.44V36.22h22.05V43Z"
        style="fill: #231f20"
      ></path>
      <path
        d="M400.72,35.88h3l18.52,20.58V36.22H429V70.86h-3L407.48,50.33V70.52h-6.76Z"
        style="fill: #231f20"
      ></path>
    </svg>

    <svg
      v-if="subBrand === 'PT' && !isResponsive"
      width="432"
      height="26"
      viewBox="0 0 370.84 21.61"
      :id="`logo-${subBrand.toLowerCase()}`"
    >
      <title>Pottery Barn Teen</title>
      <g fill="#434343">
        <path
          d="M303.65,0v6.01h3.97v2.58h-3.97v8.88c0,0.68,0.36,1.23,1.1,1.23c1.23,0,1.55-0.87,1.55-2.36v-2.52h2.58v2.87c0,3.16-1.55,4.91-5.43,4.91c-3.33,0-5.17-1.61-5.17-4.91V8.59h-2.16V6.01c3.23,0,4.07-3.33,4.33-6.01H303.65z"
        ></path>
        <path
          d="M317.91,14.31c0,2.26,0.52,4.52,3.23,4.52c1.13,0,2.97-0.87,3.26-2.78h3.75c-0.52,3.88-4.29,5.55-7.78,5.55c-5.52,0-8.17-3.07-8.17-8.04c0-5.01,3.55-8.04,8.17-8.04c5.26,0,8.2,3.75,8.2,8.78H317.91z M322.85,11.72v-0.84c0-1.65-0.87-2.78-2.42-2.78c-1.58,0-2.45,1.13-2.45,2.78v0.84H322.85z"
        ></path>
        <path
          d="M337.3,14.31c0,2.26,0.52,4.52,3.23,4.52c1.13,0,2.97-0.87,3.26-2.78h3.75c-0.52,3.88-4.29,5.55-7.78,5.55c-5.52,0-8.17-3.07-8.17-8.04c0-5.01,3.55-8.04,8.17-8.04c5.26,0,8.2,3.75,8.2,8.78H337.3z M342.24,11.72v-0.84c0-1.65-0.87-2.78-2.42-2.78c-1.58,0-2.45,1.13-2.45,2.78v0.84H342.24z"
        ></path>
        <path
          d="M357.92,6.01v2.49h0.06c1.42-2.07,2.94-2.97,5.55-2.97c3.68,0,4.91,2.13,4.91,5.49v6.14c0,0.9,0.23,1.39,1.16,1.39h1.23v2.58h-9.59v-2.58h0.68c0.94,0,1.16-0.48,1.16-1.39v-5.55c0-1.87-0.29-3.16-2.49-3.16c-1.49,0-2.42,1.39-2.42,2.75v5.97c0,0.9,0.23,1.39,1.16,1.39h0.78v2.58h-9.75v-2.58h1.29c0.94,0,1.16-0.48,1.16-1.39V9.98c0-0.9-0.23-1.39-1.16-1.39h-1.32V6.01H357.92z"
        ></path>
        <path
          d="M3.9,11.09v8.28h0.78v0.41H0v-0.41h0.76V2.09H0V1.68h5.49c0.38,0,1.78-0.08,2.58,0.43C8.94,2.66,9.19,3.4,9.19,4.31v4.3c0,0.46-0.11,1.26-0.97,1.83c-0.81,0.54-2.16,0.65-3.26,0.65H3.9z M6.04,3.27c0-0.35,0.02-0.61-0.19-0.87c-0.23-0.27-0.55-0.32-1-0.32H3.9v8.61h1.14c0.36,0,0.64-0.13,0.83-0.35c0.23-0.27,0.17-0.69,0.17-0.92V3.27z"
        ></path>
        <path
          d="M32.79,17.26c0,1.63-1.07,2.68-3.96,2.67c-3.09,0-3.92-1.04-3.92-2.67V6.32c0-1.95,1.36-2.94,4.01-2.96c2.54-0.01,3.87,1.27,3.87,2.96V17.26z M29.95,4.93c0-0.91-0.34-1.28-1.04-1.26c-0.75,0-1.18,0.33-1.18,1.26V18.4c0,0.68,0.22,1.21,1.07,1.21c0.84,0,1.14-0.34,1.14-1.21V4.93z"
        ></path>
        <path
          d="M57.91,3.49v3.28h-0.43V4.21h-2.4v15.19h0.7v0.37h-4.21v-0.37h0.68V4.21h-2.39v2.56h-0.43V3.49H57.91z"
        ></path>
        <path
          d="M83.04,3.49v3.28h-0.43V4.21h-2.41v15.19h0.7v0.37H76.7v-0.37h0.68V4.21h-2.39v2.56h-0.43V3.49H83.04z"
        ></path>
        <path
          d="M103.14,11.12h3.12V9.7h0.43v3.58h-0.43v-1.43h-3.12v7.2h3.12v-2.46h0.43v3.18h-7.08v-0.37h0.7V3.86h-0.7V3.49h7.08v3.18h-0.43V4.21h-3.12V11.12z"
        ></path>
        <path
          d="M129.58,11.43c0,0.1,1.04,0.18,1.76,0.67c0.73,0.5,0.8,1.02,0.8,1.75v5.56h0.7v0.37h-3.53v-6.51c0-0.51,0.12-1.01-0.37-1.31c-0.31-0.2-0.89-0.16-1.21-0.16h-0.95v7.6h0.68v0.37h-4.21v-0.37h0.7V3.86h-0.7V3.49h4.95c0.34,0,1.6-0.07,2.32,0.38c0.78,0.5,1.01,1.16,1.01,1.99v3.47c0,0.17,0.05,0.95-0.58,1.49C130.35,11.33,129.58,11.36,129.58,11.43z M128.68,4.93c0-0.31,0.05-0.54-0.17-0.78c-0.24-0.26-0.6-0.28-0.87-0.28h-0.87v7.59h1.02c0.32,0,0.58-0.11,0.75-0.31c0.21-0.24,0.14-0.63,0.14-0.82V4.93z"
        ></path>
        <path
          d="M150.99,13.96l-2.95-10.1h-0.7V3.49h4.21v0.37h-0.68l2.49,8.57l2.2-8.57h-0.7V3.49h2.3v0.37h-0.68l-2.66,10.13v5.41h0.68v0.37h-4.21v-0.37h0.7V13.96z"
        ></path>
        <path
          d="M183.56,13.06v4.07c0,0.69-0.04,1.2-0.53,1.75c-0.68,0.74-2.1,0.88-2.71,0.88h-6.82v-0.41h0.76V2.09h-0.76V1.68h6.5c0.81,0,1.42,0.14,2.03,0.6c1,0.76,0.91,1.69,0.91,1.71v4.34c0,0.02,0.04,0.89-0.61,1.42c-0.83,0.69-1.48,0.73-1.46,0.8c0,0.13,1.16,0.11,1.99,0.82C183.36,11.8,183.56,12.24,183.56,13.06z M177.41,19.36h1.69c0.06,0,0.63,0,0.91-0.22c0.3-0.25,0.42-0.52,0.42-0.88v-6.4c0-0.27-0.02-0.49-0.19-0.69c-0.19-0.22-0.51-0.28-0.87-0.28h-1.95V19.36z M177.41,2.09v8.31h1.65c0.06,0,0.4,0.02,0.61-0.21c0.19-0.21,0.13-0.51,0.13-0.66V3.16c0-0.06,0.04-0.66-0.13-0.87c-0.17-0.21-0.89-0.21-0.91-0.21H177.41z"
        ></path>
        <path
          d="M204.55,3.86h-0.7V3.49h4.35v0.37h-0.79l2.83,15.55h0.7v0.37h-4.26v-0.37h0.6l-0.68-4.55h-3.46l-0.94,4.55h0.7v0.37h-2.22v-0.37h0.61L204.55,3.86z M203.23,14.23h3.21l-1.5-8.85L203.23,14.23z"
        ></path>
        <path
          d="M233.82,11.43c0,0.1,1.04,0.18,1.76,0.67c0.73,0.5,0.8,1.02,0.8,1.75v5.56h0.7v0.37h-3.53v-6.51c0-0.51,0.12-1.01-0.38-1.31c-0.31-0.2-0.88-0.16-1.21-0.16h-0.95v7.6h0.68v0.37h-4.21v-0.37h0.7V3.86h-0.7V3.49h4.94c0.34,0,1.61-0.07,2.32,0.38c0.79,0.5,1.01,1.16,1.01,1.99v3.47c0,0.17,0.05,0.95-0.58,1.49C234.59,11.33,233.82,11.36,233.82,11.43z M232.92,4.93c0-0.31,0.05-0.54-0.17-0.78c-0.24-0.26-0.6-0.28-0.87-0.28h-0.87v7.59h1.02c0.32,0,0.58-0.11,0.75-0.31c0.2-0.24,0.14-0.63,0.14-0.82V4.93z"
        ></path>
        <path
          d="M258.62,19.78L255.2,6.25v13.16h0.7v0.37h-2.22v-0.37h0.68V3.86h-0.68V3.49h3.53l3.57,14.11V3.86h-0.68V3.49h2.22v0.37h-0.7v15.91H258.62z"
        ></path>
      </g>
      <rect x="278.98" y="0.02" width="1.3" height="21.57"></rect>
    </svg>

    <svg
      v-if="subBrand === 'PT' && isResponsive"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 225.88 79.85"
      style="enable-background: new 0 0 225.88 79.85"
      xml:space="preserve"
      :id="`logo-mobile-pt`"
    >
      <title>Pottery Barn Teen</title>
      <g>
        <rect x="28.66" y="29.98" width="168.57" height="1.29" />
        <g>
          <path
            d="M62.25,43.14v10.21H69v4.39h-6.75v15.09c0,1.15,0.6,2.09,1.86,2.09c2.09,0,2.63-1.48,2.63-4.01v-4.28h4.39v4.88
			c0,5.38-2.63,8.34-9.22,8.34c-5.65,0-8.78-2.74-8.78-8.34V57.74h-3.68v-4.39c5.49,0,6.91-5.65,7.35-10.21H62.25z"
          />
          <path
            d="M86.48,67.45c0,3.84,0.88,7.68,5.49,7.68c1.92,0,5.05-1.48,5.54-4.72h6.36c-0.88,6.59-7.3,9.44-13.22,9.44
			c-9.38,0-13.88-5.21-13.88-13.66c0-8.5,6.04-13.66,13.88-13.66c8.95,0,13.94,6.37,13.94,14.92H86.48z M94.88,63.06v-1.43
			c0-2.8-1.48-4.72-4.12-4.72c-2.69,0-4.17,1.92-4.17,4.72v1.43H94.88z"
          />
          <path
            d="M119.43,67.45c0,3.84,0.88,7.68,5.49,7.68c1.92,0,5.05-1.48,5.54-4.72h6.36c-0.88,6.59-7.3,9.44-13.22,9.44
			c-9.38,0-13.88-5.21-13.88-13.66c0-8.5,6.04-13.66,13.88-13.66c8.95,0,13.94,6.37,13.94,14.92H119.43z M127.82,63.06v-1.43
			c0-2.8-1.48-4.72-4.12-4.72c-2.69,0-4.17,1.92-4.17,4.72v1.43H127.82z"
          />
          <path
            d="M154.47,53.35v4.22h0.11c2.41-3.51,4.99-5.05,9.44-5.05c6.26,0,8.34,3.62,8.34,9.33v10.42c0,1.54,0.38,2.36,1.97,2.36
			h2.09v4.39h-16.3v-4.39h1.15c1.59,0,1.98-0.82,1.98-2.36v-9.44c0-3.18-0.49-5.38-4.22-5.38c-2.52,0-4.12,2.36-4.12,4.66v10.15
			c0,1.54,0.38,2.36,1.97,2.36h1.32v4.39h-16.57v-4.39h2.19c1.59,0,1.98-0.82,1.98-2.36V60.1c0-1.54-0.38-2.36-1.98-2.36h-2.25
			v-4.39H154.47z"
          />
        </g>
        <g>
          <path
            class="st0"
            d="M3.36,8.11v7.13h0.67v0.35H0v-0.35h0.65V0.36H0V0h4.73c0.33,0,1.53-0.07,2.22,0.37
			c0.75,0.48,0.96,1.11,0.96,1.9v3.7c0,0.39-0.1,1.09-0.83,1.58c-0.7,0.46-1.86,0.56-2.81,0.56H3.36z M5.2,1.38
			c0-0.3,0.02-0.53-0.16-0.75C4.85,0.4,4.57,0.36,4.18,0.36H3.36v7.41h0.98c0.31,0,0.56-0.11,0.72-0.3c0.2-0.23,0.15-0.6,0.15-0.79
			V1.38z"
          />
          <path
            class="st0"
            d="M28.23,13.42c0,1.41-0.92,2.31-3.41,2.3c-2.66,0-3.38-0.89-3.38-2.3V4c0-1.68,1.17-2.53,3.45-2.55
			c2.19-0.01,3.33,1.09,3.33,2.55V13.42z M25.79,2.8c0-0.78-0.29-1.1-0.9-1.09c-0.65,0-1.01,0.28-1.01,1.09v11.6
			c0,0.59,0.19,1.04,0.93,1.04c0.72,0,0.98-0.29,0.98-1.04V2.8z"
          />
          <path
            class="st0"
            d="M49.87,1.57v2.83H49.5v-2.2h-2.07v13.08h0.6v0.32h-3.63v-0.32h0.59V2.19h-2.06v2.2h-0.37V1.57H49.87z"
          />
          <path
            class="st0"
            d="M71.51,1.57v2.83h-0.37v-2.2h-2.07v13.08h0.6v0.32h-3.63v-0.32h0.59V2.19h-2.06v2.2h-0.37V1.57H71.51z"
          />
          <path
            class="st0"
            d="M88.82,8.14h2.69V6.91h0.37V10H91.5V8.76h-2.69v6.2h2.69v-2.12h0.37v2.74h-6.1v-0.32h0.6V1.88h-0.6V1.57h6.1
			v2.74H91.5V2.19h-2.69V8.14z"
          />
          <path
            class="st0"
            d="M111.59,8.41c0,0.09,0.9,0.16,1.51,0.57c0.63,0.43,0.69,0.88,0.69,1.5v4.79h0.6v0.32h-3.04v-5.6
			c0-0.44,0.1-0.87-0.32-1.13c-0.27-0.17-0.76-0.13-1.04-0.13h-0.82v6.55h0.59v0.32h-3.63v-0.32h0.6V1.88h-0.6V1.57h4.26
			c0.29,0,1.38-0.06,2,0.33c0.68,0.43,0.87,1,0.87,1.71v2.99c0,0.15,0.04,0.82-0.5,1.28C112.25,8.32,111.59,8.34,111.59,8.41z
			 M110.81,2.8c0-0.27,0.04-0.47-0.15-0.67c-0.21-0.22-0.51-0.24-0.75-0.24h-0.75v6.53h0.88c0.28,0,0.5-0.1,0.65-0.27
			c0.18-0.21,0.12-0.54,0.12-0.71V2.8z"
          />
          <path
            class="st0"
            d="M130.02,10.58l-2.54-8.7h-0.6V1.57h3.63v0.32h-0.59l2.14,7.38l1.9-7.38h-0.6V1.57h1.98v0.32h-0.59l-2.29,8.72
			v4.66h0.59v0.32h-3.63v-0.32h0.6V10.58z"
          />
          <path
            class="st0"
            d="M158.07,9.81v3.51c0,0.6-0.03,1.03-0.46,1.51c-0.59,0.64-1.81,0.76-2.33,0.76h-5.87v-0.35h0.65V0.36h-0.65V0
			h5.6c0.7,0,1.22,0.12,1.75,0.52c0.86,0.65,0.78,1.46,0.78,1.47v3.74c0,0.01,0.03,0.76-0.52,1.22c-0.72,0.6-1.27,0.63-1.26,0.69
			c0,0.11,1,0.09,1.71,0.71C157.89,8.72,158.07,9.1,158.07,9.81z M152.77,15.23h1.45c0.05,0,0.54,0,0.78-0.19
			c0.26-0.22,0.36-0.45,0.36-0.76V8.77c0-0.23-0.02-0.42-0.16-0.6c-0.16-0.19-0.44-0.24-0.75-0.24h-1.68V15.23z M152.77,0.36v7.15
			h1.42c0.05,0,0.34,0.01,0.52-0.18c0.16-0.18,0.11-0.44,0.11-0.57V1.28c0-0.06,0.03-0.57-0.11-0.75c-0.15-0.18-0.77-0.18-0.78-0.18
			H152.77z"
          />
          <path
            class="st0"
            d="M176.14,1.88h-0.6V1.57h3.75v0.32h-0.68l2.44,13.39h0.6v0.32h-3.67v-0.32h0.51l-0.59-3.92h-2.98l-0.81,3.92
			h0.6v0.32h-1.91v-0.32h0.53L176.14,1.88z M175.01,10.82h2.76l-1.29-7.62L175.01,10.82z"
          />
          <path
            class="st0"
            d="M201.35,8.41c0,0.09,0.9,0.16,1.51,0.57c0.63,0.43,0.69,0.88,0.69,1.5v4.79h0.6v0.32h-3.04v-5.6
			c0-0.44,0.1-0.87-0.32-1.13c-0.27-0.17-0.76-0.13-1.04-0.13h-0.82v6.55h0.59v0.32h-3.63v-0.32h0.6V1.88h-0.6V1.57h4.26
			c0.29,0,1.38-0.06,2,0.33c0.68,0.43,0.87,1,0.87,1.71v2.99c0,0.15,0.04,0.82-0.5,1.28C202.01,8.32,201.35,8.34,201.35,8.41z
			 M200.57,2.8c0-0.27,0.04-0.47-0.15-0.67c-0.21-0.22-0.51-0.24-0.75-0.24h-0.75v6.53h0.88c0.28,0,0.5-0.1,0.65-0.27
			c0.18-0.21,0.12-0.54,0.12-0.71V2.8z"
          />
          <path
            class="st0"
            d="M222.71,15.59l-2.95-11.65v11.33h0.6v0.32h-1.91v-0.32h0.59V1.88h-0.59V1.57h3.04l3.07,12.15V1.88h-0.59V1.57
			h1.91v0.32h-0.6v13.7H222.71z"
          />
        </g>
      </g>
    </svg>
  </span>
</template>

<script>
import { getSubBrand } from "../../util/context";
import EventBus from "../../util/event-bus";
import breakPoints from "../../mixins/breakPoints";
export default {
  name: "PTLogo",
  mixins: [breakPoints],
  data() {
    return {
      subBrand: getSubBrand(this).id,
      isResponsive: this.isMobile,
    };
  },
  created() {
    if (this.isMediumBreakPoint()) {
      this.isResponsive = true;
    }
  },
  mounted() {
    EventBus.$on("mediumBreakPoint", () => {
      this.isResponsive = true;
    });
    EventBus.$on("largeBreakPoint", () => {
      this.isResponsive = false;
    });
  },
};
</script>

<template>
  <div
    v-if="shouldShowEmailOverlay"
    data-style="join-email-campaign"
    id="join-email-campaign"
    class="join_email_capture_component"
    :class="[brand, { 'sweepstakes-email-overlay': isSweepsOverlay }]"
    data-component="Global-header-JoinEmailCampaign"
  >
    <template v-if="isMobileActive || this.isResponsive">
      <div class="mobile" v-if="showMobileCampaign">
        <a
          href="#"
          class="emailSignupClose"
          v-on:click.prevent="minimizeModal"
          title="Close"
          ><span class="offscreen">Close</span></a
        >
        <div class="mobile-email-signup-wrapper">
          <h3 class="mobile-email-header" v-show="mobileHeaderText">
            <template v-if="ecmEmailOverlay">
              <EcmContentSlot
                v-if="subBrand === 'WEK'"
                contentLocation="kids-email-overlay-copy-m/m"
              />
              <EcmContentSlot v-else contentLocation="email-overlay-copy-m/m" />
            </template>
            <span v-else v-html="mobileHeaderText"></span>
          </h3>
          <div
            class="email-campaign-header"
            v-if="config.enableEmailCampaignHeader"
          >
            <div :class="config.emailCampaignLogoClassName">
              <SearchFlyoutLogo />
            </div>
            <p class="stay-in-touch">STAY IN TOUCH</p>
            <div
              class="email-campaign-description"
              v-show="config.showCampaignDescription"
              v-html="config.emailCampaignDescription"
            />
          </div>
          <form
            id="join-email"
            class="mobile-email-signup-form"
            :class="`mobile-email-signup-form ${
              emailOptOutEnabledOnMobile ? 'email-opt-out-enabled' : ''
            }`"
            action="/m/customer-service/confirm-mobile-email-signup.html"
            v-on:submit.prevent="onSubmit"
          >
            <template v-if="mobileAgeSelector && showMobileAgeSelector">
              <h2 class="mobile-age-selector-label">Enter Your Birthday:*</h2>
              <span class="mobile-required">*Required Information</span>
              <div class="mobile-age-selector">
                <select
                  aria-label="month"
                  class="month"
                  required
                  v-model="month"
                >
                  <option value="">Month</option>
                  <option
                    v-for="(option, index) in months"
                    :key="index"
                    v-bind:value="option"
                  >
                    {{ option }}
                  </option>
                </select>
                <select aria-label="day" class="day" v-model="day">
                  <option value="">Day</option>
                  <option
                    v-for="(option, index) in days"
                    :key="index"
                    v-bind:value="option"
                  >
                    {{ option }}
                  </option>
                </select>
                <select aria-label="year" class="year" required v-model="year">
                  <option value="">Year</option>
                  <option
                    v-for="(option, index) in years"
                    :key="index"
                    v-bind:value="option"
                  >
                    {{ option }}
                  </option>
                </select>
              </div>
            </template>
            <div class="mobile-email-signup">
              <label
                for="mobileEmailAddr"
                class="mobile-email-signup-label"
              ></label>
              <input
                type="email"
                id="mobileEmailAddr"
                name="emailAddr"
                value=""
                class="emailField"
                :placeholder="mobile_email_placeholder_text"
                maxlength="75"
                novalidate=""
                autocomplete="off"
                required
                @focus="toggleAgeSelector()"
                pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                v-on:input="checkEmailValidity"
              />
              <input
                id="joinEmail"
                type="submit"
                class="mobile-email-signup-submit"
                value="Submit"
              />
            </div>
            <div
              class="email-opt-out-checkbox mobile-view"
              v-if="emailOptOutEnabledOnMobile"
            >
              <div class="email-opt-out-wrapper">
                <input
                  type="checkbox"
                  class="email-opt-out"
                  id="emailCampaignOptOut"
                  name="optOut"
                  @click="selectEmailOptOut"
                  :value="!isEmailOptedOut"
                  :checked="isEmailOptedOut"
                />
                <label
                  class="emailCampaignOptOut-updates-label"
                  for="emailCampaignOptOut"
                ></label>
              </div>
              <div
                v-html="emailOptOutLegaleseMessageForMobile"
                class="notification-text"
              ></div>
            </div>
            <MarketingNotification
              v-if="subBrand === 'WEK'"
              :isEmailSignUpEnabled="isWeEmailSignupEnabledOnWeKids"
              :isDefaultEmailOptInSelected="isWeDefaultSelected"
              :marketingNotificationText="
                joinEmailCampaignConfig.weNotificationText
              "
              :emailSignUp="'signUpDefault'"
              :flowType="'modern-baby-kids'"
              ref="marketingNotification"
            />
            <MarketingNotification
              v-if="subBrand === 'WE'"
              :isEmailSignUpEnabled="isWeKidsEmailSignupEnabledOnWe"
              :isDefaultEmailOptInSelected="isWeKidsDefaultSelected"
              :marketingNotificationText="
                joinEmailCampaignConfig.weKidsNotificationText
              "
              :emailSignUp="'signUpModernBabyKids'"
              :flowType="'default'"
              ref="marketingNotification"
            />
          </form>
          <div class="ccpa-text-info" v-show="showMobileCcpaText">
            <p v-html="config.financialIncentiveTerms"></p>
          </div>
        </div>
      </div>
      <div
        v-show="showThankYouSection && isResponsive"
        class="mobile accordionHeader joinEmailThankYou"
      >
        <a
          href="#"
          v-on:click.prevent="minimizeModal"
          class="emailSuccessClose"
          title="Close"
          ><span class="offscreen">Close</span></a
        >
        <h3 class="mobile-email-header-confirm">
          <template v-if="ecmEmailOverlay">
            <EcmContentSlot
              v-if="subBrand === 'WEK'"
              contentLocation="kids-email-overlay-copy-confirm-m/m"
            />
            <EcmContentSlot
              v-else
              contentLocation="email-overlay-copy-confirm-m/m"
            />
          </template>
          <template v-else>
            {{ mobile_header_confirm_text }}
          </template>
        </h3>
      </div>
    </template>

    <Modal v-show="showModal && !isResponsive" v-on:close="minimizeModal">
      <div
        v-show="showInitialEmailCapture"
        class="email-campaign-wrapper joinEmailList"
        :class="{ 'email-opt-out-enabled': emailOptOutEnabledOnDesktop }"
        :style="imageLink && { 'background-image': 'url(' + imageLink + ')' }"
      >
        <div class="WE_Logo" v-if="!ecmEmailOverlay && brand === 'WE'">
          <svg
            width="190px"
            height="34px"
            viewBox="0 0 385 69"
            version="1.1"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <title>West Elm</title>
            <desc>
              West Elm offers modern furniture and home decor featuring
              inspiring designs and colors.
            </desc>
            <defs></defs>
            <g
              class="west-elm__logo-outer"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g class="west-elm__logo" fill="#565458">
                <g class="west-elm__logo-inner">
                  <path
                    d="M371.8148,19.4978 C364.8178,19.5228 361.0758,22.9968 358.6098,25.6918 C357.5108,26.8938 357.2048,27.4498 356.6688,27.4278 C356.1328,27.4058 356.0068,26.8248 355.5038,25.8128 C354.9618,24.8318 352.6128,19.6708 345.5178,19.6708 C338.6058,19.6708 334.4318,23.7418 332.1598,26.0138 C331.6688,26.5048 330.7378,26.8378 330.7378,25.7858 L330.7378,21.8148 C330.7378,20.2208 330.3158,20.2208 327.7138,20.2308 C325.1838,20.2388 324.3938,20.2338 324.3978,21.8358 L324.3978,67.4848 C324.4038,68.7228 325.1888,68.6938 327.6778,68.6938 C329.6918,68.6938 330.8968,68.7588 330.8968,67.4848 L330.8968,44.7018 C330.8968,33.4868 336.1978,26.2378 342.9718,26.2148 C349.8498,26.1898 351.1028,32.3428 351.1208,37.1348 L351.1208,67.7578 C351.1208,69.1088 352.3128,68.6938 354.2088,68.6938 C356.1428,68.6938 357.4658,69.0668 357.4658,67.7578 L357.4658,44.7018 C357.4658,33.4868 362.5578,26.2438 370.4188,26.2148 C376.3148,26.1938 377.9758,31.0178 377.9938,35.7848 L377.9938,67.1848 C377.9938,68.7588 378.4718,68.6978 381.0588,68.6978 C383.6458,68.6978 384.4228,68.5458 384.4228,67.1848 L384.4228,34.0848 C384.3908,24.8628 379.0888,19.4728 371.8148,19.4978"
                    class="west-elm__logo-letter-w"
                  ></path>
                  <path
                    d="M303.7386,1.7078 C303.7386,0.6488 303.5866,-0.0002 302.4886,-0.0002 L291.6456,0.1828 C290.8496,0.1828 290.9726,0.8498 290.9726,3.1158 C290.9726,4.7848 290.9086,5.8518 291.6456,5.8518 C295.8476,5.8518 297.1596,7.7258 297.1596,10.4578 L297.1596,66.8798 C297.1656,68.3618 296.8706,68.4108 300.3226,68.3968 C303.8856,68.3858 303.7446,68.5348 303.7386,66.8548 L303.7386,1.7078 Z"
                    class="west-elm__logo-letter-e"
                  ></path>
                  <path
                    d="M90.349,23.4861 C94.786,23.4861 100.9,27.1031 100.919,32.8941 C100.935,36.9661 100.494,37.5201 99.422,37.5201 C99.006,37.5201 80.726,37.5821 78.958,37.5821 C77.768,37.5821 77.674,36.9341 77.674,35.8111 C77.674,30.9221 82.258,23.4861 90.349,23.4861 M78.614,43.4081 L102.541,43.3331 C104.461,43.3261 105.955,42.7391 106.545,41.0641 C107.183,38.7671 107.699,36.8341 107.405,32.3961 C106.657,20.6111 96.79,17.2071 90.635,17.2071 C77.199,17.2071 69.574,28.5071 69.574,43.6601 C69.574,52.1561 71.215,56.4881 74.749,61.7051 C78.313,66.9201 84.96,68.8911 90.411,68.8721 C95.83,68.8521 104.891,65.5941 106.798,63.3681 C107.161,62.9441 107.198,61.5961 106.909,60.1681 C106.661,58.9471 106.18,57.2551 105.303,57.5901 C103.826,58.1541 99.351,62.0121 91.243,62.0411 C77.353,62.0911 77.142,48.7351 77.031,46.0621 C76.924,43.4601 77.368,43.4121 78.614,43.4081"
                    class="west-elm__logo-letter-s"
                  ></path>
                  <path
                    d="M199.6927,60.0242 C198.2097,60.6092 196.8737,61.6432 192.9777,61.6562 C185.8687,61.6812 184.2837,56.1052 184.2667,51.4242 L184.2667,25.9182 C184.3067,24.8682 184.6607,24.6582 186.0087,24.6582 L199.6467,24.6192 C200.1847,24.6192 200.0857,23.6852 200.0857,21.3742 C200.0857,19.0642 200.2077,18.0142 199.3467,18.0142 L186.0087,18.0482 C184.8107,18.0482 184.2667,17.6722 184.2667,15.9642 L184.2667,3.9512 C184.2667,2.0892 184.0807,2.0502 180.9797,2.9942 C177.5887,4.0272 177.5887,4.0272 177.5887,5.3622 L177.5887,15.9642 C177.5887,17.2272 177.3037,18.0972 175.8187,18.0972 L170.7137,18.1052 C169.4737,18.1052 169.8577,19.5302 169.8637,21.3742 C169.8717,23.3262 169.8347,24.7152 170.7137,24.7152 L176.0297,24.6582 C177.0217,24.7532 177.5887,24.8002 177.5887,25.8092 L177.5887,47.2522 C177.6327,59.5082 179.2867,62.2122 182.0347,65.0162 C184.7737,67.8662 188.5457,68.6452 191.2337,68.6352 C193.9637,68.6252 197.8447,67.5332 200.3867,66.3942 C201.6877,65.8112 201.8227,65.3942 201.3237,62.6412 C200.8097,59.8102 200.4897,59.7092 199.6927,60.0242"
                    class="west-elm__logo-letter-t"
                  ></path>
                  <path
                    d="M144.0462,40.1712 C139.6982,38.1642 133.6222,36.9072 133.6222,30.9542 C133.6222,25.8852 138.4092,23.4322 141.7252,23.4322 C143.8342,23.4322 146.6552,24.0942 149.5152,25.2382 C152.3922,26.4422 152.3032,25.4252 153.4552,23.5242 C154.2782,22.1662 154.5062,20.8762 154.1352,20.5622 C152.3262,19.0352 147.4002,17.0622 141.7422,17.0812 C131.4782,17.1172 126.1382,23.6242 126.1652,30.9542 C126.1902,38.0302 132.1622,42.4902 137.7872,45.0882 C143.5582,47.7092 149.4152,49.6972 149.4322,54.5822 C149.4432,57.8142 146.6322,62.4962 140.7062,62.4962 C135.7602,62.4962 130.7202,59.5652 128.9872,58.7362 C128.0202,58.2742 127.6262,59.2942 126.8862,60.8482 C125.9492,62.8172 125.2812,64.2842 126.0202,64.7342 C129.5712,66.9002 135.6202,69.0152 140.6332,68.9982 C149.8442,68.9652 156.9872,63.3862 156.9562,54.4102 C156.9242,45.0212 148.3602,42.2422 144.0462,40.1712"
                    class="west-elm__logo-letter-e"
                  ></path>
                  <path
                    d="M259.9456,23.4861 C264.3826,23.4861 270.4966,27.1031 270.5156,32.8941 C270.5316,36.9661 270.0906,37.5201 269.0186,37.5201 C268.6026,37.5201 250.3226,37.5821 248.5546,37.5821 C247.3646,37.5821 247.2706,36.9341 247.2706,35.8111 C247.2706,30.9221 251.8546,23.4861 259.9456,23.4861 M248.2106,43.4081 L272.1376,43.3331 C274.0576,43.3261 275.5516,42.7391 276.1406,41.0641 C276.7796,38.7671 277.2956,36.8341 277.0006,32.3961 C276.2536,20.6111 266.3866,17.2071 260.2316,17.2071 C246.7956,17.2071 239.1696,28.5071 239.1696,43.6601 C239.1696,52.1561 240.8116,56.4881 244.3456,61.7051 C247.9096,66.9201 254.5566,68.8911 260.0076,68.8721 C265.4266,68.8521 274.4876,65.5941 276.3946,63.3681 C276.7576,62.9441 276.7946,61.5961 276.5056,60.1681 C276.2576,58.9471 275.7766,57.2551 274.8986,57.5901 C273.4226,58.1541 268.9476,62.0121 260.8396,62.0411 C246.9496,62.0911 246.7386,48.7351 246.6266,46.0621 C246.5206,43.4601 246.9646,43.4121 248.2106,43.4081"
                    class="west-elm__logo-letter-l"
                  ></path>
                  <path
                    d="M54.2894,18.7673 C51.6144,18.7673 50.8984,18.5543 50.4224,19.6233 C50.2634,19.9803 43.4834,54.7283 43.4834,54.7283 C43.2164,55.7573 43.0184,56.2023 42.4894,56.2023 C41.8964,56.2023 41.6484,55.9193 41.4524,55.0223 L31.7404,20.0703 C31.4934,19.0593 31.0674,18.6863 28.6924,18.6863 C26.3174,18.6863 25.7944,18.9323 25.5164,20.0703 L16.0884,55.0283 C15.8344,55.9113 15.5634,56.2023 14.9724,56.2023 C14.4424,56.2023 14.2104,55.1743 13.9414,54.1453 L6.6394,19.4513 C6.2244,18.1413 5.5714,18.4673 3.1334,18.4673 C0.7234,18.4673 -0.0206,18.5943 0.0004,19.9593 C0.0104,20.5883 10.3164,64.5483 10.7514,66.3863 C11.2744,68.3633 11.9414,69.0073 14.7264,68.9973 C17.9794,68.9863 18.3814,67.5733 18.8624,66.3863 L27.7124,34.9043 C28.0124,34.0203 28.0584,33.5393 28.6924,33.5393 C29.3274,33.5393 29.3734,34.0203 29.6744,34.9043 L38.5234,66.3863 C39.0054,67.5733 39.4064,68.9863 42.6604,68.9973 C45.4444,69.0073 46.1114,68.3633 46.6354,66.3863 C47.0694,64.5483 57.1274,20.2283 57.1114,19.7333 C57.0744,18.5623 56.1194,18.7673 54.2894,18.7673"
                    class="west-elm__logo-letter-m"
                  ></path>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <template>
          <template v-if="!isMobileActive && ecmEmailOverlay">
            <EcmContentSlot
              v-if="subBrand === 'WEK'"
              :callback="checkForSweepsOverlay"
              contentLocation="kids-email-overlay-copy"
              class="welcome_subtext ecm-content"
            />
            <EcmContentSlot
              v-else
              :callback="checkForSweepsOverlay"
              contentLocation="email-overlay-copy"
              class="welcome_subtext ecm-content"
            />
          </template>
          <template v-else>
            <h2 v-show="welcome_header_text">{{ welcome_header_text }}</h2>
            <div v-show="welcome_subtext" class="welcome_subtext">
              {{ welcome_subtext }}
            </div>
          </template>
        </template>
        <div class="email-campaign-header" v-show="brand === 'PB'">
          <div class="pb-logo">
            <svg
              width="407"
              height="29"
              viewBox="0 0 337.1 23.3"
              role="img"
              aria-labelledby="title"
              class="icon-cart"
            >
              <title id="title">Pottery Barn</title>
              <path
                class="st0"
                d="M5,12.1v10.6h1v0.5H0v-0.5h1V0.5H0V0h7.1c0.5,0,2.3-0.1,3.3,0.5c1.1,0.7,1.4,1.7,1.4,2.8v5.5c0,0.6-0.1,1.6-1.2,2.4c-1,0.7-2.8,0.8-4.2,0.8H5z M7.8,2.1c0-0.4,0-0.8-0.2-1.1C7.2,0.6,6.8,0.5,6.2,0.5H5v11.1h1.5c0.5,0,0.8-0.2,1.1-0.4c0.3-0.3,0.2-0.9,0.2-1.2V2.1z"
              ></path>
              <path
                class="st0"
                d="M42.1,19.8c0,2.1-1.4,3.5-5.1,3.4c-4,0-5-1.3-5-3.4V5.7c0-2.5,1.8-3.8,5.2-3.8c3.3,0,5,1.6,5,3.8V19.8zM38.5,3.9c0-1.2-0.4-1.6-1.3-1.6c-1,0-1.5,0.4-1.5,1.6v17.3c0,0.9,0.3,1.6,1.4,1.6c1.1,0,1.5-0.4,1.5-1.6V3.9z"
              ></path>
              <path
                class="st0"
                d="M74.4,2.3v4.2h-0.5V3.3h-3.1v19.5h0.9v0.5h-5.4v-0.5h0.9V3.3h-3.1v3.3h-0.5V2.3H74.4z"
              ></path>
              <path
                class="st0"
                d="M106.7,2.3v4.2h-0.5V3.3h-3.1v19.5h0.9v0.5h-5.4v-0.5h0.9V3.3h-3.1v3.3h-0.5V2.3H106.7z"
              ></path>
              <path
                class="st0"
                d="M132.5,12.1h4v-1.8h0.5v4.6h-0.5v-1.8h-4v9.3h4v-3.2h0.5v4.1H128v-0.5h0.9v-20H128V2.3h9.1v4.1h-0.5V3.3h-4V12.1z"
              ></path>
              <path
                class="st0"
                d="M166.5,12.5c0,0.1,1.3,0.2,2.3,0.9c0.9,0.6,1,1.3,1,2.2v7.1h0.9v0.5h-4.5v-8.4c0-0.7,0.2-1.3-0.5-1.7c-0.4-0.3-1.1-0.2-1.6-0.2h-1.2v9.8h0.9v0.5h-5.4v-0.5h0.9v-20h-0.9V2.3h6.4c0.4,0,2.1-0.1,3,0.5c1,0.6,1.3,1.5,1.3,2.6v4.5c0,0.2,0.1,1.2-0.7,1.9C167.5,12.4,166.5,12.5,166.5,12.5z M165.4,4.2c0-0.4,0.1-0.7-0.2-1c-0.3-0.3-0.8-0.4-1.1-0.4h-1.1v9.8h1.3c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.2-0.8,0.2-1.1V4.2z"
              ></path>
              <path
                class="st0"
                d="M194,15.8l-3.8-13h-0.9V2.3h5.4v0.5h-0.9l3.2,11l2.8-11H199V2.3h3v0.5h-0.9l-3.4,13v7h0.9v0.5h-5.4v-0.5h0.9V15.8z"
              ></path>
              <path
                class="st0"
                d="M235.9,14.6v5.2c0,0.9,0,1.5-0.7,2.3c-0.9,1-2.7,1.1-3.5,1.1H223v-0.5h1V0.5h-1V0h8.4c1,0,1.8,0.2,2.6,0.8c1.3,1,1.2,2.2,1.2,2.2v5.6c0,0,0,1.1-0.8,1.8c-1.1,0.9-1.9,0.9-1.9,1c0,0.2,1.5,0.1,2.6,1.1C235.6,13,235.9,13.6,235.9,14.6zM228,22.7h2.2c0.1,0,0.8,0,1.2-0.3c0.4-0.3,0.5-0.7,0.5-1.1v-8.2c0-0.3,0-0.6-0.2-0.9c-0.2-0.3-0.7-0.4-1.1-0.4H228V22.7zM228,0.5v10.7h2.1c0.1,0,0.5,0,0.8-0.3c0.2-0.3,0.2-0.6,0.2-0.9V1.9c0-0.1,0.1-0.9-0.2-1.1c-0.2-0.3-1.1-0.3-1.2-0.3H228z"
              ></path>
              <path
                class="st0"
                d="M262.9,2.8H262V2.3h5.6v0.5h-1l3.6,20h0.9v0.5h-5.5v-0.5h0.8l-0.9-5.8H261l-1.2,5.8h0.9v0.5h-2.8v-0.5h0.8L262.9,2.8z M261.2,16.1h4.1l-1.9-11.4L261.2,16.1z"
              ></path>
              <path
                class="st0"
                d="M300.5,12.5c0,0.1,1.3,0.2,2.3,0.9c0.9,0.6,1,1.3,1,2.2v7.1h0.9v0.5h-4.5v-8.4c0-0.7,0.2-1.3-0.5-1.7c-0.4-0.3-1.1-0.2-1.6-0.2h-1.2v9.8h0.9v0.5h-5.4v-0.5h0.9v-20h-0.9V2.3h6.4c0.4,0,2.1-0.1,3,0.5c1,0.6,1.3,1.5,1.3,2.6v4.5c0,0.2,0.1,1.2-0.7,1.9C301.5,12.4,300.5,12.5,300.5,12.5z M299.3,4.2c0-0.4,0.1-0.7-0.2-1c-0.3-0.3-0.8-0.4-1.1-0.4h-1.1v9.8h1.3c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.2-0.8,0.2-1.1V4.2z"
              ></path>
              <path
                class="st0"
                d="M332.4,23.3l-4.4-17.4v16.9h0.9v0.5H326v-0.5h0.9v-20H326V2.3h4.5l4.6,18.1V2.8h-0.9V2.3h2.8v0.5h-0.9v20.5H332.4z"
              ></path>
            </svg>
          </div>
          <p>Join our VIP list for inspiration, new arrivals & more.</p>
        </div>
        <div class="email-campaign-header" v-show="brand === 'RJ'">
          <div class="rj-logo">
            <RJLogo />
          </div>
          <p>Join our VIP list for inspiration, new arrivals & more.</p>
        </div>
        <div
          class="email-campaign-header"
          v-if="config.enableEmailCampaignHeader"
        >
          <div :class="config.emailCampaignLogoClassName">
            <SearchFlyoutLogo />
          </div>
          <p class="stay-in-touch">STAY IN TOUCH</p>
          <div
            class="email-campaign-description"
            v-show="config.showCampaignDescription"
            v-html="config.emailCampaignDescription"
          />
        </div>
        <div class="email-overlay-hero">
          <div class="email-overlay-form">
            <form
              name="join-email"
              id="join-email"
              class="email-campaign-form-sticky"
              v-on:submit.prevent="onSubmit"
            >
              <template v-if="show_age_selector">
                <h2 class="age-selector-label">Enter Your Birthday:*</h2>
                <span>*Required Information</span>
                <div class="age-selector">
                  <select
                    required
                    v-model="month"
                    name="birthdate.ageValidationBirthmonth"
                  >
                    <option value="">Month</option>
                    <option
                      v-for="(option, index) in months"
                      :key="index"
                      v-bind:value="option"
                    >
                      {{ option }}
                    </option>
                  </select>

                  <select
                    required
                    v-model="day"
                    name="birthdate.ageValidationBirthday"
                  >
                    <option value="">Day</option>
                    <option
                      v-for="(option, index) in days"
                      :key="index"
                      v-bind:value="option"
                    >
                      {{ option }}
                    </option>
                  </select>
                  <select
                    required
                    v-model="year"
                    name="birthdate.ageValidationBirthyear"
                  >
                    <option value="">Year</option>
                    <option
                      v-for="(option, index) in years"
                      :key="index"
                      v-bind:value="option"
                    >
                      {{ option }}
                    </option>
                  </select>
                </div>
              </template>
              <div class="email-campaign-form-wrapper-sticky">
                <div
                  class="error-section email-campaign-error-section-sticky"
                ></div>
                <input
                  id="emailCampaign"
                  type="hidden"
                  value="true"
                  name="emailCampaignStickyOverlay"
                />
                <fieldset class="email-campaign-fieldset-sticky">
                  <input
                    type="email"
                    id="emailAddr"
                    name="emailAddr"
                    aria-label="email"
                    value=""
                    :placeholder="getEmailPlaceholderText"
                    data-validate="true"
                    maxlength="75"
                    class="emailField"
                    required
                    ref="emailId"
                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                    v-on:input="checkEmailValidity"
                  />
                  <div
                    class="email-opt-out-checkbox"
                    v-if="emailOptOutEnabledOnDesktop"
                  >
                    <div class="email-opt-out-wrapper">
                      <input
                        type="checkbox"
                        class="email-opt-out"
                        id="emailCampaignOptOut"
                        name="optOut"
                        @click="selectEmailOptOut"
                        :value="!isEmailOptedOut"
                        :checked="isEmailOptedOut"
                      />
                      <label
                        class="emailCampaignOptOut-updates-label"
                        for="emailCampaignOptOut"
                      ></label>
                    </div>
                    <div
                      v-html="emailOptOutLegaleseMessageForDesktop"
                      class="notification-text"
                    ></div>
                  </div>
                </fieldset>
                <MarketingNotification
                  v-if="subBrand === 'WEK' && !isMobileActive"
                  :isEmailSignUpEnabled="isWeEmailSignupEnabledOnWeKids"
                  :isDefaultEmailOptInSelected="isWeDefaultSelected"
                  :marketingNotificationText="
                    joinEmailCampaignConfig.weNotificationText
                  "
                  :emailSignUp="'signUpDefault'"
                  :flowType="'modern-baby-kids'"
                  ref="marketingNotification"
                />
                <MarketingNotification
                  v-if="subBrand === 'WE' && !isMobileActive"
                  :isEmailSignUpEnabled="isWeKidsEmailSignupEnabledOnWe"
                  :isDefaultEmailOptInSelected="isWeKidsDefaultSelected"
                  :marketingNotificationText="
                    joinEmailCampaignConfig.weKidsNotificationText
                  "
                  :emailSignUp="'signUpModernBabyKids'"
                  :flowType="'default'"
                  ref="marketingNotification"
                />
                <div class="inline-message" v-if="showEmailInvalid">
                  <div id="emailAddr.error" class="validation-msg">
                    Enter a valid email
                  </div>
                </div>
                <template v-if="show_phone_number">
                  <input
                    type="tel"
                    pattern="[0-9]{10}"
                    id="marketingSmsInfo.phone"
                    name="marketingSmsInfo.phone"
                    aria-label="phone"
                    value=""
                    class="phone-number"
                    :placeholder="getMobileNumPlaceholderText"
                    ref="phoneNumber"
                  />
                  <div class="sms-opt-in-checkbox">
                    <div class="key-rewards-checkbox">
                      <input
                        type="checkbox"
                        class="sms-opt-in"
                        id="key-rewards"
                        name="marketingSmsInfo.marketingSmsPreference"
                        @click="toggleMobileField($event)"
                      />
                      <label
                        class="key-rewards-updates-label"
                        for="key-rewards"
                      ></label>
                    </div>
                    <div class="notification-text">
                      Enter your mobile number and select to receive automated
                      marketing text messages about new items, great savings and
                      more. You understand that consent is not required to make
                      a purchase. Message and data rates may apply. Message
                      frequency varies. Wireless Carriers are not liable for
                      delayed or undelivered messages. Text HELP for help and
                      STOP to cancel.
                    </div>
                  </div>
                  <div class="the-key-loyalty-program">
                    <div class="notification-info" id="sms-info-text">
                      For questions, Please
                      <a
                        href="/customer-service/email-us/?cm_type=lnav"
                        target="_blank"
                        >contact us</a
                      >.
                      <template v-if="brand === 'WS'">
                        <a
                          class="more-info"
                          href="/customer-service/legal-statement.html?cm_type=fnav#textmessages"
                          target="_blank"
                          >Terms
                        </a>
                      </template>
                      <template v-else>
                        <a
                          class="more-info"
                          href="/about-us/terms.html?cm_type=fnav#textmessages"
                          target="_blank"
                          >Terms
                        </a>
                      </template>
                    </div>
                  </div>
                </template>
                <div class="email-campaign-form-bottom">
                  <!-- FUTURE: Remove brand specific IF/ELSE condition -->
                  <template v-if="brand === 'WS' || brand === 'MG'">
                    <button
                      id="joinNow"
                      class="email-campaign-submit-button"
                      title="Submit"
                    >
                      Submit
                    </button>
                  </template>
                  <template v-else-if="brand === 'WE'">
                    <input
                      id="joinNow"
                      type="submit"
                      alt="sign up now →"
                      title="sign up →"
                      value="sign up now →"
                    />
                  </template>
                  <template v-else-if="config.enableCampaignFormBottom">
                    <button :title="config.enableCampaignFormBottomButtonText">
                      {{ config.enableCampaignFormBottomButtonText }}
                    </button>
                  </template>
                  <template v-else>
                    <button id="joinNowImg" title="Submit"></button>
                  </template>
                  <template v-if="show_no_thanks">
                    <div class="no-thanks" v-on:click="closeModal">
                      <a
                        href="#"
                        class="stickyHeaderCloseButton"
                        title="No, thanks"
                        >No, Thanks</a
                      >
                    </div>
                  </template>
                </div>
                <div
                  v-if="config.financialIncentiveTerms"
                  class="financial-incentive-terms-container"
                >
                  <span v-html="config.financialIncentiveTerms"></span>
                </div>
                <div
                  v-else-if="ecmEmailOverlay && !isMobileActive"
                  class="financial-incentive-terms-container ecm-content"
                >
                  <EcmContentSlot
                    v-if="subBrand === 'WEK'"
                    contentLocation="kids-email-overlay-copy-legal"
                  />
                  <EcmContentSlot
                    v-else
                    contentLocation="email-overlay-copy-legal"
                  />
                </div>
                <div
                  v-else-if="brand === 'WS'"
                  class="financial-incentive-terms-container"
                >
                  <p>
                    California residents: can see
                    <a
                      href="/customer-service/legal-statement.html#financial-incentives"
                      target="_blank"
                      >Financial Incentive Terms</a
                    >
                    for terms and can request
                    <a
                      href="/customer-service/legal-statement.html#do-not-sell"
                      target="_blank"
                      >Do Not Sell My Info</a
                    >. Please visit our
                    <a
                      href="/customer-service/legal-statement.html#privacy"
                      target="_blank"
                      >privacy policy</a
                    >
                    to learn how we use your information.
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="sticky-join-email-minimize">
          <a
            v-on:click.prevent="minimizeModal"
            href="#"
            class="stickyOverlayMinimizeButton"
            title="Minimize"
            ><span class="offscreen"></span
          ></a>
        </div>
      </div>

      <div
        id="additional-options"
        v-show="showThankYouSection"
        class="joinEmailListAdditionalOptions"
        :style="{ 'background-image': 'url(' + thankYouImageLink + ')' }"
      >
        <div class="moreOptionsFormWrapper">
          <div
            class="email-signup-intro"
            v-show="config.showCatalogRequestLink"
            v-if="!showFinalThankYouSection"
          >
            <h2>Want even more inspiration?</h2>
            <p>
              <a
                href="https://www.potterybarn.com/customer-service/catalog-request/"
                >Request</a
              >
              our catalog.
            </p>
          </div>
          <form
            v-show="showAdditionalOptions"
            v-on:submit.prevent="onSubmitMoreOptions"
            id="emailmeform"
            name="emailme"
            class="emailmeform"
          >
            <div
              :class="config.emailCampaignLogoClassName"
              v-if="showAdditionalThankYouLogo"
            >
              <SearchFlyoutLogo />
            </div>
            <h2
              class="thankYouHeader"
              v-if="brand != 'PB'"
              v-show="thankYouHeaderText"
            >
              {{ thankYouHeaderText }}
            </h2>
            <h2 v-if="thankYouNote" class="thankYouNote">
              {{ thankYouNote }}
            </h2>
            <p class="email-signup-intro" v-if="emailIntroText">
              {{ emailSignUpIntroText }}
            </p>
            <div
              class="email-benefits"
              v-if="!(brand == 'PB' || brand == 'RJ') && showEmailMePostalCode"
            >
              <fieldset class="zip-field">
                <label for="emailMeBean.address.zip">Zip Code:</label>
                <input
                  type="text"
                  id="emailMeBean.address.zip"
                  class="email-zip"
                  name="emailMeBean.address.zip"
                  value=""
                  maxlength="5"
                  placeholder="ENTER ZIP CODE"
                  pattern="[0-9]*"
                />
              </fieldset>
            </div>
            <input
              id="emailCampaign"
              type="hidden"
              value="true"
              name="emailCampaign"
            />
            <fieldset
              id="select-brand"
              class="selectBrand"
              v-if="showFamilyBrandsSignUpForm"
            >
              <legend />
              <p></p>
              <h2 v-show="brand !== 'WE'">
                Sign up to get emails <span>from our family of brands</span>
              </h2>
              <p></p>
              <ul v-if="brand === 'WS'" class="radio-checkbox form-group">
                <!-- FUTURE: Remove brand specific IF/ELSE condition -->
                <li class="pb group-child">
                  <input
                    type="checkbox"
                    id="3nav"
                    name="emailMeBean.catalogs"
                    class="prettycheckbox"
                    value="3"
                  /><label for="3nav"
                    ><span class="miniBox"></span>Pottery Barn</label
                  >
                </li>
                <li class="pk group-child">
                  <input
                    type="checkbox"
                    id="7nav"
                    name="emailMeBean.catalogs"
                    class="Prettycheckbox"
                    value="7"
                  /><label for="7nav"
                    ><span class="miniBox"></span>Pottery Barn Kids</label
                  >
                </li>
                <li
                  class="pt group-child"
                  v-if="!isPTAgeGateEnabled && isSupportedFamilyBrand"
                  data-test-id="pt-checkbox-1"
                >
                  <input
                    type="checkbox"
                    id="8nav"
                    name="emailMeBean.catalogs"
                    class="Prettycheckbox"
                    value="8"
                  /><label for="8nav"
                    ><span class="miniBox"></span>Pottery Barn Teen</label
                  >
                </li>
                <li class="we group-child">
                  <input
                    type="checkbox"
                    id="6nav"
                    name="emailMeBean.catalogs"
                    class="Prettycheckbox"
                    value="6"
                  /><label for="6nav"
                    ><span class="miniBox"></span>west elm</label
                  >
                </li>
                <li class="mg group-child">
                  <input
                    type="checkbox"
                    id="14nav"
                    name="emailMeBean.catalogs"
                    class="Prettycheckbox"
                    value="14"
                  /><label for="14nav"
                    ><span class="miniBox"></span>Mark and Graham</label
                  >
                </li>
                <li class="rj group-child">
                  <input
                    type="checkbox"
                    id="24nav"
                    name="emailMeBean.catalogs"
                    class="Prettycheckbox"
                    value="24"
                  /><label for="24nav"
                    ><span class="miniBox"></span>Rejuvenation</label
                  >
                </li>
              </ul>
              <ul v-else class="radio-checkbox form-group">
                <!-- FUTURE: Remove brand specific IF/ELSE condition -->
                <li class="ws group-child" v-show="brand !== 'WS'">
                  <input
                    type="checkbox"
                    id="0nav"
                    name="emailMeBean.catalogs"
                    class="Prettycheckbox"
                    value="0"
                  /><label for="0nav"
                    ><span class="miniBox"></span>Williams Sonoma</label
                  >
                </li>
                <li class="we group-child" v-show="brand !== 'WE'">
                  <input
                    type="checkbox"
                    id="6nav"
                    name="emailMeBean.catalogs"
                    class="Prettycheckbox"
                    value="6"
                  /><label for="6nav"
                    ><span class="miniBox"></span>west elm</label
                  >
                </li>
                <li class="pb group-child" v-show="brand !== 'PB'">
                  <input
                    type="checkbox"
                    id="3nav"
                    name="emailMeBean.catalogs"
                    class="Prettycheckbox"
                    value="3"
                  /><label for="3nav"
                    ><span class="miniBox"></span>Pottery Barn</label
                  >
                </li>
                <li
                  class="pt group-child"
                  v-if="!isPTAgeGateEnabled && isSupportedFamilyBrand"
                  data-test-id="pt-checkbox-2"
                >
                  <input
                    type="checkbox"
                    id="8nav"
                    name="emailMeBean.catalogs"
                    class="Prettycheckbox"
                    value="8"
                  /><label for="8nav"
                    ><span class="miniBox"></span>Pottery Barn Teen</label
                  >
                </li>
                <li
                  class="pk group-child"
                  v-show="
                    brand === 'WE' ||
                    brand === 'PB' ||
                    brand === 'PT' ||
                    brand === 'WS' ||
                    brand === 'RJ'
                  "
                >
                  <input
                    type="checkbox"
                    id="7nav"
                    name="emailMeBean.catalogs"
                    class="Prettycheckbox"
                    value="7"
                  /><label for="7nav"
                    ><span class="miniBox"></span>Pottery Barn Kids</label
                  >
                </li>
                <li class="mg group-child" v-show="brand !== 'MG'">
                  <input
                    type="checkbox"
                    id="14nav"
                    name="emailMeBean.catalogs"
                    class="Prettycheckbox"
                    value="14"
                  /><label for="14nav"
                    ><span class="miniBox"></span>Mark and Graham</label
                  >
                </li>
                <li class="rj group-child" v-show="brand !== 'RJ'">
                  <input
                    type="checkbox"
                    id="24nav"
                    name="emailMeBean.catalogs"
                    class="Prettycheckbox"
                    value="24"
                  /><label for="24nav"
                    ><span class="miniBox"></span>Rejuvenation</label
                  >
                </li>
              </ul>
            </fieldset>

            <fieldset class="catalogSelect" v-if="show_catalog">
              <input
                type="hidden"
                name="_catalogSelected"
                value="false"
              /><input
                type="checkbox"
                id="catalogSignUpCheck"
                class="catalogSignup"
                name="catalogSelected"
                v-model="showCatalog"
              /><label for="catalogSignUpCheck">
                {{ catalogSignUpCheckBoxText }}</label
              >
            </fieldset>
            <!-- eslint-disable-next-line vue/no-useless-template-attributes -->
            <template v-if="showCatalog" id="catalogSignUp">
              <fieldset class="addressblock">
                <div>
                  <fieldset>
                    <label for="catalogRequestBean.address.fullName">
                      Full Name<span class="required">*</span></label
                    >
                    <input
                      type="text"
                      id="catalogRequestBean.address.fullName"
                      name="catalogRequestBean.address.fullName"
                      value=""
                      maxlength="25"
                      placeholder="Full Name"
                      autocomplete="on"
                      autocorrect="off"
                      autocapitalize="words"
                      aria-required="true"
                      required
                    />
                  </fieldset>

                  <fieldset class="inline-message">
                    <div
                      id="catalogRequestBean.address.fullName.error"
                      class="validation-msg"
                    ></div>
                  </fieldset>
                </div>
                <div class="aosDCPickupToggle">
                  <div class="inline-display">
                    <fieldset class="address-line-1">
                      <label for="catalogRequestBean.address.addrLine1">
                        Address<span class="required">*</span></label
                      ><input
                        type="text"
                        id="catalogRequestBean.address.addrLine1"
                        name="catalogRequestBean.address.addrLine1"
                        value=""
                        maxlength="35"
                        placeholder="Address 1"
                        autocomplete="on"
                        autocorrect="off"
                        autocapitalize="words"
                        aria-required="true"
                        required
                      />
                    </fieldset>

                    <fieldset class="inline-message">
                      <div
                        id="catalogRequestBean.address.addrLine1.error"
                        class="validation-msg"
                      ></div>
                    </fieldset>
                  </div>
                  <div>
                    <fieldset class="address-line-2">
                      <label for="catalogRequestBean.address.addrLine2">
                        Address 2 (optional)</label
                      >
                      <input
                        type="text"
                        id="catalogRequestBean.address.addrLine2"
                        name="catalogRequestBean.address.addrLine2"
                        value=""
                        maxlength="35"
                        placeholder="Address 2"
                        autocomplete="on"
                        autocorrect="off"
                        autocapitalize="words"
                        aria-required="true"
                      />
                    </fieldset>
                    <fieldset class="inline-message">
                      <div
                        id="catalogRequestBean.address.addrLine2.error"
                        class="validation-msg"
                      ></div>
                    </fieldset>
                  </div>
                  <div class="clear-left">
                    <fieldset>
                      <label for="catalogRequestBean.address.city">
                        City<span class="required">*</span></label
                      >
                      <input
                        type="text"
                        id="catalogRequestBean.address.city"
                        name="catalogRequestBean.address.city"
                        value=""
                        maxlength="25"
                        placeholder="City"
                        autocomplete="on"
                        autocorrect="off"
                        autocapitalize="words"
                        aria-required="true"
                        required
                      />
                    </fieldset>

                    <fieldset class="inline-message">
                      <div
                        id="catalogRequestBean.address.city.error"
                        class="validation-msg"
                      ></div>
                    </fieldset>
                  </div>
                  <div class="state-zip">
                    <div class="inline-display">
                      <fieldset class="state zCS">
                        <label for="catalogRequestBean.address.state">
                          State<span class="required">*</span></label
                        ><select
                          name="catalogRequestBean.address.state"
                          id="catalogRequestBean.address.state"
                          aria-required="true"
                          required="true"
                        >
                          <option
                            v-for="state in stateList"
                            :key="state.value"
                            :value="state.value"
                          >
                            {{ state.label }}
                          </option>
                        </select>
                      </fieldset>
                    </div>
                    <div>
                      <fieldset class="zip zCS">
                        <label for="catalogRequestBean.address.zip"
                          >Zip<span class="required">*</span></label
                        >
                        <input
                          type="text"
                          id="catalogRequestBean.address.zip"
                          name="catalogRequestBean.address.zip"
                          value=""
                          maxlength="5"
                          placeholder="Zip"
                          class="zip-code"
                          autocomplete="on"
                          autocorrect="off"
                          autocapitalize="words"
                          aria-required="true"
                          pattern="[0-9]*"
                          required
                        />
                      </fieldset>
                    </div>
                  </div>
                </div>
              </fieldset>
              <fieldset id="select-brand-catalog" class="selectBrandCatalog">
                <p v-show="brand === 'PT' || brand === 'WS'">Select:</p>
                <ul v-if="brand === 'WS'" class="radio-checkbox form-group">
                  <!-- FUTURE: Remove brand specific IF/ELSE condition -->
                  <li class="ws group-child">
                    <input
                      type="checkbox"
                      id="0nav"
                      name="catalogRequestBean.catalogs"
                      class="Prettycheckbox"
                      value="0"
                      checked="checked"
                    /><label for="0nav"
                      ><span class="miniBox"></span>Williams Sonoma</label
                    >
                  </li>
                  <li class="pb group-child">
                    <input
                      type="checkbox"
                      id="3nav"
                      name="catalogRequestBean.catalogs"
                      class="Prettycheckbox"
                      value="3"
                    /><label for="3nav"
                      ><span class="miniBox"></span>Pottery Barn</label
                    >
                  </li>
                  <li class="pk group-child">
                    <input
                      type="checkbox"
                      id="7nav"
                      name="catalogRequestBean.catalogs"
                      class="Prettycheckbox"
                      value="7"
                    /><label for="7nav"
                      ><span class="miniBox"></span>Pottery Barn Kids</label
                    >
                  </li>
                  <li class="be group-child">
                    <input
                      type="checkbox"
                      id="cat9nav"
                      name="catalogRequestBean.catalogs"
                      class="Prettycheckbox"
                      value="9"
                    /><label for="cat9nav"
                      ><span class="miniBox"></span>Pottery Barn Bed +
                      Bath</label
                    >
                  </li>
                  <li class="we group-child">
                    <input
                      type="checkbox"
                      id="6nav"
                      name="catalogRequestBean.catalogs"
                      class="Prettycheckbox"
                      value="6"
                    /><label for="6nav"
                      ><span class="miniBox"></span>west elm</label
                    >
                  </li>
                  <li class="mg group-child">
                    <input
                      type="checkbox"
                      id="14nav"
                      name="catalogRequestBean.catalogs"
                      class="Prettycheckbox"
                      value="14"
                    /><label for="14nav"
                      ><span class="miniBox"></span>Mark and Graham</label
                    >
                  </li>
                </ul>
                <ul v-else class="radio-checkbox form-group">
                  <!-- FUTURE: Remove brand specific IF/ELSE condition -->
                  <li class="pt group-child" v-show="brand === 'PT'">
                    <input
                      type="checkbox"
                      id="3nav"
                      name="catalogRequestBean.catalogs"
                      class="Prettycheckbox"
                      value="3"
                    /><label for="3nav"
                      ><span class="miniBox"></span>Pottery Barn Teen</label
                    >
                  </li>
                  <li
                    class="pk group-child"
                    v-show="brand === 'PK' || brand === 'WS'"
                  >
                    <input
                      type="checkbox"
                      id="7nav"
                      name="catalogRequestBean.catalogs"
                      class="Prettycheckbox"
                      value="7"
                    /><label for="7nav"
                      ><span class="miniBox"></span>Pottery Barn Kids</label
                    >
                  </li>
                  <li
                    class="we group-child"
                    v-show="brand === 'WE' || brand === 'WS'"
                  >
                    <input
                      type="checkbox"
                      id="6nav"
                      name="catalogRequestBean.catalogs"
                      class="Prettycheckbox"
                      value="6"
                    /><label for="6nav"
                      ><span class="miniBox"></span>west elm</label
                    >
                  </li>
                  <li class="ws group-child" v-show="brand === 'WS'">
                    <input
                      type="checkbox"
                      id="0nav"
                      name="catalogRequestBean.catalogs"
                      class="Prettycheckbox"
                      value="0"
                    /><label for="0nav"
                      ><span class="miniBox"></span>Williams Sonoma</label
                    >
                  </li>
                  <li
                    class="pb group-child"
                    v-show="brand !== 'PB' || brand === 'WS'"
                  >
                    <input
                      type="checkbox"
                      id="3nav"
                      name="catalogRequestBean.catalogs"
                      class="Prettycheckbox"
                      value="3"
                    /><label for="3nav"
                      ><span class="miniBox"></span>Pottery Barn</label
                    >
                  </li>
                  <li
                    class="be group-child"
                    v-show="brand === 'PK' || brand === 'PT'"
                  >
                    <input
                      type="checkbox"
                      id="9nav"
                      name="catalogRequestBean.catalogs"
                      class="Prettycheckbox"
                      value="9"
                    /><label for="9nav"
                      ><span class="miniBox"></span>Pottery Barn Teen</label
                    >
                  </li>
                  <li
                    class="be group-child"
                    v-show="brand === 'WE' || brand === 'WS'"
                  >
                    <input
                      type="checkbox"
                      id="cat9nav"
                      name="catalogRequestBean.catalogs"
                      class="Prettycheckbox"
                      value="9"
                    /><label for="cat9nav"
                      ><span class="miniBox"></span>Pottery Barn Bed +
                      Bath</label
                    >
                  </li>
                  <li
                    class="od group-child"
                    v-show="brand === 'PK' || brand === 'PT' || brand === 'WE'"
                  >
                    <input
                      type="checkbox"
                      id="18nav"
                      name="catalogRequestBean.catalogs"
                      class="Prettycheckbox"
                      value="18"
                    /><label for="18nav"
                      ><span class="miniBox"></span>Pottery Barn Outdoor
                      Spaces</label
                    >
                  </li>
                  <li class="pk group-child" v-show="brand === 'WE'">
                    <input
                      type="checkbox"
                      id="7nav"
                      name="catalogRequestBean.catalogs"
                      class="Prettycheckbox"
                      value="7"
                    /><label for="7nav"
                      ><span class="miniBox"></span>Pottery Barn Kids</label
                    >
                  </li>
                  <li
                    class="mg group-child"
                    v-show="brand !== 'MG' || brand === 'WS'"
                  >
                    <input
                      type="checkbox"
                      id="14nav"
                      name="catalogRequestBean.catalogs"
                      class="Prettycheckbox"
                      value="14"
                    /><label for="14nav"
                      ><span class="miniBox"></span>Mark and Graham</label
                    >
                  </li>
                </ul>
              </fieldset>
            </template>
            <div
              v-if="
                !isEmailSignUpSuccessLegalDisclaimerBottom &&
                !showFinalThankYouSection &&
                showThankYouSection
              "
              class="success-financial-incentive-terms-container"
            >
              <p>
                California residents: can see
                <a
                  href="/customer-service/legal-statement.html#financial-incentives"
                  target="_blank"
                  >Financial Incentive Terms</a
                >
                for terms and can request
                <a
                  href="/customer-service/legal-statement.html#do-not-sell"
                  target="_blank"
                  >Do Not Sell My Info</a
                >. Please visit our
                <a
                  href="/customer-service/legal-statement.html#privacy"
                  target="_blank"
                  >privacy policy</a
                >
                to learn how we use your information.
              </p>
            </div>
            <fieldset class="actions">
              <!-- FUTURE: Remove brand specific IF/ELSE condition -->
              <template
                v-if="
                  brand === 'WS' ||
                  brand === 'MG' ||
                  brand === 'PB' ||
                  brand === 'RJ'
                "
              >
                <button
                  :id="
                    submitNowDynamicButtonId ? 'submitNowButton' : 'submitNow'
                  "
                  class="email-campaign-submit-button button"
                  v-on:submit.prevent="onSubmitMoreOptions"
                  value="Submit"
                  name="submit"
                  alt="Submit"
                >
                  Submit
                </button>
              </template>
              <template v-else-if="brand === 'WE'">
                <input
                  id="joinNow"
                  type="submit"
                  alt="sign up now →"
                  title="sign up now →"
                  value="sign up now →"
                />
              </template>
              <template v-else-if="show_actions">
                <button
                  class="submit-button"
                  title="Submit"
                  v-on:submit.prevent="onSubmitMoreOptions"
                ></button>
                <button
                  class="cancel-overlay"
                  title="Cancel"
                  v-on:click.prevent="minimizeModal"
                ></button>
              </template>
            </fieldset>
            <input
              type="hidden"
              id="emailMeBean.address.emailAddr"
              name="emailMeBean.address.emailAddr"
              value=""
            />
            <input
              type="hidden"
              id="catalogRequestBean.address.emailAddr"
              name="catalogRequestBean.address.emailAddr"
              value=""
            />
            <input
              id="metricsEmail"
              type="hidden"
              value=""
              v-model="submitted_metricsEmail"
              name="metricsEmail"
            />

            <div class="emailSubmitted hide" data-campaign-email="{email:''}">
              Email Submitted
            </div>
          </form>
        </div>
        <div
          class="continue-shopping"
          v-show="
            showFinalThankYouSection || config.showThankYouAfterEmailInput
          "
        >
          <template v-if="ecmEmailOverlay">
            <EcmContentSlot
              v-if="subBrand === 'WEK'"
              contentLocation="kids-email-overlay-copy-confirm"
            />
            <EcmContentSlot
              v-else
              contentLocation="email-overlay-copy-confirm"
            />
            <input
              id="joinNow"
              type="submit"
              alt="Start Shopping →"
              value="Start Shopping →"
              v-on:click="closeModal"
            />
          </template>
          <template v-else>
            <h2 class="we-title" v-if="we_title">west elm</h2>
            <h2 id="thank-you-hdr" v-show="brand !== 'PB'">
              {{ thankYouFinalHeader }}
            </h2>
            <h2 class="finalThankYou" v-show="brand !== 'PB'">
              {{ thankYouFinalHeaderText }}
            </h2>
            <template v-if="we_title">
              <input
                id="joinNow"
                type="submit"
                alt="Start Shopping →"
                value="Start Shopping →"
                v-if="showContinueOption"
                v-on:click="closeModal"
              />
            </template>
            <template v-else>
              <button
                class="continue"
                v-if="showContinueOption || config.showThankYouAfterEmailInput"
                v-on:click="closeModal"
              >
                Continue Shopping
              </button>
              <img
                v-if="finalThankYouImgEnabled"
                v-bind:src="finalThankYouImgSrc"
              />
            </template>
          </template>
        </div>
        <!-- FUTURE: Remove brand specific IF/ELSE condition -->
        <div
          class="visit-other-brands"
          v-show="
            brand === 'PT' ||
            brand === 'PK' ||
            brand === 'WE' ||
            brand === 'PB' ||
            brand === 'WS'
          "
          v-if="!showFinalThankYouSection && config.showVisitOtherBrands"
        >
          <template v-if="brand === 'PT'">
            <p class="pbteen-visit" v-if="isPTAgeGateEnabled">
              Visit <b>Pottery Barn Teen</b> to sign up for
              <a
                href="https://www.pbteen.com/customer-service/catalog-request?emailCampaign=false"
                target="_blank"
                ><u><b>catalogs</b></u></a
              >
            </p>
            <p class="rejuvenation-visit">
              Visit <b>Rejuvenation</b> to sign up for a
              <a
                href="https://www.rejuvenation.com/customer-service/request"
                target="_blank"
                ><u><b>catalog</b></u></a
              >
            </p>
          </template>
          <template v-if="brand === 'PK' || brand === 'WE' || brand === 'WS'">
            <p v-if="isPTAgeGateEnabled" data-test-id="pt-rj-links">
              Visit <strong>Pottery Barn Teen</strong> to sign up for
              <a
                href="https://www.pbteen.com/customer-service/email-list?emailCampaign=false"
                target="_blank"
                ><u><strong>emails</strong></u></a
              >
              &amp;
              <a
                href="https://www.pbteen.com/customer-service/catalog-request?emailCampaign=false"
                target="_blank"
                ><u><strong>catalogs</strong></u></a
              >
              and <strong>Rejuvenation</strong> to sign up for a
              <a
                href="https://www.rejuvenation.com/customer-service/request"
                target="_blank"
                rel="noopener noreferrer"
                ><u><strong>catalog.</strong></u></a
              >
            </p>
            <p v-if="!isPTAgeGateEnabled" data-test-id="rj-link">
              Visit <strong>Rejuvenation</strong> to sign up for a
              <a
                href="https://www.rejuvenation.com/customer-service/request"
                target="_blank"
                rel="noopener noreferrer"
                ><u><strong>catalog.</strong></u></a
              >
            </p>
          </template>
          <div
            v-if="brand === 'PB' && isPTAgeGateEnabled"
            data-test-id="pt-link"
          >
            Visit
            <a
              href="https://www.pbteen.com/customer-service/email-list?emailCampaign=false"
              target="_blank"
              >Pottery Barn Teen</a
            >
            to sign up for emails
          </div>
        </div>
        <div
          class="success-financial-incentive-terms-container"
          v-if="
            isEmailSignUpSuccessLegalDisclaimerBottom &&
            !showFinalThankYouSection &&
            showThankYouSection
          "
        >
          <span v-html="config.financialIncentiveTerms"></span>
        </div>
        <div class="sticky-join-email-minimize" v-show="brand === 'PB'">
          <a
            v-on:click.prevent="minimizeModal"
            href="#"
            class="stickyOverlayMinimizeButton"
            title="Minimize"
            ><span class="offscreen"></span
          ></a>
        </div>
      </div>
    </Modal>

    <div v-show="showStickyMinimized" class="stickyHeaderMinimized">
      <a
        v-on:click="closeModal"
        href="#"
        class="stickyHeaderCloseButton sticky-close-button"
        title="Close"
      ></a>
      <div
        class="joinEmailStickyHeader sticky-header"
        v-on:click="displayModal"
      >
        Ready to save 15%? Click to sign up.
      </div>
    </div>
  </div>
</template>

<script>
import { addEvent } from "@js-ecom-tracking/datalayer";
import Modal from "../Modal.vue";
import EcmContentSlot from "../EcmContentSlot.vue";
import MarketingNotification from "../MarketingNotification.vue";
import {
  getConfigById,
  getBrandConcept,
  getSubBrand,
} from "../../util/context";
import EventBus from "../../util/event-bus";
import { getCookie } from "../../util/cookies";
import { formSubmit } from "../../services/formSubmit";
import { logger } from "@js-ecom-mfe/logger";
import {
  REPONAME,
  EMAIL_OVERLAY_SUPPRESSION_DURATION_IN_DAYS,
} from "../../util/constants";
import breakPoints from "../../mixins/breakPoints";
import {
  browserSessionStorageAvailable,
  saveItemToSessionStorage,
  getItemFromSessionStorage,
} from "@js-ecom-mfe/browser-storage";
import { loadScript } from "../../util/scriptHandler.js";
import RJLogo from "../../components/brand_logo/RJLogo.vue";
import SearchFlyoutLogo from "./SearchFlyout/SearchFlyoutLogo.vue";
import Cookies from "js-cookie";
const fileName = "JoinEmailCampaign.vue";

export default {
  name: "join-email-campaign",
  components: {
    Modal,
    EcmContentSlot,
    MarketingNotification,
    RJLogo,
    SearchFlyoutLogo,
  },
  mixins: [breakPoints],
  data() {
    const emailCampaignConfig = getConfigById("joinEmailCampaign", this) ?? {};
    const emailOptOutConsentConfig =
      emailCampaignConfig?.emailOptOutConsent ?? {};

    return {
      // isSweepsOverlay is needed to track if 'email-overlay-copy' content slot contains a sweepstakes overlay format
      // This data is used to dynamically apply the class 'sweepstakes-email-overlay' ensuring that the proper styles are applied to the entire component
      isSweepsOverlay: false,
      showModal: false,
      showInitialEmailCapture: true,
      showAdditionalOptions: false,
      showStickyMinimized: false,
      showThankYouSection: false,
      showFinalThankYouSection: false,
      supportAdditionalOptions: false,
      showMobileCampaign: false,
      mobileAgeSelector: false,
      isResponsive: false,
      showContinueOption: false,
      showCatalog: false,
      showMobileCcpaText: false,
      showEmailInvalid: false,
      brand: getBrandConcept(this).toUpperCase(),
      cookieName: `ad_sess_${getBrandConcept(this).toLowerCase()}_email`,
      isWeKidsEmailSignupEnabledOnWe:
        emailCampaignConfig?.isWeKidsEmailSignupEnabledOnWe || false,
      isWeKidsDefaultSelected:
        emailCampaignConfig?.isWeKidsDefaultSelected || false,
      isWeEmailSignupEnabledOnWeKids:
        emailCampaignConfig?.isWeEmailSignupEnabledOnWeKids || false,
      isWeDefaultSelected: emailCampaignConfig?.isWeDefaultSelected || false,
      isPTAgeGateEnabled: emailCampaignConfig?.isPTAgeGateEnabled ?? false,
      joinEmailCampaignConfig: emailCampaignConfig || {},
      config: this.getConfig(),
      submitNowDynamicButtonId:
        emailCampaignConfig?.submitNowDynamicButtonId || false,
      showFamilyBrandsSignUpForm:
        emailCampaignConfig?.showFamilyBrandsSignUpForm ?? true,
      showEmailMePostalCode: emailCampaignConfig?.showEmailMePostalCode ?? true,
      thankYouNote: emailCampaignConfig?.thank_you_note ?? false,
      showAdditionalThankYouLogo:
        emailCampaignConfig?.showAdditionalThankYouLogo ?? false,
      suppressionDurationInDays:
        emailCampaignConfig?.suppressionDurationInDays ??
        EMAIL_OVERLAY_SUPPRESSION_DURATION_IN_DAYS,
      day: "",
      month: "",
      year: "",
      subBrand: getSubBrand(this).id,
      months: Array.from({ length: 12 }, (x, index) =>
        new Date(new Date().getFullYear(), index).toLocaleDateString("en-US", {
          month: "long",
        })
      ),
      years: Array.from({ length: 100 }).map(
        (ele, index) => new Date().getFullYear() - index
      ),
      analytics: {},
      submitted_metricsEmail: "",
      isEmailOptedOut: false,
      emailOptOutEnabledOnDesktop:
        emailOptOutConsentConfig?.desktop?.enable ?? false,
      emailOptOutEnabledOnMobile:
        emailOptOutConsentConfig?.mobile?.enable ?? false,
      emailOptOutLegaleseMessageForDesktop:
        emailOptOutConsentConfig?.desktop?.legaleseMessage ?? "",
      emailOptOutLegaleseMessageForMobile:
        emailOptOutConsentConfig?.mobile?.legaleseMessage ?? "",
      isJoinEmailDesktopCampaignEnabled:
        emailCampaignConfig?.desktop?.isEnabled || false,
      isJoinEmailMobileCampaignEnabled:
        emailCampaignConfig?.mobile?.isEnabled || false,
      shouldShowEmailSignUpModal: false,
      showEmailSignUpModal: false,
    };
  },
  computed: {
    // decides if the whole JoinEmailCampaign component should be rendered
    shouldShowEmailOverlay() {
      return (
        (this.showMarketingCampaignOverlay && this.isJoinEmailOverlayEnabled) ||
        this.showEmailSignUpModal
      );
    },
    isSupportedFamilyBrand() {
      return this.brand === "PK" || this.brand === "PB" || this.brand === "WS";
    },
    imageLink() {
      return this.config.img_link ? "'" + this.config.img_link + "'" : "";
    },
    thankYouImageLink() {
      return this.showFinalThankYouSection
        ? this.config.more_options_thank_you_img_link
        : this.config.thank_you_img_link;
    },
    ecmEmailOverlay() {
      return this.config.ecmEmailOverlay;
    },
    supportStickyMinimized() {
      return this.config.support_sticky_minimized;
    },
    thankYouHeaderText() {
      return this.config.thank_you_header_text;
    },
    thankYouFinalHeaderText() {
      return this.config.thank_you_final_header_text;
    },
    thankYouFinalHeader() {
      return this.config.thank_you_final_header;
    },
    welcome_header_text() {
      return this.config.welcome_header_text;
    },
    welcome_subtext() {
      return this.config.welcome_subtext;
    },
    mobileHeaderText() {
      return this.config.mobile_header_text;
    },
    stateList() {
      return this.config.state_list;
    },
    show_phone_number() {
      return this.config.show_phone_number;
    },
    show_age_selector() {
      return this.config.show_age_selector;
    },
    show_no_thanks() {
      return this.config.show_no_thanks;
    },
    show_catalog() {
      return this.config.show_catalog;
    },
    show_actions() {
      return this.config?.show_actions;
    },
    we_title() {
      return this.config.we_title;
    },
    emailIntroText() {
      return this.config.emailIntroText;
    },
    emailSignUpIntroText() {
      return this.config.emailSignUpIntroText;
    },
    catalogSignUpCheckBoxText() {
      return this.config.catalogSignUpCheckBoxText;
    },
    finalThankYouImgSrc() {
      return this.config.finalThankYouImgSrc;
    },
    finalThankYouImgEnabled() {
      return this.config.finalThankYouImgEnabled;
    },
    mobile_header_confirm_text() {
      return this.config.mobile_header_confirm_text;
    },
    mobile_email_placeholder_text() {
      return this.config.mobile_email_placeholder_text;
    },
    showMobileAgeSelector() {
      return this.config.showMobileAgeSelector;
    },
    days() {
      return Array.from({
        length: new Date(
          this.year,
          this.months.indexOf(this.month) + 1,
          0
        ).getDate(),
      }).map((ele, index) => index + 1);
    },
    isEmailSignUpSuccessLegalDisclaimerBottom() {
      return (
        getConfigById("joinEmailCampaign", this)
          ?.isEmailSignUpSuccessLegalDisclaimerBottom ?? true
      );
    },
    /*
     * It checks if site overlay is enabled or disabled from brand config flag.
     * First it checks the view size using isMobile flag, if true it checks isJoinEmailMobileCampaignEnabled otherwise isJoinEmailDesktopCampaignEnabled.
     * @returns {boolean} - Returns boolean value".
     */
    isJoinEmailOverlayEnabled() {
      return this.isMobile
        ? this.isJoinEmailMobileCampaignEnabled
        : this.isJoinEmailDesktopCampaignEnabled;
    },
    /*
     * It checks if the desktop mobile number input field placeholder text is customized from brand config.
     * First it checks if joinEmailCampaignConfig exists, if true it checks desktopPhonePlaceholderText and returns that string if it is set.
     * Otherwise if joinEmailCampaignConfig or desktopPhonePlaceholderText are null, it defaults the placeholder text to "Enter mobile number (Optional)"
     * @returns {string} - Returns string value
     */
    getMobileNumPlaceholderText() {
      return (
        this.joinEmailCampaignConfig?.desktopPhonePlaceholderText ||
        "Enter mobile number (Optional)"
      );
    },
    /*
     * It checks if the desktop email input field placeholder text is customized from brand config.
     * First it checks if joinEmailCampaignConfig exists, if true it checks desktopEmailPlaceholderText and returns that string if it is set.
     * Otherwise if joinEmailCampaignConfig or desktopEmailPlaceholderText are null, it defaults the placeholder text to "Enter your email address *"
     * @returns {string} - Returns string value
     */
    getEmailPlaceholderText() {
      return (
        this.joinEmailCampaignConfig?.desktopEmailPlaceholderText ||
        "Enter your email address *"
      );
    },
  },
  props: {
    isMobileActive: {
      type: Boolean,
      default: () => false,
    },
    showMarketingCampaignOverlay: {
      type: Boolean,
      default: () => true,
    },
  },
  beforeMount() {
    if (this.isMediumBreakPoint()) {
      this.isResponsive = true;
    }
  },
  mounted() {
    EventBus.$on("mediumBreakPoint", () => {
      if (
        getItemFromSessionStorage("mobileJoinEmail", "show") === null ||
        getItemFromSessionStorage("mobileJoinEmail", "show")
      ) {
        this.isResponsive = true;
        this.showMobileCampaign = true;
        if (browserSessionStorageAvailable()) {
          saveItemToSessionStorage("mobileJoinEmail", "show", true);
        }
      }
    });
    this.supportAdditionalOptions = this.config.support_more_options;

    if (this.hasWelcomeCookie()) {
      saveItemToSessionStorage("mobileJoinEmail", "show", false);
      this.showMobileCampaign = false;
    }

    if (!this.hasWelcomeCookie() && !this.isResponsive) {
      this.displayModal();
    }
    WSI.state.onChange("emailSignUp", ({ email }) => {
      if (this.$refs.emailId) {
        this.$refs.emailId.value = email;
      }
      this.showInitialEmailCapture = true;
      this.shouldShowEmailSignUpModal = true;
      if (this.emailIsValid(email)) {
        // first argument is null because this function is also used an DOM event handler with event as first argument
        this.onSubmit(null, email);
      } else {
        this.showEmailInvalid = true;
        this.displayModal();
      }
    });
    if (this.brand === "WE" || this.subBrand === "WEK") {
      WSI.state.onChange(
        "footerEmailSignUp",
        ({ email, isEmailOptIn, flowTypeValue }) => {
          if (this.$refs.emailId) {
            this.$refs.emailId.value = email;
          }

          if (this.$refs.marketingNotification?.$refs.flowType) {
            this.$refs.marketingNotification.$refs.flowType.value =
              flowTypeValue;
          }
          if (this.$refs.marketingNotification?.$refs.notificationOptIn) {
            this.$refs.marketingNotification.$refs.notificationOptIn.value =
              isEmailOptIn;
            this.$refs.marketingNotification.$refs.notificationOptIn.checked =
              isEmailOptIn;
          }
          this.showInitialEmailCapture = true;
          this.shouldShowEmailSignUpModal = true;
          if (this.emailIsValid(email)) {
            // first argument is null because this function is also used an DOM event handler with event as first argument
            this.onSubmit(null, email);
          } else {
            this.showEmailInvalid = true;
            this.displayModal();
          }
        }
      );
    }
    this.analytics.addEvent = addEvent;
  },
  methods: {
    removeModalClass() {
      document.querySelector("body").classList.remove("modal-open");
    },
    async onSubmit(event, emailAddr) {
      const url = "/customer-service/join-email-confirm.jsonp";
      if (
        this.brand === "PT" &&
        this.calculateAge(
          this.months.indexOf(this.month),
          this.day,
          this.year
        ) < 13
      ) {
        location.replace(
          "https://www.pbteen.com/customer-service/too-young.html"
        );
      } else {
        try {
          // if 'emailAddr' is present it means that this function is called from footer,
          // so we can pass this value without need to crawl through DOM elements
          const queryParams = {
            emailAddr,
            emailCampaignStickyOverlay: "true",
          };
          const res = await formSubmit("join-email", url, queryParams);
          if (res.status === 200) {
            const responseData = res.data;

            // FUTURE: We need a better way to extract metricsEmail value(hashed email). The backend API could send data in a JSON format instead of a HTML response that contains the hashedEmail
            const hashedEmail =
              responseData.indexOf("metricsEmail") !== -1
                ? responseData.substring(
                    responseData.indexOf("metricsEmail") + 35,
                    responseData.lastIndexOf("metricsEmail") - 8
                  )
                : "";
            this.submitted_metricsEmail = hashedEmail;
            if (this.config.enableCRE) {
              const formElement = document.getElementById("join-email");
              const email =
                formElement.getElementsByClassName("emailField")[0].value;
              let isEmailOptIn =
                formElement.getElementsByClassName("email-opt-in")[0];
              if (isEmailOptIn) {
                if (this.brand === "WE" && this.subBrand !== "WEK") {
                  await loadScript(
                    `https://lookup.collectandgather.com/email-opt-in-service/v1/${this.brand}/${email}?callBack=parseEmail`
                  );
                } else if (this.subBrand === "WEK") {
                  await loadScript(
                    `https://lookup.collectandgather.com/email-opt-in-service/v1/WEK/${email}?callBack=parseEmail`
                  );
                }
              } else {
                // FUTURE: add in config for environment specific
                await loadScript(
                  `https://lookup.collectandgather.com/email-opt-in-service/v1/${this.brand}/${email}?callBack=parseEmail`
                );
              }
            }
            this.showInitialEmailCapture = false;
            this.showMobileCampaign = false;
            if (browserSessionStorageAvailable()) {
              saveItemToSessionStorage("mobileJoinEmail", "show", false);
            }
            this.showThankYouSection = true;
            this.showAdditionalOptions = this.supportAdditionalOptions;
            this.displayModal();
            const verifySmsOptIn = this.$refs.phoneNumber?.required || false;
            // DP behind ADG pages to use DP 'WSI.dataLayer._addEvent' instead of analytics.adEvent to record email/sms data
            if (typeof WSI.dataLayer._addEvent === "function") {
              WSI.dataLayer._addEvent({
                name: "elementInteraction",
                data: {
                  category: "email overlay",
                  item: "email signup",
                  email: hashedEmail,
                  includesSms: verifySmsOptIn,
                },
              });
            } else {
              this.analytics.addEvent({
                category: "email overlay",
                item: "email signup",
                email: hashedEmail,
                includesSms: verifySmsOptIn,
              });
            }
            // remove modal-open class when modal close
            this.removeModalClass();
          } else {
            logger.error(
              REPONAME,
              fileName,
              "onSubmit",
              `Did not get 200 response for email signup`
            );
          }
        } catch (e) {
          logger.error(
            REPONAME,
            fileName,
            "onSubmit",
            `Error getting response for email signup: ${e}`
          );
        }
      }
      return false;
    },
    async onSubmitMoreOptions() {
      const url = "/customer-service/additional-options/index.jsonp";
      try {
        const res = await formSubmit("emailmeform", url);
        if (res.status === 200) {
          this.showInitialEmailCapture = false;
          this.showAdditionalOptions = false;
          this.showFinalThankYouSection = true;
          this.showContinueOption = true;
        }
      } catch (e) {
        logger.error(
          REPONAME,
          fileName,
          "onSubmitMoreOptions",
          `Error getting response for email signup: ${e}`
        );
      }

      return false;
    },
    toggleAgeSelector() {
      this.mobileAgeSelector = true;
      this.showMobileCcpaText = true;
    },
    selectEmailOptOut() {
      this.isEmailOptedOut = !this.isEmailOptedOut;
    },
    /**
     * Given a birth date return the age of the user.
     * @param {String} birthMonth the birth month zero offset
     * @param {String} birthDay the day of birth
     * @param {String} birthYear the year of birth
     * @returns {Integer} the age of the user
     */
    calculateAge(birthMonth, birthDay, birthYear) {
      // make sure the month is a valid index
      const currentBirthMonth = parseInt(birthMonth, 10);
      if (
        currentBirthMonth < 0 ||
        currentBirthMonth > 11 ||
        isNaN(currentBirthMonth)
      ) {
        // set month to January
        birthMonth = "0";
      }

      // make sure the day is valid (even for months without 31 days 31 is valid)
      const currentBirthDay = parseInt(birthDay, 10);
      if (
        currentBirthDay < 1 ||
        currentBirthDay > 31 ||
        isNaN(currentBirthDay)
      ) {
        birthDay = "31";
      }

      // validate the year
      const currentBirthYear = parseInt(birthYear, 10);
      if (
        currentBirthYear < 1900 ||
        currentBirthYear > 9999 ||
        isNaN(currentBirthYear)
      ) {
        birthYear = "1970";
      }

      // convert input form to a date; the birthMonth is a zero offset therefore add one to it
      const dob = new Date(`${birthMonth + 1}/${birthDay}/${birthYear}`);
      const monthDifference = Date.now() - dob.getTime();
      const ageDifferenceInDateFormat = new Date(monthDifference);
      const yearFromDate = ageDifferenceInDateFormat.getUTCFullYear();

      // return the age of the user by using the ECMAScript epoch
      return Math.abs(yearFromDate - 1970);
    },
    closeModal() {
      this.showModal = false;
      this.showStickyMinimized = false;
      this.showThankYouSection = false;
      this.showFinalThankYouSection = false;
      this.shouldShowEmailSignUpModal = false;
      this.showEmailSignUpModal = false;
      this.removeModalClass();
      this.setWelcomeCookie();
    },
    minimizeModal() {
      this.showModal = false;
      this.showMobileCampaign = false;
      this.shouldShowEmailSignUpModal = false;
      this.showEmailSignUpModal = false;
      if (browserSessionStorageAvailable()) {
        saveItemToSessionStorage("mobileJoinEmail", "show", false);
      }
      this.showThankYouSection = false;
      this.showStickyMinimized = this.supportStickyMinimized;
      this.removeModalClass();
      this.setWelcomeCookie();
    },
    displayModal() {
      this.showEmailSignUpModal = this.shouldShowEmailSignUpModal;
      this.showModal = true;
      this.showStickyMinimized = false;
      if (this.shouldShowEmailOverlay) {
        document.querySelector("body").classList.add("modal-open");
      }
      this.setWelcomeCookie();
    },
    hasWelcomeCookie() {
      return !!getCookie(this.cookieName);
    },
    setWelcomeCookie() {
      Cookies.set(this.cookieName, true, {
        expires: this.suppressionDurationInDays,
        path: "/",
      });
    },
    toggleMobileField(event) {
      let mobileField = this.$refs.phoneNumber;
      event.target.checked
        ? (mobileField.required = true)
        : (mobileField.required = false);
    },
    emailIsValid(email) {
      const emailRegex =
        /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
      return emailRegex.test(email);
    },
    getConfig() {
      const config = getConfigById("joinEmailCampaign", this);
      if (!config) {
        console.warn(`Config error in JoinEmailCampaign.vue.`);
        return {};
      }
      return {
        img_link: config.img_link || "",
        thank_you_img_link: config.thank_you_img_link || "",
        more_options_thank_you_img_link:
          config.more_options_thank_you_img_link || "",
        support_sticky_minimized: config.show_sticky_minimized || false,
        support_more_options: config.show_more_options || false,
        thank_you_header_text: config.thank_you_header_text || "",
        thank_you_final_header_text: config.thank_you_final_header_text || "",
        thank_you_final_header: config.thank_you_final_header || "",
        welcome_header_text: config.welcome_header_text || "",
        welcome_subtext: config.welcome_subtext || "",
        mobile_header_text: config.mobile_header_text || "",
        state_list: config.state_list || "",
        show_phone_number: config.show_phone_number || "",
        show_age_selector: config.show_age_selector || "",
        ecmEmailOverlay: config.ecmEmailOverlay || false,
        show_no_thanks: config.show_no_thanks || "",
        show_catalog: config.show_catalog || "",
        show_actions: config.show_actions ?? true,
        we_title: config.we_title || "",
        emailIntroText: config.emailIntroText || "",
        emailSignUpIntroText: config.emailSignUpIntroText || "",
        catalogSignUpCheckBoxText: config.catalogSignUpCheckBoxText || "",
        finalThankYouImgSrc: config.finalThankYouImgSrc || "",
        finalThankYouImgEnabled: config.finalThankYouImgEnabled || false,
        mobile_header_confirm_text: config.mobile_header_confirm_text || "",
        mobile_email_placeholder_text:
          config.mobile_email_placeholder_text || "",
        // config specific to PT only
        showMobileAgeSelector: config.showMobileAgeSelector ?? false,
        financialIncentiveTerms: config.financialIncentiveTerms || "",
        showCatalogRequestLink: config.showCatalogRequestLink || false,
        showVisitOtherBrands: config.showVisitOtherBrands ?? true,
        showThankYouAfterEmailInput:
          config.showThankYouAfterEmailInput || false,
        enableCRE: config.enableCRE || false,
        enableEmailCampaignHeader: config?.enableEmailCampaignHeader ?? false,
        emailCampaignLogoClassName: config?.emailCampaignLogoClassName ?? "",
        showCampaignDescription: config?.showCampaignDescription ?? false,
        emailCampaignDescription: config?.emailCampaignDescription ?? "",
        enableCampaignFormBottom: config?.enableCampaignFormBottom ?? false,
        enableCampaignFormBottomButtonText:
          config?.enableCampaignFormBottomButtonText ?? "Submit",
      };
    },
    checkEmailValidity({ currentTarget }) {
      const that = currentTarget;
      that.setCustomValidity("");
      that.checkValidity();
      that.setCustomValidity(
        that.validity.valid ? "" : "Please enter a valid email"
      );
    },
    checkForSweepsOverlay() {
      const sweeps = this.$el.querySelectorAll(".rei-overlay-sweeps");
      if (sweeps?.length) {
        this.isSweepsOverlay = true;
      }
    },
  },
};
</script>

export default {
  methods: {
    /**
     * It shows the dropdown menu by clicking menu icon
     * @param event - event
     * @param selector - element selector
     */
    showDropdownMenu(event, selector) {
      if (!event?.target?.classList?.contains("open")) {
        const navItems = document.querySelectorAll(selector);
        navItems?.forEach((navItem) => {
          navItem.classList?.remove("open");
        });
        event?.target?.classList.add("open");
        event?.target?.setAttribute("aria-expanded", "true");
      } else {
        event?.target?.classList.remove("open");
        event?.target?.setAttribute("aria-expanded", "false");
      }
    },
    /**
     * To reset arrow icon with default state
     * To close dropdown menu using escape keys
     */
    closeDropdownMenu() {
      const dropdownMenuList = document.querySelector(".drop-down-icon.open");
      dropdownMenuList?.classList?.remove("open");
      dropdownMenuList?.setAttribute("aria-expanded", "false");
      dropdownMenuList?.focus();
    },
    /**
     * To convert HTML code into readable format for the screen reader users
     * @param menuItem - Menu Items
     */
    getNavItemText(menuItem) {
      return `${(menuItem?.text || menuItem?.catData?.name)?.replace(
        "&amp;",
        "&"
      )} Menu`;
    },
    /**
     * To close the dropdown whenever the focus is out of the element
     * @param event - event
     */
    handleDropdownFocusOut(event) {
      const dropdown = event.currentTarget;
      const isDropdownChild = dropdown.contains(event.relatedTarget);
      if (!isDropdownChild) {
        const dropdownMenuList = document.querySelector(".drop-down-icon.open");
        dropdownMenuList?.classList?.remove("open");
        dropdownMenuList?.setAttribute("aria-expanded", "false");
      }
    },
  },
};

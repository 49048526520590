<template>
  <div data-style="email-sms-capture-form-screen">
    <EmailSmsCaptureForm
      data-test-id="email-sms-capture-form"
      :show="
        currentFormState === FORM_STATE.UNSENT ||
        currentFormState === FORM_STATE.LOADING
      "
      @submit="handleSubmit"
    />
    <EmailSmsLoadingSpinner
      data-test-id="email-sms-loading-spinner"
      :show="currentFormState === FORM_STATE.LOADING"
    />
  </div>
</template>

<script>
import EmailSmsCaptureForm from "./EmailSmsCaptureForm.vue";
import EmailSmsLoadingSpinner from "./EmailSmsLoadingSpinner.vue";
import { FORM_STATE } from "../../util/marketingOverlayFormState";

export default {
  name: "email-sms-capture-form-screen",
  components: {
    EmailSmsCaptureForm,
    EmailSmsLoadingSpinner,
  },
  props: {
    currentFormState: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      FORM_STATE: FORM_STATE,
    };
  },
  methods: {
    handleSubmit(formData) {
      this.$emit("submit", formData);
    },
  },
};
</script>

<template>
  <div
    data-style="pzn-triggered-campaigns"
    v-if="isActive && config.campaigns && campaign"
    id="pzn-triggered-campaign"
    data-component="Global-PznTriggeredCampaign"
  >
    <h2 id="pzn-triggered-campaign-header">{{ campaign.name }}</h2>
    <div class="product-carousel">
      <vueper-slides
        ref="pznTriggeredVueperSlides"
        @ready="onCarouselRender($event)"
        @next="onCarouselNext($event)"
        @previous="onCarouselPrevious($event)"
        @slide="onCarouselScroll()"
        class="no-shadow"
        :visible-slides="2"
        :slide-multiple="isSlideMultiple"
        :bullets="false"
        :infinite="false"
        :gap="5"
        :arrows-outside="false"
        :dragging-distance="200"
        :slide-ratio="1"
        :breakpoints="{ 800: { visibleSlides: 2, slideMultiple: 2 } }"
        disable-arrows-on-edges
      >
        <vueper-slide
          v-for="product in campaign.products"
          :key="product.groupId"
        >
          <template v-slot:content>
            <ProductCell :product="product" :imgConfig="config.img" />
          </template>
        </vueper-slide>
      </vueper-slides>
    </div>
  </div>
</template>

<script>
import { addEvent } from "@js-ecom-tracking/datalayer";
import breakPoints from "../../../mixins/breakPoints";
import {
  defaultCampaign,
  defaultConfig,
  getCampaignsWithProductDetails,
  isEnabledByConfig,
  getCampaignConfigById,
} from "../../../helpers/pznTriggeredCampaignsHelper.js";
import { getBrandConcept, getConfigById } from "../../../util/context";
import EventBus from "../../../util/event-bus";
import {
  getItemFromSessionStorage,
  removeItemFromSessionStorage,
  saveItemToSessionStorage,
} from "@js-ecom-mfe/browser-storage";
import { logger } from "@js-ecom-mfe/logger";
import ProductCell from "./ProductCell.vue";
import { VueperSlide, VueperSlides } from "vueperslides";
import { REPONAME } from "../../../util/constants";

const fileName = "PznTriggeredCampaigns.vue";

export default {
  name: "pzn-triggered-campaigns",
  components: {
    ProductCell,
    VueperSlide,
    VueperSlides,
  },
  computed: {
    brandFirstVisit: function () {
      return `${this.brandConcept}FirstVisit`;
    },
  },
  data() {
    return {
      addEvent: () => ({}),
      brandConcept: "",
      campaign: defaultCampaign,
      config: defaultConfig,
      isActive: false,
      isSlideMultiple: true,
    };
  },
  mixins: [breakPoints],
  methods: {
    /**
     * On Initial Load, checks if the number of products
     * are 3 or less, if yes then disable the carousel slide by 2
     * feature
     */
    onCarouselRender() {
      let numberOfProducts = this.campaign?.products.length;

      if (numberOfProducts <= 3) {
        this.isSlideMultiple = false;
      }
    },
    /**
     * On carousel's Next button click
     * For Odd number of products,check if we are on the last slide,
     * if yes than slide the carousel by 1 item
     * by setting isSlideMultiple to false
     */
    onCarouselNext(params) {
      let currentSlide = params.currentSlide.index;
      let numberOfProducts = this.campaign?.products.length;
      if (!this.isSlideMultiple || currentSlide >= numberOfProducts - 3) {
        document.querySelector(
          "#pzn-triggered-campaign .vueperslides__arrow--next"
        ).style.display = "none";
      }

      if (
        numberOfProducts > 3 &&
        numberOfProducts % 2 !== 0 &&
        numberOfProducts - (currentSlide + 4) === 1
      ) {
        this.isSlideMultiple = false;
      }
    },
    /**
     * On carousel's Previous button click
     * For Odd number of products, if we have isSlideMultiple set to false
     * and we are not on the last slide
     * than enable sliding the carousel by 2 item
     * by setting isSlideMultiple to true
     */
    onCarouselPrevious(params) {
      let currentSlide = params.currentSlide.index;
      let numberOfProducts = this.campaign?.products.length;
      document
        .querySelector("#pzn-triggered-campaign .vueperslides__arrow--next")
        .style.removeProperty("display");

      if (
        numberOfProducts > 3 &&
        !this.isSlideMultiple &&
        currentSlide % 2 !== 0
      ) {
        this.isSlideMultiple = true;
      }
    },
    onCarouselScroll() {
      this.addEvent({
        category: "pzn triggered carousel",
        item: "scroll",
        campaignName: `${this.campaign?.name}`,
      });
    },
    async showCampaigns() {
      const methodName = "showCampaigns";

      try {
        if (
          this.isMediumBreakPoint() ||
          !isEnabledByConfig(this.config, this.$store)
        ) {
          return;
        }

        this.campaign = await getCampaignsWithProductDetails(
          this.config,
          this.brandConcept,
          this.$store
        );

        this.isActive = this.showCarousel();
        this.showCampaignNotification();
      } catch (error) {
        logger.error(REPONAME, fileName, methodName, error);
      }
    },
    showCampaignNotification() {
      const isFirstVisit = !getItemFromSessionStorage(
        "pznTriggeredCampaigns",
        this.brandFirstVisit
      );

      if (isFirstVisit) {
        const campaignConfig = getCampaignConfigById(
          this.config.campaigns,
          this.campaign?.id
        );
        saveItemToSessionStorage(
          "pznTriggeredCampaigns",
          this.brandFirstVisit,
          true
        );

        if (
          campaignConfig?.notificationEnabled &&
          this.campaign?.products.length
        ) {
          this.$emit("enablePznNotification");
        }
      }
    },
    showCarousel() {
      if (this.campaign?.products.length) {
        this.$emit("setPznIsActive");
        return true;
      }

      return false;
    },
  },
  created() {
    this.brandConcept = getBrandConcept(this);
    this.config = getConfigById("pznTriggeredCampaigns", this) || {};
  },
  mounted() {
    // TODO: Remove when RM is fixed for duplicate components
    const firstComponentLoaded = !!getItemFromSessionStorage(
      "pznTriggeredCampaigns",
      "firstComponentLoaded"
    );

    if (!firstComponentLoaded) {
      saveItemToSessionStorage(
        "pznTriggeredCampaigns",
        "firstComponentLoaded",
        true
      );
      return;
    }
    // END
    this.addEvent = addEvent;

    EventBus.$on("largeBreakPoint", this.showCampaigns);

    this.showCampaigns();

    // TODO: Remove when RM is fixed for duplicate components
    removeItemFromSessionStorage(
      "pznTriggeredCampaigns",
      "firstComponentLoaded"
    );
    // END
  },
};
</script>

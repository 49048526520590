/**
 * Function to check if we can send request to constructor
 * Given the test variant key name check the value to see if that
 * variant is enabled.
 * @param {Object} store the VueX data object
 * @param {String} variant the AB test variant to check for
 * @returns {Boolean} returns true if all a/c are done
 */
export function checkConstructorEnabled(store, variant) {
  let constructorConfig = store?.state?.header?.config?.constructor ?? {};
  const constructorEnabled = constructorConfig?.enabled ?? false;
  const abTestEnabled = constructorConfig.abTestConfig?.enabled ?? false;

  if (!constructorEnabled) {
    // feature flag if disabled
    return false;
  }

  if (constructorEnabled && !abTestEnabled) {
    // feature flag is enabled and abTest flag is disabled
    return true;
  }

  if (constructorEnabled && abTestEnabled) {
    // if feature flag is enabled and abTest flag is enabled should check variant

    // get the current active test variant
    const activeTests = store?.state?.header?.activeTests ?? {};

    // get the test name
    const abTestId = constructorConfig.abTestConfig?.ABTest ?? "";

    // get active variant
    const activeVariant = activeTests[abTestId]?.variation ?? "";

    // get the test variant
    const abTestVariant = constructorConfig.abTestConfig?.[variant] ?? "";

    return abTestVariant === activeVariant;
  }
}

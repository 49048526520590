<template>
  <div
    data-style="email-sms-error-screen"
    data-test-id="email-sms-error-screen"
    v-show="currentFormState === FORM_STATE.FAILURE"
    style="text-align: center"
  >
    <h1 class="heading-primary" data-test-id="error-title">
      {{ getHeaderErrorText }}
    </h1>
    <p class="text-xs" data-test-id="error-description">
      {{ getEmailSmsErrorText }}
    </p>
    <button
      class="btn-primary"
      data-test-id="error-button"
      @click="handleErrorButtonClick"
    >
      {{ getStartShoppingText }}
    </button>
  </div>
</template>

<script>
import { FORM_STATE } from "../../util/marketingOverlayFormState";
import { mapGetters } from "vuex";

export default {
  name: "email-sms-error-screen",
  props: {
    currentFormState: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      FORM_STATE: FORM_STATE,
    };
  },
  computed: {
    ...mapGetters([
      "getEmailSmsErrorText",
      "getStartShoppingText",
      "getHeaderErrorText",
    ]),
  },
  methods: {
    /**
     * handleErrorButtonClick()
     * Emits 'errorScreenClosed' event
     */
    handleErrorButtonClick() {
      this.$emit("errorScreenClosed");
    },
  },
};
</script>

<template>
  <Modal
    data-style="mobile-marketing-overlay-container"
    v-show="shouldDisplayCampaign"
    @close="handleDismissModalAndSuppressCampaign"
  >
    <div v-if="isEcmContentVisible">
      <EcmContentSlot contentLocation="mobile-email-overlay-top" />
    </div>
    <div class="marketing-overlay-container design-platform">
      <EmailSmsCaptureCampaignContainer
        data-test-id="email-sms-capture-campaign-container"
        v-show="selectedCampaign === 'emailSmsCapture'"
        @formSubmitted="handleSuppressCampaign"
        @confirmationScreenClosed="handleDismissModal"
        @errorScreenClosed="handleDismissModal"
      />
      <div
        class="text-center pb-3"
        v-on:click="closeModal"
        v-if="isShowNoThanks"
        data-test-id="no-thanks"
      >
        <a href="#" class="btn-quaternary">{{ NoThanksLabel }}</a>
      </div>
      <div v-if="isEcmContentVisible">
        <EcmContentSlot contentLocation="mobile-email-overlay-bottom" />
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapGetters } from "vuex";
import Cookies from "js-cookie";
import Modal from "../Modal.vue";
import EmailSmsCaptureCampaignContainer from "./EmailSmsCaptureCampaignContainer.vue";
import EcmContentSlot from "../EcmContentSlot.vue";

export default {
  name: "mobile-marketing-overlay-container",
  components: {
    Modal,
    EmailSmsCaptureCampaignContainer,
    EcmContentSlot,
  },
  data() {
    return {
      shouldDisplayCampaign: false,
      isShowNoThanks: false,
      isEcmContentVisible: true,
    };
  },
  computed: {
    ...mapGetters({
      suppressionCookie: "getSuppressionCookie",
      suppressionDurationInDays: "getSuppressionDurationInDays",
      selectedCampaign: "getSelectedCampaign",
      showNoThanks: "getShowNoThanks",
      NoThanksLabel: "getNoThanksLabel",
    }),
  },
  methods: {
    /**
     * handleSuppressionCookie().
     * Shows email overlay if cookie is not present.
     */
    handleSuppressionCookie() {
      const cookie = Cookies.get(this.suppressionCookie);
      if (!cookie) {
        this.shouldDisplayCampaign = true;
      }
    },
    /**
     * handleDismissModal().
     * Close email overlay when 'errorScreenClosed' event is captured.
     */
    handleDismissModal() {
      this.shouldDisplayCampaign = false;
    },
    /**
     * handleSuppressCampaign().
     * Add cookie ad_sess_(brand)_email.
     */
    handleSuppressCampaign() {
      Cookies.set(this.suppressionCookie, true, {
        expires: this.suppressionDurationInDays,
        path: "/",
      });
      this.isShowNoThanks = false;
      this.isEcmContentVisible = false;
    },
    /**
     * handleDismissModalAndSuppressCampaign().
     * Close email overlay and Add cookie ad_sess_(brand)_email.
     */
    handleDismissModalAndSuppressCampaign() {
      this.handleDismissModal();
      this.handleSuppressCampaign();
    },
    /**
     * closeModal().
     * Close email overlay when No thanks link is clicked.
     */
    closeModal() {
      this.handleDismissModalAndSuppressCampaign();
    },
    /**
     * showNoThanksLink().
     * Toggles No_Thanks link visibility
     */
    showNoThanksLink() {
      this.isShowNoThanks = this.showNoThanks;
    },
  },
  mounted() {
    this.handleSuppressionCookie();
    this.showNoThanksLink();
  },
};
</script>

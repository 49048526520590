/* eslint-disable camelcase */

export const mockData = {
  success: true,
  data: {
    items: [
      {
        activity_time: "2021-04-16T09:01:41Z",
        score: "2.75",
        product: "jade-colored-glass-centerpiece-bowl-d6702",
        is_favorite: "",
        prod_event_type: "skuTrigger",
        camp_type: "sku_noc",
        category: "Taper Chocolate",
        sku: "6436424",
      },
      {
        activity_time: "2021-04-16T09:01:41Z",
        score: "2.75",
        product: "overlapping-diamonds-mirror-w1847",
        is_favorite: "",
        prod_event_type: "skuTrigger",
        camp_type: "sku_noc",
        category: "Taper Chocolate",
        sku: "6436424",
      },
      {
        activity_time: "2021-04-16T09:01:41Z",
        score: "10.25",
        product: "dynasty-rug-rosette-t3928",
        is_favorite: "",
        prod_event_type: "skuTrigger",
        camp_type: "sku_noc",
        category: "Taper Chocolate",
        sku: "6436424",
      },
      {
        activity_time: "2021-04-16T09:01:41Z",
        score: "10.25",
        product: "large-curved-baskets-d4400",
        is_favorite: "",
        prod_event_type: "skuTrigger",
        camp_type: "sku_noc",
        category: "Taper Chocolate",
        sku: "6436424",
      },
      {
        activity_time: "2021-04-16T09:01:41Z",
        score: "10.25",
        product: "modern-weave-oversized-storage-bin-c469",
        is_favorite: "",
        prod_event_type: "skuTrigger",
        camp_type: "sku_noc",
        category: "Taper Chocolate",
        sku: "6436424",
      },
      {
        activity_time: "2021-04-16T09:01:41Z",
        score: "10.25",
        product: "organic-shaped-mugs-set-of-4-white-e1554",
        is_favorite: "",
        prod_event_type: "skuTrigger",
        camp_type: "sku_noc",
        category: "Taper Chocolate",
        sku: "6436424",
      },
      {
        activity_time: "2021-04-16T09:01:41Z",
        score: "10.35",
        product: "modern-weave-handled-baskets-d111",
        is_favorite: "",
        prod_event_type: "skuTrigger",
        camp_type: "sku_noc",
        category: "Taper Chocolate",
        sku: "6436424",
      },
      {
        activity_time: "2021-04-16T09:01:41Z",
        score: "10.25",
        product: "mid-century-multi-hook-d3413",
        is_favorite: "",
        prod_event_type: "skuTrigger",
        camp_type: "sku_noc",
        category: "Taper Chocolate",
        sku: "6436424",
      },
      {
        activity_time: "2021-04-16T09:01:41Z",
        score: "10.25",
        product: "large-curved-baskets-d4400",
        is_favorite: "",
        prod_event_type: "skuTrigger",
        camp_type: "sku_noc",
        category: "Taper Chocolate",
        sku: "6436424",
      },
      {
        activity_time: "2021-04-16T09:01:41Z",
        score: "2.75",
        product: "harmony-2-piece-chaise-sectional-h2478",
        is_favorite: "",
        prod_event_type: "skuTrigger",
        camp_type: "sku_noc",
        category: "Taper Chocolate",
        sku: "6436424",
      },
      {
        activity_time: "2021-04-16T09:01:41Z",
        score: "2.75",
        product: "two-tone-woven-baskets-d4701",
        is_favorite: "",
        prod_event_type: "skuTrigger",
        camp_type: "sku_noc",
        category: "Taper Chocolate",
        sku: "6436424",
      },
      {
        activity_time: "2021-04-16T09:01:41Z",
        score: "2.75",
        product: "two-tone-woven-baskets-natural-white-d5157",
        is_favorite: "",
        prod_event_type: "skuTrigger",
        camp_type: "sku_noc",
        category: "Taper Chocolate",
        sku: "6436424",
      },
      {
        activity_time: "2021-04-16T09:01:41Z",
        score: "2.75",
        product: "urban-2-piece-chaise-sectional-h1532",
        is_favorite: "",
        prod_event_type: "skuTrigger",
        camp_type: "sku_noc",
        category: "Taper Chocolate",
        sku: "6436424",
      },
    ],
  },
};

export const emptyMockData = {
  success: true,
  data: {
    items: [],
  },
};

import Cookies from "js-cookie";

const UI_USERNAME_COOKIE = "UI_USERNAME_COOKIE";
const SUPPORTED_COOKIE_VERSIONS = ["V3", "V4"];
import {
  PROFILE_SUMMARY_LOCAL_STORAGE_KEY,
  B2B_MEMBER_INDICATOR,
  NON_B2B_MEMBER,
  B2B_MEMBER,
} from "./constants";
import { getItemFromLocalStorage } from "@js-ecom-mfe/browser-storage";

export const getDesignTradeMemberStatus = (isUiUserNameCookieEnabled) => {
  if (isUiUserNameCookieEnabled) {
    return getDesignTradeMemberStatusByUiUserNameCookie();
  }
  return getDesignTradeMemberStatusByProfileSummary();
};

const getDesignTradeMemberStatusByUiUserNameCookie = () => {
  let designTradeMemberStatus = NON_B2B_MEMBER;
  const authCookie = Cookies.get(UI_USERNAME_COOKIE);
  if (authCookie) {
    const authData = authCookie.split("|");
    const cookieDataContractVersion = authData[0].toUpperCase(); // expecting v3 version
    if (SUPPORTED_COOKIE_VERSIONS.includes(cookieDataContractVersion)) {
      designTradeMemberStatus =
        authData[4] === B2B_MEMBER_INDICATOR ? B2B_MEMBER : NON_B2B_MEMBER;
    }
  }
  return designTradeMemberStatus;
};

const getDesignTradeMemberStatusByProfileSummary = () => {
  let designTradeMemberStatus = NON_B2B_MEMBER;
  const profileSummary = getItemFromLocalStorage(
    PROFILE_SUMMARY_LOCAL_STORAGE_KEY
  );

  if (profileSummary) {
    designTradeMemberStatus =
      profileSummary.value.b2bMembershipIndicator === B2B_MEMBER_INDICATOR
        ? B2B_MEMBER
        : NON_B2B_MEMBER;
  }
  return designTradeMemberStatus;
};

<template>
  <ul
    data-style="sub-brands-list"
    class="home-sub-nav"
    v-if="sub_brands.show_sub_brands_links"
  >
    <li
      v-for="(brand, index) in sub_brands.brands"
      :key="index"
      :class="brand.brand_name === currentSubBrand ? 'active' : ''"
    >
      <a :href="brand.link">{{ brand.brand_name }}</a>
    </li>
  </ul>
</template>

<script>
import { getConfigById, getSubBrand } from "../../util/context";
import { logger } from "@js-ecom-mfe/logger";
import { REPONAME } from "../../util/constants";

const fileName = "SubBrandsList.vue";

export default {
  name: "sub-brands-list",
  data() {
    return {
      currentSubBrand: "",
    };
  },
  methods: {
    getBrandsList() {
      const subBrands = getConfigById("subBrands", this);
      if (!subBrands || !subBrands.brands) {
        logger.warn(
          REPONAME,
          fileName,
          "getBrandsList",
          `Config error in subBrands`
        );
        return { brands: [] };
      }
      return subBrands;
    },
  },
  created() {
    this.sub_brands = this.getBrandsList();
    this.currentSubBrand = getSubBrand(this).brand_name;
  },
};
</script>

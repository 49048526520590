<template data-style="analytics"></template>
<script>
import { reportToTarget } from "../../util/experimentationReporting";

export default {
  name: "Analytics",
  async mounted() {
    //Call Adobe Test & Target to report active tests
    if (this.$store.state.header.activeTests) {
      reportToTarget(
        this.$store.state.header.activeTests,
        this.$store.state.header.concept
      );
    }
  },
};
</script>

import Cookie from "js-cookie";
import { getLoginState } from "../services/loginState";
import { getItemFromLocalStorage } from "@js-ecom-mfe/browser-storage";
import {
  PROFILE_SUMMARY_LOCAL_STORAGE_KEY,
  GOVERNOR_USER_STATE_KEY,
} from "./constants.js";

const uiCookieParser = (value) => {
  if (!value) {
    return value;
  }
  const splitRegex = /(?:\\.|[^\|\\]+)+/g;
  const [, displayName, uuid] = unescape(value.replace("+", " ")).match(
    splitRegex
  );
  return { displayName, uuid };
};

// return uuid from the cookie value.
// the logic is start from (string.length - 6) and go up to 36 chars backwards
const persistCookieParse = (value) => {
  return value && typeof value === "string" && value.slice(-42, -6);
};

const setGovernorState = (displayName) => {
  if (window.WSI && window.WSI.state) {
    window.WSI.state.change(GOVERNOR_USER_STATE_KEY, displayName);
  }
};

const parseServiceResponse = (response) => {
  if (response?.loginState?.loggedIn) {
    setGovernorState(response.loginState.displayName);
  } else {
    setGovernorState("");
  }
};

/**
 * Detects the user signedIn status and sets in the governor state.
 *
 * @param {String} applicationUri
 * @param {String} uiUserCookieName
 * @param {String} persistCookieName
 * @returns {Promise | Undefined}
 */
export const setSignedInStatus = (
  applicationUri,
  uiUserCookieName,
  persistCookieName
) => {
  let userCookieValue;
  if (uiUserCookieName) {
    userCookieValue = uiCookieParser(Cookie.get(uiUserCookieName));
  }
  const persistCookieValue = persistCookieParse(Cookie.get(persistCookieName));
  const profileSummaryForLoggedInUser = getItemFromLocalStorage(
    PROFILE_SUMMARY_LOCAL_STORAGE_KEY
  );
  if (userCookieValue && persistCookieValue) {
    if (userCookieValue.uuid === persistCookieValue) {
      setGovernorState(userCookieValue.displayName);
    } else {
      return getLoginState(applicationUri).then(parseServiceResponse);
    }
  } else if (profileSummaryForLoggedInUser && persistCookieValue) {
    if (profileSummaryForLoggedInUser.value.uuid === persistCookieValue) {
      setGovernorState(profileSummaryForLoggedInUser.value.displayName);
    } else {
      return getLoginState(applicationUri).then(parseServiceResponse);
    }
  } else if (persistCookieValue) {
    return getLoginState(applicationUri).then(parseServiceResponse);
  } else {
    setGovernorState("");
  }
};

/**
 * Detects the user signedIn status and sets in the governor state.
 *
 * @param {String} applicationUri
 * @param {String} uiUserCookieName
 * @param {String} hasUserNameInGoverState
 * @returns {Promise | Undefined}
 */
export const setSignedInStatusV2 = (
  applicationUri,
  uiUserCookieName,
  hasUserNameInGoverState
) => {
  let userCookieValue;
  if (uiUserCookieName) {
    userCookieValue = uiCookieParser(Cookie.get(uiUserCookieName));
  }
  const profileSummaryForLoggedInUser = getItemFromLocalStorage(
    PROFILE_SUMMARY_LOCAL_STORAGE_KEY
  );
  if (
    userCookieValue &&
    profileSummaryForLoggedInUser &&
    !!hasUserNameInGoverState
  ) {
    if (profileSummaryForLoggedInUser.value.uuid === userCookieValue.uuid) {
      setGovernorState(profileSummaryForLoggedInUser.value.displayName);
    } else {
      return getLoginState(applicationUri).then(parseServiceResponse);
    }
  } else if (!hasUserNameInGoverState && userCookieValue) {
    return getLoginState(applicationUri).then(parseServiceResponse);
  } else if (!userCookieValue && hasUserNameInGoverState) {
    return getLoginState(applicationUri).then(parseServiceResponse);
  } else {
    setGovernorState("");
  }
};

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showPrimaryNavMenu())?_c('ul',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeMenu),expression:"closeMenu"}],class:`nav-menu ${_vm.mobileNavBrandTitle ? _vm.mobileNavBrandTitle.id : ''}`,attrs:{"data-style":"primary-nav","id":_vm.activeTab && `tabpanel-${_vm.activeTab}`,"role":(_vm.activeTab && 'tabpanel') || '',"aria-labelledby":_vm.activeTab && `tabpanel-${_vm.activeTab}`,"data-component":"Global-header-PrimaryNav","data-test-id":"global-header-primary-nav"}},[(_vm.showMobileTabbedNav && _vm.activeTab ? false : _vm.mobileNavBrandTitle)?_c('li',{class:`site-link-title HOD ${_vm.mobileNavBrandTitle.id}`,attrs:{"data-test-id":"site-link-title"}},[_c('a',{class:{ 'mobile-brand-title': _vm.isResponsiveScreen },attrs:{"href":_vm.mobileNavBrandTitle.link}},[_vm._v("\n      "+_vm._s(_vm.mobileNavBrandTitle.brand_name))])]):_vm._e(),_vm._v(" "),_vm._l((_vm.topNavItems),function(topNavItem,index){return _c('li',{key:topNavItem.indexKey,ref:`topNavItem-${index}`,refInFor:true,class:_vm.getClasses(topNavItem, index),attrs:{"data-params":_vm.headerLinkParams},on:{"click":_vm.storeLinkClickInfo}},[(topNavItem.hasOwnProperty('enabled') ? topNavItem.enabled : true)?[(topNavItem.customComponent)?[_c('a',{attrs:{"href":topNavItem.url || 'javascript:void(0)'},domProps:{"innerHTML":_vm._s(topNavItem.name)}}),_vm._v(" "),_c(topNavItem.customComponent,{tag:"component"})]:(
          !topNavItem.displayText && (topNavItem.catData || topNavItem.custom)
        )?[(topNavItem.custom || topNavItem.catData.categories)?_c('PrimaryNavPopOutMenu',{attrs:{"catData":topNavItem.catData,"customData":topNavItem.custom
              ? topNavItem.categories || topNavItem.catData
              : null,"navLinkCatId":topNavItem.id,"hasSpotlight":topNavItem.hasSpotlight || false,"isResponsive":_vm.isResponsiveScreen,"mobileCatSiblingLinks":topNavItem.mobileCatSiblingLinks || {},"showLeftNavHeaders":_vm.config.showLeftNavHeaders || false,"showLinksIfHasGlobalOrNavLink":_vm.config.showLinksIfHasGlobalOrNavLink || true,"spotlightId":topNavItem.spotlightId || '',"showSubcatImgs":topNavItem.showSubcatImgs || false,"isVerticalMenu":_vm.isVerticalMenu,"addNumbering":!topNavItem.displayText}}):_vm._e()]:(_vm.showAnchorLinkAndChildren(topNavItem))?[_c('a',{attrs:{"href":_vm.getHref(topNavItem) || 'javascript:void(0)',"aria-expanded":_vm.isResponsiveScreen && _vm.topNavActive === index && _vm.topNavActiveState
              ? 'true'
              : 'false',"data-test-id":"top-nav-item-cat-data-link"},domProps:{"innerHTML":_vm._s(
            topNavItem.text
              ? topNavItem.text
              : topNavItem.catData
                ? topNavItem.catData.name
                : ''
          )},on:{"click":function($event){_vm.isResponsiveScreen &&
            (topNavItem.categories || topNavItem.catData.categories)
              ? _vm.toggleTopNav($event, index)
              : _vm.isIpad && _vm.isIpadDeviceCheckEnabled
                ? _vm.toggleTopNav($event, index, _vm.getHref(topNavItem))
                : null}}}),_vm._v(" "),_c('button',{ref:"menuIcon",refInFor:true,staticClass:"drop-down-icon",attrs:{"type":"button","aria-label":_vm.getNavItemText(topNavItem),"aria-expanded":"false","data-test-id":"dropdown-icon"},on:{"click":function($event){return _vm.showDropdownMenu($event, '#nav-main')},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.closeDropdownMenu.apply(null, arguments)}}},[_c('SVGIcon',{attrs:{"icon":'icon-dropdown'}})],1),_vm._v(" "),(topNavItem.custom || topNavItem.catData.categories)?_c('PrimaryNavPopOutMenu',{attrs:{"catData":topNavItem.catData,"customData":topNavItem.custom
              ? topNavItem.categories || topNavItem.catData
              : null,"navLinkCatId":topNavItem.id,"hasSpotlight":topNavItem.hasSpotlight || false,"isResponsive":_vm.isResponsiveScreen,"mobileCatSiblingLinks":topNavItem.mobileCatSiblingLinks || {},"showLeftNavHeaders":_vm.config.showLeftNavHeaders || false,"showLinksIfHasGlobalOrNavLink":_vm.config.showLinksIfHasGlobalOrNavLink || true,"spotlightId":topNavItem.spotlightId || '',"showSubcatImgs":topNavItem.showSubcatImgs || false,"isVerticalMenu":_vm.isVerticalMenu,"tabNavParam":_vm.getTabNavParam()}}):_vm._e()]:_vm._e(),_vm._v(" "),(_vm.showStaticAnchorLink(topNavItem))?[_c('a',{class:topNavItem.class,attrs:{"href":topNavItem.url || 'javascript:void(0)',"data-test-id":"static-top-nav-url-link"},domProps:{"innerHTML":_vm._s(topNavItem.text)}})]:_vm._e()]:_vm._e()],2)})],2):_vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
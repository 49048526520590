<template>
  <div
    data-style="international-shipping-msg-block"
    class="international-shipping-msg-block"
  >
    <div class="international-shipping-header">
      Some items in this category are not eligible for international shipping.
      <a href="/customer-service/returns-and-exceptions.html">Learn more</a>
    </div>
    <div class="international-shipping-help">
      If you need assistance, please view our
      <a href="/customer-service/international-orders.html"
        >contact information</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "international-shipping-msg-block",
};
</script>

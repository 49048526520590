<template>
  <div
    data-style="action-link-list"
    class="linklist-separated-lists separated-lists"
    :class="isTopNavArrowEnabled ? 'top-nav-a11y' : ''"
    @mouseenter="mouseEnterActionLinkList"
    @mouseleave="mouseLeaveActionLinkList"
  >
    <ul
      id="nav-user-links"
      data-component="Global-header-ActionLinkList"
      data-test-id="Global-header-ActionLinkList"
    >
      <!-- My Account -->
      <MyAccount
        :keyRewardsAmount="keyRewardsAmount"
        :keyRewardsMyAccountNavigationCTALink="
          keyRewardsMyAccountNavigationCTALink
        "
        :keyRewardsMyAccountNavigationCTAText="
          keyRewardsMyAccountNavigationCTAText
        "
        :shouldDisplayKeyRewardsMyAccountNavigationCTA="
          shouldDisplayKeyRewardsMyAccountNavigationCTA
        "
        :shouldDisplayKeyRewardsNotification="
          shouldDisplayKeyRewardsNotification
        "
        :subBrand="subBrand"
        data-test-id="Global-my-account"
        @myAccountMouseOver="$emit('dismissKeyRewardsNotification')"
        @onIntersect="$emit('onIntersect', $event)"
        @onNonIntersect="$emit('onNonIntersect', $event)"
        :displayUserName="displayUserName"
        :displaySignInPrompt="displaySignInPrompt"
        v-if="!isRetailStoreSession"
      />
      <li
        class="hidden"
        data-test-id="retail-store-session-hidden-placeholder"
        v-else
      ></li>
      <template v-for="(link, index) in actionLinkList">
        <li
          :class="
            isTopNavArrowEnabled
              ? `top-nav-accessibility ${link.class}`
              : link.class
          "
          :key="index"
          v-if="handleLink(link)"
          @mouseenter="recentIcon(link)"
          @mouseleave="updateRVICarousel(link)"
        >
          <a
            v-if="link.text.includes('Key Rewards')"
            :href="updateKeyRewardsLink(link)"
            :target="link.target"
          >
            <SVGIcon v-if="link.SVGIcon" :icon="link.SVGIcon"></SVGIcon>
            <img v-if="link.imageUrl" :src="link.imageUrl" alt="Key Rewards" />
            <span class="link-text"
              >{{ link.text }}
              <span
                v-if="link.showCount && favoriteCount > 0"
                class="favorite-count"
                >({{ favoriteCount }})</span
              >
            </span>
          </a>
          <a v-else :href="link.link" :target="link.target">
            <SVGIcon v-if="link.SVGIcon" :icon="link.SVGIcon"></SVGIcon>
            <img v-if="link.imageUrl" :src="link.imageUrl" />
            <span class="link-text"
              >{{ link.text }}
              <span
                v-if="link.showCount && favoriteCount > 0"
                class="favorite-count"
                >({{ favoriteCount }})</span
              >
            </span>
          </a>
          <button
            v-if="isTopNavArrowEnabled && link.hoverComponent"
            type="button"
            :aria-label="link.text + ' Menu'"
            aria-expanded="false"
            class="drop-down-icon"
            ref="menuIcon"
            @click="showDropdownMenu($event, recentIcon(link))"
            @keydown.esc="closeDropdownMenu"
          >
            <SVGIcon :icon="'icon-dropdown'"></SVGIcon>
          </button>
          <!-- Simple hover panel with a list of hyperlinks -->
          <div
            v-if="link.overlayLinkList"
            id="hover-panel-submenu"
            class="submenu"
          >
            <ul>
              <li
                v-for="(item, index) in link.overlayLinkList"
                :class="item.class"
                :key="index"
              >
                <a :href="item.link" :target="item.target">
                  <SVGIcon v-if="item.SVGIcon" :icon="item.SVGIcon"></SVGIcon>
                  <img v-if="item.imageUrl" :src="item.imageUrl" />
                  <span>{{ item.text }}</span>
                </a>
              </li>
            </ul>
          </div>
          <!-- Custom hover component -->
          <component
            v-if="link.hoverComponent"
            :is="link.hoverComponent"
            :resetCarousel="resetCarousel"
            :loadChildDataOnHover="resetCarousel"
            @productFavoriteTogglerOperationsRecommendation="emitFavoriteFlyout"
          ></component>
        </li>
      </template>

      <li
        v-if="cart && !cart.showInFlyout"
        :class="
          isTopNavArrowEnabled
            ? `top-nav-accessibility ${cart.class}`
            : cart.class
        "
        class="cart-button"
        @mouseenter="showCartItems"
        @mouseleave="hideCartItems"
      >
        <a :href="cart.link" :target="cart.target" class="view-cart">
          <SVGIcon v-if="cart.SVGIcon" :icon="cart.SVGIcon"></SVGIcon>
          <img v-if="cart.imageUrl" :src="cart.imageUrl" />
          <span>{{ cart.text }}</span>

          <span
            v-if="!cart.hideCount"
            class="cart-count"
            :class="{ delimiter: delimiterCondition }"
            >{{ displayCartCount }}</span
          >
        </a>
        <button
          v-if="isTopNavArrowEnabled"
          type="button"
          :aria-label="cart.text + ' Menu'"
          aria-expanded="false"
          class="drop-down-icon"
          ref="menuIcon"
          @click="showCartItemsData($event)"
          @keyup.esc="closeDropdownMenu"
        >
          <SVGIcon :icon="'icon-dropdown'"></SVGIcon>
        </button>
        <!-- Custom cart hover component -->
        <QuickCart
          data-test-id="quick-cart-model-tooltip"
          v-if="isModelPopupEnabled"
          :isDataReloadRequired="reloadCartItems"
          @updateCartCount="onUpdateCartCount"
        />
      </li>

      <li
        v-if="cart && cart.showInFlyout"
        :class="cart.class"
        class="cart-button top-nav-accessibility"
      >
        <a href="javascript:void(0)" @click="handleFlyout()" class="view-cart">
          <SVGIcon v-if="cart.SVGIcon" :icon="cart.SVGIcon"></SVGIcon>
          <img v-if="cart.imageUrl" alt="cart image" :src="cart.imageUrl" />
          <span>{{ cart.text }}</span>

          <span
            v-if="!cart.hideCount"
            class="cart-count"
            :class="{ delimiter: delimiterCondition }"
            >{{ displayCartCount }}</span
          >
        </a>
      </li>
    </ul>
    <template v-if="hasLoaded && !isShoppingCart">
      <Flyout
        @handleFlyoutClose="handleFlyoutClose"
        flyoutClass="desktop-cart-flyout"
      >
        <template v-slot:flyout-content>
          <QuickCart
            :isDataReloadRequired="reloadCartFlyoutContentCartItems"
            :showInFlyout="cart.showInFlyout"
          />
        </template>
      </Flyout>
    </template>
  </div>
</template>

<script>
import { addEvent } from "@js-ecom-tracking/datalayer";
const SVGIcon = () =>
  import(/* webpackChunkName: "header-footer-svg-icon" */ "../../SVGIcon.vue");
import MyAccount from "./MyAccount.vue";
import FindAStorePopOut from "./FindAStorePopOut.vue";
import RegistryPopOut from "./RegistryPopOut.vue";
import QuickCart from "./QuickCart.vue";
import Flyout from "../Flyout.vue";
import RecentlyViewedItemsPopOut from "../RecentlyViewedItemsPopOut.vue";
import { getConfigById, getKeyRewardsLink } from "../../../util/context";
import EventBus from "../../../util/event-bus";
import { logger } from "@js-ecom-mfe/logger";
import Cookies from "js-cookie";
import { RVI_LABELS, REPONAME } from "../../../util/constants";
import { getFavoritesCount } from "@js-ecom-mfe/favorites";
import { mapGetters, mapActions, mapMutations } from "vuex";
import dropdownMenu from "../../../mixins/dropdownMenu";
const fileName = "ActionLinkList.vue";

export default {
  name: "action-link-list",
  components: {
    SVGIcon,
    MyAccount,
    FindAStorePopOut,
    RegistryPopOut,
    QuickCart,
    RecentlyViewedItemsPopOut,
    Flyout,
  },
  props: {
    keyRewardsAmount: {
      type: String,
      default: "",
    },
    keyRewardsMyAccountNavigationCTALink: {
      type: String,
      default: "/account/keyrewards.html",
    },
    keyRewardsMyAccountNavigationCTAText: {
      type: String,
      default: "Key Rewards",
    },
    subBrand: {
      type: String,
      required: true,
    },
    shouldDisplayKeyRewardsMyAccountNavigationCTA: {
      type: Boolean,
      default: false,
    },
    shouldDisplayKeyRewardsNotification: {
      type: Boolean,
      default: false,
    },
    displayUserName: {
      type: Boolean,
      default: true,
    },
    displaySignInPrompt: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const quickCartConfig = getConfigById("quickCart", this);
    const sessionCartCookie = Cookies.get(quickCartConfig.sessionCartCookie);
    const savedCartCookie = Cookies.get(quickCartConfig.savedCartCookie);
    const cartCookie = sessionCartCookie ? sessionCartCookie : savedCartCookie;
    const isModelPopupEnabled = quickCartConfig.isModelPopupEnabled ?? false;

    return {
      cartCookie,
      favoriteCount: 0,
      isSignedIn: false,
      cart: [],
      actionLinkList: [],
      resetCarousel: false,
      reloadCartItems: false,
      reloadCartFlyoutContentCartItems: false,
      hasLoaded: false,
      isRetailStoreSession: false,
      isTopNavArrowEnabled:
        getConfigById("showTopNavArrows", this)?.enabled || false,
      stillInteractingWithActionLinkList: false,
      isModelPopupEnabled,
    };
  },
  mixins: [dropdownMenu],
  methods: {
    ...mapActions(["setCartQuantity"]),
    ...mapMutations(["setDisplaySignInPrompt"]),
    /**
     * Passed to Favorite Flyout, after emitted by Recommendation Wrapper
     * @param {Object} recommendationFavoriteItem -- Favorite Item comes from recommendation wrapper
     */
    emitFavoriteFlyout(recommendationFavoriteItem) {
      this.$emit(
        "productFavoriteTogglerOperationsRecommendation",
        recommendationFavoriteItem
      );
    },
    getCartQuantityFromCookie() {
      return this.cartCookie.split("|")[3];
    },
    /**
     * Event handler to update flyoutCount when favorites updated using favorites standalone component
     * @param {event} evt
     */
    handleFavoritesCountChange(evt) {
      WSI.state.change("updateFlyoutCount", evt.detail.count);
    },
    handleLink(link) {
      const rviLabels = link?.rviLabels ?? RVI_LABELS;
      if (!rviLabels?.includes(link.text)) {
        return true;
      }
      if (link.disabled) {
        return false;
      }
      if (link.ABTestEnabled) {
        let rviABTest = this.$store.state.header.activeTests[link.ABTest];
        if (rviABTest) {
          return rviABTest.variation === link.activeVariant;
        }
      }
      return true;
    },
    mouseEnterActionLinkList() {
      this.stillInteractingWithActionLinkList = true;
      setTimeout(() => {
        if (this.stillInteractingWithActionLinkList) {
          this.setDisplaySignInPrompt(false);
        }
      }, 100);
    },
    mouseLeaveActionLinkList() {
      this.stillInteractingWithActionLinkList = false;
    },
    recentIcon(link) {
      if (link.rviLabels?.includes(link.text)) {
        addEvent({
          category: "recently-viewed",
          item: "open",
        });

        setTimeout(() => {
          this.resetCarousel = true;
        }, this.resetCarouselTimeout);
      }
    },
    updateRVICarousel(link) {
      if (link.rviLabels?.includes(link.text)) {
        this.resetCarousel = false;
      }
    },
    getConfig() {
      const config = getConfigById("actionLinks", this);
      if (!config) {
        logger.warn(
          REPONAME,
          fileName,
          "getConfig",
          `Config error in ActionLinkList`
        );
        return {
          actionLinkList: [],
          cart: {},
          account: {},
        };
      }

      return {
        actionLinkList: config.actionLinkList || [],
        cart: config.cart || null,
        account: config.account || {},
      };
    },
    /**
     * Gets called on mouse enter of a cart icon
     */
    showCartItems() {
      if (this.isModelPopupEnabled) {
        this.reloadCartItems = true;
      }
    },
    /**
     * Gets called on mouse leave of a cart icon
     */
    hideCartItems() {
      this.reloadCartItems = false;
    },
    /**
     * Updates cart count on page load and hover over cart icon
     */
    onUpdateCartCount(count) {
      this.setCartQuantity(count);
    },
    onUpdateFlyoutCount(count) {
      this.favoriteCount = count;
    },
    /**
     * Updates anchor href for Key rewards links.
     * @param {link} link
     */
    updateKeyRewardsLink(link) {
      return getKeyRewardsLink(link);
    },
    // toggles cart flyout
    handleFlyout() {
      this.reloadCartFlyoutContentCartItems =
        !this.reloadCartFlyoutContentCartItems;
      EventBus.$emit("toggleFlyout");
    },
    // closes the flyout container
    handleFlyoutClose() {
      this.reloadCartFlyoutContentCartItems =
        !this.reloadCartFlyoutContentCartItems;
    },
    /**
     * Gets called on click of the cart dropdown icon
     * @param event - event
     */
    showCartItemsData(event) {
      this.showCartItems();
      this.showDropdownMenu(event, "#nav-user-links .open");
    },
  },
  computed: {
    ...mapGetters(["getCartQuantity"]),

    retailStoreConfig() {
      return getConfigById("retailStoreConfig", this);
    },

    displayCartCount() {
      const quantity = this.getCartQuantity;

      // eslint-disable-next-line no-undefined
      if (quantity === undefined || quantity === 0) {
        return this.cart.displayZeroCount ? 0 : "";
      } else {
        return quantity;
      }
    },
    delimiterCondition() {
      if (this.cart.displayZeroCount) {
        return this.getCartQuantity >= 0;
      } else {
        return this.getCartQuantity > 0;
      }
    },
    // the page is shopping cart page or not
    isShoppingCart() {
      const bodyId = document.body.id;
      return bodyId === "shopping-cart";
    },
    recommendationConfig() {
      return getConfigById("recommendation", this) || {};
    },
    resetCarouselTimeout() {
      const { resetCarouselTimeout } = this.recommendationConfig;
      return resetCarouselTimeout || 0;
    },
  },
  created() {
    const config = this.getConfig();
    this.actionLinkList = config.actionLinkList;
    this.cart = config.cart;
  },
  async mounted() {
    this.hasLoaded = true;
    this.isRetailStoreSession =
      !!Cookies.get(this.retailStoreConfig?.storeCookieName) ||
      !!Cookies.get(this.retailStoreConfig?.storeSessionEndedCookieName);
    // Disabling registry pop out in Retail Store Session to restrict associate from login
    if (this.isRetailStoreSession) {
      this.actionLinkList = this.actionLinkList.map((actionLink) => {
        if (actionLink.link.includes("/registry/")) {
          const actionItem = { ...actionLink, hoverComponent: "" };
          return actionItem;
        }
        return actionLink;
      });
    }
    this.$emit("hasLoaded", this.hasLoaded);
    // Listen for cart-quantity-changed WSI State Change event
    WSI.state.onChange("cart-quantity-changed", (newQuantity) => {
      this.onUpdateCartCount(newQuantity);
    });

    // Retrieve Cart Quantity Count from the cookie
    if (typeof this.cartCookie === "string" && this.cartCookie !== "") {
      const cartQuantityFromCookie = this.getCartQuantityFromCookie();
      this.onUpdateCartCount(cartQuantityFromCookie);
    }

    // show the favorite's count only if a link needs it
    const showCount = this.actionLinkList.reduce(
      (result, link) => result || link.showCount,
      false
    );
    if (showCount) {
      this.favoriteCount = await getFavoritesCount();
    } else {
      this.favoriteCount = 0;
    }
    WSI.state.onChange("updateFlyoutCount", this.onUpdateFlyoutCount);
    WSI.state.onChange("updateCartCount", this.onUpdateCartCount);
    window.addEventListener(
      "favoritesCountChange",
      this.handleFavoritesCountChange
    );
  },
  updated() {
    // update favorite count for mobile/responsive viewport
    EventBus.$emit("setFavoriteCount", this.favoriteCount);
  },
  beforeDestroy() {
    window.removeEventListener(
      "favoritesCountChange",
      this.handleFavoritesCountChange
    );
  },
};
</script>

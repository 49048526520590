var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.isVerticalMenu ? 'vertical-nav' : 'pop-out-menu',attrs:{"data-style":"primary-nav-pop-out-menu","data-cat-id":_vm.catId},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.closeDropdownMenu.apply(null, arguments)}}},[(_vm.customComponent && _vm.customComponent !== '')?[_c(_vm.customComponent,{tag:"component"})]:(_vm.sortedCustomData && _vm.sortedCustomData.length)?_vm._l((_vm.sortedCustomData),function(column,index){return _c('div',{key:index,staticClass:"col"},[_vm._l((column),function(section){return [(section.path)?_c('h3',{key:section.name + '-header'},[_c('a',{attrs:{"href":_vm.getNavLink(section)},domProps:{"innerHTML":_vm._s(section.name)}})]):((section.path === '' || !section.path) && section.link)?_c('h3',{key:section.name + '-header',staticClass:"header-link"},[_c('a',{staticClass:"no-header-parent",attrs:{"href":_vm.getSectionLink(section.link)},domProps:{"innerHTML":_vm._s(section.name)}})]):_c('h3',{key:section.name + '-header',ref:`navHeaderItem-${section.name}-header`,refInFor:true,class:{
            open:
              _vm.navHeaderActive === `${section.name}-header` &&
              _vm.navHeaderActiveState,
          },attrs:{"role":_vm.isResponsive ? 'button' : '',"tabindex":_vm.isResponsive ? '0' : '',"aria-expanded":_vm.mobileAccordionMenuExpand(`${section.name}-header`)},domProps:{"innerHTML":_vm._s(section.name)},on:{"click":function($event){return _vm.toggleSubNav(`${section.name}-header`)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.toggleSubNav(`${section.name}-header`)}}}),_vm._v(" "),(section.items)?_c('ul',{key:section.name + '-links'},_vm._l((section.items),function(navItem,navIdx){return _c('li',{key:navIdx},[(_vm.isBrandList || navItem.isExternal)?_c('a',{staticClass:"nav-external",attrs:{"href":navItem.link,"target":"_blank","rel":"nofollow"},domProps:{"innerHTML":_vm._s(navItem.name)}}):_c('a',{attrs:{"href":_vm.getNavLink(navItem)},domProps:{"innerHTML":_vm._s(navItem.name)}})])}),0):_vm._e()]})],2)}):(_vm.displayLinksubcatPopout)?[(_vm.columns && _vm.columns.A && _vm.columns.A.length)?_c('div',{staticClass:"col",attrs:{"data-params":_vm.linkParams},on:{"click":function($event){$event.stopPropagation();return _vm.storeLinkClickInfo($event)}}},[_c('ul',_vm._l((_vm.columns.A),function(linksubcat){return _c('li',{key:linksubcat.id + '-header'},[_c('a',{attrs:{"href":_vm.getNavLink(linksubcat)},domProps:{"innerHTML":_vm._s(linksubcat.name)}})])}),0)]):_vm._e()]:(Object.keys(_vm.columns).length > 0)?_vm._l((_vm.columns),function(column,key){return _c('div',{key:column.id,staticClass:"col",class:key,attrs:{"data-params":_vm.linkParams},on:{"click":function($event){$event.stopPropagation();return _vm.storeLinkClickInfo($event)}}},[_vm._l((column),function(navHeader){return [(
            _vm.isResponsive &&
            navHeader.path &&
            _vm.mobileCatSiblingLinks &&
            _vm.mobileCatSiblingLinks[navHeader.path] &&
            _vm.mobileCatSiblingLinks[navHeader.path].length
          )?_vm._l((_vm.mobileCatSiblingLinks[
              navHeader.path
            ]),function(siblingLink,index){return _c('a',{key:`${siblingLink.text}-${index}`,staticClass:"sibling-link",attrs:{"href":_vm.getNavLink(siblingLink)},domProps:{"innerHTML":_vm._s(siblingLink.text)}})}):_vm._e(),_vm._v(" "),(_vm.showClickableCategoryHeader(navHeader))?_c('h3',{key:navHeader.id + '-header',ref:`navHeaderItem-${navHeader.id}`,refInFor:true,staticClass:"clickable",class:{
            open: _vm.navHeaderActive === navHeader.id && _vm.navHeaderActiveState,
            'cch-mobile-nav': _vm.cchMobileNav && !_vm.cchDisplayNewArrow,
          },attrs:{"data-path":navHeader.path,"role":_vm.isResponsive ? 'button' : '',"tabindex":_vm.isResponsive ? '0' : '',"aria-expanded":_vm.mobileAccordionMenuExpand(navHeader.id)},on:{"click":function($event){return _vm.toggleSubNav(navHeader.id)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.toggleSubNav(navHeader.id)}}},[_c('a',{staticClass:"clickable-category-header-link",attrs:{"href":_vm.getNavLink(navHeader),"tabindex":_vm.isResponsive ? '-1' : ''},domProps:{"innerHTML":_vm._s(`${navHeader.name}&nbsp;`)},on:{"click":function($event){return _vm.clearSelectedFacetsFromSession($event, navHeader)}}}),_vm._v(" "),(_vm.isResponsive && !_vm.cchDisplayNewArrow)?_c('a',{class:`clickable-category-header-link ${
              _vm.cchMobileNav && !_vm.cchDisplayNewArrow ? 'cch-mobile-nav' : ''
            }`,attrs:{"href":_vm.getNavLink(navHeader),"tabindex":_vm.isResponsive ? '-1' : ''},on:{"click":function($event){$event.stopPropagation();}}}):_vm._e(),_vm._v(" "),(_vm.isResponsive && _vm.cchMobileNav && _vm.cchDisplayNewArrow)?_c('a',{class:`clickable-category-header-link ${
              _vm.navHeaderActive === navHeader.id && _vm.navHeaderActiveState
                ? 'align-new-cch-arrow'
                : 'cch-new-arrow'
            }`,on:{"click":[function($event){return _vm.openCchHeaderLink(navHeader)},function($event){$event.preventDefault();return _vm.toggleSubNav(navHeader.id)}]}},[_c('SVGIcon',{attrs:{"icon":'cch-chevron-right'}})],1):_vm._e()]):_c('h3',{key:navHeader.id + '-header',ref:`navHeaderItem-${navHeader.id}`,refInFor:true,class:{
            open: _vm.navHeaderActive === navHeader.id && _vm.navHeaderActiveState,
          },attrs:{"data-path":navHeader.path,"tabindex":_vm.isResponsive ? '0' : '',"role":_vm.isResponsive ? 'button' : '',"aria-expanded":_vm.mobileAccordionMenuExpand(navHeader.id)},domProps:{"innerHTML":_vm._s(navHeader.name)},on:{"click":function($event){return _vm.toggleSubNav(navHeader.id)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.toggleSubNav(navHeader.id)}}}),_vm._v(" "),_c('ul',{key:navHeader.id + '-links',class:{ 'visual-nav-category': navHeader.hasVisualNavCategory }},[_vm._l((_vm.showHideNavlinks(navHeader.navLinks)),function(navItem){return [(_vm.removeCchRefCategoryFromMenu(navItem))?_c('li',{key:navItem.id,attrs:{"data-nav-type":navItem.type}}):_c('li',{key:navItem.id,attrs:{"data-nav-type":navItem.type}},[(
                  navItem.imagePath &&
                  _vm.showSubcatImgs &&
                  _vm.colsWithHasVisNavCatTrue.includes(navHeader.displayColumn)
                )?_c('a',{attrs:{"href":_vm.getNavLink(navItem)},on:{"click":function($event){$event.preventDefault();return _vm.openNavLinks(navItem)}}},[_c('img',{attrs:{"src":_vm.getWcmImageFullPath(navItem.imagePath),"alt":navItem.name}}),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(navItem.name)}})]):_c('a',{attrs:{"href":_vm.getNavLink(navItem)},domProps:{"innerHTML":_vm._s(navItem.name)},on:{"click":function($event){$event.preventDefault();return _vm.openNavLinks(navItem)}}})])]})],2)]})],2)}):[_c('div',{staticClass:"col",attrs:{"data-params":_vm.linkParams},on:{"click":function($event){$event.stopPropagation();return _vm.storeLinkClickInfo($event)}}},[_c('ul',{staticClass:"non-header-supercat-links"},_vm._l((_vm.nonHeaderSuperCatLinks),function(link,idx){return _c('li',{key:idx},[_c('a',{attrs:{"href":_vm.getNavLink(link)},domProps:{"innerHTML":_vm._s(_vm.getSubCatText(link, idx))}})])}),0)])],_vm._v(" "),(_vm.showSpotlight && _vm.hasSpotlight)?_c('div',{staticClass:"spotlight"},[_c('PersonalizedPlacement',{attrs:{"placementId":'MMS-' + _vm.concept + '-SPOT-' + _vm.catId}}),_vm._v(" "),(!_vm.ecmPromos.length && (_vm.catId || _vm.spotlightId))?_c('EcmContentSlot',{attrs:{"contentLocation":'spotlight-' + (_vm.spotlightId || _vm.catId)}}):_vm._e(),_vm._v(" "),(_vm.ecmPromos.length > 0)?_c('div',{staticClass:"figures"},_vm._l((_vm.ecmPromos),function(ecmPromo){return _c('figure',[_c('EcmContentSlot',{attrs:{"contentLocation":'spotlight-' + ecmPromo}})],1)}),0):_vm._e()],1):_vm._e()],2)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
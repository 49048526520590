import {
  browserLocalStorageAvailable,
  getItemFromLocalStorage,
  removeItemFromLocalStorage,
  saveItemToLocalStorage,
} from "@js-ecom-mfe/browser-storage";
import { removeExcessWhiteSpace } from "./string";
import { TYPE_AHEAD_RECENTS_KEY } from "./constants";

export const getRecentSearchesFromLocalStorage = () => {
  let recentSearches = [];
  if (browserLocalStorageAvailable()) {
    const recentSearchesFromLocalStorage = getItemFromLocalStorage(
      TYPE_AHEAD_RECENTS_KEY
    );
    if (
      /* eslint-disable no-undefined */
      typeof recentSearchesFromLocalStorage !== "undefined" &&
      recentSearchesFromLocalStorage !== null
    ) {
      recentSearches = [...recentSearchesFromLocalStorage];
    }
  }
  return recentSearches;
};

export const removeRecentSearchesFromLocalStorage = () => {
  if (browserLocalStorageAvailable()) {
    removeItemFromLocalStorage(TYPE_AHEAD_RECENTS_KEY);
    return true;
  }
};

/**
 * Saves the raw input (e.g. when user types in a search term and immediately hits enter)
 * to the Local Storage.
 * @param {String} rawInput Raw input as typed in by user.
 * @param {String} searchURL Search URL associated with raw input search.
 * @param {Integer} recentlySearchedMaxSize - number of search terms to save
 */
export const saveRawInput = (rawInput, searchURL, recentlySearchedMaxSize) => {
  const trimmedRawInput = removeExcessWhiteSpace(rawInput);
  if (trimmedRawInput.length) {
    saveRecentSearchToLocalStorage(
      {
        asDisplay: trimmedRawInput,
        keyword: trimmedRawInput,
        options: {},
        searchURL: `${searchURL}${trimmedRawInput}`,
      },
      recentlySearchedMaxSize
    );
  }
};

/**
 * Saves a TypeAhead suggestion to the Local Storage.
 * @param {String} typeAheadSuggestion TypeAhead suggestion to save.
 * @param {String} searchURL Search URL associated with TypeAhead suggestion search.
 * @param {Integer} recentlySearchedMaxSize - number of search terms to save
 */
export const saveTypeAheadSuggestion = (
  typeAheadSuggestion,
  searchURL,
  recentlySearchedMaxSize
) => {
  const trimmedSearchSuggestion = removeExcessWhiteSpace(typeAheadSuggestion);
  if (trimmedSearchSuggestion.length) {
    saveRecentSearchToLocalStorage(
      {
        asDisplay: trimmedSearchSuggestion,
        keyword: trimmedSearchSuggestion,
        options: {
          typeAheadWord: trimmedSearchSuggestion,
        },
        searchURL: `${searchURL}${trimmedSearchSuggestion}`,
      },
      recentlySearchedMaxSize
    );
  }
};

/**
 * Saves a Search Term object to Local Storage.
 * @param {Object} searchTerm Search Term object to save.
 * @param {Integer} recentlySearchedMaxSize - number of search terms to save
 */
const saveRecentSearchToLocalStorage = (
  searchTerm,
  recentlySearchedMaxSize
) => {
  if (browserLocalStorageAvailable()) {
    const newSearchObject = {
      analytics: {
        typeAheadType: "recent",
      },
      asDisplay: searchTerm.asDisplay,
      keyword: searchTerm.keyword,
      options: searchTerm.options,
      searchURL: searchTerm.searchURL,
    };

    const updatedRecentSearchList = [
      ...[newSearchObject],
      ...getRecentSearchesFromLocalStorage(),
    ];

    const uniqueRecentSearchList = updatedRecentSearchList
      .reduce((acc, recentSearch) => {
        if (
          !acc.find(
            (savedSearchTerm) =>
              savedSearchTerm.keyword === recentSearch.keyword
          )
        ) {
          acc.push(recentSearch);
        }
        return acc;
      }, [])
      .splice(0, recentlySearchedMaxSize);
    saveItemToLocalStorage(TYPE_AHEAD_RECENTS_KEY, uniqueRecentSearchList);
  }
};

<template>
  <div class="design-platform">
    <a
      :data-style="dataStyle"
      :href="updateKeyRewardsLink(keyRewardsLink)"
      id="key-rewards-navigation-cta"
      class="grid"
      :class="gridCols"
      data-component="Global-header-KeyRewardsNavigationCTA"
      data-test-id="Global-header-key-rewards-navigation-cta"
    >
      <div data-style="Global-header-KeyRewardsNavigationAmount">
        <p data-test-id="Global-header-key-rewards-navigation-cta-amount">
          <sup>$</sup>
          <span>{{ amount }}</span>
        </p>
      </div>
      <div data-style="Global-header-KeyRewardsNavigationText">
        <p data-test-id="Global-header-key-rewards-navigation-cta-text">
          {{ text }}
        </p>
      </div>
      <img
        v-if="hasArrow"
        alt=""
        src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTdweCIgaGVpZ2h0PSIxMHB4IiB2aWV3Qm94PSIwIDAgMTcgMTAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+RmlsbCAxPC90aXRsZT4KICAgIDxnIGlkPSJGb3ItRGV2IiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iLUtleS1SZXdhcmRzX3NpdGV3aWRlLUJhbm5lci1zcGVjcyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTgyNS4wMDAwMDAsIC0yMy4wMDAwMDApIiBmaWxsPSIjOTE2NjE0Ij4KICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg1OTcuMTEwMDAwLCAxOS41MDAwMDApIj4KICAgICAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJGaWxsLTEiIHBvaW50cz0iMjM5LjE2MDkzMSAzLjQ2IDIzOC4zOTMwMjMgNC40NjUzMjQ5NCAyNDEuODA1NTI1IDcuODcxNjcxOTEgMjI4LjQ0IDcuODcxNjcxOTEgMjI4LjQ0IDkuMDQ0MzQzNjQgMjQxLjgwNTUyNSA5LjA0NDM0MzY0IDIzOC40MjE1MzUgMTIuNTM0Njc1MSAyMzkuMTYwOTMxIDEzLjU0IDI0NC4yOCA4LjUxNDMwODQ2Ij48L3BvbHlnb24+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg=="
      />
    </a>
  </div>
</template>

<script>
import createDataLayerImpressionObserver from "../../util/createDataLayerImpressionObserver";
import { getKeyRewardsLink } from "../../../src/util/context";
export default {
  name: "KeyRewardsNavigationCTA",
  props: {
    amount: {
      type: String,
      default: "",
    },
    dataStyle: {
      type: String,
      default: "",
    },
    gridCols: {
      type: String,
      default: "",
    },
    hasArrow: {
      type: Boolean,
      default: false,
    },
    keyRewardsLink: {
      type: String,
      default: "/account/keyrewards.html",
    },
    text: {
      type: String,
      default: "Key Rewards",
    },
  },
  methods: {
    updateKeyRewardsLink(keyRewardsLink) {
      return getKeyRewardsLink({ text: "key rewards", link: keyRewardsLink });
    },
  },
  data() {
    return {
      intersectionObserver: createDataLayerImpressionObserver(this),
    };
  },
  mounted() {
    this.intersectionObserver?.observe(this.$el);
  },
  destroyed() {
    this.intersectionObserver?.disconnect();
  },
};
</script>

import { removeItemFromLocalStorage } from "@js-ecom-mfe/browser-storage";
const STORED_LINK_INFO_NAME = "linkClickInfo";
const lengthLimit = 50;

/** Tests the validity of a param key or value. Allows 0 and false.
 * @param value the value to be tested for validity
 * @returns {Boolean} true if valid, false otherwise
 */
const isValid = (value) => {
  return (
    typeof value !== "undefined" &&
    value !== null &&
    value !== "" &&
    !Number.isNaN(value) &&
    String(value).length <= lengthLimit
  );
};

/**
 * Collects analytics parameters and adds them to the analytics datalayer.
 * Collects parameters from Local Storage - they were stored
 * there when a link to this page was followed. Also removes the Local
 * Storage item.
 *
 * @param {Object} linkClickInfo An object containing info about the link
 *     followed to reach this page. An object JSON-parsed from the local
 *     storage item. Ex.:
 *     { "originPage": "https://www.qa114.williams-sonoma.com/shop/cutlery/?cm_type=gnav",
 *       "destinationPage": "https://local.westelm.com/shop/outdoor/outdoor-dining-tables/?cm_type=gnav",
 *       "timeStamp": "2024-08-15T18:36:12.389Z",
 *       "parameters": [
 *           { "name": "cm_src", "value": "wsipipentry" },
 *           {  "name": "originsc", "value": "cookware" },
 *       ]
 *     }
 * @param {String} href The Url of this page
 * @param {Array} parametersToReport A configuration setting containing
 *     the names of parameters that are to be reported specifically from
 *     local storage: Ex.: [ "cm_src", "cm_type", "originsc" ]
 * @param {Boolean} testMode Whether to append diagnostic strings onto the
 *     end of the param values. For testing in lower environments only
 */
export const addParamsToDataLayer = function (
  linkClickInfo,
  href,
  parametersToReport,
  testMode = false
) {
  let paramsToAdd = []; // Accummulates params from storage
  const destStr = linkClickInfo?.destinationPage.endsWith("/")
    ? linkClickInfo.destinationPage.slice(0, -1)
    : linkClickInfo.destinationPage;
  const hrefStr = href && href.endsWith("/") ? href.slice(0, -1) : href;
  // Note addParameters is a function - checking for its existence
  if (
    !Array.isArray(linkClickInfo.parameters) ||
    !destStr ||
    destStr !== hrefStr ||
    !Array.isArray(parametersToReport) ||
    parametersToReport.length < 1 ||
    !window?.WSI?.tracking?.api?.addParameters
  ) {
    removeItemFromLocalStorage(STORED_LINK_INFO_NAME);
    return;
  }
  linkClickInfo.parameters.forEach((param) => {
    if (
      isValid(param?.name) &&
      isValid(param?.value) &&
      parametersToReport.includes(param.name) &&
      !paramsToAdd.some((obj) => obj.name === param.name)
    ) {
      const pushVal = testMode
        ? `${param.value}-FROM-LOCALSTORE`
        : String(param.value);
      paramsToAdd.push({ name: String(param.name), value: pushVal });
    }
  });
  removeItemFromLocalStorage(STORED_LINK_INFO_NAME);
  // Adds the collected parameters into datalayer
  if (paramsToAdd.length > 0) {
    window?.WSI?.tracking?.api?.addParameters(paramsToAdd);
  }
};

import { REPONAME } from "../util/constants";
import { logger } from "@js-ecom-mfe/logger";

const fileName = "govenorState.js";

const governorKeysCollection = [
  { key: "currentZipCodeInfo" }, // this is for ship to
  { key: "storeZipCodeInfo" }, // this is for my store
  { key: "activeTargetTests" },
];

/**
 * Listen to governor for passed keys and calls the mutation whenever the value is changed in the governor
 * @param {function} mutationCallback -- Mutation from the VueX that will update the appropriate state object
 * @param {array} governorKeys -- A collection of key that we want to listen from governor.
 */
export function startListening(
  mutationCallback,
  governorKeys = governorKeysCollection
) {
  if (window.WSI && window.WSI.state) {
    governorKeys.forEach((obj) => {
      obj.cb = function (data) {
        mutationCallback({ [obj.key]: data });
      };
      window.WSI.state.onChange(obj.key, obj.cb);
    });
  } else {
    logger.error(REPONAME, fileName, "startListening", "WSI.state not found");
  }
}

/**
 * Removes all listeners from the governor.
 * @param {array} governorKeys -- A collection of key that we want to listen from governor.
 */
export function stopListening(governorKeys = governorKeysCollection) {
  governorKeys.forEach((obj) => {
    if (typeof obj.cb === "function") {
      window.WSI.state.remove(obj.key, obj.cb);
    }
  });
}

<template>
  <div data-style="mobile-top-links">
    <KeyRewardsNavigationCTA
      v-if="shouldShowKeyRewardsMobileNavigationCTA"
      :amount="keyRewardsAmount"
      :dataStyle="'Global-header-KeyRewardsNavigationCTA-mobile'"
      :gridCols="'grid-cols-3'"
      :hasArrow="true"
      :keyRewardsLink="keyRewardsMobileNavigationCTALink"
      :text="keyRewardsMobileNavigationCTAText"
      data-test-id="Global-mobile-key-rewards-navigation-cta"
      @onIntersect="$emit('onIntersect', $event)"
      @onNonIntersect="$emit('onNonIntersect', $event)"
    />
    <ul v-if="topLinks" class="iconedLinks" :class="numberLinks" id="topOfNav">
      <!-- my account -->
      <li
        v-if="shouldShowAccountIcon"
        class="account-link"
        data-test-id="mobile-top-links-account-link"
      >
        <a
          :href="`/m${
            isSignedIn
              ? topLinks.myAccount.link.signedIn
              : topLinks.myAccount.link.signedOut
          }`"
        >
          <SVGIcon
            v-if="icon(topLinks.myAccount)"
            :icon="icon(topLinks.myAccount)"
          ></SVGIcon>
          <span
            data-test-id="account-text"
            class="mobile-utility-nav-top-link-text"
            >{{ accountText }}</span
          >
        </a>
      </li>
      <!-- favorites -->
      <li
        v-if="shouldShowFavoritesIcon"
        class="favorites-link"
        data-test-id="mobile-top-links-favorites-link"
      >
        <a class="favorites" :href="topLinks.favorites.link">
          <span class="favorites-svg-container">
            <SVGIcon
              v-if="icon(topLinks.favorites)"
              :icon="icon(topLinks.favorites)"
            ></SVGIcon>
            <span
              v-if="favoriteText"
              data-test-id="account-text"
              class="mobile-utility-nav-top-link-text"
              >{{ favoriteText }}</span
            >
            <div
              v-if="icon(topLinks.favorites) && favoriteCount > 0"
              class="favorite-count"
            >
              <p class="favorite-count-text">{{ favoriteCount }}</p>
            </div>
          </span>
          <span class="mobile-utility-nav-top-link-text">{{
            topLinks.favorites.mobileUtilityNavText
          }}</span>
        </a>
      </li>
      <!-- top links -->
      <li v-for="(topLink, index) in renderedTopLinks" :key="index">
        <a
          v-if="topLink.text.includes('Key Rewards')"
          :href="`/m${updateKeyRewards(topLink)}`"
        >
          <SVGIcon v-if="topLink.SVGIcon" :icon="topLink.SVGIcon"></SVGIcon>
          {{ topLink.text }}
        </a>
        <a
          v-if="
            topLink.text !== 'Recently Viewed' &&
            !(
              topLink.enableBabyRegistryLink &&
              topLink.babyRegistryLink &&
              topLink.babyRegistryTargetTab === activeTab
            )
          "
          :href="`${topLink.targetIsResponsive ? '' : '/m'}${topLink.link}`"
        >
          <SVGIcon v-if="icon(topLink)" :icon="icon(topLink)"> </SVGIcon>
          <span
            v-if="isMobileUtilityNavEnabled"
            class="mobile-utility-nav-top-link-text"
          >
            {{ topLink.mobileUtilityNavText }}
          </span>
          <span v-else>{{ topLink.text }}</span>
        </a>
        <a
          v-if="
            topLink.text !== 'Recently Viewed' &&
            topLink.enableBabyRegistryLink &&
            topLink.babyRegistryLink &&
            topLink.babyRegistryTargetTab === activeTab
          "
          :href="topLink.babyRegistryLink"
        >
          <SVGIcon v-if="icon(topLink)" :icon="icon(topLink)"> </SVGIcon>
          <span
            v-if="isMobileUtilityNavEnabled"
            class="mobile-utility-nav-top-link-text"
          >
            {{ topLink.mobileUtilityNavText }}
          </span>
          <span v-else>{{ topLink.text }}</span>
        </a>
        <a
          v-else-if="topLink.text === 'Recently Viewed'"
          :href="'#'"
          data-test-id="recentlyViewedLink"
          @click="handleRVIFlyout($event)"
        >
          <span class="recently-viewed-icon">
            <SVGIcon v-if="icon(topLink)" :icon="icon(topLink)"> </SVGIcon>
            <span
              v-if="shouldShowPznNotification"
              class="pzn-mobile-notification"
              data-test-id="pznMobileNotification"
            >
              <SVGIcon icon="pzn-notification-icon"></SVGIcon>
            </span>
          </span>
          <span
            v-if="isMobileUtilityNavEnabled"
            class="mobile-utility-nav-top-link-text"
            >{{ topLink.mobileUtilityNavText }}</span
          >
          <span v-else>{{ topLink.text }}</span>
        </a>
      </li>
      <li
        v-if="isMobileUtilityNavEnabled && mobileUtilityNavMyStoreEnabled"
        v-show="myStoreLoaded"
      >
        <div class="my-store-mobile-container">
          <MyStore
            v-on:hasLoaded="myStoreLoaded = $event"
            :isMainInstance="true"
            :isMobileUtilityNavEnabled="isMobileUtilityNavEnabled"
            :topLinksConfig="topLinks"
          />
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { addEvent } from "@js-ecom-tracking/datalayer";
import EventBus from "../../util/event-bus";
import { getConfigById, getKeyRewardsLink } from "../../util/context";
import {
  getItemFromSessionStorage,
  saveItemToSessionStorage,
} from "@js-ecom-mfe/browser-storage";
import { GOVERNOR_USER_STATE_KEY } from "../../../src/util/constants.js";
import KeyRewardsNavigationCTA from "../key-rewards/KeyRewardsNavigationCTA.vue";
import { mapActions, mapGetters } from "vuex";
import MyStore from "../my-store/MyStore.vue";
import { RVI_LABELS } from "../../util/constants";
const SVGIcon = () =>
  import(/* webpackChunkName: "header-footer-svg-icon" */ "../SVGIcon.vue");

export default {
  name: "mobile-top-links",
  data() {
    return {
      // eslint-disable-next-line no-empty-function
      addEvent: () => {},
      campaignName: "",
      isPznTriggeredCampaignActive: false,
      isSignedIn: false,
      mobileUtilityNavConfig: {},
      myStoreLoaded: false,
      renderedTopLinks: [],
      shouldShowPznNotification: false,
      topLinks: [],
      userName: "",
      activeTab: "",
    };
  },
  components: {
    KeyRewardsNavigationCTA,
    MyStore,
    SVGIcon,
  },
  computed: {
    accountText() {
      const customGreetingEnabled =
        this.topLinks?.myAccount?.isMobileUtilityNavCustomLoggedInGreeting ||
        false;
      if (this.isMobileUtilityNavEnabled) {
        if (this.isSignedIn) {
          return customGreetingEnabled
            ? `Hi, ${this.userName.split("")[0]}!`
            : this.topLinks.myAccount.mobileUtilityNavText;
        } else {
          return this.topLinks?.myAccount?.text ?? "Sign In";
        }
      } else {
        return this.userName;
      }
    },
    mobileUtilityNavMyStoreEnabled() {
      return this.topLinks?.myStore?.isEnabled || false;
    },
    numberLinks() {
      const accountEnabled = this.shouldShowAccountIcon ? 1 : 0;
      const favoritesEnabled = this.shouldShowFavoritesIcon ? 1 : 0;
      const storeEnabledAndLoaded =
        this.mobileUtilityNavMyStoreEnabled && this.myStoreLoaded ? 1 : 0;
      return this.isMobileUtilityNavEnabled
        ? `num-columns-${
            favoritesEnabled +
            accountEnabled +
            this.renderedTopLinks.length +
            storeEnabledAndLoaded
          }`
        : "";
    },
    shouldShowAccountIcon() {
      return typeof this.topLinks?.myAccount?.isEnabled === "boolean"
        ? this.topLinks.myAccount.isEnabled
        : true;
    },
    shouldShowFavoritesIcon() {
      return this.topLinks?.favorites?.isEnabled || false;
    },
    favoriteText() {
      return this.topLinks?.favorites?.text ?? false;
    },
    showFavoritesCount() {
      return this.topLinks?.favorites?.showCount ?? true;
    },
  },
  props: {
    favoriteCount: {
      type: Number,
      default: 0,
    },
    isMainInstance: {
      type: Boolean,
      default: false,
    },
    isMobileUtilityNavEnabled: {
      type: Boolean,
      default: false,
    },
    keyRewardsAmount: {
      type: String,
      default: "",
    },
    keyRewardsMobileNavigationCTALink: {
      type: String,
      default: "/account/keyrewards.html",
    },
    keyRewardsMobileNavigationCTAText: {
      type: String,
      default: "View Your Key Rewards!",
    },
    myStoreEnabled: {
      type: Boolean,
      default: false,
    },
    shouldShowKeyRewardsMobileNavigationCTA: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions(["setPznTriggeredCampaign"]),
    ...mapGetters(["getPznTriggeredCampaign"]),
    updateShouldShowPznNotification() {
      const isMobileNotificationEnabled = getItemFromSessionStorage(
        "pznTriggeredCampaigns",
        "mobileNotificationEnabled"
      );

      const hasBeenClicked = getItemFromSessionStorage(
        "pznTriggeredCampaigns",
        "mobileLinkHasBeenClicked"
      );

      this.shouldShowPznNotification =
        isMobileNotificationEnabled && !hasBeenClicked;
    },
    getTopLinks() {
      return getConfigById("mobileTopLinks", this);
    },
    handleRVIFlyout(e) {
      e.preventDefault();
      if (this.isPznTriggeredCampaignActive) {
        this.addEvent({
          category: "pzn triggered carousel",
          item: "display",
          campaignName: this.campaignName,
        });
        saveItemToSessionStorage(
          "pznTriggeredCampaigns",
          "mobileLinkHasBeenClicked",
          true
        );
        this.shouldShowPznNotification = false;
      }
      this.addEvent({
        category: "recently-viewed",
        item: "open",
      });
      EventBus.$emit("toggleRecentlyViewedDrawer");
    },
    handleLink(link) {
      const rviLabels = link?.rviLabels ?? RVI_LABELS;
      if (!rviLabels?.includes(link.text)) {
        return true;
      }
      if (link.disabled) {
        return false;
      }
      // Override ABTest variation check when M.U.N. is enabled
      if (link.ABTestEnabled && !this.isMobileUtilityNavEnabled) {
        let rviABTest = this.$store.state.header.activeTests[link.ABTest];
        if (rviABTest) {
          return rviABTest.variation === link.activeVariant;
        }
      }
      return true;
    },
    icon(link) {
      if (this.isMobileUtilityNavEnabled) {
        return link.mobileUtilityNavIcon
          ? link.mobileUtilityNavIcon
          : link.SVGIcon;
      }
      return link.SVGIcon;
    },
    updateKeyRewards(link) {
      return getKeyRewardsLink(link);
    },
  },
  created() {
    const topLinksData = this.getTopLinks();
    this.topLinks = topLinksData.topLinks;
    this.renderedTopLinks = this.topLinks.links.filter((link) =>
      this.handleLink(link)
    );
    this.mobileUtilityNavConfig = topLinksData?.mobileUtilityNav || {};
    this.userName = this.topLinks.myAccount.text;
  },
  mounted() {
    window.WSI.state.onChange(GOVERNOR_USER_STATE_KEY, (displayName) => {
      this.isSignedIn = !!displayName;
      const firstName = displayName.split(" ")[0];
      this.userName =
        this.isSignedIn && firstName
          ? `${firstName}'s Account`
          : this.topLinks.myAccount.text;
    });
    this.addEvent = addEvent;
    EventBus.$on("setPznTriggeredCampaignActive", (campaignName) => {
      this.isPznTriggeredCampaignActive = true;
      this.campaignName = campaignName;
    });

    EventBus.$on("pznMobileNotificationEnabledStatusChanged", () => {
      this.updateShouldShowPznNotification();
    });
    EventBus.$on("onTabSelection", (activeTab) => {
      this.activeTab = activeTab;
    });
  },
};
</script>

<template>
  <ul
    data-style="primary-nav"
    :id="activeTab && `tabpanel-${activeTab}`"
    :role="(activeTab && 'tabpanel') || ''"
    :aria-labelledby="activeTab && `tabpanel-${activeTab}`"
    :class="`nav-menu ${mobileNavBrandTitle ? mobileNavBrandTitle.id : ''}`"
    data-component="Global-header-PrimaryNav"
    data-test-id="global-header-primary-nav"
    v-click-outside="closeMenu"
    v-if="showPrimaryNavMenu()"
  >
    <li
      v-if="showMobileTabbedNav && activeTab ? false : mobileNavBrandTitle"
      :class="`site-link-title HOD ${mobileNavBrandTitle.id}`"
      data-test-id="site-link-title"
    >
      <a
        :href="mobileNavBrandTitle.link"
        v-bind:class="{ 'mobile-brand-title': isResponsiveScreen }"
      >
        {{ mobileNavBrandTitle.brand_name }}</a
      >
    </li>
    <li
      :key="topNavItem.indexKey"
      v-for="(topNavItem, index) in topNavItems"
      :class="getClasses(topNavItem, index)"
      :ref="`topNavItem-${index}`"
      :data-params="headerLinkParams"
      @click="storeLinkClickInfo"
    >
      <template
        v-if="topNavItem.hasOwnProperty('enabled') ? topNavItem.enabled : true"
      >
        <template v-if="topNavItem.customComponent">
          <a
            :href="topNavItem.url || 'javascript:void(0)'"
            v-html="topNavItem.name"
          ></a>
          <component :is="topNavItem.customComponent"> </component>
        </template>
        <template
          v-else-if="
            !topNavItem.displayText && (topNavItem.catData || topNavItem.custom)
          "
        >
          <PrimaryNavPopOutMenu
            v-if="topNavItem.custom || topNavItem.catData.categories"
            :catData="topNavItem.catData"
            :customData="
              topNavItem.custom
                ? topNavItem.categories || topNavItem.catData
                : null
            "
            :navLinkCatId="topNavItem.id"
            :hasSpotlight="topNavItem.hasSpotlight || false"
            :isResponsive="isResponsiveScreen"
            :mobileCatSiblingLinks="topNavItem.mobileCatSiblingLinks || {}"
            :showLeftNavHeaders="config.showLeftNavHeaders || false"
            :showLinksIfHasGlobalOrNavLink="
              config.showLinksIfHasGlobalOrNavLink || true
            "
            :spotlightId="topNavItem.spotlightId || ''"
            :showSubcatImgs="topNavItem.showSubcatImgs || false"
            :isVerticalMenu="isVerticalMenu"
            :addNumbering="!topNavItem.displayText"
          />
        </template>
        <template v-else-if="showAnchorLinkAndChildren(topNavItem)">
          <a
            :href="getHref(topNavItem) || 'javascript:void(0)'"
            v-html="
              topNavItem.text
                ? topNavItem.text
                : topNavItem.catData
                  ? topNavItem.catData.name
                  : ''
            "
            @click="
              isResponsiveScreen &&
              (topNavItem.categories || topNavItem.catData.categories)
                ? toggleTopNav($event, index)
                : isIpad && isIpadDeviceCheckEnabled
                  ? toggleTopNav($event, index, getHref(topNavItem))
                  : null
            "
            :aria-expanded="
              isResponsiveScreen && topNavActive === index && topNavActiveState
                ? 'true'
                : 'false'
            "
            data-test-id="top-nav-item-cat-data-link"
          ></a>
          <button
            type="button"
            :aria-label="getNavItemText(topNavItem)"
            aria-expanded="false"
            class="drop-down-icon"
            ref="menuIcon"
            data-test-id="dropdown-icon"
            @click="showDropdownMenu($event, '#nav-main')"
            @keyup.esc="closeDropdownMenu"
          >
            <SVGIcon :icon="'icon-dropdown'"></SVGIcon>
          </button>
          <PrimaryNavPopOutMenu
            v-if="topNavItem.custom || topNavItem.catData.categories"
            :catData="topNavItem.catData"
            :customData="
              topNavItem.custom
                ? topNavItem.categories || topNavItem.catData
                : null
            "
            :navLinkCatId="topNavItem.id"
            :hasSpotlight="topNavItem.hasSpotlight || false"
            :isResponsive="isResponsiveScreen"
            :mobileCatSiblingLinks="topNavItem.mobileCatSiblingLinks || {}"
            :showLeftNavHeaders="config.showLeftNavHeaders || false"
            :showLinksIfHasGlobalOrNavLink="
              config.showLinksIfHasGlobalOrNavLink || true
            "
            :spotlightId="topNavItem.spotlightId || ''"
            :showSubcatImgs="topNavItem.showSubcatImgs || false"
            :isVerticalMenu="isVerticalMenu"
            :tabNavParam="getTabNavParam()"
          />
        </template>
        <template v-if="showStaticAnchorLink(topNavItem)">
          <a
            :href="topNavItem.url || 'javascript:void(0)'"
            :class="topNavItem.class"
            v-html="topNavItem.text"
            data-test-id="static-top-nav-url-link"
          ></a>
        </template>
      </template>
    </li>
  </ul>
</template>

<script>
import PrimaryNavPopOutMenu from "./PrimaryNavPopOutMenu.vue";
import { getCategoryDataFromPath } from "../../util/categoryData";
import {
  getApplicationUri,
  getBrandConcept,
  getConfigById,
  getSubBrand,
  getSubBrands,
} from "../../util/context";
import EventBus from "../../util/event-bus";
import {
  getNavBarConfig,
  getPrimaryHeaderConfig,
  getMobileSubBrandPrimaryHeaderConfig,
} from "../../util/nav";
import {
  getItemFromLocalStorage,
  saveItemToLocalStorage,
} from "@js-ecom-mfe/browser-storage";
import { logger } from "@js-ecom-mfe/logger";
import { REPONAME } from "../../util/constants";
import breakPoints from "../../mixins/breakPoints";
import clickOutside from "../../directives/click-outside";
import RegistryPopOut from "./ActionLinkList/RegistryPopOut.vue";
import { removeTrailingSlash } from "../../util/string";
import { isDeviceIpad } from "../../util/checkDeviceType";
const SVGIcon = () =>
  import(/* webpackChunkName: "header-footer-svg-icon" */ "../SVGIcon.vue");
import dropdownMenu from "../../mixins/dropdownMenu";
import Vue from "vue";
import { mapState } from "vuex";
import {
  storeLinkClickInfo,
  createDataParamsAttr,
} from "../../common/linkUtilities";
Vue.use(clickOutside);
const fileName = "PrimaryNav.vue";

export default {
  name: "primary-nav",
  components: {
    PrimaryNavPopOutMenu,
    RegistryPopOut,
    SVGIcon,
  },
  props: {
    brandContext: {
      type: String,
      default: "",
    },
    isVerticalMenu: {
      type: Boolean,
      default: false,
      required: false,
    },
    showMobileTabbedNav: {
      type: Boolean,
      default: false,
    },
    topLevelTabs: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      applicationUri: "",
      concept: "",
      config: getNavBarConfig(this) || {},
      isPrimaryNavSSREnabled:
        this.$store?.state?.header?.config?.isPrimaryNavSSREnabled ?? false,
      isPrimaryNavSSREnabledNonBot:
        this.$store?.state?.header?.config?.isPrimaryNavSSREnabledNonBot ??
        false,
      isSeoBot: this.$store?.state?.header?.activeProperties?.isSeoBot === "1",
      subBrand: "",
      currentUrl: "",
      topNavItems: [],
      // eslint-disable-next-line no-undefined
      topNavActive: undefined,
      topNavActiveState: false,
      isResponsiveScreen: false,
      isIpadDeviceCheckEnabled:
        getConfigById("isIpadDeviceCheckEnabled", this)?.enabled || true,
      isIpad: "",
      tabReferences: {},
      activeTab: "",
    };
  },
  mixins: [breakPoints, dropdownMenu],
  methods: {
    async getCategoryData() {
      return await window.catInfoLoaded;
    },
    closeMenu(e) {
      if (!this.isIpad) {
        return true;
      }
      // eslint-disable-next-line no-undefined
      this.topNavActive = undefined;
    },
    /**
     * @returns {boolean} If active tab present in mobile brand title or tabs list is undefined
     * then it returns true else it is false
     */
    showPrimaryNavMenu() {
      return this.showMobileTabbedNav && this.mobileNavBrandTitle
        ? // eslint-disable-next-line no-undefined
          this.mobileNavBrandTitle?.tabs === undefined ||
            this.mobileNavBrandTitle?.tabs?.includes(this.activeTab)
        : true;
    },
    /**
     * Given the top nav item object to show the anchor link.
     * @param {object} topNavItem object holds the category info
     * @returns {object} top nav item cataegory data or custom value
     */
    showAnchorLinkAndChildren(topNavItem) {
      return (
        (this.showMobileTabbedNav && topNavItem.custom
          ? // eslint-disable-next-line no-undefined
            topNavItem?.tabs === undefined ||
            topNavItem?.tabs?.includes(this.activeTab)
          : true) &&
        (topNavItem.catData || topNavItem.custom)
      );
    },
    /**
     * Given the top nav item object to show the anchor link.
     * @param {object} topNavItem object holds the Url path
     * @returns {boolean} top nav item doens't have cataegory data or custom value
     * then it returns true else false
     */
    showStaticAnchorLink(topNavItem) {
      return (
        (this.showMobileTabbedNav
          ? // eslint-disable-next-line no-undefined
            topNavItem?.tabs === undefined ||
            topNavItem?.tabs?.includes(this.activeTab)
          : true) &&
        !topNavItem.catData &&
        !topNavItem.custom &&
        !topNavItem.customComponent
      );
    },

    getClasses(topNavItem, index) {
      let classList = topNavItem.class;
      if (
        (this.currentUrl.indexOf(topNavItem.path) === 1 &&
          !this.isResponsiveScreen) ||
        (this.topNavActive === index &&
          this.topNavActiveState &&
          this.isResponsiveScreen) ||
        (!topNavItem?.displayText && topNavItem?.catData)
      ) {
        classList = `${classList} active`;
      } else if (topNavItem?.displayText === false) {
        classList = `${classList} hideNavMenu`;
      } else {
        classList = `${classList}`;
      }
      return classList;
    },
    /**
     * Utility routine to clean up a given path and then
     * given a query parameter append to that path.
     * @param {string} path string to process
     * @param {string} queryParam string to append to path
     * @returns {string} properly formed path
     */
    createPath(path, queryParam) {
      let returnPath;
      if (path) {
        // remove any leading and trailing slashes and add in one leading slash
        returnPath = `/${path.trim().replace(/^\/+|\/+$/g, "")}`;

        if (!returnPath.endsWith(".html")) {
          // if not html in path add the trailing slash
          returnPath = `${returnPath}/`;
        }
        if (
          queryParam &&
          !this.parametersToRemove.some((paramToRemove) =>
            queryParam.includes(paramToRemove)
          )
        ) {
          returnPath = `${returnPath}${queryParam}`;
        }
      }
      return returnPath;
    },
    /**
     * Given the navigation item object create an appropriate href link.
     * @param {object} topNavItem object holds the path info
     * @returns {string} path of the link
     */
    getHref(topNavItem) {
      if (topNavItem?.custom) {
        return topNavItem.link
          ? `${this.createPath(topNavItem.link, "?cm_type=gnav")}`
          : `${this.createPath(topNavItem.path, "?cm_type=gnav")}`;
      } else if (topNavItem?.catData?.heroLink) {
        return `${this.createPath(
          topNavItem.catData.heroLink,
          "?cm_type=gnav"
        )}`;
      } else {
        return `${this.createPath(topNavItem?.catData?.path, "?cm_type=gnav")}`;
      }
    },
    async getTopNavItems() {
      this.concept = this.brandContext
        ? this.brandContext
        : getBrandConcept(this);
      const brandId = this.isMediumBreakPoint()
        ? this.concept
        : getSubBrand(this).id;
      this.subBrand = this.brandContext ? this.brandContext : brandId;
      let topNavItems = [];
      // this is required for mobile for a brand which has sub brands
      if (this.brandContext) {
        topNavItems = getMobileSubBrandPrimaryHeaderConfig(
          this,
          this.brandContext,
          this.isResponsiveScreen
        );
      } else {
        const isMobile = this.isResponsiveScreen && this.isMobile;
        topNavItems = getPrimaryHeaderConfig(this, isMobile);
      }
      const categoryData = await this.getCategoryData();
      this.buildTabReferences();
      // For each topNavItem:
      // - Find its corresponding catData and set it under its catData property.
      // - Assign a unique index key so it can be efficiently indexed for VueJS v-for change detection.
      topNavItems.forEach((topNavItem, index) => {
        if (topNavItem.path && topNavItem.path.length && !topNavItem.custom) {
          const correspondingCatDataForTopNavItem = getCategoryDataFromPath(
            categoryData,
            topNavItem.path,
            this.showMobileTabbedNav && this.activeTab,
            this.tabReferences,
            this.topLevelTabs
          );
          topNavItem.catData = {};
          if (correspondingCatDataForTopNavItem) {
            topNavItem.catData = correspondingCatDataForTopNavItem;
          }
        }
        topNavItem.indexKey = `${
          topNavItem.name ||
          topNavItem.text ||
          (topNavItem.catData && topNavItem.catData.name)
        }-${index}`;
        topNavItem.displayText = topNavItem?.displayText ?? true;
      });
      return topNavItems.filter(
        (topNavItem) =>
          (topNavItem.text && !topNavItem.path) ||
          topNavItem.name ||
          topNavItem.catData?.name
      );
    },
    /**
     * Re-render the Primary Nav when the viewport changes between
     * responsive and non-responsive modes.
     * @param {boolean} newResponsiveState new values for responsive state, true if responsive
     */
    handleResponsiveState(newResponsiveState) {
      if (this.isResponsiveScreen !== newResponsiveState) {
        this.isResponsiveScreen = this.isVerticalMenu
          ? true
          : newResponsiveState;
        this.getPrimaryNav();
      }
    },
    /**
     * @param {object} e eventHandler
     * @param {number} topNavItem the index of the nav item which is selected
     * @param {string} path redirect/route path of the selected nav item
     */
    toggleTopNav(e, topNavItem, path) {
      // since conditional event binding with modifier is not working have to do traditional way
      e.preventDefault();
      if (
        this.topNavActive === topNavItem &&
        this.isIpad &&
        this.isIpadDeviceCheckEnabled &&
        !this.isResponsiveScreen
      ) {
        window.location.href = `${removeTrailingSlash(
          this.applicationUri
        )}${path}`;
        return true;
      }
      this.topNavActiveState =
        this.topNavActive === topNavItem ? !this.topNavActiveState : true;
      this.topNavActive = topNavItem;
      /**
       * On clicking the nav, when it got scrolled beyond the view port,
       * then this will make sure that the opened navitem is at the top of the mobileNavigation
       */
      this.$nextTick(() => {
        const topNavItemEl = this.$refs[`topNavItem-${this.topNavActive}`]?.[0];
        if (topNavItemEl) {
          const nav_item_offset = topNavItemEl.getBoundingClientRect().top;
          const menu_offset = topNavItemEl
            .closest(".mobileNavigation")
            .getBoundingClientRect().top;
          if (nav_item_offset < menu_offset) {
            topNavItemEl.closest(".mobileNavigation").scrollTop =
              topNavItemEl.offsetTop;
          }
        }
      });
    },
    buildTabReferences() {
      let tabReferences =
        this.$store?.state?.header?.tabsData?.references || {};
      if (tabReferences && Object.keys(tabReferences).length) {
        saveItemToLocalStorage("tabReferences", tabReferences);
      }
      this.tabReferences =
        tabReferences && Object.keys(tabReferences).length
          ? tabReferences
          : getItemFromLocalStorage("tabReferences");
    },
    async getPrimaryNav() {
      try {
        this.topNavItems = [];
        this.topNavItems = await this.getTopNavItems();
        await this.$nextTick();
        // get personalization data only for desktop
        if (!this.isResponsiveScreen) {
          EventBus.$emit("primaryNavRendered");
        }
      } catch (e) {
        logger.warn(REPONAME, fileName, "mounted", e);
      }
    },
    getTabNavParam() {
      return this.showMobileTabbedNav
        ? `&tabnav=${this.activeTab}`
        : this.isResponsiveScreen
          ? `&tabnav=regNav`
          : `&tabnav=non-mobiletabnav`;
    },
    ssrMapTopNavItems(topNavItems) {
      const updatedNavItems = topNavItems.map((item) => {
        let text;
        if (item.path && !item.text) {
          const data =
            item.path.match(/\/?[a-zA-Z-]+\/([a-zA-Z\-_&+]+)/i) || "";
          text = (data[1] || item.path)
            .replace(/(dorm-|modern-|home-|apartment-)/i, "")
            .replace(/[-_\/]/g, " ")
            .replace(/ [a-z]/g, (m) => m.toUpperCase())
            .replace(/^[a-z]/g, (m) => m.toUpperCase());
        }
        return {
          ...item,
          text: text || item.text,
          custom: true,
        };
      });
      return updatedNavItems;
    },
    storeLinkClickInfo,
  },
  created() {
    this.concept = getBrandConcept(this);
    this.subBrand = getSubBrand(this).id;
    if (
      (this.isPrimaryNavSSREnabled && this.isSeoBot) ||
      (this.isPrimaryNavSSREnabledNonBot && !this.isSeoBot)
    ) {
      if (this.isMediumBreakPoint() || this.isVerticalMenu) {
        this.isResponsiveScreen = true;
      }
      if (typeof window !== "undefined") {
        this.currentUrl = window.location.pathname;
      }
      EventBus.$on("mediumBreakPoint", () => {
        this.handleResponsiveState(true);
      });
      EventBus.$on("largeBreakPoint", () => {
        this.handleResponsiveState(false);
      });
      this.topNavItems = getPrimaryHeaderConfig(this);
      this.categoryData =
        this.$store?.state?.header?.config?.primaryNavBar?.catData ?? {};
      if (this.topNavItems.length && Object.entries(this.categoryData).length) {
        this.topNavItems.forEach((topNavItem, index) => {
          if (
            topNavItem?.path?.length &&
            !topNavItem.custom &&
            this.categoryData?.categories
          ) {
            const correspondingCatDataForTopNavItem = getCategoryDataFromPath(
              this.categoryData.categories,
              topNavItem.path,
              this.showMobileTabbedNav && this.activeTab,
              this.tabReferences,
              this.topLevelTabs
            );

            topNavItem.catData = {};
            if (correspondingCatDataForTopNavItem) {
              topNavItem.catData = correspondingCatDataForTopNavItem;
            }
          }
          topNavItem.indexKey = `${
            topNavItem.name ||
            topNavItem.text ||
            (topNavItem.catData && topNavItem.catData.name)
          }-${index}`;
          topNavItem.displayText = topNavItem?.displayText ?? true;
        });

        this.topNavItems = this.topNavItems.filter(
          (topNavItem) =>
            (topNavItem.text && !topNavItem.path) ||
            topNavItem.name ||
            topNavItem.catData?.name
        );
        this.topNavItems = this.ssrMapTopNavItems(this.topNavItems);
      }
    } else if (!this.isPrimaryNavSSREnabled && !this.isSeoBot) {
      this.concept = getBrandConcept(this);
      this.subBrand = getSubBrand(this).id;
      let topNavItems = getPrimaryHeaderConfig(this);
      if (!topNavItems?.length) {
        topNavItems = getPrimaryHeaderConfig(this);
      }

      this.topNavItems = this.ssrMapTopNavItems(topNavItems);
    }
  },
  beforeMount() {
    // this is required for sub brands toggle nav on mobile since on page load responsive is false
    // DOM reactivity for sub brand is not happening on page load
    if (this.isMediumBreakPoint() || this.isVerticalMenu) {
      this.isResponsiveScreen = true;
    }
    EventBus.$on("onTabSelection", (activeTab) => {
      this.activeTab = activeTab;
      this.getPrimaryNav();
    });
  },
  mounted() {
    this.isIpad = isDeviceIpad();
    this.applicationUri = getApplicationUri(this);
    this.currentUrl = window.location.pathname;
    EventBus.$on("mediumBreakPoint", () => {
      this.handleResponsiveState(true);
    });
    EventBus.$on("largeBreakPoint", () => {
      this.handleResponsiveState(false);
    });
    this.getPrimaryNav();
  },
  computed: {
    ...mapState({
      linkParametersConfig: (state) =>
        state.header?.config?.linkParameters ?? {},
      parametersToReport: (state) =>
        state.header?.config?.linkParameters?.parametersToReport ?? [],
      parametersToRemove: (state) =>
        state.header?.config?.linkParameters?.parametersToRemove ?? [],
    }),
    headerLinkParams() {
      return createDataParamsAttr(
        {
          // eslint-disable-next-line camelcase
          cm_type: "gnav",
        },
        this.linkParametersConfig
      );
    },
    /**
     * Return brand full name of a brand
     */
    mobileNavBrandTitle() {
      // todo: need to get sub brands of a brand for mobile (PT, PK, PB, WS)
      const brand = this.concept;
      const subBrands = getSubBrands(this);
      return subBrands.length > 0
        ? subBrands.find((subBrand) => subBrand.id === brand)
        : "";
    },
  },
};
</script>

<template>
  <marketing-overlay
    data-style="mobile-marketing-overlay"
    :overlay-email-title="mobileMarketingOverlayConfig.overlayEmailTitle"
    :overlay-mobile-title="mobileMarketingOverlayConfig.overlayMobileTitle"
    :overlay-thank-you-title="mobileMarketingOverlayConfig.overlayThankYouTitle"
    :field-hint="mobileMarketingOverlayConfig.fieldHint"
    :signup-form-field="mobileMarketingOverlayConfig.signupFormField"
    :financial-incentives-terms-link="
      mobileMarketingOverlayConfig.financialIncentivesTermsLink
    "
    :do-not-sell-link="mobileMarketingOverlayConfig.doNotSellLink"
    :privacy-policy-link="mobileMarketingOverlayConfig.privacyPolicyLink"
    :terms-policy-url="mobileMarketingOverlayConfig.termsPolicyUrl"
    :contact-us-url="mobileMarketingOverlayConfig.contactUsUrl"
    :overlay-position="mobileMarketingOverlayConfig.mobileOverlayPosition"
    :brand-name="brand"
    :email-signup-header-content-type="
      mobileMarketingOverlayConfig.mktgMsgContentTypeOnEmailSignup
    "
    :sms-optin-header-content-type="
      mobileMarketingOverlayConfig.mktgMsgContentTypeOnSmsOptIn
    "
    :thank-you-screen-content-type="
      mobileMarketingOverlayConfig.mktgMsgContentTypeOnThankYouView
    "
    :age-check-required="mobileMarketingOverlayConfig.ageCheckRequired"
    :email-trigger-url="mobileMarketingOverlayConfig.emailTriggerUrl"
    :flat-file-service-version="
      mobileMarketingOverlayConfig.flatFileServiceVersion
    "
    :cookie-name="cookieName"
    :cookie-duration="cookieDuration"
    :email-hero-image="emailHeroImage"
    :mobile-hero-image="mobileHeroImage"
    :thank-you-hero-image="thankYouHeroImage"
    :thank-you-screen-button-text="thankYouScreenButtonText"
    :show-ccpa-info-display="showCcpaInfoDisplay"
    :enable-cre="joinEmailCampaignConfig.enableCRE"
    :is-override-marketing-overlay-content="
      mobileMarketingOverlayConfig.isOverrideMarketingOverlayContent
    "
    data-test-id="global-mobile-marketing-overlay"
  ></marketing-overlay>
</template>

<script>
import { EMAIL_OVERLAY_SUPPRESSION_DURATION_IN_DAYS } from "../../util/constants";
import { getConfigById, getBrandConcept } from "../../util/context";

export default {
  name: "mobile-marketing-overlay",
  data() {
    return {
      brand: getBrandConcept(this).toUpperCase(),
      cookieName: `ad_sess_${getBrandConcept(this).toLowerCase()}_email`,
      mobileMarketingOverlayConfig: getConfigById("marketingOverlay", this),
      joinEmailCampaignConfig: getConfigById("joinEmailCampaign", this) || {},
    };
  },
  methods: {
    /**
     * Determines whether the email overlay position is configured
     * to be displayed as modal.
     *
     * @returns {Boolean} - true if "modal", false otherwise
     */
    isOverlayPositionModal() {
      return (
        this.mobileMarketingOverlayConfig?.mobileOverlayPosition === "modal"
      );
    },
  },
  computed: {
    cookieDuration() {
      return (
        this.joinEmailCampaignConfig?.emailOverlayCookieExpirationDays ||
        EMAIL_OVERLAY_SUPPRESSION_DURATION_IN_DAYS
      );
    },
    emailHeroImage() {
      return this.isOverlayPositionModal()
        ? this.mobileMarketingOverlayConfig?.emailHeroImage
        : "";
    },
    mobileHeroImage() {
      return this.isOverlayPositionModal()
        ? this.mobileMarketingOverlayConfig?.mobileHeroImage
        : "";
    },
    showCcpaInfoDisplay() {
      return this.isOverlayPositionModal() ? "true" : "false";
    },
    thankYouHeroImage() {
      return this.isOverlayPositionModal()
        ? this.mobileMarketingOverlayConfig?.thankYouHeroImage
        : "";
    },
    thankYouScreenButtonText() {
      return this.isOverlayPositionModal()
        ? this.mobileMarketingOverlayConfig?.thankYouScreenButtonText
        : "";
    },
  },
  // loading the marketing overlay web component during mounting phase for client side rendering
  mounted() {
    require(/* webpackChunkName: "mobile-marketing-overlay-comp" */ "@web-component-ecom-marketing/marketing-overlay");
  },
};
</script>

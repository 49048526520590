import Vue from "vue";
let eventHandler;

Vue.directive("click-outside", {
  // eslint-disable-next-line no-unused-vars
  bind: function (el, binding, vnode) {
    eventHandler = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value();
      }
    };
    document.body.addEventListener("click", eventHandler);
  },
  unbind: function (el) {
    document.body.removeEventListener("click", eventHandler);
  },
});

<template>
  <SimpleHeader
    data-style="header"
    v-if="showSimpleHeader"
    :subBrandContext="subBrandContext"
    :subBrand="subBrand"
    :isMobileOverlayV2Enabled="isMobileOverlayV2Enabled"
    :isMobileOverlayEnabled="isMobileOverlayEnabled"
    :showMarketingCampaignOverlay="showMarketingCampaignOverlay"
    :className="getClassName"
  >
    <template v-slot:simpleMobileHeader>
      <header
        :data-details="mobileMenuState ? 'open' : 'close'"
        ref="mobileHeader"
        role="presentation"
      >
        <div
          class="rtcBanner"
          v-if="isRetailStoreSession && retailStoreBannerMessage"
          data-test-id="retail-store-banner"
        >
          <p>{{ retailStoreBannerMessage }}</p>
        </div>
        <div ref="mobileHeaderContainer">
          <div id="mobileDDHeader" class="HOD" ref="stickyMobileHeader">
            <div class="menu-icon-container">
              <button
                type="button"
                id="menu-icon"
                aria-label="show site navigation"
                @click="toggleHamburgerMenu(simpleHeaderHamburgerMenuType)"
                data-test-id="Global-simple-header-mobile-hamburger-icon"
                :aria-expanded="mobileMenuState"
                class="link"
              >
                <MobileNotificationBadge
                  v-if="shouldDisplayKeyRewardsNotification"
                  data-test-id="Global-header-mobile-notification-badge"
                />
                <div v-html="mobileHeaderConfig.icons.hamburgerSVG"></div>
              </button>
              <button
                v-if="enableMobileExploreButton"
                aria-label="Explore"
                @click="toggleHamburgerMenu('secondary')"
                class="search-icon explore-flyout"
              >
                <div v-html="mobileHeaderConfig.icons.exploreSVG"></div>
              </button>
            </div>
            <MobileHeaderIcon
              data-test-id="Global-header-mobile-header-icon"
              :favoriteCount="favoriteCount"
              :mobileHeaderConfig="mobileHeaderConfig"
              @close="toggleHamburgerMenu"
              showSearchFlyout
            />
          </div>
        </div>
        <!-- for mobile and responsive flyout -->
        <div
          v-if="isResponsive"
          class="mobileNavigation"
          data-test-id="Global-header-mobile-navigation"
          :class="[
            isMobileUtilityNavEnabled ? 'with-mobile-utility-nav' : '',
            getMyStoreIsFlyoutOpen == true ? 'overflow-visible' : '',
          ]"
        >
          <nav
            id="nav-main"
            aria-label="primary"
            v-if="
              simpleHeaderHamburgerMenuType === 'all' ||
              simpleHeaderHamburgerMenuType === 'primary'
            "
          >
            <EcmContentSlot
              v-show="isMobile"
              contentLocation="mobile-global-nav-top-b2b"
            />
            <PrimaryNav />

            <template v-for="(subBrand, index) in mobileSubBrands">
              <PrimaryNav :brand-context="subBrand.id" :key="index" />
            </template>
          </nav>
          <!-- MobileExploreMenu toggle menu starts -->
          <div
            v-if="showMobileExploreMenu"
            @click="toggleMobileExploreMenuItems()"
            class="explore-toggle-menu"
          >
            {{ exploreMenuName }}
          </div>
          <nav
            v-if="showMobileExploreMenuItems"
            id="nav-secondary-container"
            class="mobile-secondary-container explore-toggle-menu-items"
            aria-label="secondary"
          >
            <SecondaryNav :isResponsive="isResponsive" />
          </nav>
          <!-- MobileExploreMenu toggle menu ends -->
          <div
            class="simple-mobile-header-spacer"
            v-if="
              simpleHeaderHamburgerMenuType === 'all' ||
              simpleHeaderHamburgerMenuType === 'primary'
            "
          />
          <div
            v-if="
              handleMobileUtilityNav &&
              (simpleHeaderHamburgerMenuType === 'all' ||
                simpleHeaderHamburgerMenuType === 'secondary')
            "
            class="mobile-utility-nav"
          >
            <MobileTopLinks
              v-if="isResponsive"
              class="HOD"
              :favoriteCount="favoriteCount"
              :isMainInstance="true"
              :isMobileUtilityNavEnabled="isMobileUtilityNavEnabled"
              :keyRewardsAmount="availableLRCBalance"
              :keyRewardsMobileNavigationCTALink="
                appendDataLayerQueryString(
                  keyRewardsMobileNavigationCTANotification,
                  keyRewardsMobileNavigationCTAHref
                )
              "
              :keyRewardsMobileNavigationCTAText="
                keyRewardsMobileNavigationCTAText
              "
              :myStoreEnabled="myStoreConfig.enabled && isResponsive"
              :shouldShowKeyRewardsMobileNavigationCTA="
                shouldShowKeyRewardsMobileNavigationCTA
              "
              data-test-id="Global-header-mobile-top-links"
              @onIntersect="handleIntersect($event)"
              @onNonIntersect="handleNonIntersect($event)"
            />
            <nav
              v-if="!showMobileExploreMenu"
              id="nav-secondary-container"
              class="mobile-secondary-container"
              aria-label="secondary"
            >
              <SecondaryNav :isResponsive="isResponsive" />
            </nav>
          </div>
          <div
            v-else-if="
              simpleHeaderHamburgerMenuType === 'all' ||
              simpleHeaderHamburgerMenuType === 'secondary'
            "
          >
            <EcmContentSlot
              v-show="isMobile"
              contentLocation="mobile-global-nav-top/m"
            />
            <MobileTopLinks
              v-if="isMobile || isMediumBreakPoint()"
              class="HOD"
              :favoriteCount="favoriteCount"
              :keyRewardsAmount="availableLRCBalance"
              :keyRewardsMobileNavigationCTALink="
                appendDataLayerQueryString(
                  keyRewardsMobileNavigationCTANotification,
                  keyRewardsMobileNavigationCTAHref
                )
              "
              :keyRewardsMobileNavigationCTAText="
                keyRewardsMobileNavigationCTAText
              "
              :shouldShowKeyRewardsMobileNavigationCTA="
                shouldShowKeyRewardsMobileNavigationCTA
              "
              data-test-id="Global-header-mobile-top-links"
              @onIntersect="handleIntersect($event)"
              @onNonIntersect="handleNonIntersect($event)"
            />
            <nav
              id="nav-secondary-container"
              class="mobile-secondary-container"
              aria-label="secondary"
            >
              <SecondaryNav :isResponsive="isResponsive" />
            </nav>
            <EcmContentSlot
              v-show="isMobile"
              contentLocation="mobile-global-nav-middle/m"
            />
            <div class="my-store-mobile-container">
              <MyStore
                v-if="myStoreConfig.enabled && isResponsive"
                :isMainInstance="true"
              />
            </div>
          </div>

          <MobileSiteLinks
            v-if="isResponsive && isMobileSiteLinks"
            class="HOD"
          />
          <EcmContentSlot
            v-show="isMobile"
            contentLocation="mobile-global-nav-bottom/m"
          />
          <EcmContentSlot
            v-show="isMobile"
            contentLocation="mobile-global-nav-bottom-b2b"
          />
        </div>
        <SignInPromptContainer
          v-if="shouldDisplaySignInPrompt"
          :isMobile="isMobile"
        ></SignInPromptContainer>
        <MPulseTagger />
      </header>
    </template>
  </SimpleHeader>
  <header
    v-else
    :id="subBrandContext"
    data-component="Global-Header"
    data-test-id="Global-header"
    :class="getClassName"
  >
    <div
      class="rtcBanner"
      v-if="isRetailStoreSession && retailStoreBannerMessage"
      data-test-id="retail-store-banner"
    >
      <p>{{ retailStoreBannerMessage }}</p>
    </div>
    <SeoSchemaTags />
    <template v-if="displayIvrOverlay">
      <InternationalVisitorRedirectOverlay
        :countryCode="wsGeoLocationCountryCode"
        :config="internationalVisitorRedirectConfig"
      />
    </template>
    <div
      class="mobile-header-wrapper"
      data-test-id="mobile-header-wrapper"
      :class="
        this.isMobile && isSiteWideBannerEnabled && !showBrandsListBannerHeader
          ? 'hide-header'
          : ''
      "
    >
      <section id="top-promo">
        <EcmContentSlot
          v-show="!this.isMobile"
          contentLocation="global-wide-banner-1"
          :contentArray="getSsrEcmContentData['global-wide-banner-1/']"
          :refreshOnClient="true"
        />
        <EcmContentSlot
          v-show="!this.isMobile"
          contentLocation="global-wide-banner-2"
          :contentArray="getSsrEcmContentData['global-wide-banner-2/']"
          :refreshOnClient="true"
        />
        <EcmContentSlot
          v-show="!this.isMobile"
          contentLocation="global-wide-banner-3"
          :contentArray="getSsrEcmContentData['global-wide-banner-3/']"
          :refreshOnClient="true"
        />
        <EcmContentSlot
          v-show="!this.isMobile"
          contentLocation="global-wide-banner-4"
          :contentArray="getSsrEcmContentData['global-wide-banner-4/']"
          :refreshOnClient="true"
        />
        <div v-show="this.isMobile">
          <EcmContentSlot contentLocation="mobile-wide-banner-1/m" />
          <EcmContentSlot contentLocation="mobile-wide-banner-2/m" />
          <EcmContentSlot contentLocation="mobile-wide-banner-3/m" />
          <EcmContentSlot contentLocation="mobile-wide-banner-4/m" />
        </div>
      </section>
      <section id="cross-brand-bar" data-test-id="cross-brand-bar">
        <div class="separated-lists">
          <BrandsList @subBrandSelection="handleSubBrandData" />
          <CCPAButton class="HOM" />
          <div class="aside-container">
            <MyStore
              v-if="
                myStoreConfig.enabled &&
                myStoreConfig.crossBrandBar &&
                !isResponsive
              "
              :isMainInstance="true"
            />
            <ListAside class="HOM" />
          </div>
        </div>
      </section>
      <section id="bottom-promo">
        <EcmContentSlot
          v-show="!this.isMobile"
          contentLocation="global-wide-banner-bottom-1"
          :contentArray="getSsrEcmContentData['global-wide-banner-bottom-1/']"
          :refreshOnClient="true"
          :style="{ width: '100%' }"
        />
        <EcmContentSlot
          v-show="!this.isMobile"
          contentLocation="global-wide-banner-bottom-2"
          :contentArray="getSsrEcmContentData['global-wide-banner-bottom-2/']"
          :refreshOnClient="true"
        />
        <EcmContentSlot
          v-show="!this.isMobile"
          contentLocation="global-wide-banner-bottom-3"
          :contentArray="getSsrEcmContentData['global-wide-banner-bottom-3/']"
          :refreshOnClient="true"
        />
        <EcmContentSlot
          v-show="!this.isMobile"
          contentLocation="global-wide-banner-bottom-4"
          :contentArray="getSsrEcmContentData['global-wide-banner-bottom-4/']"
          :refreshOnClient="true"
        />
        <div
          v-if="this.isMobile && isMobileEcmBelowCrossBrandBannerEnabled"
          data-test-id="site-wide-banner-mobile"
        >
          <EcmContentSlot
            contentLocation="mobile-wide-banner-bottom-1/m"
            :contentArray="
              getSsrEcmContentData['mobile-wide-banner-bottom-1/m/']
            "
            :refreshOnClient="true"
          />
          <EcmContentSlot contentLocation="mobile-wide-banner-bottom-2/m" />
          <EcmContentSlot contentLocation="mobile-wide-banner-bottom-3/m" />
          <EcmContentSlot
            contentLocation="mobile-wide-banner-bottom-4/m"
            :contentArray="
              getSsrEcmContentData['mobile-wide-banner-bottom-4/m/']
            "
            :refreshOnClient="true"
          />
        </div>
      </section>
    </div>
    <div
      class="sticky-header"
      ref="headerMain"
      :class="{ pinned: scrollPos > headerPos && !isSubBrandBarSticky }"
    >
      <header class="main-container HOM" role="presentation">
        <div class="my-store-position">
          <MyStore
            v-if="
              myStoreConfig.enabled &&
              !myStoreConfig.crossBrandBar &&
              !isResponsive
            "
            :isMainInstance="true"
          />
          <section
            id="sub-brand-bar"
            :class="{ pinned: scrollPos > headerPos && isSubBrandBarSticky }"
          >
            <div class="separated-lists">
              <SubBrandsList />
              <ActionLinkList
                :keyRewardsAmount="availableLRCBalance"
                :keyRewardsMyAccountNavigationCTALink="
                  appendDataLayerQueryString(
                    this.keyRewardsMyAccountCTANotification,
                    this.keyRewardsMyAccountCTAHref
                  )
                "
                :keyRewardsMyAccountNavigationCTAText="
                  keyRewardsMyAccountNavigationCTAText
                "
                :shouldDisplayKeyRewardsMyAccountNavigationCTA="
                  shouldDisplayKeyRewardsMyAccountNavigationCTA
                "
                :shouldDisplayKeyRewardsNotification="
                  shouldDisplayKeyRewardsNotification
                "
                :subBrand="subBrand"
                data-testid="Global-action-link-list"
                @dismissKeyRewardsNotification="dismissKeyRewardsNotification"
                @onIntersect="handleIntersect($event)"
                @onNonIntersect="handleNonIntersect($event)"
                @productFavoriteTogglerOperationsRecommendation="
                  updateFavoritedRecommendationItem
                "
              />
            </div>
          </section>
        </div>
        <BrandLogo v-if="!isResponsive" />

        <SearchBar />
      </header>
      <header
        :data-details="mobileMenuState ? 'open' : 'close'"
        ref="mobileHeader"
        role="presentation"
      >
        <!-- Prevent page moving by creating a container with fixed height -->
        <div class="mobile-header-container" ref="mobileHeaderContainer">
          <div
            id="mobileDDHeader"
            class="HOD sticky-mobile-header bottom-shadow"
            :class="{ pinned: lastScrollPos > scrollPos && scrollPos !== 0 }"
            ref="stickyMobileHeader"
          >
            <button
              type="button"
              id="menu-icon"
              aria-label="show site navigation"
              @click="toggleHamburgerMenu"
              data-test-id="Global-header-mobile-hamburger-icon"
              :aria-expanded="mobileMenuState"
              class="link"
              ref="hamburgerMenuIcon"
            >
              <MobileNotificationBadge
                v-if="shouldDisplayKeyRewardsNotification"
                data-test-id="Global-header-mobile-notification-badge"
              />
              <div v-html="mobileHeaderConfig.icons.hamburgerSVG"></div>
            </button>
            <MobileHeaderIcon
              data-test-id="Global-header-mobile-header-icon"
              :favoriteCount="favoriteCount"
              :mobileHeaderConfig="mobileHeaderConfig"
              @close="toggleHamburgerMenu"
              ref="mobileHeaderIconCloseRef"
            />
          </div>
        </div>
        <div class="HOD">
          <SearchBar :tabIndexValue="mobileMenuState ? -1 : 0" />
        </div>

        <div
          class="mobileNavigation"
          data-test-id="Global-header-mobile-navigation"
          :class="[
            isMobileUtilityNavEnabled ? 'with-mobile-utility-nav' : '',
            getMyStoreIsFlyoutOpen == true ? 'overflow-visible' : '',
          ]"
        >
          <div
            v-if="handleMobileUtilityNav"
            class="mobile-utility-nav"
            :class="isMobile && !mobileMenuState ? 'mobile-dropdown-A11y' : ''"
          >
            <MobileTopLinks
              v-if="isResponsive"
              class="HOD"
              :favoriteCount="favoriteCount"
              :isMainInstance="true"
              :isMobileUtilityNavEnabled="isMobileUtilityNavEnabled"
              :keyRewardsAmount="availableLRCBalance"
              :keyRewardsMobileNavigationCTALink="
                appendDataLayerQueryString(
                  this.keyRewardsMobileNavigationCTANotification,
                  this.keyRewardsMobileNavigationCTAHref
                )
              "
              :keyRewardsMobileNavigationCTAText="
                keyRewardsMobileNavigationCTAText
              "
              :myStoreEnabled="myStoreConfig.enabled && isResponsive"
              :shouldShowKeyRewardsMobileNavigationCTA="
                shouldShowKeyRewardsMobileNavigationCTA
              "
              data-test-id="Global-header-mobile-top-links"
              @onIntersect="handleIntersect($event)"
              @onNonIntersect="handleNonIntersect($event)"
            />
            <nav
              id="nav-secondary-container"
              class="HOM"
              aria-label="secondary"
            >
              <SecondaryNav :isResponsive="isResponsive" />
            </nav>
          </div>
          <div v-else>
            <EcmContentSlot
              v-show="this.isMobile"
              contentLocation="mobile-global-nav-top/m"
            />
            <MobileTopLinks
              v-if="this.isMobile || this.isMediumBreakPoint()"
              class="HOD"
              :favoriteCount="favoriteCount"
              :keyRewardsAmount="availableLRCBalance"
              :keyRewardsMobileNavigationCTALink="
                appendDataLayerQueryString(
                  this.keyRewardsMobileNavigationCTANotification,
                  this.keyRewardsMobileNavigationCTAHref
                )
              "
              :keyRewardsMobileNavigationCTAText="
                keyRewardsMobileNavigationCTAText
              "
              :shouldShowKeyRewardsMobileNavigationCTA="
                shouldShowKeyRewardsMobileNavigationCTA
              "
              data-test-id="Global-header-mobile-top-links"
              @onIntersect="handleIntersect($event)"
              @onNonIntersect="handleNonIntersect($event)"
            />
            <nav
              id="nav-secondary-container"
              class="HOM"
              aria-label="secondary"
            >
              <SecondaryNav :isResponsive="isResponsive" />
            </nav>
            <EcmContentSlot
              v-show="this.isMobile"
              contentLocation="mobile-global-nav-middle/m"
            />
            <div class="my-store-mobile-container">
              <MyStore
                v-if="myStoreConfig.enabled && isResponsive"
                :isMainInstance="true"
              />
            </div>
          </div>
          <nav
            id="nav-main"
            aria-label="primary"
            :class="isMobile && !mobileMenuState ? 'mobile-dropdown-A11y' : ''"
            @mouseenter="mouseEnterMainNav"
            @mouseleave="mouseLeaveMainNav"
          >
            <EcmContentSlot
              v-show="this.isMobile"
              contentLocation="mobile-global-nav-top-b2b"
            />
            <MobileTabbedNavigation
              data-test-id="mobile-tabbed-navigation"
              v-if="showMobileTabbedNav"
              :config="mobileTabbedNavConfig"
              :tabDefinitions="this.tabDefinitions()"
              :subBrandName="subBrandName"
            />
            <PrimaryNav
              :showMobileTabbedNav="showMobileTabbedNav"
              :topLevelTabs="topLevelTabsDefinition()"
            />
            <!-- this is required for mobile which has sub brands -->
            <template v-for="(subBrand, index) in mobileSubBrands">
              <PrimaryNav
                :brand-context="subBrand.id"
                :showMobileTabbedNav="showMobileTabbedNav"
                :key="index"
                :topLevelTabs="topLevelTabsDefinition()"
              />
            </template>
          </nav>
          <MobileSiteLinks
            v-if="isResponsive && isMobileSiteLinks"
            class="HOD"
          />
          <EcmContentSlot
            v-show="this.isMobile"
            contentLocation="mobile-global-nav-bottom/m"
          />
          <EcmContentSlot
            v-show="this.isMobile"
            contentLocation="mobile-global-nav-bottom-b2b"
          />
          <MobileBottomLinks
            v-if="isResponsive"
            class="HOD"
            :subBrand="subBrand"
            :class="isMobile && !mobileMenuState ? 'mobile-dropdown-A11y' : ''"
          />
        </div>
        <KeyRewardsBanner
          v-if="shouldDisplayKeyRewardsSitewideBanner"
          @onIntersect="handleIntersect($event)"
          @onNonIntersect="handleNonIntersect($event)"
          :keyRewardsAmount="availableLRCBalance"
          :keyRewardsBannerLinkHref="
            appendDataLayerQueryString(
              this.keyRewardsBannerNotification,
              this.keyRewardsBannerHref
            )
          "
          :keyRewardsBannerMessagePrefix="keyRewardsBannerMessagePrefix"
          :keyRewardsBannerMessageSuffix="keyRewardsBannerMessageSuffix"
          data-test-id="Global-header-key-rewards-sitewide-banner"
        />
      </header>
    </div>
    <!-- this spacer prevents jumpiness on the shop list on sticky header -->
    <div class="sticky-header-spacer"></div>
    <InternationalShippingMsgBlock
      v-if="
        isInternational && !this.isMobile && showInternationalShippingMsgBlock
      "
    />
    <EcmContentSlot
      v-show="!this.isMobile && !isInternational"
      contentLocation="global-ticker-1"
      :contentArray="getSsrEcmContentData['global-ticker-1/']"
      :refreshOnClient="true"
    />
    <!-- global-ticker-2 is only for international mode -->
    <EcmContentSlot
      v-show="!this.isMobile && isInternational"
      contentLocation="global-ticker-2"
      :contentArray="getSsrEcmContentData['global-ticker-2/']"
      :refreshOnClient="true"
    />
    <EcmContentSlot
      v-show="!this.isMobile"
      contentLocation="global-ticker-3"
      :contentArray="getSsrEcmContentData['global-ticker-3/']"
      :refreshOnClient="true"
    />
    <EcmContentSlot
      v-show="!this.isMobile"
      contentLocation="global-ticker-4"
      :contentArray="getSsrEcmContentData['global-ticker-4/']"
      :refreshOnClient="true"
    />
    <EcmContentSlot
      v-show="this.isMobile"
      contentLocation="mobile-global-ticker-1/m"
    />
    <EcmContentSlot
      v-show="this.isMobile"
      contentLocation="mobile-global-ticker-2/m"
    />
    <EcmContentSlot
      v-show="this.isMobile"
      contentLocation="mobile-global-ticker-3/m"
    />
    <EcmContentSlot
      v-show="this.isMobile"
      contentLocation="mobile-global-ticker-4/m"
    />
    <InternationalWelcomeMat />
    <!-- this template will be rendered when two-step mobile overlay is enabled for mobile device -->
    <template v-if="!isSeoBot">
      <template
        v-if="
          this.isMobile &&
          isMobileOverlayV2Enabled &&
          showMarketingCampaignOverlay &&
          !isRetailStoreCookieExist
        "
      >
        <MobileMarketingOverlayContainer />
      </template>
      <template
        v-else-if="
          this.isMobile &&
          isMobileOverlayEnabled &&
          showMarketingCampaignOverlay &&
          !isRetailStoreCookieExist
        "
      >
        <MobileMarketingOverlay />
      </template>
      <template
        v-else-if="
          checkCurrentPathForEmailOverlaySuppresion && !isRetailStoreCookieExist
        "
      >
        <JoinEmailCampaign
          :isMobileActive="isMobile"
          :showMarketingCampaignOverlay="showMarketingCampaignOverlay"
        />
      </template>
    </template>
    <MPulseTagger />
    <Analytics />
    <template v-if="flyoutEnabled">
      <RecentlyViewedFlyout :flyoutConfig="flyoutConfig" />
      <RecommendationsFlyOut />
    </template>
    <div
      v-if="recommendationFavoriteFlyoutConfig.isEnabled"
      class="recommendation-favorite-flyout"
    >
      <FlyoutFavoriteContainer
        @favoriteClickThru="onViewFavoritesClick"
        :isMobileView="isMobile"
        :favoriteItem="favoritedRecommendationItem"
        :flyoutConfig="recommendationFavoriteFlyoutConfig"
      />
    </div>
    <PixleePixel
      v-if="pixleeConfig && pixleeConfig.isEnabled"
      :apiKey="pixleeConfig.apiKey"
    />
    <SignInPromptContainer
      v-if="shouldDisplaySignInPrompt"
      :isMobile="isMobile"
    ></SignInPromptContainer>
  </header>
</template>
<script>
import { addEvent, open } from "@js-ecom-tracking/datalayer";
import { getCookie } from "./util/cookies";
import { getFavoritesCount } from "@js-ecom-mfe/favorites";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import ActionLinkList from "./components/header/ActionLinkList/ActionLinkList.vue";
import Analytics from "./components/header/Analytics.vue";
import BrandLogo from "./components/header/BrandLogo.vue";
import BrandsList from "./components/header/BrandsList.vue";
import CCPAButton from "./components/header/CCPAHeaderButton.vue";
import Cookies from "js-cookie";
import EcmContentSlot from "./components/EcmContentSlot.vue";
import EventBus from "./util/event-bus";
import InternationalShippingMsgBlock from "./components/header/InternationalShippingMsgBlock.vue";
import KeyRewardsBanner from "./components/key-rewards/KeyRewardsBanner.vue";
import MobileBottomLinks from "./components/header/MobileBottomLinks.vue";
import MobileHeaderIcon from "./components/header/MobileHeaderIcon.vue";
import MobileMarketingOverlay from "./components/header/MobileMarketingOverlay.vue";
import MobileMarketingOverlayContainer from "./components/header/MobileMarketingOverlayContainer.vue";
import MobileSiteLinks from "./components/header/MobileSiteLinks.vue";
import MobileTabbedNavigation from "./components/header/MobileTabbedNavigation.vue";
import MobileTopLinks from "./components/header/MobileTopLinks.vue";
import MPulseTagger from "./components/header/MPulseTagger.vue";
import MyStore from "./components/my-store/MyStore.vue";
import PrimaryNav from "./components/header/PrimaryNav.vue";
import SecondaryNav from "./components/header/SecondaryNav.vue";
import SeoSchemaTags from "./components/header/SeoSchemaTags.vue";
import SubBrandsList from "./components/header/SubBrandsList.vue";
import { getKeyRewards } from "./services/keyRewards.js";
import SimpleHeader from "./components/header/SimpleHeader/SimpleHeader.vue";
import SignInPromptContainer from "./components/header/SignInPromptContainer.vue";
import PixleePixel from "./components/header/PixleePixel.vue";
import {
  browserLocalStorageAvailable,
  browserSessionStorageAvailable,
  getItemFromLocalStorage,
  saveItemToLocalStorage,
  removeItemFromLocalStorage,
  saveJsonItemToSessionStorage,
  getJsonItemFromSessionStorage,
  saveItemToSessionStorage,
} from "@js-ecom-mfe/browser-storage";
import MobileNotificationBadge from "./components/key-rewards/MobileNotificationBadge.vue";
import {
  getBrandConcept,
  getSubBrands,
  getSubBrand,
  getConfigById,
  getApplicationUri,
  getGovernorState,
} from "./util/context";
import {
  startListening as startGovernorWatch,
  stopListening as stopGovernorWatch,
} from "./util/governorState";
import breakPoints from "./mixins/breakPoints";
import resize from "./mixins/resize";
import noScroll from "./mixins/noScroll";
import {
  setSignedInStatus,
  setSignedInStatusV2,
} from "./util/detectSignedInStatus";
import userLocationDetails from "./mixins/userLocationDetails";
import {
  ACTIVE_TARGET_TESTS_KEY,
  DEFAULT_INTERNATIONAL_COOKIE_VALUE,
  GOVERNOR_USER_STATE_KEY,
  IVR_PREF_HIDE_SITE_LINK_DISMISS,
  CURRENT_ZIP_CODE_INFO,
  MOBILE_EMAIL_OVERLAY_V2_VERSION,
  ORDER_CONFIRMATION_URL,
  B2B_RTC_SESSION,
  RTC_SESSION,
  PROFILE_SUMMARY_LOCAL_STORAGE_KEY,
  UI_USERNAME_COOKIE,
  PAGE_SESSION_DATA_KEY,
  REGISTERED_USER_COOKIE,
  US_MARKET_CODE,
  USER_LOCATION_KEY,
  STORE_LOCATION_KEY,
} from "./util/constants.js";
import { GENERIC_SERVICE_TIME_OUT } from "./services/const/timeouts.js";
import {
  loyaltyEndpoint,
  profileSummaryEndpoint,
} from "./services/const/endpoints.js";
import { getDesignTradeMemberStatus } from "./util/getDesignTradeMemberStatus";
import { getProfileSummary } from "./services/profileSummary.js";
import { getGlobalDesignChatVisibility } from "./services/designChat";
import { addParamsToDataLayer } from "./helpers/analyticsParams";
import { getUserZipCode } from "@vue-component-ecom-zip-code/zipcode-utility";

export default {
  name: "main-header",
  components: {
    ActionLinkList,
    Analytics,
    BrandLogo,
    BrandsList,
    CCPAButton,
    FlyoutFavoriteContainer: () =>
      import(
        /* webpackChunkName: "header-lazy-components" */ "@vue-component-ecom-product-recommendations/product-recommendations"
      ).then(({ FlyoutFavoriteContainer }) => FlyoutFavoriteContainer),
    EcmContentSlot,
    ListAside: () =>
      import(
        /* webpackChunkName: "header-lazy-components" */ "./components/header/ListAside.vue"
      ),
    InternationalShippingMsgBlock,
    InternationalVisitorRedirectOverlay: () =>
      import(
        /* webpackChunkName: "header-lazy-components" */ "./components/header/InternationalVisitorRedirectOverlay.vue"
      ),
    InternationalWelcomeMat: () =>
      import(
        /* webpackChunkName: "header-lazy-components" */ "./components/header/InternationalWelcomeMat.vue"
      ),
    JoinEmailCampaign: () =>
      import(
        /* webpackChunkName: "header-lazy-components" */ "./components/header/JoinEmailCampaign.vue"
      ),
    KeyRewardsBanner,
    MobileBottomLinks,
    MobileHeaderIcon,
    MobileTabbedNavigation,
    MobileTopLinks,
    MobileMarketingOverlay,
    MobileMarketingOverlayContainer,
    MobileNotificationBadge,
    MobileSiteLinks,
    MPulseTagger,
    MyStore,
    PrimaryNav,
    RecentlyViewedFlyout: () =>
      import(
        /* webpackChunkName: "header-lazy-components" */ "./components/header/RecentlyViewedFlyout.vue"
      ),
    RecommendationsFlyOut: () =>
      import(
        /* webpackChunkName: "header-lazy-components" */ "./components/header/RecommendationsFlyOut.vue"
      ),
    SearchBar: () =>
      import(
        /* webpackChunkName: "header-lazy-components" */ "./components/header/SearchBar.vue"
      ),
    SecondaryNav,
    SeoSchemaTags,
    SubBrandsList,
    SimpleHeader,
    SignInPromptContainer,
    PixleePixel,
  },
  mixins: [breakPoints, resize, noScroll, userLocationDetails],
  data() {
    const cookies = getConfigById("cookies", this) || {};
    const flyoutConfig = getConfigById("viewMoreRecommendations", this) || {};
    const joinEmailCampaignConfig =
      getConfigById("joinEmailCampaign", this) || {};
    const keyRewardsConfig =
      getConfigById("keyRewardsSitewideVisibility", this) || {};
    const marketingOverlayConfig =
      getConfigById("marketingOverlay", this) || {};
    const mobileHeaderConfig = getConfigById("mobileHeader", this) || {};
    const mobileTopLinksConfig = getConfigById("mobileTopLinks", this) || {};
    const myStoreConfig = getConfigById("myStore", this) || {};
    const latLangSessionAttributesEnabled =
      getConfigById("latLangSessionAttributesEnabled", this) || false;
    const isRetailStoresEnabled =
      getConfigById("retailStores", this)?.isRetailStoresEnabled || false;
    const retailDirectCookieName =
      getConfigById("retailStores", this)?.retailDirectCookieName || "";
    const retailStoreNumber =
      getConfigById("retailStores", this)?.retailStoreNumber || "";
    const internationalVisitorRedirectConfig = getConfigById(
      "internationalVisitorRedirect",
      this
    );
    const recommendationConfig = getConfigById("recommendation", this) || {};
    const showSimpleHeader = getConfigById("showSimpleHeader", this) ?? false;
    const mobileTabbedNavConfig = getConfigById("mobileTabbedNav", this) || {};
    const simpleHeaderMenuConfig =
      getConfigById("simpleHeaderMenu", this) ?? {};
    const enableMobileExploreButton =
      simpleHeaderMenuConfig?.enableMobileExploreButton ?? false;
    const showMobileExploreMenu =
      simpleHeaderMenuConfig?.showMobileExploreMenu ?? false;
    const showMobileExploreMenuItems = false;
    const exploreMenuName =
      simpleHeaderMenuConfig?.exploreMenuName ?? "Explore";
    const profileConfig = getConfigById("profile", this) ?? {};
    const emailOverlaySuppressionForPaths =
      getConfigById("emailOverlaySuppressionForPaths", this)?.paths || [];
    const suppressEmailOverlayIfUserLoggedIn =
      getConfigById("suppressEmailOverlayIfUserLoggedIn", this) || false;
    const globalDesignChatConfig =
      getConfigById("globalDesignChat", this) || {};
    const isForceClickToOpenDesignChatEnabled =
      globalDesignChatConfig?.isForceClickToOpenEnabled ?? false;
    const ecmContentSlotsToFetchServerSide =
      getConfigById("ecm", this)?.ecmContentSlotsToFetchServerSide || [];
    const signInPromptConfigs = getConfigById("signInPrompt", this) || {};
    const linkParametersConfig = getConfigById("linkParameters", this) || {};
    const parametersToReport = linkParametersConfig?.parametersToReport || [];
    const parametersTestMode = linkParametersConfig?.testMode || false;

    return {
      cookies,
      emailOverlayCookieExpirationDays:
        joinEmailCampaignConfig?.emailOverlayCookieExpirationDays || 60,
      emailOverlayCookieName:
        cookies?.emailOverlaySuppression ||
        `ad_sess_${getBrandConcept(this).toLowerCase()}_email`,
      favoriteCount: 0,
      flyoutConfig,
      hasDisplayName: false,
      headerPos: 0,
      impressions: {
        completed: [],
        strategies: {
          click: {
            timeout: 0,
          },
          hover: {
            timeout: 500,
          },
          view: {
            timeout: 0,
          },
        },
        timers: {},
      },
      isRetailStoreSession: false,
      isOrderConfirmationPage: false,
      retailStoreBannerMessage: "",
      isInternational: false,
      isKeyRewardsMasterDesktopEnabled: keyRewardsConfig?.desktop?.isEnabled,
      isKeyRewardsMasterMobileEnabled: keyRewardsConfig?.mobile?.isEnabled,
      isKeyRewardsMobileNavigationCTAEnabled:
        keyRewardsConfig?.mobile?.KeyRewardsNavigationCTA?.isEnabled,
      isKeyRewardsMobileNotificationEnabled:
        keyRewardsConfig?.mobile?.notificationBadge?.isEnabled,
      isKeyRewardsMyAccountNavigationCTAEnabled:
        keyRewardsConfig?.desktop?.KeyRewardsNavigationCTA?.isEnabled,
      isKeyRewardsNotificationDismissed:
        browserLocalStorageAvailable() &&
        getItemFromLocalStorage("isKeyRewardsNotificationDismissed"),
      isKeyRewardsNotificationEnabled:
        keyRewardsConfig?.desktop?.accountIconNotificationBadge?.isEnabled,
      isKeyRewardsSitewideBannerEnabled:
        keyRewardsConfig?.desktop?.KeyRewardsBanner?.isEnabled,
      isMobileEcmBelowCrossBrandBannerEnabled:
        getConfigById("isMobileEcmBelowCrossBrandBannerEnabled", this) || true,
      isMobileSiteLinks: getConfigById("mobileSiteLinks", this).enabled,
      isMobileUtilityNavEnabled:
        mobileTopLinksConfig?.mobileUtilityNav?.isEnabled || false,
      isResponsive: false,
      isSeoBot: this.$store?.state?.header?.activeProperties?.isSeoBot === "1",
      isSubBrandBarSticky: this.$store.state.header.config.subBrandBarSticky,
      isMobileOverlayEnabled:
        getConfigById("marketingOverlay", this)?.mobileOverlayEnabled || false,
      keyRewardsAvailableBalanceThreshold:
        keyRewardsConfig?.availableBalanceThreshold,
      keyRewardsBannerHref:
        keyRewardsConfig?.desktop?.KeyRewardsBanner?.link?.href,
      keyRewardsBannerMessage:
        keyRewardsConfig?.desktop?.KeyRewardsBanner?.message,
      keyRewardsBannerMessagePrefix: "",
      keyRewardsBannerMessageSuffix: "",
      keyRewardsBannerNotification:
        keyRewardsConfig?.desktop?.KeyRewardsBanner?.keyRewardsNotification,
      keyRewardsConfig,
      keyRewardsData: {},
      keyRewardsMessageSuppressionExpiration:
        browserLocalStorageAvailable() &&
        getItemFromLocalStorage("keyRewardsMessageSuppressionExpiration"),
      keyRewardsMobileHref:
        keyRewardsConfig?.mobile?.KeyRewardsNavigationCTA?.link?.href,
      keyRewardsMobileNavigationCTAHref:
        keyRewardsConfig?.mobile?.KeyRewardsNavigationCTA?.link?.href,
      keyRewardsMobileNavigationCTALink:
        keyRewardsConfig?.mobile?.KeyRewardsNavigationCTA?.link?.href,
      keyRewardsMobileNavigationCTANotification:
        keyRewardsConfig?.mobile?.KeyRewardsNavigationCTA
          ?.keyRewardsNotification,
      keyRewardsMobileNavigationCTAText:
        keyRewardsConfig?.mobile?.KeyRewardsNavigationCTA?.link?.text,
      keyRewardsMobileNotification:
        keyRewardsConfig?.mobile?.KeyRewardsNavigationCTA
          ?.keyRewardsNotification,
      keyRewardsMyAccountCTAHref:
        keyRewardsConfig?.desktop?.KeyRewardsNavigationCTA?.link?.href,
      keyRewardsMyAccountCTANotification:
        keyRewardsConfig?.desktop?.KeyRewardsNavigationCTA
          ?.keyRewardsNotification,
      keyRewardsMyAccountNavigationCTALink:
        keyRewardsConfig?.desktop?.KeyRewardsNavigationCTA?.link?.href,
      keyRewardsMyAccountNavigationCTAText:
        keyRewardsConfig?.desktop?.KeyRewardsNavigationCTA?.link?.text,
      keyRewardsSitewideBannerToken: {},
      lastScrollPos: 0,
      marketingOverlayConfig,
      mobileHeaderConfig,
      mobileMenuState: false,
      mobileTopLinksConfig,
      myStoreConfig,
      scrollPos: 0,
      shouldShowKeyRewardsDesktopNotification: false,
      shouldShowKeyRewardsMobileNotification: false,
      showInternationalShippingMsgBlock:
        getConfigById("internationalShippingMsgBlock", this)?.enabled || false,
      subBrand: getSubBrand(this).id ?? "",
      internationalVisitorRedirectConfig:
        internationalVisitorRedirectConfig ?? {},
      isIvrEnabled: internationalVisitorRedirectConfig?.enabled ?? false,
      wsGeoLocationCountryCode: "",
      displayIvrOverlay: false,
      latLangSessionAttributesEnabled,
      isRetailStoresEnabled,
      recommendationFavoriteFlyoutConfig:
        recommendationConfig?.wsiRecs?.flyout ?? {},
      retailDirectCookieName,
      retailStoreNumber,
      showSimpleHeader,
      simpleHeaderHamburgerMenuType: enableMobileExploreButton
        ? "primary"
        : "all",
      mobileTabbedNavConfig,
      isMobileTabbedNavEnabled: mobileTabbedNavConfig?.isEnabled || false,
      enableMobileExploreButton,
      showMobileExploreMenu,
      showMobileExploreMenuItems,
      exploreMenuName,
      pixleeConfig: getConfigById("pixlee", this) || {},
      localStorageExpiryForProfileSummary:
        profileConfig?.profileSummaryLocalStorageExpiryInHours || 60,
      isUserNameCookieEnabled: profileConfig?.isUiUserNameCookieEnabled ?? true,
      emailOverlaySuppressionForPaths,
      currentPath: "",
      suppressEmailOverlayIfUserLoggedIn,
      isForceClickToOpenDesignChatEnabled,
      ecmContentSlotsToFetchServerSide,
      showBrandsListBannerHeader: true,
      isSiteWideBannerEnabled:
        mobileHeaderConfig?.isSiteWideBannerEnabled || false,
      isCrossBrandNavigationEnabled:
        mobileHeaderConfig?.isCrossBrandNavigationEnabled || false,
      signInPromptConfigs,
      stillInteractingWithMainNav: false,
      subBrandName: "",
      parametersToReport,
      parametersTestMode,
    };
  },
  methods: {
    ...mapActions([
      "headerPromoFetch",
      "setAdobeTargetTests",
      "setFavoritedRecommendationItem",
      "setMyStoreIsFlyoutOpen",
      "ecmDataFetch",
    ]),
    ...mapMutations([
      "setGovernorState",
      "shippingCountryUpdate",
      "setDisplaySignInPrompt",
    ]),
    isSignInPromptEnabled() {
      //Check AB Configurations
      const activeTest =
        this.$store.state.header.activeTests[this.signInPromptConfigs?.ABTest];
      if (
        activeTest &&
        activeTest.variation !== this.signInPromptConfigs.activeVariant
      ) {
        return false;
      }
      //Check configurations
      if (
        !browserSessionStorageAvailable() ||
        !this.signInPromptConfigs.isEnabled
      ) {
        return false;
      }

      return this.isMobile
        ? this.signInPromptConfigs.mobile?.isEnabled
        : this.signInPromptConfigs.desktop?.isEnabled;
    },
    /**
     * Calls the addEvent Data Layer method with information about the impression
     * @param {Object} impressionData - Information to be passed to the Data Layer
     */
    addDataLayerEvent(impressionData) {
      addEvent(impressionData);
    },
    /**
     * Checks to see if a component impression is already listed in the completed impressions array
     * If it is not, the component is added. Otherwise the method returns.
     * @param {String} placement - Data Layer identifier pointing to where the component lives on the page
     */
    addToCompletedImpressions(placement) {
      const isPlacementInCompletedImpressions =
        this.impressions.completed?.find((entry) => entry === placement);
      if (isPlacementInCompletedImpressions) {
        return;
      }
      this.impressions.completed.push(placement);
    },
    /**
     * Forward user to favorites page and add favoritesPopupClickThru event
     */
    onViewFavoritesClick() {
      window.location.href = "/favorites";
      addEvent({
        category: "favoritesPopupClickThru",
      });
    },
    /**
         * Calculated User's WSGEO cookie in Object format is receieved from this getter and passed on to WSI.state
         * so any MFE relying on WSGEO cookie - can now access from WSI.state
         * for e.g { country: 'US',
            state: 'CA',
            zipCode: 95028,
            latitude: 42.3465,
            longitude: -83.0598}
         */
    userGeoLocation() {
      return this.getMyStoreUserLocation();
    },
    /**
     * Calls the "close" method on an open Data Layer token
     * @param {Object} token - The Data Layer token used for page load impressions
     */
    closeDataLayerToken(token) {
      if (token?.close) {
        token.close();
      }
    },
    /**
     * Calls the appropriate Data Layer method based on the type of user event
     * @param {String} type - Type of user event [click, hover, other]
     * @param {Object} impressionData - Information to be passed to the Data Layer
     * @param {Object|null} token - The Data Layer token used for page load impressions
     */
    createDataLayerImpression(type, impressionData, token = null) {
      switch (type) {
        case "hover":
          this.addDataLayerEvent(impressionData);
          break;
        case "click":
          this.addDataLayerEvent(impressionData);
          break;
        default:
          this.updateDataLayer(impressionData, token);
          this.closeDataLayerToken(token);
          break;
      }
    },

    /**
     * Returns, if present, value of the campaign vendor (cm_ven) from the query string
     * @returns {String} Lowercase cm_ven value
     */
    getCmVen() {
      const queryString = window.location.search;
      const params = new URLSearchParams(queryString);
      return params.get("cm_ven")?.toLowerCase() || "";
    },
    /**
     * Determine whether email overlay suppression cookie already exists
     * @returns {Boolean} True if cookie exists, false if cookie does not exist
     */
    hasEmailOverlaySuppressionCookie() {
      return !!Cookies.get(this.emailOverlayCookieName);
    },
    /**
     * Updates cookies to set email overlay suppression cookie value to true with specified expiration
     */
    setEmailOverlaySuppressionCookie() {
      if (!this.hasEmailOverlaySuppressionCookie()) {
        Cookies.set(this.emailOverlayCookieName, true, {
          expires: this.emailOverlayCookieExpirationDays,
        });
      }
    },
    setShippingCountry() {
      let internationalCookie = Cookies.get(this.cookies?.international);
      if (!internationalCookie || internationalCookie === "") {
        internationalCookie = DEFAULT_INTERNATIONAL_COOKIE_VALUE;
        Cookies.set(this.cookies.international, internationalCookie);
        // when not in international mode we don't need country name cookie
        Cookies.remove(this.cookies.internationalCountryName);
      }
      const countryCode = internationalCookie.split("-")[0];
      this.shippingCountryUpdate(countryCode);
    },
    /**
     * Gets called when hamburger menu or close button is clicked
     */
    async toggleHamburgerMenu(type = "all") {
      this.mobileMenuState = !this.mobileMenuState;
      this.toggleNoScroll(this.mobileMenuState);
      this.shouldDisplayKeyRewardsNotification &&
        this.dismissKeyRewardsNotification();
      // Make sure to close myStore if it is open.
      if (this.getMyStoreIsFlyoutOpen) {
        this.setMyStoreIsFlyoutOpen(false);
      }
      if (type !== "all") {
        this.simpleHeaderHamburgerMenuType = type;
      }
      await this.$nextTick();
      this.$refs?.mobileHeaderIconCloseRef?.$refs?.closeIconRef?.focus();
    },

    /**
     * Gets called when explore menu icon is clicked
     */
    toggleMobileExploreMenuItems() {
      this.showMobileExploreMenuItems = !this.showMobileExploreMenuItems;
    },

    /*
     * Dismisses KeyRewards Notification in state and localStorage
     */
    dismissKeyRewardsNotification() {
      if (
        this.hasDisplayName &&
        (this.isKeyRewardsNotificationEnabled ||
          this.isKeyRewardsMobileNotificationEnabled)
      ) {
        const isLocalStorageAvailable = browserLocalStorageAvailable();
        const key = "isKeyRewardsNotificationDismissed";
        const value = true;
        const dismissed =
          isLocalStorageAvailable && getItemFromLocalStorage(key);
        if (!dismissed) {
          this[key] = value;
          saveItemToLocalStorage(key, value);
        }
      }
    },
    /**
     * Calls the appropriate method to update Data Layer based on component impression strategy
     * @param {Object} event - Contains a component reference to the name of the component emitting an event
     */
    handleIntersect(event) {
      const componentConfig = this.isResponsive
        ? this.keyRewardsConfig?.mobile[event.component]
        : this.keyRewardsConfig?.desktop[event.component];
      const impressionData = {
        ...componentConfig.keyRewardsNotification,
        context: this.parseDataLayerContext(
          componentConfig.keyRewardsNotification.context
        ),
      };
      const placement = impressionData.placement;
      const impressionStrategy = componentConfig.impressionStrategy;
      const hasImpressionHappened = this.impressions.completed?.find(
        (entry) => entry === placement
      );
      if (hasImpressionHappened) {
        return;
      }
      this.impressions.timers[event.component] = setTimeout(() => {
        this.createDataLayerImpression(
          impressionStrategy,
          impressionData,
          this.keyRewardsSitewideBannerToken
        );
        this.addToCompletedImpressions(impressionData.placement);
      }, this.impressions.strategies[impressionStrategy].timeout || 0);
    },
    /**
     * Clears open timers associated with any delayed Data Layer impressions
     * @param {Object} event - Contains a component reference to the name of the component emitting an event
     */
    handleNonIntersect(event) {
      const componentTimer = this.impressions.timers?.[event.component] || {};
      clearTimeout(componentTimer);
      delete this.impressions.timers[event.component];
    },
    /**
     * Replaces the hash from the Data Layer context value with the user's available Loyalty Rewards balance
     * @param {String} context - Data Layer identifier showcasing a value the users sees during the impression
     */
    parseDataLayerContext(context) {
      return context.replace("#", this.availableLRCBalance);
    },
    parseKeyRewardsBannerMessage() {
      if (!this.keyRewardsBannerMessage) {
        return;
      }
      const parsedMessage = this.keyRewardsBannerMessage?.includes("#")
        ? this.keyRewardsBannerMessage.split("#")
        : ["", ""];
      [this.keyRewardsBannerMessagePrefix, this.keyRewardsBannerMessageSuffix] =
        parsedMessage;
    },
    /**
     * Gets called when user scroll up/down the viewport
     */
    handleScroll() {
      // set upward scrolling for mobile header
      this.lastScrollPos = Math.max(0, this.scrollPos);
      this.scrollPos = Math.max(0, window.pageYOffset);
    },
    /**
     * Determines whether an object contains any key/value pairs
     * @param {Object} dataObject - object containing any type of data
     * @returns {Boolean} - whether the object contains any key/value pairs
     */
    hasData(dataObject) {
      return dataObject && Object.keys(dataObject).length > 0;
    },
    /**
     * Removes keyRewardsMessageSuppressionExpiration object from Local Storage if current time has passed threshold time
     */
    clearKeyRewardsMessageSuppressionAfterExpiration() {
      Date.now() > this.keyRewardsMessageSuppressionExpiration &&
        removeItemFromLocalStorage("keyRewardsMessageSuppressionExpiration");
    },
    /**
     * Removes the KeyRewardsData object and isKeyRewardsNotificationDismissed value from Local Storage and
     * calls clearKeyRewardsMessageSuppressionAfterExpiration
     * sets the data values for these back to their component defaults
     */
    clearKeyRewardsData() {
      removeItemFromLocalStorage("keyRewardsData");
      removeItemFromLocalStorage("isKeyRewardsNotificationDismissed");
      this.keyRewardsData = {};
      this.isKeyRewardsNotificationDismissed = false;
    },
    /**
     * Removes the profileSummary object value from Local Storage
     */
    clearProfileSummaryData() {
      removeItemFromLocalStorage(PROFILE_SUMMARY_LOCAL_STORAGE_KEY);
    },

    /**
     * Takes in Data Layer Guidance context, pageArea, placement, and href strings from config
     * and returns an href with a Data Layer Guidance-compliant cm_sp query string parameter.
     * @param {Object} - an object containing strings for context, pageArea, and placement, from brand configs.
     * @param {String} - a string representing the initial href, from brand configs.
     */
    appendDataLayerQueryString(dataLayerConfig, href) {
      if (!dataLayerConfig) {
        return href;
      }
      const { context, pageArea, placement } = dataLayerConfig;
      const contextWithBalance = this.parseDataLayerContext(context);
      return `${href}?cm_sp=${pageArea}-_-${contextWithBalance}-_-${placement}`;
    },
    // this method should be called on mount only on client-side.
    // this is to ensure that detecting login status is not blocking from rendering the shop
    setUserSignedInStatus() {
      const { userPersistCookie, userNameCookie, disablePersistCookie } =
        getConfigById("myAccount", this);
      const applicationUri = getApplicationUri(this);
      if (disablePersistCookie) {
        if (this.isUserNameCookieEnabled) {
          setSignedInStatusV2(
            applicationUri,
            userNameCookie,
            this.hasUserNameInGoverState
          );
        } else {
          setSignedInStatusV2(applicationUri, "", this.hasUserNameInGoverState);
        }
      } else {
        // if setSignedInStatus needs to make an XHR call it will return a promise or else undefined
        if (this.isUserNameCookieEnabled) {
          setSignedInStatus(applicationUri, userNameCookie, userPersistCookie);
        } else {
          setSignedInStatus(applicationUri, "", userPersistCookie);
        }
      }
    },
    /**
     * Checks for brand configs, page state, and session storage to determine whether or not to display
     * the <SignInPromptContainer>
     */
    checkShouldDisplaySignInPrompt() {
      const pageData =
        getJsonItemFromSessionStorage(PAGE_SESSION_DATA_KEY) || {};

      if (pageData.isSignInPromptDisabled || !this.isSignInPromptEnabled()) {
        return false;
      }

      if (
        this.signInPromptConfigs.registeredUsersOnly &&
        !Cookies.get(REGISTERED_USER_COOKIE)
      ) {
        this.disableSignInPromptForRemainder();
        return false;
      }

      if (this.signInPromptConfigs?.exclusionList?.length) {
        const normalizedExclusionList =
          this.signInPromptConfigs.exclusionList.map((path) => {
            if (!path.startsWith("/")) {
              return "/" + path;
            }
            return path;
          });
        const matchesExclusionList = normalizedExclusionList.some((path) =>
          window?.location?.pathname?.startsWith(path)
        );
        if (matchesExclusionList) {
          this.disableSignInPromptForRemainder();
          return false;
        }
      }

      const currentPageNumber = pageData?.hitDepth;

      if (this.signInPromptConfigs.displayOnPage === currentPageNumber) {
        this.setDisplaySignInPrompt(true);
      }
      return true;
    },
    /**
     * Sets the session cookie to disable sign in prompt for remainder of session
     */
    disableSignInPromptForRemainder() {
      saveItemToSessionStorage(
        PAGE_SESSION_DATA_KEY,
        "isSignInPromptDisabled",
        true
      );
    },
    /**
     * Calls the Loyalty API endpoint to fetch Key Rewards LRC data
     * @returns {Object} - Object containing Key Rewards response or empty object.
     */
    async getAvailableLRCData() {
      const keyRewardsAxiosResponse = await getKeyRewards(
        loyaltyEndpoint,
        GENERIC_SERVICE_TIME_OUT
      );
      return keyRewardsAxiosResponse ? keyRewardsAxiosResponse : {};
    },
    /**
     * Calls the "update" Data Layer method with information about the impression
     * @param {Object} impressionData - Information to be passed to the Data Layer
     * @param {Object} token - The Data Layer token used for page load impressions
     */
    updateDataLayer(impressionData, token) {
      if (token?.update) {
        token.update("page.attributes", {
          keyRewardsNotification: impressionData,
        });
      }
    },
    /**
     * Set favoriteCount in data() to new value
     * @param {Number} count - new favoriteCount value
     */
    updateFavoriteCount(count) {
      this.favoriteCount = count;
    },
    /**
     * this method is checks the WSGEO cookie and sets `displayIvrOverlay` for foreign visitors
     * if the IVR_PREF cookie is present, the component will not display for foreign visitors.
     */
    setInternationalVisitorDetails() {
      const geoLocationInfo = Cookies.get(CURRENT_ZIP_CODE_INFO);

      const ivrHideSiteLinkCookie = Cookies.get(
        this.internationalVisitorRedirectConfig?.cookies?.IVR_PREF_COOKIE_NAME
      );

      //set the countryCode so prop can be passed down to the IVR component
      this.wsGeoLocationCountryCode = geoLocationInfo?.split("|")?.[0];

      // check if the countryCode obtained from WSGEO cookie is present in the keys of countryMap set under brand configs.
      // return true if the countryMap does not include the countryCode, false otherwise
      const isAnInternationalVisitor = Object.keys(
        this?.internationalVisitorRedirectConfig?.countryMap
      )?.includes(this.wsGeoLocationCountryCode);

      const hideSiteLinkForInternationalVisitor =
        ivrHideSiteLinkCookie === IVR_PREF_HIDE_SITE_LINK_DISMISS;

      // decide whether to display overlay for foreign visitor or not.
      if (isAnInternationalVisitor && this.isIvrEnabled && !this.isSeoBot) {
        this.displayIvrOverlay = !hideSiteLinkForInternationalVisitor;
        if (hideSiteLinkForInternationalVisitor) {
          this.displayIvrOverlay = false;
        }
      }
    },
    assignRetailCookieValuesFromQueryParams() {
      const queryParamPath = new URLSearchParams(window?.location?.search);
      const storeId = queryParamPath?.get("storeid");
      const rCode = queryParamPath?.get("rcode");
      const retailCat = queryParamPath?.get("retailcat");
      const oType = queryParamPath?.get("otype");
      const estid = queryParamPath?.get("estid");
      if (
        !Cookies.get(this.retailDirectCookieName) &&
        storeId &&
        rCode &&
        retailCat &&
        oType
      ) {
        if (estid) {
          Cookies.set(
            this.retailDirectCookieName,
            `${storeId}|${rCode}|${retailCat}|${oType}|${estid}`
          );
        } else {
          Cookies.set(
            this.retailDirectCookieName,
            `${storeId}|${rCode}|${retailCat}|${oType}`
          );
        }
        Cookies.set(this.retailStoreNumber, `${storeId}`);
      }
    },
    /**
     * Sets the latitude and longitude values to session storage.
     */
    setGeoLocationDetails() {
      let addressObject = [];
      const geoLocationInfo = Cookies.get(CURRENT_ZIP_CODE_INFO);
      if (geoLocationInfo) {
        addressObject = geoLocationInfo.split("|");
        if (addressObject && addressObject.length >= 5) {
          const latlng = {
            lat: addressObject[3],
            lng: addressObject[4],
          };
          if (
            !(
              getJsonItemFromSessionStorage("latitude") &&
              getJsonItemFromSessionStorage("longitude")
            )
          ) {
            saveJsonItemToSessionStorage("latitude", JSON.parse(latlng.lat));
            saveJsonItemToSessionStorage("longitude", JSON.parse(latlng.lng));
          }
        }
      }
    },
    updateFavoritedRecommendationItem(favoritedRecommendationItem) {
      this.setFavoritedRecommendationItem(favoritedRecommendationItem);
    },
    tabDefinitions() {
      const tabsDefinitionList =
        this.$store.state.header?.tabsData?.definitions;
      if (tabsDefinitionList && tabsDefinitionList.length) {
        saveItemToLocalStorage("tabsDefinitionList", tabsDefinitionList);
        return tabsDefinitionList;
      }
      const tabDefinitions = getItemFromLocalStorage("tabsDefinitionList");
      if (tabDefinitions && tabDefinitions.length) {
        return tabDefinitions;
      }
      return [];
    },
    topLevelTabsDefinition() {
      let topLevelTabs = this.$store.state.header?.topLevelTabs;
      if (topLevelTabs) {
        saveItemToLocalStorage("topLevelTabs", topLevelTabs);
        return topLevelTabs;
      } else {
        return getItemFromLocalStorage("topLevelTabs") || "";
      }
    },
    /**
     * listen to other MFEs if they need to check the enablement of global design chat
     */
    checkGlobalDesignChatEnablement() {
      WSI.state.onChange("checkGlobalDesignChatEnablement", () => {
        this.setGlobalDesignChatVisibility();
      });
    },
    /**
     * check and send true/false to other MFEs for the enablement of global design chat
     */
    setGlobalDesignChatVisibility() {
      const globalDesignChatConfig = getConfigById("globalDesignChat", this);
      if (globalDesignChatConfig) {
        WSI.state.change("onGlobalDesignChatVisibilityChange", {
          isGlobalDesignChatVisible: getGlobalDesignChatVisibility(
            window?.location?.pathname,
            globalDesignChatConfig,
            this.isMobile
          ),
        });
      }
    },
    /**
     * Calls the Profile Summary API endpoint to fetch Profile Summary data
     * @returns {Object} - Object containing profile summary response or empty object.
     */
    async getProfileSummaryData() {
      const profileSummaryResponse = await getProfileSummary(
        profileSummaryEndpoint,
        GENERIC_SERVICE_TIME_OUT
      );
      return profileSummaryResponse ? profileSummaryResponse : {};
    },
    /**
     * Sync profile summary information in local storage
     */
    async syncProfileSummary() {
      if (!this.isLocalStorageProfileSummaryValid()) {
        const currentDate = new Date();
        const expirationTime = currentDate.setHours(
          currentDate.getHours() + this.localStorageExpiryForProfileSummary
        );
        const profileInformationToStore = {
          value: await this.getProfileSummaryData(),
          expiration: expirationTime,
        };
        // store profileSummary info if available.
        if (
          profileInformationToStore.value &&
          JSON.stringify(profileInformationToStore.value) !== "{}"
        ) {
          saveItemToLocalStorage(
            PROFILE_SUMMARY_LOCAL_STORAGE_KEY,
            profileInformationToStore
          );
        }
      }
    },
    /**
     * Check whether profile Summary key is present in local storage and it's not expired
     * @returns {true} - if profile Summary key is present in local storage and it's not expired
     * @returns {false} - if profile Summary key is not present in local storage or it's expired
     */
    isLocalStorageProfileSummaryValid() {
      const profileSummary =
        getItemFromLocalStorage(PROFILE_SUMMARY_LOCAL_STORAGE_KEY) || "";
      const currentTime = new Date().getTime();
      return profileSummary && currentTime <= profileSummary.expiration;
    },

    // For accessibility with keyboard users
    setHamburgerIconFocus() {
      this.$refs?.hamburgerMenuIcon?.focus();
    },
    /**
     * check and register the method to open the design chat forcefully from DOM
     * ECM author can use "window.designChat.forceOpenDesignChat()" to open the design chat
     * E.g. <a onclick="window.designChat.forceOpenDesignChat()">Click Here</a>
     */
    checkIfForceClickToOpenDesignChatEnabled() {
      if (this.isForceClickToOpenDesignChatEnabled) {
        window.designChat = {
          forceOpenDesignChat: this.forceOpenDesignChat,
        };
      }
    },
    // It will open design chat
    forceOpenDesignChat() {
      const chatLink = document.querySelector("#quiqChatLaunch");
      if (chatLink) {
        chatLink.click();
      }
    },
    // fetches ECM content data for slots taken from brand configs
    fetchEcmContentData() {
      const applicationUri = getApplicationUri(this);

      return this.ecmContentSlotsToFetchServerSide?.length
        ? this.ecmDataFetch({
            applicationUri,
            isMobile: this.isMobile,
            contentIds: this.ecmContentSlotsToFetchServerSide,
          })
        : Promise.resolve();
    },
    /**
     * Updates session storage with the current hit depth of the page.
     * Also makes this available to the datalayer for any components to use.
     */
    updatePageHitDepth() {
      const pageData =
        getJsonItemFromSessionStorage(PAGE_SESSION_DATA_KEY) || {};
      const newHitDepth = (pageData.hitDepth || 0) + 1;
      saveItemToSessionStorage(PAGE_SESSION_DATA_KEY, "hitDepth", newHitDepth);
      // update page datalayer if it hasn't already closed
      try {
        open("Hit Depth - src/Header.vue")
          ?.update("page", {
            hitDepth: newHitDepth,
          })
          ?.close();
      } catch {
        // this will run if the data layer was already closed. No action to take.
        return null;
      }
    },
    /**
     * Handler for mouse entering the main navigation area
     */
    mouseEnterMainNav() {
      // detects a meaningful interaction with the main nav and hides the sign in prompt if true
      this.stillInteractingWithMainNav = true;
      setTimeout(() => {
        if (this.stillInteractingWithMainNav) {
          this.setDisplaySignInPrompt(false);
        }
      }, 400);
    },
    /**
     * Handler for mouse leaving the main navigation area
     */
    mouseLeaveMainNav() {
      this.stillInteractingWithMainNav = false;
    },

    /**
     * Param - brandName - selected sub brand name from brand list
     * Assigns the subbrand name to sub-menu tab and sends MobileTabbedNavigation as props to highlight
     */
    handleSubBrandData(brandName) {
      this.subBrandName = brandName;
    },
    /**
     * Sets stores based on user's geolocation from initial load.
     * Checks to see if governorState already has user geolocation loaded, if not
     * use the VueX subscribe pattern to listen for the mutation to occur.
     */
    async setOptimizedGovernorState() {
      if (this.isPostalCodeOptimizationEnabled) {
        const zipCodeConfigs = {
          bingMaps: this.$store.state.header?.config?.bingMaps,
          googleMaps: this.$store.state.header?.config?.googleMaps,
          maps: this.$store.state.header?.config?.maps,
        };
        let useCanadaPostalCodeFormat = false;
        if (this.getMarketCode !== US_MARKET_CODE) {
          useCanadaPostalCodeFormat = true;
        }
        const zipCodeInfo = await getUserZipCode(
          zipCodeConfigs,
          useCanadaPostalCodeFormat
        );
        const govenorStateLocation = `${zipCodeInfo?.country}|${zipCodeInfo?.state}|${zipCodeInfo?.value}|${zipCodeInfo?.lat}|${zipCodeInfo?.lng}`;
        window.WSI &&
          window.WSI.state &&
          window.WSI.state.change(USER_LOCATION_KEY, govenorStateLocation);
        const storeZipCodeInfo = await getUserZipCode(
          {},
          useCanadaPostalCodeFormat,
          "mystore"
        );
        const storeGovenorStateLocation = `${storeZipCodeInfo?.country}|${storeZipCodeInfo?.state}|${storeZipCodeInfo?.value}|${storeZipCodeInfo?.lat}|${storeZipCodeInfo?.lng}`;
        window.WSI &&
          window.WSI.state &&
          window.WSI.state.change(
            STORE_LOCATION_KEY,
            storeGovenorStateLocation
          );
      }
    },
  },
  serverPrefetch() {
    const fetchEcmContentDataPromise = this.fetchEcmContentData();
    return Promise.all([fetchEcmContentDataPromise]);
  },
  async beforeMount() {
    let linkClickInfo = {};
    if (browserLocalStorageAvailable()) {
      linkClickInfo = getItemFromLocalStorage("linkClickInfo", {});
    }
    if (Object.getOwnPropertyNames(linkClickInfo).length !== 0) {
      addParamsToDataLayer(
        linkClickInfo,
        window?.location?.href,
        this.parametersToReport,
        this.parametersTestMode
      );
    }
    await this.$store.commit("urlUpdate", window.location.href);
    if (
      this.isKeyRewardsMasterDesktopEnabled &&
      this.isKeyRewardsSitewideBannerEnabled
    ) {
      this.keyRewardsSitewideBannerToken = open(
        "Key Rewards Banner /src/Header.vue"
      ); // this data layer token would be closed in createDataLayerImpression() method
    }
    const designTradeMemberStatus = getDesignTradeMemberStatus();
    if (designTradeMemberStatus) {
      open("Design Trade Member /src/Header.vue")
        ?.update("x_user", {
          profile: {
            designTradeMemberTier: designTradeMemberStatus,
          },
        })
        ?.close();
    }
    if (!this.hasEmailOverlaySuppressionCookie()) {
      const cmVen = this.getCmVen();
      const suppressedCampaigns =
        this.marketingOverlayConfig?.suppressForCampaignKeys || [];
      if (
        suppressedCampaigns.length > 0 &&
        suppressedCampaigns.filter((key) => key.toLowerCase() === cmVen)
          .length > 0
      ) {
        this.setEmailOverlaySuppressionCookie();
      }
    }
  },
  created() {
    if (typeof window !== "undefined") {
      this.currentUrl = window.location.pathname;
    }
    this.parseKeyRewardsBannerMessage();
    EventBus.$on("mediumBreakPoint", () => {
      this.isResponsive = true;
    });
    EventBus.$on("largeBreakPoint", () => {
      this.isResponsive = false;
    });
  },
  watch: {
    async hasDisplayName(newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }
      if (!newValue) {
        this.clearKeyRewardsData();
        return;
      }
      if (browserLocalStorageAvailable() && this.shouldShowKeyRewardsFeature) {
        const lrcData = getItemFromLocalStorage("keyRewardsData", {});
        const hasLRCData = this.hasData(lrcData);
        if (!hasLRCData) {
          saveItemToLocalStorage(
            "keyRewardsData",
            await this.getAvailableLRCData()
          );
        }
        this.keyRewardsData = getItemFromLocalStorage("keyRewardsData");
        return;
      }
    },
  },
  async mounted() {
    this.updatePageHitDepth();
    this.isRetailStoreSession =
      !!Cookies.get(this.retailStoreConfig?.storeCookieName) ||
      !!Cookies.get(this.retailStoreConfig?.storeSessionEndedCookieName);
    this.isOrderConfirmationPage = window.location.href.includes(
      ORDER_CONFIRMATION_URL
    );
    if (this.isOrderConfirmationPage) {
      this.retailStoreBannerMessage =
        this.retailStoreConfig?.retailStoreBannerMessage;
    } else if (Cookies.get(this.retailStoreConfig?.storeCookieName)) {
      let storeCookie = Cookies.get(this.retailStoreConfig?.storeCookieName)
        .replace(/[+]/g, " ")
        .split("|")
        .map((rtcData) => rtcData.trim());
      let sessionType = storeCookie[9] ? B2B_RTC_SESSION : RTC_SESSION;
      let storeID = storeCookie[0] || "";
      let associateID = storeCookie[6] || "";
      let associateName = storeCookie[7] || "";
      this.retailStoreBannerMessage = `${sessionType} | Store#: ${storeID} | AssociateId: ${associateID} | ${associateName}`;
    }
    if (this.latLangSessionAttributesEnabled) {
      this.setGeoLocationDetails();
    }
    if (
      this.isRetailStoresEnabled &&
      this.retailDirectCookieName &&
      !this.isSeoBot
    ) {
      this.assignRetailCookieValuesFromQueryParams();
    }
    if (
      Object.keys(this.internationalVisitorRedirectConfig).length &&
      this.isIvrEnabled
    ) {
      this.setInternationalVisitorDetails();
    }
    const favoritesEnabled =
      this.mobileHeaderConfig?.firstHeaderIcon === "favorites" ||
      this.mobileHeaderConfig?.secondHeaderIcon === "favorites" ||
      this.mobileTopLinksConfig?.topLinks?.favorites?.isEnabled ||
      false;

    EventBus.$on("setFavoriteCount", this.updateFavoriteCount);
    // show the favorite's count only if favorites is enabled
    const profileSummaryFromLocal =
      getItemFromLocalStorage(PROFILE_SUMMARY_LOCAL_STORAGE_KEY) || {};
    const uiUserNameCookie = Cookies.get(UI_USERNAME_COOKIE);
    const uiUserNameSplitted = uiUserNameCookie?.split("|");
    const userProfileId = profileSummaryFromLocal?.value?.uuid
      ? profileSummaryFromLocal?.value?.uuid
      : uiUserNameSplitted?.[2];
    const { isLogInV2ApiEnabled } = getConfigById("myAccount", this);
    if (favoritesEnabled && isLogInV2ApiEnabled && userProfileId) {
      this.favoriteCount = await getFavoritesCount({
        profileId: userProfileId,
        isLogInV2ApiEnabled: isLogInV2ApiEnabled,
      });
    } else if (favoritesEnabled) {
      this.favoriteCount = await getFavoritesCount();
    }

    //listening to governor state
    startGovernorWatch(this.setGovernorState);
    WSI.state.onChange(GOVERNOR_USER_STATE_KEY, (displayName) => {
      this.hasDisplayName = !!displayName;
      if (!this.hasDisplayName) {
        this.clearKeyRewardsData();
        this.clearProfileSummaryData();
        this.checkShouldDisplaySignInPrompt();
      } else {
        Cookies.set(REGISTERED_USER_COOKIE, 1, { expires: 90 });
        this.syncProfileSummary();
      }
    });
    this.clearKeyRewardsMessageSuppressionAfterExpiration();
    /*
          Setting the storeListResponse to update the data on store.vue and use in footer app instead of calling duplicate store api.
        */
    WSI.state.storeListResponse = {};
    /**
     * Setting sticky header height in the WSI.state to allow access from other MFEs.
     * We can't get the derived style because the elements are not set until the user scrolls down.
     */
    WSI.state.stickyHeaderHeight = getConfigById(
      "stickyHeaderHeights",
      this
    ) || { desktop: 0, mobile: 0 };
    const isSortFilterSticky = getConfigById(
      "mobileHeader",
      this
    ).stickySortFilterEnabled;
    if (isSortFilterSticky) {
      this.$refs.stickyMobileHeader.style.boxShadow = "none";
    }
    const mobileHeaderContainer = this.$refs.mobileHeaderContainer;
    const mobileHeader = this.$refs.stickyMobileHeader;
    // Set container height = header height to prevent page jumping when scrolling
    mobileHeaderContainer.style.height = getComputedStyle(mobileHeader).height;
    EventBus.$on("mediumBreakPoint", () => {
      this.isResponsive = true;
    });
    EventBus.$on("largeBreakPoint", () => {
      this.isResponsive = false;
      mobileHeaderContainer.style.height = "auto";
    });
    EventBus.$on("setSearchFocusMobile", () => {
      this.showBrandsListBannerHeader = false;
      // When the mobile search is clicked, hide the hamburger menu if it's open.
      this.mobileMenuState && this.toggleHamburgerMenu();
    });
    EventBus.$on("toggleRecentlyViewedDrawer", () => {
      this.mobileMenuState && this.toggleHamburgerMenu();
    });
    this.setShippingCountry();
    window.addEventListener("scroll", this.handleScroll);
    if (this.isMediumBreakPoint()) {
      this.handleResponsiveState();
    }
    // set initial header main position
    this.headerPos = this.$refs?.headerMain?.offsetTop;
    // safe initialization of scrollPos
    this.lastScrollPos = Math.max(0, window.pageYOffset);
    this.$nextTick().then(this.setUserSignedInStatus);
    this.isInternational =
      this.$store.state.header.shippingCountry.countryCode !== "US";
    // handle change country event
    WSI.state.onChange("currencyUpdate", (data) => {
      this.isInternational = data.selectedCountry !== "US";
    });
    EventBus.$on("showBrandsListBannerHeader", (data) => {
      this.showBrandsListBannerHeader = data;
    });
    // listens for governor state mutation from adobe target
    this.subscriptionToAdobeTargetTests = this.$store.subscribe((mutation) => {
      if (
        mutation.type === "setGovernorState" &&
        mutation.payload &&
        mutation.payload[ACTIVE_TARGET_TESTS_KEY]
      ) {
        this.setAdobeTargetTests(mutation.payload[ACTIVE_TARGET_TESTS_KEY]);
      }
    });
    WSI.state.change("updateUserPrefs", this.userGeoLocation());
    EventBus.$on("setHamburgerMenuFocus", this.setHamburgerIconFocus);
    this.currentPath = window.location.pathname;
    this.checkGlobalDesignChatEnablement();
    this.checkIfForceClickToOpenDesignChatEnabled();
    await this.setOptimizedGovernorState();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
    EventBus.$off();
    // Unsubscribe to adobe target vuex mutations
    if (this.subscriptionToAdobeTargetTests) {
      this.subscriptionToAdobeTargetTests();
    }
    removeItemFromLocalStorage("keyRewardsData");
  },
  destroyed() {
    //stop listening to governor state
    stopGovernorWatch();
  },
  computed: {
    ...mapGetters([
      "getMyStoreIsFlyoutOpen",
      "getMyStoreUserLocation",
      "getMarketCode",
      "getConcept",
      "getStoreLocation",
      "shouldDisplaySignInPrompt",
    ]),
    ...mapGetters({
      isPostalCodeOptimizationEnabled: "getPostalCodeOptimizationEnabled",
    }),
    ...mapState({
      favoritedRecommendationItem: (state) =>
        state.header.favoritedRecommendationItem,
    }),
    hasUserNameInGoverState() {
      const governorState = getGovernorState(this);
      return governorState && governorState[GOVERNOR_USER_STATE_KEY];
    },
    getSsrEcmContentData() {
      return this.$store.state.header.ssrEcmContentData;
    },
    retailStoreConfig() {
      return getConfigById("retailStoreConfig", this);
    },
    availableLRCBalance() {
      return this.keyRewardsData.availableLRCBalance;
    },
    isKeyRewardsBalanceAtOrAboveThreshold() {
      return (
        parseInt(this.keyRewardsData.totalCertificateBalance) >=
        parseInt(this.keyRewardsAvailableBalanceThreshold)
      );
    },
    isKeyRewardsMessagingSuppressed() {
      return this.keyRewardsMessageSuppressionExpiration
        ? Date.now() <= this.keyRewardsMessageSuppressionExpiration
        : false;
    },
    isMobileOverlayV2Enabled() {
      return (
        this.isMobileOverlayEnabled &&
        this.marketingOverlayConfig?.version === MOBILE_EMAIL_OVERLAY_V2_VERSION
      );
    },
    mobileSubBrands() {
      const brand = getBrandConcept(this);
      const subBrands = getSubBrands(this);
      return subBrands.length > 0 && this.isResponsive
        ? subBrands.filter(
            (subBrand) => subBrand.id !== brand && subBrand.isMobile !== false
          )
        : {};
    },
    flyoutEnabled() {
      return this.flyoutConfig && this.flyoutConfig.enabled;
    },
    handleMobileUtilityNav() {
      const mobileUtilityNavABConfig =
        this.mobileTopLinksConfig?.mobileUtilityNav?.abTestConfig || {};
      const mobileUtilityNavABTest = this.$store.state.header.activeTests[
        mobileUtilityNavABConfig?.ABTest || ""
      ] || { variation: "" };
      const mobileUtilityNavABTestEnabled =
        mobileUtilityNavABConfig?.enabled || false;
      const abTestVariantActive = mobileUtilityNavABTestEnabled
        ? mobileUtilityNavABTest.variation ===
          mobileUtilityNavABConfig.activeVariant
        : false;
      return (
        (this.isMobileUtilityNavEnabled && !mobileUtilityNavABTestEnabled) ||
        (mobileUtilityNavABTestEnabled && abTestVariantActive)
      );
    },
    subBrandContext() {
      return this.$store.getters.getSubBrandContext || this.subBrand;
    },
    shouldShowKeyRewardsMobileNavigationCTA() {
      return (
        this.isKeyRewardsMobileNavigationCTAEnabled &&
        this.isResponsive &&
        this.isKeyRewardsMasterMobileEnabled &&
        this.hasData(this.keyRewardsData) &&
        this.isKeyRewardsBalanceAtOrAboveThreshold
      );
    },
    shouldDisplayKeyRewardsMyAccountNavigationCTA() {
      return (
        this.isKeyRewardsMyAccountNavigationCTAEnabled &&
        this.hasData(this.keyRewardsData) &&
        this.isKeyRewardsMasterDesktopEnabled &&
        this.isKeyRewardsBalanceAtOrAboveThreshold
      );
    },
    shouldDisplayKeyRewardsNotification() {
      this.shouldShowKeyRewardsMobileNotification =
        this.isKeyRewardsMobileNotificationEnabled &&
        this.isResponsive &&
        this.isKeyRewardsMasterMobileEnabled &&
        this.hasData(this.keyRewardsData);
      this.shouldShowKeyRewardsDesktopNotification =
        this.isKeyRewardsNotificationEnabled &&
        !this.isResponsive &&
        this.isKeyRewardsMasterDesktopEnabled &&
        this.hasData(this.keyRewardsData);
      return (
        (this.shouldShowKeyRewardsDesktopNotification ||
          this.shouldShowKeyRewardsMobileNotification) &&
        !this.isKeyRewardsNotificationDismissed &&
        this.hasDisplayName &&
        this.isKeyRewardsBalanceAtOrAboveThreshold
      );
    },
    shouldDisplayKeyRewardsSitewideBanner() {
      return (
        this.isKeyRewardsSitewideBannerEnabled &&
        !this.isResponsive &&
        this.hasData(this.keyRewardsData) &&
        this.isKeyRewardsMasterDesktopEnabled &&
        this.isKeyRewardsBalanceAtOrAboveThreshold
      );
    },
    shouldShowKeyRewardsFeature() {
      const isMobileComponentEnabled =
        this.isKeyRewardsMobileNavigationCTAEnabled ||
        this.isKeyRewardsMobileNotificationEnabled;
      const isDesktopComponentEnabled =
        this.isKeyRewardsMyAccountNavigationCTAEnabled ||
        this.isKeyRewardsNotificationEnabled ||
        this.isKeyRewardsSitewideBannerEnabled;
      if (
        this.isResponsive &&
        this.isKeyRewardsMasterMobileEnabled &&
        !this.isKeyRewardsMessagingSuppressed &&
        isMobileComponentEnabled
      ) {
        return true;
      }
      if (
        !this.isResponsive &&
        this.isKeyRewardsMasterDesktopEnabled &&
        !this.isKeyRewardsMessagingSuppressed &&
        isDesktopComponentEnabled
      ) {
        return true;
      }
      return false;
    },
    showMobileTabbedNav() {
      const { abTestConfig = {} } = this.mobileTabbedNavConfig ?? {};
      const { activeTests = {} } = this.$store.state.header;
      const { isEnabled = false, activeVariant, ABTest } = abTestConfig;
      if (
        !this.isResponsive ||
        !this.isMobileTabbedNavEnabled ||
        !this.tabDefinitions()?.length
      ) {
        return false;
      }
      return isEnabled && Object.keys(activeTests).length
        ? activeTests[ABTest]?.variation === activeVariant
        : true;
    },
    /**
     * Check Current Path For Email Overlay Suppresion
     * @returns {true}
     * @returns {false}
     */
    checkCurrentPathForEmailOverlaySuppresion() {
      return !this.emailOverlaySuppressionForPaths.length || this.currentPath;
    },
    isRetailStoreCookieExist() {
      return !!getCookie(this.retailStoreConfig?.storeCookieName);
    },
    /**
     * isEmailOverlayEnabledBasedOnUserLogin checks the flag configuration using suppressEmailOverlayIfUserLoggedIn.
     * If suppressEmailOverlayIfUserLoggedIn is false then it checks user login status from cookie or localStorage.
     * Otherwise it returns true
     * @returns {boolean} - Returns boolean value indicating the user is logged in or not".
     */
    isEmailOverlayEnabledBasedOnUserLogin() {
      if (this.suppressEmailOverlayIfUserLoggedIn) {
        if (this.isUserNameCookieEnabled) {
          return !Cookies.get("UI_USERNAME_COOKIE");
        } else {
          return !getItemFromLocalStorage(PROFILE_SUMMARY_LOCAL_STORAGE_KEY);
        }
      }
      return true;
    },
    /**
     * Handles email overlay visibility based on the current path
     * It looks for the current path in email overlay suppression list provided from brand config
     * @returns {true} - if current path is in the list
     * @returns {false} - if current path is not in the list
     */
    isEmailOverlayVisibleBasedOnPath() {
      if (!this.currentPath) {
        return;
      }
      const suppressionList = this.emailOverlaySuppressionForPaths;
      const isModalSupressed =
        (suppressionList.includes("/") && this.currentPath === "/") ||
        suppressionList.find(
          (item) => item !== "/" && this.currentPath.includes(item)
        );
      if (isModalSupressed) {
        this.setEmailOverlaySuppressionCookie();
        return false;
      }
      return true;
    },
    getClassName() {
      return `market-${this.getMarketCode} ${this.getConcept}`;
    },
    showMarketingCampaignOverlay() {
      return (
        this.isEmailOverlayEnabledBasedOnUserLogin &&
        this.isEmailOverlayVisibleBasedOnPath
      );
    },
  },
};
</script>

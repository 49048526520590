<template>
  <div
    data-style="drawer"
    :class="['drawer-component', customClass]"
    v-show="showDrawer"
    @keydown="checkKeyDown"
    data-test-id="drawer-component"
  >
    <button
      class="drawer-component-scrim"
      @click="closeDrawer"
      v-bind:style="styleObject"
    ></button>
    <div
      class="drawer-component-container"
      v-bind:style="styleObject"
      :drawer-direction="direction"
      :drawer-closing="isClosing"
      ref="containerRef"
      role="dialog"
    >
      <div class="design-platform">
        <button
          v-if="showCloseIcon"
          type="button"
          data-test-id="drawer-component-close-button"
          class="cursor-pointer mr-1 mt-1 p-2 absolute top-1 right-1"
          aria-label="Close Overlay"
          ref="closeButton"
          tabindex="0"
          @keyup.esc="closeDrawer"
          @click="closeDrawer"
        >
          <svg width="14" height="14" xmlns="http://www.w3.org/2000/svg">
            <g fill="currentColor" fill-rule="nonzero">
              <path
                d="M13.368 12.741l-.715.716L2.565 3.369.632 1.436 1.347.72z"
              ></path>
              <path d="M1.41 13.385l-.707-.707L12.724.658l.707.706z"></path>
            </g>
          </svg>
        </button>
      </div>
      <div class="drawer-component-header">
        <slot name="header"></slot>
      </div>
      <div class="drawer-component-content">
        <slot name="content"></slot>
      </div>
      <div class="drawer-component-footer">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import breakPoints from "../../mixins/breakPoints";
import focusTrap from "../../mixins/focusTrap";
import EventBus from "../../util/event-bus";

export default {
  name: "drawer",
  mixins: [breakPoints, focusTrap],
  props: {
    showDrawer: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: [String, Array],
    },
    direction: {
      type: String,
      default: "left",
      validator: function (value) {
        // The value must match one of these strings
        return ["left", "right"].indexOf(value) !== -1;
      },
    },
    lockScreen: {
      type: Boolean,
      default: false,
    },
    sitsInlineWith: {
      type: String,
      default: "",
    },
    fixedHeader: {
      type: String,
      default: "",
    },
    focusElement: {
      type: Function,
      default: null,
    },
    showCloseIcon: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isClosing: false,
      styleObject: {},
    };
  },

  watch: {
    showDrawer(drawerState) {
      // Determine if we need to lock the screen.
      if (this.lockScreen) {
        document.body.style.overflow = drawerState ? "hidden" : "auto";
      }

      // If the drawer is open, a sitsInlineWith prop is set, and the user isn't on medium or small design screen.
      if (drawerState && this.sitsInlineWith && !this.isMediumBreakPoint()) {
        const domElement = document.querySelector(this.sitsInlineWith);
        // Null check for the domElement.
        if (domElement) {
          const topBounding = domElement.getBoundingClientRect().top;
          const topPixels = `${topBounding}px`;
          // To handle the DrawerPosition and Sticky header
          this.setDrawerHeight(domElement, topPixels);
        }
      }
      /**
       * setup focusTrap mixin and
       * Set the focus inside of the overlay.
       */
      if (drawerState) {
        this.setFocusTriggerEl(document?.activeElement);
        this.setCloserFunction(this.closeDrawer);
        this.getFocusableElements(this.$refs?.containerRef);
        this.overlayTitleFocus();
      }
      EventBus.$emit("focusReturnToOrigin");
    },
  },
  mounted() {
    /**
     * The mounted lifecycle hook will signal the "ready" event when this component is rendered on the DOM,
     * allowing the parent MyStore to know that this component has finished rendering.
     **/
    this.$emit("hasLoaded", true);
  },
  methods: {
    closeDrawer() {
      // Prevent the user from spam clicking the scrim or close button, causing unintended side effects.
      if (this.isClosing) {
        return;
      }
      this.isClosing = true;

      setTimeout(() => {
        this.isClosing = false;
        this.$emit("close");
      }, 500);
    },
    setDrawerHeight(domElement, topPixels) {
      // To handle the Drawer opening position more than window height
      if (this.fixedHeader && domElement.offsetTop <= window.pageYOffset) {
        const fixedHeaderHeight = document.querySelector(
          this.fixedHeader
        ).offsetHeight;
        this.styleObject = {
          top: `${fixedHeaderHeight}px`,
        };
      } else {
        this.styleObject = {
          top: topPixels,
          height: `calc(100vh - ${topPixels})`,
        };
      }
    },
    // For Accessibility with keyboard users
    overlayTitleFocus() {
      setTimeout(this.handleTitleFocus, 500);
    },
    handleTitleFocus() {
      if (this.focusElement === null) {
        this.$refs.closeButton && this.$refs.closeButton.focus();
      } else {
        this.focusElement();
      }
    },
  },
};
</script>

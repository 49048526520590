<template>
  <div
    data-style="brand-logo"
    id="logo-group"
    :key="key"
    data-component="Global-header-BrandLogo"
  >
    <a :href="link">
      <img v-if="img_link" :src="img_link" :alt="alt_text" />
      <component v-else :is="logo_img"></component>
      <img
        v-if="img_brand_statement"
        :src="img_brand_statement"
        :alt="alt_text"
        class="brand-statement"
      />
    </a>
  </div>
</template>

<script>
import {
  getBrandConcept,
  getConfigById,
  getApplicationUri,
} from "../../util/context";
import { logger } from "@js-ecom-mfe/logger";
import breakPoints from "../../mixins/breakPoints";
import PTLogo from "../brand_logo/PTLogo.vue";
import PKLogo from "../brand_logo/PKLogo.vue";
import PBLogo from "../brand_logo/PBLogo.vue";
import WELogo from "../brand_logo/WELogo.vue";
import WSLogo from "../brand_logo/WSLogo.vue";
import MGLogo from "../brand_logo/MGLogo.vue";
import RJLogo from "../brand_logo/RJLogo.vue";
import { REPONAME, GLOBAL_NETSTORAGE_PATH } from "../../util/constants";

const fileName = "BrandLogo.vue";

export default {
  name: "brand-logo",
  props: {
    prefix: {
      type: String,
      default: "",
    },
  },
  mixins: [breakPoints],
  data() {
    return {
      logo_img: "",
      link: "/?cm_type=gnav",
      img_link: "",
      alt_text: "",
      key: "",
      img_brand_statement: "",
    };
  },
  components: {
    PTLogo,
    PKLogo,
    PBLogo,
    WELogo,
    WSLogo,
    MGLogo,
    RJLogo,
  },
  methods: {
    getConfig() {
      const brandLogo = getConfigById("brandLogo", this);

      if (!brandLogo) {
        logger.warn(
          REPONAME,
          fileName,
          "getConfig",
          `Config error in BrandLogo`
        );
        return [];
      }
      return brandLogo;
    },
    getBrandLogoSvg() {
      const brand = getBrandConcept(this);
      return `${brand}Logo`;
    },
    getLogoLink() {
      const config = this.getConfig();
      if (this.isMobile) {
        // eslint-disable-next-line no-undef
        return `${this.prefix}${config.link || $this.link}`;
      } else {
        // eslint-disable-next-line no-undef
        return config.link || $this.link;
      }
    },
    getImageLink() {
      const config = this.getConfig();
      if (config?.loadFromNetStorage && config.img_link) {
        const netStoragePath = config?.netStoragePath ?? GLOBAL_NETSTORAGE_PATH;
        const applicationUri = getApplicationUri(this);
        return `${applicationUri}${netStoragePath}${config.img_link}`;
      } else {
        return config.img_link || this.img_link;
      }
    },
    setBrandLogoData() {
      const config = this.getConfig();
      this.img_link = this.getImageLink();
      if (!this.img_link) {
        this.logo_img = this.getBrandLogoSvg();
      }
      this.link = this.getLogoLink();
      this.alt_text = config.alt_text || this.alt_text;
      this.key = config.key || this.key;
      this.img_brand_statement =
        config.img_brand_statement || this.img_brand_statement;
    },
  },
  created() {
    // set brand logo data in created hook to allow logo rendering in SSR
    // in this case isMobile relies on isMobile property of the component, which comes from state on server side, and not on window width
    this.setBrandLogoData();
  },
  mounted() {
    // set brand logo data in mounted hook to allow logo rendering in CSR
    // in this case isMobile relies on window width
    this.setBrandLogoData();
  },
};
</script>

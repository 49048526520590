import axios from "axios";
import { getCookie } from "../util/cookies";
import { REPONAME } from "../util/constants";
import { logger } from "@js-ecom-mfe/logger";
import { getBrConfig } from "../services/brConfig";
import { getApplicationUri } from "../util/context";
import { GENERIC_SERVICE_TIME_OUT } from "./const/timeouts";

const fileName = "vta.js";

export const buildSearchParam = async (query, genId, scope) => {
  const brConfig = await getBrConfig(getApplicationUri(scope), genId, scope);
  const config = Object.assign({}, brConfig);
  let suggestUrl = `${config.typeAheadUrl}?request_type=suggest`;
  suggestUrl += `&account_id=${config.accountId}`;
  suggestUrl += `&domain_key=${config.domainKey}`;
  suggestUrl += `&request_id=${Math.floor(Math.random() * 1000000000000)}`;
  suggestUrl += `&url=${window.location.href.substr(
    0,
    window.location.href.indexOf("#")
  )}`;
  suggestUrl += `&ref_url=${window.location.host}`;
  suggestUrl += `&_br_uid_2=${getCookie("_br_uid_2")}`;
  suggestUrl += `&q=${encodeURIComponent(query)}`;
  return suggestUrl;
};

export const fetchProductsFromBloomreach = async (query, genId, scope) => {
  const suggestUrl = await buildSearchParam(query, genId, scope);
  try {
    const response = await axios.get(suggestUrl, {
      timeout: GENERIC_SERVICE_TIME_OUT,
    });
    return response;
  } catch (error) {
    logger.error(REPONAME, fileName, "fetchProductsFromBloomreach", error);
    return Promise.reject(error);
  }
};

<template>
  <ul data-style="mobile-site-links">
    <li class="site-link-title" v-if="mobileBrands.length > 0">
      Shop Our Brands
    </li>
    <li v-for="(brand, index) in mobileBrands" :key="index">
      <a :href="brand.link">{{ brand.brand_name }}</a>
    </li>
  </ul>
</template>

<script>
// todo: this need to be moved to common, so we don't have to duplicate the effort of iterating cross brands bars for dekstop and mobile
import { getBrandConcept, getConfigById } from "../../util/context";
export default {
  name: "mobile-site-links",
  data() {
    return {
      brands: [],
    };
  },
  methods: {
    getBrands() {
      const brand = getBrandConcept(this);
      // eslint-disable-next-line no-undef
      return crossBrands[brand];
    },
  },
  computed: {
    mobileBrands() {
      return this.brands.filter((brand) => brand.isMobile);
    },
  },
  created() {
    this.brands = getConfigById("crossBrandBar", this).brandBarOrder;
  },
};
</script>

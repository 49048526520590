import Vue from "vue";
import Vuelidate from "vuelidate";
import { createHeaderStore } from "./store";

import Header from "./Header.vue";

Vue.use(Vuelidate);

if (process.env.NODE_ENV !== "production") {
  Vue.config.devtools = true;
}

//This tells Vue that the element/component comes from a source that Vue doesn’t know about, and compiler ignores it.
Vue.config.ignoredElements = ["marketing-overlay"];

export function createApp() {
  const store = createHeaderStore();

  // sync(store);

  const app = new Vue({
    store,
    render: (h) => h(Header),
  });

  //   return { app, router };
  return { app, store };
}

// @FUTURE: We could have used this.$root.$data to access concept in Vue

<template>
  <div data-style="seo-schema-tags" v-if="enabled">
    <script v-html="tags" type="application/ld+json"></script>
  </div>
</template>

<script>
import { getConfigById } from "../../util/context";

export const SEO_TAGS = {
  CONTACT_TYPE: {
    CUSTOMER_SERVICE: "Customer Service",
  },
  CONTEXT: "https://schema.org/",
  TYPE: {
    CONTACTPOINT: "ContactPoint",
    ORGANIZATION: "Organization",
    POSTALADDRESS: "PostalAddress",
    SEARCHACTION: "SearchAction",
    WEBSITE: "WebSite",
  },
};

export default {
  name: "SeoSchemaTags",
  data() {
    const { enabled, sameAs, telephone, email, addressCountry, image } =
      getConfigById("seoSchemaMarkup", this) ?? {};
    const name = getConfigById("longConceptName", this) ?? "";
    const url = this.$store.state?.header?.clientApplicationUri ?? "";

    return {
      enabled,
      tags: [
        {
          "@context": SEO_TAGS.CONTEXT,
          "@type": SEO_TAGS.TYPE.WEBSITE,
          name,
          url,
          potentialAction: {
            "@type": SEO_TAGS.TYPE.SEARCHACTION,
            "query-input": "required name=search_term_string",
            target: `${url}search/results.html?words={search_term_string}`,
          },
        },
        {
          "@context": SEO_TAGS.CONTEXT,
          "@type": SEO_TAGS.TYPE.ORGANIZATION,
          name,
          url,
          image,
          logo: image,
          telephone,
          sameAs,
          address: {
            "@type": SEO_TAGS.TYPE.POSTALADDRESS,
            addressCountry,
          },
          contactPoint: {
            "@type": SEO_TAGS.TYPE.CONTACTPOINT,
            email,
            contactType: SEO_TAGS.CONTACT_TYPE.CUSTOMER_SERVICE,
          },
        },
      ],
    };
  },
};
</script>

<template>
  <div
    data-style="find-a-store-pop-out"
    class="pop-out-menu find-a-store"
    data-component="Global-header-FindAStorePopOut"
  >
    <div class="store-user-links">
      <template v-for="(storeLink, idx) in storeLinks">
        <div :key="idx" class="col">
          <div class="nav-columns-section-heading">
            {{ storeLink.heading }}
          </div>
          <ul>
            <li v-for="(link, index) in storeLink.links" :key="index">
              <a :href="link.link">{{ link.text }}</a>
            </li>
          </ul>
        </div>
      </template>
      <div v-if="store_name" class="col closest-store">
        <div class="closest-store-details">
          <div class="closest-store-heading">
            <p class="heading footer-links-heading">Nearest Store</p>
          </div>
          <p class="closest-store-name">
            <a :href="store_url" :data-layer-event="data_layer_event">
              {{ store_name.toLowerCase() }} Store
            </a>
          </p>
          <p class="closest-store-address">{{ store_address }}</p>
          <p v-if="store_address2" class="closest-store-address-2">
            {{ store_address2 }}
          </p>
          <p class="closest-store-locality">{{ store_locale }}</p>
          <p class="closest-store-phone">
            <a :href="`tel:${store_phone}`">{{ store_phone }}</a>
          </p>
          <p class="closest-store-hours">
            Open Today From {{ storeHours.formattedHours }}
          </p>
        </div>
      </div>
    </div>
    <div class="figures" v-if="ecmPromos.length > 0">
      <!-- eslint-disable-next-line vue/require-v-for-key -->
      <figure v-for="ecmPromo in ecmPromos">
        <EcmContentSlot :contentLocation="'spotlight-' + ecmPromo" />
      </figure>
    </div>
  </div>
</template>

<script>
import EcmContentSlot from "../../EcmContentSlot.vue";
import { getConfigById } from "../../../util/context";
import storeLocator from "../../../mixins/storeLocator";
import storeHours from "../../../mixins/storeHours";
import { USER_LOCATION_KEY, STORE_LOCATION_KEY } from "../../../util/constants";
import { mapGetters } from "vuex";

export default {
  name: "find-a-store",
  components: {
    EcmContentSlot,
  },
  mixins: [storeLocator, storeHours],
  data() {
    return {
      data_layer_event: "",
      store_name: "",
      store_address: "",
      store_locale: "",
      store_url: "",
      store_phone: "",
      text: "",
      linkText: "",
      hasSpotlight: "",
      ecmPromos: [],
      storeLinks: [],
      store: {},
    };
  },
  computed: {
    ...mapGetters({
      isPostalCodeOptimizationEnabled: "getPostalCodeOptimizationEnabled",
    }),
    storeHours() {
      return this.getFormattedCurrentStoreHours(this.store);
    },
  },
  mounted() {
    const {
      locateNearestStore,
      text,
      linkText,
      hasSpotlight,
      ecmPromos = [],
      storeLinks,
    } = getConfigById("findAStore", this);
    const locationKey = this.isPostalCodeOptimizationEnabled
      ? STORE_LOCATION_KEY
      : USER_LOCATION_KEY;
    this.text = text;
    this.linkText = linkText;
    this.hasSpotlight = hasSpotlight;
    this.ecmPromos = ecmPromos;
    this.storeLinks = storeLinks;
    if (locateNearestStore) {
      // this gets called from mixins
      this.getNearestStoreDetails(locationKey);
    }
  },
};
</script>

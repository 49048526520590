/**
 * Builds a reporting params object for reportToTarget() to consume.
 * Each key is the Profile Parameter that Adobe Target will use in their analytics, in the following format:
 * "profile.explatform_<concept>_<testId>"
 * @param {object} assignedAbTests AB Test assignments object from PAW service
 * @param {string} concept Concept for which we're reporting AB Test assignment
 * @returns {object} Reporting params object
 */
function buildReportingParams(assignedAbTests, concept) {
  let reportingParams = {};
  Object.keys(assignedAbTests).forEach((testId) => {
    reportingParams[testId] = {
      [`profile.explatform_${concept}_${testId}`]:
        assignedAbTests[testId].variation,
    };
  });

  return reportingParams;
}

/**
 * Reports to Target based on the assignedAbTests object from the PAW experimentation service.
 * @param {object} assignedAbTests AB Test assignments object from PAW service
 * @param {*} concept Concept for which we're reporting AB Test assignment
 */
export const reportToTarget = (assignedAbTests, concept) => {
  if (
    window?.adobe?.target?.trackEvent &&
    typeof window.adobe.target.trackEvent === "function"
  ) {
    const reportingParams = buildReportingParams(assignedAbTests, concept);
    Object.keys(reportingParams).forEach((testId) => {
      window.adobe.target.trackEvent({
        mbox: `${concept}-experimentation-platform-${testId}`,
        params: reportingParams[testId],
      });
    });
  }
};

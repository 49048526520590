import { logger } from "@js-ecom-mfe/logger";
import { REPONAME } from "./constants";
import { getConfigById, safeExistenceCheck } from "./context.js";
const fileName = "nav.js";

/**
 * Returns Global Nav Bar config for header for given scope.
 * @param {Object} scope
 * @returns {Object} Global Nav Bar config object.
 */
export const getNavBarConfig = (scope) => {
  return getConfigById("navBar", scope);
};

export const getPrimaryHeaderPaths = (scope) => {
  const primaryHeaderConfig = getPrimaryHeaderConfig(scope);
  if (primaryHeaderConfig) {
    return primaryHeaderConfig.reduce((acc, topNavItem) => {
      if (topNavItem.path && topNavItem.path.length) {
        acc.push(topNavItem.path);
      }
      return acc;
    }, []);
  } else {
    return [];
  }
};

export const getPrimaryHeaderConfig = (scope, isResponsive) => {
  const primaryNavBarConfig = isResponsive
    ? getMobileParentBrandPrimaryNavConfig(scope)
    : getConfigById("primaryNavBar", scope);
  if (!primaryNavBarConfig || !primaryNavBarConfig.topNavItems) {
    logger.warn(
      REPONAME,
      fileName,
      "getPrimaryHeaderConfig",
      `config error in primaryNavBar.`
    );
    return [];
  }
  return isResponsive && primaryNavBarConfig?.mobileNavItems
    ? primaryNavBarConfig.mobileNavItems
    : primaryNavBarConfig.topNavItems;
};
/**
 * Get subBrand primary nav bar for mobile
 * @param {*} scope
 * @param {*} subBrand
 * @param {*} isResponsive
 * @returns topNavItems or mobileNavItems
 */
export const getMobileSubBrandPrimaryHeaderConfig = (
  scope,
  subBrand,
  isResponsive
) => {
  const subBrandConfig = getConfigById("subBrandOverrides", scope);
  const isSubBrandExists = safeExistenceCheck(subBrandConfig[subBrand], scope);
  if (!isSubBrandExists || !subBrandConfig[subBrand]?.primaryNavBar) {
    logger.warn(
      REPONAME,
      fileName,
      "getMobileSubBrandPrimaryHeaderConfig",
      `config error in primaryNavBar.`
    );
    return [];
  }
  return isResponsive && subBrandConfig[subBrand].primaryNavBar?.mobileNavItems
    ? subBrandConfig[subBrand].primaryNavBar.mobileNavItems
    : subBrandConfig[subBrand].primaryNavBar.topNavItems;
};

/**
 * Get parent brand primary nav of a sub brand for mobile
 * @param {*} scope
 * @returns topNavItems or mobileNavItems
 */
export const getMobileParentBrandPrimaryNavConfig = (scope) => {
  const mobileParentBrandPrimaryNavBar = safeExistenceCheck(
    "$store.state.header.config.primaryNavBar",
    scope
  );
  if (!mobileParentBrandPrimaryNavBar) {
    logger.warn(
      REPONAME,
      fileName,
      "getMobileParentBrandPrimaryNavConfig",
      `config error in primaryNavBar for mobile.`
    );
    return [];
  }
  return mobileParentBrandPrimaryNavBar;
};

export const getSecondaryHeaderConfig = (scope) => {
  const secondaryNavBarConfig = getConfigById("secondaryNavBar", scope);
  if (!secondaryNavBarConfig || !secondaryNavBarConfig.categories) {
    logger.warn(
      REPONAME,
      fileName,
      "getSecondaryHeaderConfig",
      `config error in primaryNavBar.`
    );
    return "";
  }
  return secondaryNavBarConfig.categories;
};

export const getSecondaryHeaderPaths = (scope) => {
  const secondaryHeaderConfig = getSecondaryHeaderConfig(scope);
  if (secondaryHeaderConfig) {
    return secondaryHeaderConfig.reduce((acc, cat) => {
      if (cat.type === "supercat") {
        acc.push(cat.path);
      }
      return acc;
    }, []);
  } else {
    return [];
  }
};

export const getSecondaryHeaderEcmPromos = (scope) => {
  const secondaryHeaderConfig = getSecondaryHeaderConfig(scope);
  if (secondaryHeaderConfig) {
    return secondaryHeaderConfig.reduce((acc, cat) => {
      if (
        cat.hasSpotlight &&
        cat.ecmPromos &&
        Array.isArray(cat.ecmPromos) &&
        cat.ecmPromos.length
      ) {
        acc = acc.concat(cat.ecmPromos);
      }
      return acc;
    }, []);
  } else {
    return [];
  }
};

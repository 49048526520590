<template data-style="pixlee-pixel"></template>
<script>
import { loadScript } from "../../util/scriptHandler";
export default {
  props: {
    apiKey: {
      type: String,
      default: "",
    },
  },
  async mounted() {
    if (!window.PixleeTrackingPixel) {
      await loadScript("https://assets.pixlee.com/assets/pixlee_events.js");
      if (typeof Pixlee_Analytics !== "undefined") {
        // eslint-disable-next-line no-undef
        window.Pixlee_Analytics = new Pixlee_Analytics(this.apiKey);
      }
    }
  },
};
</script>

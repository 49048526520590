<template>
  <li
    id="my-account"
    data-component="Global-header-MyAccount"
    @mouseenter="firePznDisplayEvent"
    :class="isTopNavArrowEnabled ? 'top-nav-accessibility' : ''"
  >
    <a :href="config.link">
      <section class="design-platform">
        <img
          v-if="accountIcon"
          :src="accountIcon"
          alt="my-account-icon"
          data-style="Global-account-img-icon"
        />
        <SVGIcon
          v-if="config.SVGIcon && !accountIcon"
          :icon="config.SVGIcon"
          data-style="Global-account-icon"
        ></SVGIcon>
        <span
          v-if="
            (pznTriggeredCampaigns.isActive &&
              pznTriggeredCampaigns.notificationEnabled) ||
            shouldDisplayKeyRewardsNotification
          "
          data-style="Global-pzn-notification-icon"
          data-test-id="Global-pzn-notification-icon"
        >
          <SVGIcon
            icon="pzn-notification-icon"
            data-style="Global-pzn-notification-icon-svg"
          ></SVGIcon>
        </span>
      </section>

      <span
        v-if="displayUserName"
        :class="{ 'signed-in': !!userDisplayName }"
        >{{ text }}</span
      >
    </a>
    <button
      v-if="isTopNavArrowEnabled"
      type="button"
      :aria-label="text + ' Menu'"
      aria-expanded="false"
      class="drop-down-icon"
      ref="menuIcon"
      @click="showDropdownMenu($event, '#nav-user-links .open')"
      @keyup.esc="closeDropdownMenu"
    >
      <SVGIcon :icon="'icon-dropdown'"></SVGIcon>
    </button>
    <div
      id="myaccount-container"
      class="submenu"
      :class="{ 'pzn-triggered-content': pznTriggeredCampaigns.isActive }"
      @keyup.esc="closeDropdownMenu"
      @focusout="handleDropdownFocusOut($event)"
    >
      <div>
        <KeyRewardsNavigationCTA
          v-if="shouldDisplayKeyRewardsMyAccountNavigationCTA"
          :amount="keyRewardsAmount"
          :dataStyle="'Global-header-KeyRewardsNavigationCTA'"
          :gridCols="'grid-cols-2'"
          :hasArrow="false"
          :keyRewardsLink="keyRewardsMyAccountNavigationCTALink"
          :text="keyRewardsMyAccountNavigationCTAText"
          data-test-id="Global-header-key-rewards-navigation-cta-li"
          @onIntersect="$emit('onIntersect', $event)"
          @onNonIntersect="$emit('onNonIntersect', $event)"
        />
        <ul
          v-if="config.displaySubMenu"
          id="myaccount-submenu"
          :class="{ 'signed-in': !!userDisplayName }"
        >
          <li :key="link.text" :class="link.class" v-for="link in links">
            <a
              v-if="link.text.includes('Key Rewards')"
              :href="updateKeyRewardsLink(link)"
              :class="link.buttonClass ? link.buttonClass : link.class"
              v-html="link.text"
              @click="addAnalyticsEvent(link)"
            />
            <a
              v-else-if="isLogoutAPIEnabled && link.text.includes('Sign Out')"
              href="javascript:void(0)"
              :class="link.buttonClass ? link.buttonClass : link.class"
              v-html="link.text"
              @click="signOut"
            />
            <a
              v-else
              :href="link.link"
              :class="link.buttonClass ? link.buttonClass : link.class"
              v-html="link.text"
              @click="addAnalyticsEvent(link)"
            />
          </li>
        </ul>
      </div>
      <div v-if="pznTriggeredCampaigns.isActive" id="myaccount-divider"></div>
      <PznTriggeredCampaigns
        :class="{ 'signed-in': !!userDisplayName }"
        @setPznIsActive="onSetPznIsActive"
        @enablePznNotification="onEnablePznNotification"
      />
    </div>
  </li>
</template>

<script>
import { addEvent } from "@js-ecom-tracking/datalayer";
import {
  getBrandConcept,
  getConfigById,
  getKeyRewardsLink,
  getApplicationUri,
} from "../../../util/context";
import {
  GOVERNOR_USER_STATE_KEY,
  GLOBAL_NETSTORAGE_PATH,
  LOGOUT_API_URL,
  LOGOUT_URL,
} from "../../../../src/util/constants.js";
import { logger } from "@js-ecom-mfe/logger";
import PznTriggeredCampaigns from "./PznTriggeredCampaigns.vue";
const SVGIcon = () =>
  import(/* webpackChunkName: "header-footer-svg-icon" */ "../../SVGIcon.vue");
import { APPLY_NOW, REPONAME } from "../../../util/constants.js";
import KeyRewardsNavigationCTA from "../../key-rewards/KeyRewardsNavigationCTA.vue";
import { getDesignTradeMemberStatus } from "../../../util/getDesignTradeMemberStatus";
import dropdownMenu from "../../../mixins/dropdownMenu";
import axios from "axios";
import { logError } from "../../../util/errorLogger";

const fileName = "MyAccount.vue";

export default {
  name: "my-account",
  components: {
    PznTriggeredCampaigns,
    SVGIcon,
    KeyRewardsNavigationCTA,
  },
  props: {
    keyRewardsAmount: {
      type: String,
      default: "",
    },
    keyRewardsMyAccountNavigationCTALink: {
      type: String,
      default: "/account/keyrewards.html",
    },
    keyRewardsMyAccountNavigationCTAText: {
      type: String,
      default: "Key Rewards",
    },
    subBrand: {
      type: String,
      required: true,
    },
    shouldDisplayKeyRewardsMyAccountNavigationCTA: {
      type: Boolean,
      default: false,
    },
    shouldDisplayKeyRewardsNotification: {
      type: Boolean,
      default: false,
    },
    displayUserName: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [dropdownMenu],
  data() {
    const profileConfig = getConfigById("profile", this) ?? {};
    const myAccount = getConfigById("myAccount", this) ?? {};
    return {
      concept: "",
      config: {
        link: "",
        links: {},
        text: {},
        displaySubMenu: true,
        img_link: false,
      },
      links: [],
      pznTriggeredCampaigns: {
        isActive: false,
        notificationEnabled: false,
        shouldFireDisplayEvent: true,
      },
      text: "",
      userDisplayName: "",
      links_list: [],
      brandCreditCardName: getConfigById("creditCardServiceProviderName", this),
      isPznTriggeredCampaignsActive: false,
      isPznTriggeredCampaignsNotificationEnabled: false,
      shouldFirePznDisplayEvent: true,
      addEvent: () => ({}),
      redirection_links_list: [],
      accountIcon: false,
      isUiUserNameCookieEnabled:
        profileConfig?.isUiUserNameCookieEnabled ?? true,
      isLogoutAPIEnabled: myAccount?.enableLogoutAPI ?? false,
      isTopNavArrowEnabled:
        getConfigById("showTopNavArrows", this)?.enabled || false,
    };
  },
  methods: {
    firePznDisplayEvent() {
      this.pznTriggeredCampaigns.notificationEnabled = false;
      this.shouldDisplayKeyRewardsNotification &&
        this.$emit("myAccountMouseOver");

      if (
        this.pznTriggeredCampaigns.shouldFireDisplayEvent &&
        this.pznTriggeredCampaigns.isActive
      ) {
        this.addEvent({
          category: "pzn triggered carousel",
          item: "display",
        });

        this.pznTriggeredCampaigns.shouldFireDisplayEvent = false;
      }
    },
    addAnalyticsEvent(link) {
      if (this.brandCreditCardName === "COF" && link.text === APPLY_NOW) {
        this.addEvent({
          category: "credit card application",
          item: "apply now interaction",
          clickLocation: "creditcard-_-topnav-_-applynow",
        });
      }
    },
    /**
     * Clear inspiration boards from localstorage on logout
     */
    clearInspirationBoardsOnLogOut() {
      localStorage.setItem("inspirationBoards", JSON.stringify({}));
    },
    signOut() {
      axios
        .post(LOGOUT_API_URL)
        .then((response) => {
          // Clear inspiration boards if stored in localstorage
          this.clearInspirationBoardsOnLogOut();
          if (response) {
            const { status } = response;
            if (status === 204) {
              this.success = true;
              window.location.href = LOGOUT_URL;
            } else {
              this.error = true;
              this.errorCode = status;
              logError("Error message:", response.data.message);
            }
          } else {
            logError("Response object is undefined");
          }
        })
        .catch((error) => {
          if (error.response) {
            this.error = true;
            this.errorCode = error.response.status;
            logError("Error message:", error.response.data?.message);
          } else {
            logError("Error logging out:", error);
          }
        });
    },
    getConfig() {
      const myAccountConfig = getConfigById("myAccount", this);

      if (!myAccountConfig) {
        logger.warn(
          REPONAME,
          fileName,
          "getConfig",
          `config error in myAccount for concept ${this.concept}`
        );
        return {};
      }

      return myAccountConfig;
    },
    onEnablePznNotification() {
      this.pznTriggeredCampaigns.notificationEnabled = true;
    },
    onSetPznIsActive() {
      this.pznTriggeredCampaigns.isActive = true;
    },
    modifyRedirectionUrls() {
      this.links =
        this.redirection_links_list && this.redirection_links_list.length
          ? this.redirection_links_list
          : this.links_list;

      this.links = this.links.filter(
        ({ onlyOnSubBrands }) =>
          !onlyOnSubBrands || onlyOnSubBrands.includes(this.subBrand)
      );
    },
    /**
     * Updates anchor href for Key rewards links.
     * @param {link} link
     */
    updateKeyRewardsLink(link) {
      return getKeyRewardsLink(link);
    },

    //get Search Header Image
    myAccountIcon() {
      let accountIcon = this.config?.img_link ?? false;
      if (accountIcon && this.config?.loadFromNetStorage) {
        const netStoragePath =
          this.config?.netStoragePath ?? GLOBAL_NETSTORAGE_PATH;
        const applicationUri = getApplicationUri(this) || "/";
        accountIcon = `${applicationUri}${netStoragePath}${accountIcon}`;
      }
      return accountIcon;
    },
  },
  created() {
    this.concept = getBrandConcept(this);
    const accountConfigs = this.getConfig();
    this.config = { ...this.config, ...accountConfigs };

    //since the signedIn status is determined on client-side we are rendering signedOut durin SSR
    this.links = this.config.link_list.signedOut;
    this.text = this.config.text.signedOut;
  },
  mounted() {
    window.WSI.state.onChange(GOVERNOR_USER_STATE_KEY, (displayName) => {
      const isSignedIn = !!displayName;
      const { text, link_list, displayName: appendUserName } = this.config;
      const firstName = displayName.split(" ")[0];
      const prependText =
        appendUserName && isSignedIn && firstName ? `${firstName}'s ` : "";
      const isB2BUser =
        getDesignTradeMemberStatus(this.isUiUserNameCookieEnabled) === "member";
      this.userDisplayName = displayName;
      this.links_list = link_list[isSignedIn ? "signedIn" : "signedOut"].filter(
        (link) => {
          if (link.hideB2B && isB2BUser) {
            return false;
          }
          return true;
        }
      );
      this.redirection_links_list = this.links_list.map((item) => {
        item.link =
          // eslint-disable-next-line eqeqeq
          item.redirectUrls && this.brandCreditCardName != null
            ? item.redirectUrls[this.brandCreditCardName]
            : item.link;
        return item;
      });
      this.modifyRedirectionUrls();
      this.text = prependText + text[isSignedIn ? "signedIn" : "signedOut"];
      this.accountIcon = this.myAccountIcon();
    });
    this.addEvent = addEvent;
  },
};
</script>

import { getAutoSuggestionResults } from "../util/bing-maps";

export default {
  data() {
    return {
      userGeoLocation: {},
      autoSuggestions: [],
      isOpen: false,
      arrowCounter: 0,
    };
  },
  watch: {
    autoSuggestions(val, oldValue) {
      // actually compare them
      if (val.length !== oldValue.length) {
        this.autoSuggestions = val;
      }
    },
  },
  mounted() {
    this.onChange();
  },
  methods: {
    /**
     * This method is called when user stops typing and press enter
     */
    onChange() {
      let timer; // Timer identifier
      const waitTime = 500; // Wait time in milliseconds

      const input = this.$refs.zipInput;

      input?.addEventListener("keyup", (e) => {
        const text = e?.currentTarget.value;

        // Clear timer
        clearTimeout(timer);

        // Wait for X ms and then process the request
        timer = setTimeout(() => {
          // Only fires if not cleared
          // Let's search our bing suggestions array
          if (text) {
            this.getSuggestions();
            this.isOpen = true;
          }
        }, waitTime);
      });
    },
    /**
     * this method fetches autosuggestion and sets the results
     * to data property.
     */
    async getSuggestions() {
      this.autoSuggestions = await getAutoSuggestionResults(
        this.value,
        this.bingMapConfig,
        this.userGeoLocation
      );
    },
    /**
     * When user selects an address, update the data attribute and also update vuex state
     */
    setResult(result) {
      this.bingReturn = true;
      this.value =
        result.postalCode !== "" ? result.postalCode : result.address;
      this.isOpen = false;
      this.updateZipCode();
    },
    /**
     * this method is called upon user's keydown action and updates the arrowCounter
     */
    onArrowDown() {
      if (this.arrowCounter < this.autoSuggestions.length) {
        this.arrowCounter = this.arrowCounter + 1;
      }
    },
    /**
     * this method is called upon user's keyup action
     */
    onArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1;
      }
    },
    /**
     * this function handles closing the suggestions div
     * when user clicks or touches outside.
     */
    closeSuggestionsBox(evt) {
      this.isOpen = false;
      this.arrowCounter = -1;
    },
  },
};

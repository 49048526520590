<template>
  <div data-style="product-cell" class="product-cell-container">
    <a :href="`${product.productLink}/?cm_src=PZN-Carousel`">
      <img :src="productImageSource" :alt="htmlEntityDecode(product.name)" />
      <div class="product-name">{{ htmlEntityDecode(product.name) }}</div>
    </a>
    <p>
      <a
        href="#"
        :aria-labelledby="`See more like ${htmlEntityDecode(product.name)}`"
        class="see-more-link"
        @click="openRecommendationsFlyout()"
      >
        See More Like This
      </a>
    </p>
  </div>
</template>

<script>
import { addEvent } from "@js-ecom-tracking/datalayer";
import EventBus from "../../../util/event-bus";
import { SRC_PLACEHOLDER } from "../../../util/constants";
export default {
  props: {
    product: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    imgConfig: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      addEvent: () => ({}),
    };
  },
  computed: {
    productImageSource: function () {
      // eslint-disable-next-line no-extra-boolean-cast
      return !!this.product.thumb_image
        ? this.product.thumb_image
        : SRC_PLACEHOLDER;
    },
  },

  methods: {
    htmlEntityDecode(input) {
      const element = document.createElement("div");
      element.innerHTML = input;
      return element.childNodes.length === 0
        ? ""
        : element.childNodes[0].nodeValue;
    },

    openRecommendationsFlyout() {
      EventBus.$emit(
        "viewMoreRecommendations",
        this.product,
        "recstrat=rvi-similarity",
        "PZN"
      );

      this.addEvent({
        category: "pzn triggered carousel",
        item: "see more",
        groupId: `${this.product.pid}`,
      });
    },
  },
  mounted() {
    this.addEvent = addEvent;
  },
};
</script>

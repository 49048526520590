import axios from "./axios-instance";

export const marketingClient = {};

/**
 * Makes request to enroll customer in marketing campaign.
 *
 * @param payload contains the email and phone
 * @returns {Promise<AxiosResponse<any>>} the promise to get the response
 */
marketingClient.joinCampaign = (payload) => {
  const data = {
    emailCampaignStickyOverlay: true,
    ...(payload.hasOwnProperty("emailAddress") && {
      emailAddr: payload.emailAddress,
    }),
    ...(payload.hasOwnProperty("phoneNumber") && {
      "marketingSmsInfo.phone": payload.phoneNumber,
    }),
    ...(payload.hasOwnProperty("optOut") && {
      optOut: payload.optOut,
    }),
  };
  const queryParams = new URLSearchParams(data);

  return axios.get(
    `/customer-service/join-email-confirm.jsonp?${queryParams.toString()}`
  );
};

import { categoryDataFetch } from "../services/categoryDataService";
import {
  getItemFromLocalStorage,
  saveItemToLocalStorage,
} from "@js-ecom-mfe/browser-storage";
import { getCatGenerationId, getConfigById } from "./context";
import { deepCloneOfObject } from "./js-common";

/**
 * Get all Nav Items by in nested `categories` arrays and build url paths through recursion
 *
 * @param {Array<Object>} navItems
 * @param {String} path
 * @returns {Array<Object>}
 */
const addPathToNavItems = (navItems, path) => {
  for (let i = 0; i < navItems.length; i++) {
    navItems[i].path = `${path}/${navItems[i].id}`;
    if (navItems[i].categories && Array.isArray(navItems[i].categories)) {
      addPathToNavItems(navItems[i].categories, navItems[i].path);
    }
  }
  return navItems;
};

/**
 * Traverses a category data tree and returns the category data object with matching path.
 *
 * @param {object[]} catData -- List of category data to traverse through
 * @param {string} path -- String to match against a category data object
 */
export const getCategoryDataFromPath = (
  catData,
  path,
  activeTab,
  tabReferences,
  tabs
) => {
  const findMatchingCatData = (catArray, matchingPath) => {
    const exactMatch = catArray?.find((cat) => cat.path === matchingPath);
    if (exactMatch) {
      return exactMatch;
    } else {
      return catArray?.find((cat) => matchingPath.indexOf(cat.path) === 0);
    }
  };
  const catDataMatch = findMatchingCatData(catData, path);
  if (catDataMatch && catDataMatch.path === path) {
    //Exact match found
    if (activeTab) {
      return getActiveTabCategories(
        catDataMatch,
        tabReferences,
        activeTab,
        tabs
      );
    }
    return catDataMatch;
  } else if (
    catDataMatch &&
    path.indexOf(catDataMatch.path) === 0 &&
    catDataMatch.categories &&
    Array.isArray(catDataMatch.categories)
  ) {
    // Partial Match found
    return getCategoryDataFromPath(
      catDataMatch.categories,
      path,
      activeTab,
      tabReferences,
      catDataMatch.tabs || tabs
    );
  } else if (path.indexOf("search") === 0) {
    return "search";
  } else {
    return null;
  }
};

export const getActiveTabCategories = (
  catDataMatch,
  tabReferences,
  activeTab,
  parentLevelTabs
) => {
  if (catDataMatch?.tabs === "none") {
    return null;
  }
  if (!catDataMatch.tabs && !parentLevelTabs) {
    return catDataMatch;
  }
  const tabs = tabReferences[catDataMatch?.tabs || parentLevelTabs];
  if (tabs?.includes(activeTab)) {
    if (catDataMatch.categories) {
      if (!catDataMatch.actualCategories) {
        catDataMatch.actualCategories = deepCloneOfObject(
          catDataMatch.categories
        );
      }
      catDataMatch.categories = filterCategoriesToTab(
        catDataMatch.actualCategories || catDataMatch.categories,
        tabReferences,
        activeTab
      );
    }
    return catDataMatch;
  }
  return null;
};

const filterCategoriesToTab = (categories, tabReferences, activeTab) => {
  const filteredCategories = [];
  categories.forEach((category) => {
    if (!category.tabs) {
      filteredCategories.push(category);
    }
    if (tabReferences[category.tabs]?.includes(activeTab)) {
      filteredCategories.push(category);
    }
    if (category.categories) {
      if (!category.actualCategories) {
        category.actualCategories = deepCloneOfObject(category.categories);
      }
      category.categories = filterCategoriesToTab(
        categories.actualCategories || category.categories,
        tabReferences,
        activeTab
      );
    }
  });
  return filteredCategories;
};

export const retrieveCategoryInformation = async (store) => {
  // getCatGenerationId needs Vue instance with $store in it.
  // since this method is called from outside Vue, we need to pass it the store
  // mimicking the $store property
  const catGenId = getCatGenerationId({ $store: store });
  const catTreeLocalStorageKey = getConfigById("catTreeLocalStorageKey", {
    $store: store,
  });
  const catData = getItemFromLocalStorage(catTreeLocalStorageKey, {})[catGenId];
  if (catData && Array.isArray(catData) && catData.length) {
    return catData;
  }

  const isCatalogService =
    getConfigById("isCatalogMicroServiceEnabled", {
      $store: store,
    }) ?? false;
  const data = await categoryDataFetch(isCatalogService, store).then((res) =>
    addPathToNavItems(res, "shop")
  );
  saveItemToLocalStorage(catTreeLocalStorageKey, { [catGenId]: data });
  return data;
};

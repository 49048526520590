var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"data-style":"mobile-top-links"}},[(_vm.shouldShowKeyRewardsMobileNavigationCTA)?_c('KeyRewardsNavigationCTA',{attrs:{"amount":_vm.keyRewardsAmount,"dataStyle":'Global-header-KeyRewardsNavigationCTA-mobile',"gridCols":'grid-cols-3',"hasArrow":true,"keyRewardsLink":_vm.keyRewardsMobileNavigationCTALink,"text":_vm.keyRewardsMobileNavigationCTAText,"data-test-id":"Global-mobile-key-rewards-navigation-cta"},on:{"onIntersect":function($event){return _vm.$emit('onIntersect', $event)},"onNonIntersect":function($event){return _vm.$emit('onNonIntersect', $event)}}}):_vm._e(),_vm._v(" "),(_vm.topLinks)?_c('ul',{staticClass:"iconedLinks",class:_vm.numberLinks,attrs:{"id":"topOfNav"}},[(_vm.shouldShowAccountIcon)?_c('li',{staticClass:"account-link",attrs:{"data-test-id":"mobile-top-links-account-link"}},[_c('a',{attrs:{"href":`/m${
          _vm.isSignedIn
            ? _vm.topLinks.myAccount.link.signedIn
            : _vm.topLinks.myAccount.link.signedOut
        }`}},[(_vm.icon(_vm.topLinks.myAccount))?_c('SVGIcon',{attrs:{"icon":_vm.icon(_vm.topLinks.myAccount)}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"mobile-utility-nav-top-link-text",attrs:{"data-test-id":"account-text"}},[_vm._v(_vm._s(_vm.accountText))])],1)]):_vm._e(),_vm._v(" "),(_vm.shouldShowFavoritesIcon)?_c('li',{staticClass:"favorites-link",attrs:{"data-test-id":"mobile-top-links-favorites-link"}},[_c('a',{staticClass:"favorites",attrs:{"href":_vm.topLinks.favorites.link}},[_c('span',{staticClass:"favorites-svg-container"},[(_vm.icon(_vm.topLinks.favorites))?_c('SVGIcon',{attrs:{"icon":_vm.icon(_vm.topLinks.favorites)}}):_vm._e(),_vm._v(" "),(_vm.favoriteText)?_c('span',{staticClass:"mobile-utility-nav-top-link-text",attrs:{"data-test-id":"account-text"}},[_vm._v(_vm._s(_vm.favoriteText))]):_vm._e(),_vm._v(" "),(_vm.icon(_vm.topLinks.favorites) && _vm.favoriteCount > 0)?_c('div',{staticClass:"favorite-count"},[_c('p',{staticClass:"favorite-count-text"},[_vm._v(_vm._s(_vm.favoriteCount))])]):_vm._e()],1),_vm._v(" "),_c('span',{staticClass:"mobile-utility-nav-top-link-text"},[_vm._v(_vm._s(_vm.topLinks.favorites.mobileUtilityNavText))])])]):_vm._e(),_vm._v(" "),_vm._l((_vm.renderedTopLinks),function(topLink,index){return _c('li',{key:index},[(topLink.text.includes('Key Rewards'))?_c('a',{attrs:{"href":`/m${_vm.updateKeyRewards(topLink)}`}},[(topLink.SVGIcon)?_c('SVGIcon',{attrs:{"icon":topLink.SVGIcon}}):_vm._e(),_vm._v("\n        "+_vm._s(topLink.text)+"\n      ")],1):_vm._e(),_vm._v(" "),(
          topLink.text !== 'Recently Viewed' &&
          !(
            topLink.enableBabyRegistryLink &&
            topLink.babyRegistryLink &&
            topLink.babyRegistryTargetTab === _vm.activeTab
          )
        )?_c('a',{attrs:{"href":`${topLink.targetIsResponsive ? '' : '/m'}${topLink.link}`}},[(_vm.icon(topLink))?_c('SVGIcon',{attrs:{"icon":_vm.icon(topLink)}}):_vm._e(),_vm._v(" "),(_vm.isMobileUtilityNavEnabled)?_c('span',{staticClass:"mobile-utility-nav-top-link-text"},[_vm._v("\n          "+_vm._s(topLink.mobileUtilityNavText)+"\n        ")]):_c('span',[_vm._v(_vm._s(topLink.text))])],1):_vm._e(),_vm._v(" "),(
          topLink.text !== 'Recently Viewed' &&
          topLink.enableBabyRegistryLink &&
          topLink.babyRegistryLink &&
          topLink.babyRegistryTargetTab === _vm.activeTab
        )?_c('a',{attrs:{"href":topLink.babyRegistryLink}},[(_vm.icon(topLink))?_c('SVGIcon',{attrs:{"icon":_vm.icon(topLink)}}):_vm._e(),_vm._v(" "),(_vm.isMobileUtilityNavEnabled)?_c('span',{staticClass:"mobile-utility-nav-top-link-text"},[_vm._v("\n          "+_vm._s(topLink.mobileUtilityNavText)+"\n        ")]):_c('span',[_vm._v(_vm._s(topLink.text))])],1):(topLink.text === 'Recently Viewed')?_c('a',{attrs:{"href":'#',"data-test-id":"recentlyViewedLink"},on:{"click":function($event){return _vm.handleRVIFlyout($event)}}},[_c('span',{staticClass:"recently-viewed-icon"},[(_vm.icon(topLink))?_c('SVGIcon',{attrs:{"icon":_vm.icon(topLink)}}):_vm._e(),_vm._v(" "),(_vm.shouldShowPznNotification)?_c('span',{staticClass:"pzn-mobile-notification",attrs:{"data-test-id":"pznMobileNotification"}},[_c('SVGIcon',{attrs:{"icon":"pzn-notification-icon"}})],1):_vm._e()],1),_vm._v(" "),(_vm.isMobileUtilityNavEnabled)?_c('span',{staticClass:"mobile-utility-nav-top-link-text"},[_vm._v(_vm._s(topLink.mobileUtilityNavText))]):_c('span',[_vm._v(_vm._s(topLink.text))])]):_vm._e()])}),_vm._v(" "),(_vm.isMobileUtilityNavEnabled && _vm.mobileUtilityNavMyStoreEnabled)?_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.myStoreLoaded),expression:"myStoreLoaded"}]},[_c('div',{staticClass:"my-store-mobile-container"},[_c('MyStore',{attrs:{"isMainInstance":true,"isMobileUtilityNavEnabled":_vm.isMobileUtilityNavEnabled,"topLinksConfig":_vm.topLinks},on:{"hasLoaded":function($event){_vm.myStoreLoaded = $event}}})],1)]):_vm._e()],2):_vm._e()],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
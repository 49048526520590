/**
 * This method is used to convert Key Rewards certificate balances
 * to strings and round down to the nearest whole number
 * @param {String} certificateBalance a decimal number representing a key rewards certificate balance (i.e. "42.87")
 * @returns {Number} a rounded whole number (i.e. 42)
 */
export const roundDownBalance = (certificateBalance) => {
  return parseInt(certificateBalance);
};

/**
 * This method is used to convert rounded Key Rewards certificate balances
 * to location-appropriate strings (i.e. comma-separated for North America)
 * @param {Number} roundedBalance an integer representing a key rewards certificate balance (i.e. 12345)
 * @returns {String} a formatted string for the default locale (i.e. "12,345")
 */
export const formatBalanceWithCommas = (roundedBalance) => {
  return roundedBalance.toLocaleString();
};

<template>
  <div data-style="search-flyout" class="grid-recommendations-wrapper">
    <Drawer
      ref="drawerComponent"
      :showDrawer="showDrawer"
      :customClass="'search-flyout'"
      direction="right"
      :lockScreen="true"
      :sitsInlineWith="sitsInlineWith"
      :isResponsive="isResponsive"
      v-on:close="toggleDrawer"
      :showCloseIcon="true"
      v-on:ready="hasLoaded"
    >
      <template v-slot:content>
        <SearchBarInFlyout />
      </template>
      <template v-slot:footer>
        <div class="search-horizontal-line"></div>
        <div class="footer-search-img">
          <SearchFlyoutLogo />
        </div>
      </template>
    </Drawer>
  </div>
</template>
<script>
import Drawer from "../../drawer/Drawer.vue";
import EventBus from "../../../util/event-bus";
import SearchBarInFlyout from "./SearchBarInFlyout.vue";
import SearchFlyoutLogo from "./SearchFlyoutLogo.vue";

export default {
  name: "search-flyout-component",
  components: {
    Drawer,
    SearchBarInFlyout,
    SearchFlyoutLogo,
  },
  props: {
    flyoutConfig: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showDrawer: false,
      hasLoaded: false,
      sitsInlineWith: "",
      isResponsive: false,
    };
  },
  computed: {},
  methods: {
    toggleDrawer() {
      this.showDrawer = !this.showDrawer;
    },
  },
  mounted() {
    EventBus.$on("toggleSearchFlyout", () => {
      this.showDrawer = !this.showDrawer;
    });
    EventBus.$on("mediumBreakPoint", () => {
      this.isResponsive = true;
    });
    EventBus.$on("largeBreakPoint", () => {
      this.isResponsive = false;
    });
  },
};
</script>

<template>
  <svg
    data-style="we-logo"
    v-if="isWekEnabled"
    width="159px"
    height="58px"
    viewBox="0 0 159 58"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    :id="`logo-wek`"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>WE Kids</title>
    <g
      id="Logos-/-WE-Kids_selected"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="WE_Kids-Logo" fill-rule="nonzero">
        <g id="WE-Logo" fill="#363636">
          <path
            d="M35.9902439,9.59512195 C37.7453659,9.59512195 40.1697561,11.0429268 40.1629555,13.337561 C40.1629555,14.9560976 39.9921951,15.1746341 39.5687805,15.1746341 L31.4487805,15.1746341 C30.977561,15.1746341 30.9434146,14.9219512 30.9434146,14.4917073 C31.0996105,11.802494 33.2970813,9.68530965 35.9902439,9.62926829 M31.3053659,17.4760976 L40.7912195,17.4760976 C41.4641444,17.5787257 42.1204471,17.2097315 42.382439,16.5814634 C42.7300782,15.4788893 42.850879,14.3173429 42.737561,13.1668293 C42.457561,8.49560976 38.5512195,7.1297561 36.1131707,7.11609756 C30.7863415,7.11609756 27.7404878,11.5619512 27.72,17.5717073 C27.72,20.9453659 28.3482927,22.6663415 29.7687805,24.7356098 C31.1892683,26.804878 33.804878,27.6039024 35.9697561,27.6039024 C38.1346341,27.6039024 41.7131707,26.3268293 42.4780488,25.4458537 C42.6351735,25.0396139 42.652,24.5925102 42.5258537,24.1756098 C42.4302439,23.697561 42.2390244,23.0214634 41.8907317,23.1512195 C41.3034146,23.3765854 39.5278049,24.8995122 36.3112195,24.8995122 C30.8,24.8995122 30.7385366,19.6 30.697561,18.5414634 C30.6565854,17.4829268 30.8341463,17.4897561 31.3326829,17.4897561"
            id="Shape"
          ></path>
          <path
            d="M57.2565854,16.2946341 C55.5356098,15.4956098 53.1590244,14.9834146 53.1590244,12.6204878 C53.1590244,10.6126829 55.0643902,9.6497561 56.382439,9.65658537 C57.4464832,9.71071228 58.4920968,9.95592256 59.4692683,10.3804878 C60.6097561,10.8653659 60.5756098,10.462439 61.0331707,9.69756098 C61.3609756,9.15804878 61.4565854,8.64585366 61.3063415,8.52292683 C59.8466806,7.57866515 58.1336896,7.10217251 56.3960976,7.15707317 C52.2985366,7.15707317 50.2019512,9.72487805 50.2019512,12.6204878 C50.2019512,15.5160976 52.5580488,17.2029268 54.7843902,18.2409756 C57.0107317,19.2790244 59.3873171,20.084878 59.3873171,22.0243902 C59.2917237,23.8455019 57.7392981,25.2451297 55.9180488,25.1521951 C53.9512195,25.1521951 51.9639024,23.9707317 51.2741463,23.6429268 C50.8917073,23.4517073 50.7346341,23.8546341 50.4409756,24.4760976 C50.0653659,25.2546341 49.7990244,25.8419512 50.0858537,26.0126829 C51.845057,27.0536039 53.8347202,27.6425254 55.8770732,27.7268293 C59.5307317,27.7268293 62.3717073,25.5278049 62.3717073,21.9697561 C62.3717073,18.2478049 58.9570732,17.1278049 57.2770732,16.3014634"
            id="Path"
          ></path>
          <path
            d="M79.2878049,24.257561 C78.468303,24.6919129 77.5517133,24.9104843 76.6243902,24.8926829 C73.8039024,24.8926829 73.2097561,22.68 73.2097561,20.795122 L73.2507317,10.6809756 C73.2507317,10.2643902 73.4078049,10.182439 73.9336585,10.182439 L79.342439,10.182439 C79.5541463,10.182439 79.5131707,9.81365854 79.52,8.89853659 C79.5268293,7.98341463 79.5746341,7.56682927 79.2331707,7.53268293 L73.9404878,7.53268293 C73.4692683,7.53268293 73.257561,7.38243902 73.257561,6.70634146 L73.257561,1.92585366 C73.257561,1.18829268 73.182439,1.17463415 71.9531707,1.54341463 C70.5873171,1.94634146 70.5873171,1.94634146 70.5873171,2.47902439 L70.5873171,6.67902439 C70.5873171,7.18439024 70.4712195,7.52585366 69.9043902,7.52585366 L67.8556098,7.52585366 C67.3639024,7.52585366 67.5141463,8.09268293 67.5141463,8.82341463 C67.5141463,9.55414634 67.5141463,10.1482927 67.8487805,10.1482927 L69.9521951,10.1482927 C70.3482927,10.1892683 70.5736585,10.2029268 70.5736585,10.6058537 L70.5736585,19.1082927 C70.5736585,23.9707317 71.222439,25.0429268 72.3082927,26.1629268 C73.2771954,27.1159475 74.5895132,27.63792 75.9482927,27.6107317 C77.2020381,27.5400346 78.4327731,27.2439179 79.5814634,26.7365854 C80.1004878,26.5043902 80.155122,26.3404878 79.9570732,25.2478049 C79.7590244,24.155122 79.6360976,24.0868293 79.315122,24.2097561"
            id="Path"
          ></path>
          <path
            d="M120.639024,1.29756098 C120.639024,0.874146341 120.58439,0.614634146 120.147317,0.614634146 L115.844878,0.676097561 C115.530732,0.676097561 115.578537,0.935609756 115.578537,1.83707317 C115.578537,2.52 115.54439,2.91609756 115.838049,2.92292683 C117.50439,2.92292683 118.023415,3.67414634 118.016585,4.75317073 L117.927805,27.1258537 C117.927805,27.72 117.811707,27.7336585 119.177561,27.7336585 C120.543415,27.7336585 120.543415,27.795122 120.543415,27.1326829 L120.639024,1.29756098 Z"
            id="Path"
          ></path>
          <path
            d="M147.607805,8.46146341 C145.574371,8.43739541 143.641868,9.34577239 142.362927,10.9268293 C141.925854,11.404878 141.802927,11.6097561 141.59122,11.6097561 C141.379512,11.6097561 141.331707,11.3639024 141.126829,10.9678049 C140.429919,9.42576812 138.870697,8.45732407 137.179512,8.51609756 C135.13856,8.56237635 133.211824,9.46747135 131.873171,11.0087805 C131.675122,11.2 131.306341,11.3297561 131.306341,10.9131707 L131.306341,9.34243902 C131.306341,8.70731707 131.142439,8.70731707 130.11122,8.70731707 C129.08,8.70731707 128.793171,8.70731707 128.793171,9.33560976 L128.718049,27.44 C128.718049,27.9317073 129.032195,27.9180488 130.022439,27.924878 C130.821463,27.924878 131.299512,27.924878 131.299512,27.4536585 L131.299512,18.4185366 C131.299512,13.9658537 133.430244,11.1043902 136.120976,11.1043902 C138.811707,11.1043902 139.330732,13.5492683 139.330732,15.4478049 L139.317073,27.5629268 C139.317073,28.0956098 139.788293,27.9385366 140.539512,27.9385366 C141.290732,27.9385366 141.830244,28.0956098 141.837073,27.5697561 L141.878049,18.4390244 C141.878049,13.9931707 143.926829,11.124878 147.040976,11.124878 C149.383415,11.124878 150.032195,13.0439024 150.032195,14.9356098 L149.98439,27.3853659 C149.98439,28.0068293 150.16878,27.9863415 151.193171,27.9931707 C152.217561,28 152.524878,27.9317073 152.559024,27.3990244 L152.613659,14.2731707 C152.613659,10.6126829 150.517073,8.46829268 147.635122,8.46829268 L158.023438,12.384878"
            id="Path"
          ></path>
          <path
            d="M21.6965854,7.66243902 C20.6380488,7.66243902 20.3307317,7.57365854 20.16,7.99707317 C20.0985366,8.1404878 17.3531707,21.9082927 17.3531707,21.9082927 C17.2439024,22.3180488 17.1687805,22.4887805 16.9570732,22.4887805 C16.7453659,22.4887805 16.622439,22.3795122 16.5473171,22.017561 L12.7570732,8.14731707 C12.6546341,7.74439024 12.4907317,7.59414634 11.5482927,7.59414634 C10.6058537,7.59414634 10.3941463,7.68292683 10.284878,8.13365854 L6.48780488,21.9834146 C6.39219512,22.3317073 6.28292683,22.4478049 6.04390244,22.4478049 C5.80487805,22.4478049 5.74341463,22.0380488 5.64097561,21.6282927 L2.8,7.8604878 C2.63609756,7.34146341 2.37658537,7.47121951 1.43414634,7.46439024 C0.491707317,7.45756098 0.184390244,7.51219512 0.191001167,8.05170732 C0.191001167,8.29756098 4.20682927,25.7463415 4.37756098,26.4907317 C4.54829268,27.235122 4.84195122,27.5356098 5.94829268,27.5356098 C7.23902439,27.5356098 7.40292683,26.9756098 7.59414634,26.5043902 L11.1317073,14.0204878 C11.2546341,13.6721951 11.275122,13.4809756 11.5209756,13.4809756 C11.7668293,13.4809756 11.7941463,13.6721951 11.9102439,14.0273171 L15.3726829,26.524878 C15.5570732,26.9960976 15.7141463,27.5560976 17.004878,27.5629268 C18.1112195,27.5629268 18.3707317,27.3170732 18.5892683,26.5385366 C18.8078049,25.76 22.8234146,8.2497561 22.8166028,8.05170732 C22.8166028,7.58731707 22.4204878,7.66926829 21.6965854,7.66243902"
            id="Path"
          ></path>
          <path
            d="M103.238049,9.86146341 C105,9.86146341 107.417561,11.3160976 107.417561,13.6107317 C107.417561,15.222439 107.24,15.4409756 106.816585,15.4409756 L98.7034146,15.4409756 C98.2321951,15.4409756 98.1912195,15.1814634 98.1980488,14.7580488 C98.3505952,12.0687166 100.545058,9.94859218 103.238049,9.88878049 M98.5531707,17.7560976 L108.045854,17.7560976 C108.717029,17.8597219 109.372749,17.4938698 109.637073,16.8682927 C109.979809,15.7647069 110.100506,14.6041531 109.992195,13.4536585 C109.712195,8.78243902 105.805854,7.41658537 103.367805,7.4097561 C98.0341463,7.4097561 94.995122,11.8556098 94.9678049,17.8653659 C94.9678049,21.2321951 95.6029268,22.9531707 97.0165854,25.0292683 C98.4302439,27.1053659 101.052683,27.8907317 103.210732,27.8907317 C105.36878,27.8907317 108.960976,26.6204878 109.719024,25.7395122 C109.878802,25.3338236 109.895662,24.8858185 109.766829,24.4692683 C109.67122,23.9843902 109.486829,23.315122 109.138537,23.444878 C108.55122,23.6634146 106.76878,25.1863415 103.552195,25.1863415 C98.0478049,25.1863415 97.9863415,19.8868293 97.9453659,18.8282927 C97.9043902,17.7697561 98.0819512,17.7765854 98.5736585,17.7765854"
            id="Shape"
          ></path>
        </g>
        <g id="kids" transform="translate(54.000000, 38.000000)" fill="#467E8B">
          <path
            d="M19.1644118,13.1066667 C19.1644118,8.80666667 22.0867647,6.23333333 25.5435294,6.23333333 C27.3442198,6.12237816 29.0550245,7.01952133 29.9676471,8.55333333 L30.0217647,8.55333333 L30.0217647,-4.14483263e-14 L32.8020588,-4.14483263e-14 L32.8020588,19.56 L30.0217647,19.56 L30.0217647,17.66 L29.9676471,17.66 C29.0550245,19.193812 27.3442198,20.0909552 25.5435294,19.98 C22.0867647,19.98 19.1644118,17.4133333 19.1644118,13.1066667 M30.1638235,13.1066667 C30.1638235,10.6466667 28.5808824,8.91333333 26.1388235,8.91333333 C23.6967647,8.91333333 22.08,10.6466667 22.08,13.1066667 C22.08,15.5666667 23.6697059,17.3 26.1388235,17.3 C28.6079412,17.3 30.1638235,15.5666667 30.1638235,13.1066667"
            id="Shape"
          ></path>
          <path
            d="M35.0479412,18.6133333 L36.2926471,16.18 C37.5472337,16.9509866 38.9884149,17.3768447 40.4664706,17.4133333 C41.8194118,17.4133333 42.4958824,17.02 42.4958824,16.2066667 C42.4958824,15.68 42.2117647,15.2866667 41.1091176,14.7533333 L38.7279412,13.6133333 C36.7458824,12.6866667 35.9747059,11.3733333 35.9747059,9.89333333 C35.9747059,7.52 37.7605882,6.23333333 40.6288235,6.23333333 C42.1933693,6.26150414 43.7356224,6.60404914 45.1611765,7.24 L44.0314706,9.61333333 C42.9369021,9.10539891 41.7498097,8.81972854 40.5408824,8.77333333 C39.3232353,8.77333333 38.9241176,9.16666667 38.9241176,9.75333333 C38.9241176,10.34 39.2961765,10.76 40.4258824,11.2933333 L42.8679412,12.44 C44.5658824,13.22 45.4723529,14.34 45.4723529,16.0666667 C45.4723529,18.6933333 43.4429412,19.9800227 40.3717647,19.9800227 C38.5053552,19.982678 36.6696418,19.5120342 35.0411765,18.6133333"
            id="Path"
          ></path>
          <path
            d="M17.2500211,2.72666667 C17.2527356,3.59342329 16.7246791,4.37620916 15.9126448,4.70914916 C15.1006104,5.04208915 14.1649839,4.85942395 13.5430783,4.24653149 C12.9211728,3.63363904 12.7358213,2.71157236 13.0736575,1.91130661 C13.4114936,1.11104085 14.2057911,0.590637337 15.0852941,0.593312563 C16.2808282,0.593312563 17.2500211,1.5484592 17.2500211,2.72666667"
            id="Path"
          ></path>
          <rect
            id="Rectangle"
            x="13.6917647"
            y="6.65333333"
            width="2.78029412"
            height="12.9066667"
          ></rect>
          <polygon
            id="Path"
            points="8.05676471 19.56 2.78029412 13.22 2.78029412 19.56 -1.71836401e-13 19.56 -1.71836401e-13 4.85537536e-14 2.78029412 4.85537536e-14 2.78029412 12.16 7.46147059 6.65333333 11.1144118 6.65333333 5.61470588 12.52 11.7097059 19.56"
          ></polygon>
        </g>
      </g>
    </g>
  </svg>
  <svg
    v-else-if="isWebMobileEnabled"
    width="540px"
    height="110px"
    viewBox="0 0 540 110"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    :id="`logo-mobile-web`"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>WE business to business</title>
    <g
      id="Future-Cart"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="WE-B2B_stacked_re-exported" fill-rule="nonzero">
        <g
          id="Group"
          transform="translate(318.600000, 64.594000)"
          fill="#8D774B"
        >
          <path
            d="M6.3,39.8 L6.3,39.8 L6.2,44.1 L-5.68434189e-14,44.1 L-5.68434189e-14,0 L6.2,0 L6.2,19.3 L6.3,19.3 C8.1,16.1 11.3,14.1 16.1,14.1 C23.8,14.1 30.3,19.9 30.3,29.6 C30.3,39.3 23.8,45.1 16.1,45.1 C11.3,45 8.1,42.9 6.3,39.8 Z M23.8,29.5 C23.8,24 20.3,20 14.9,20 C9.5,20 6,23.9 6,29.5 C6,35.1 9.5,39 14.9,39 C20.3,39 23.8,35.1 23.8,29.5 Z"
            id="Shape"
          ></path>
          <path
            d="M35.7,32.6 L35.7,15 L42,15 L42,32 C42,36.8 44.4,39.1 48.1,39.1 C52.4,39.1 55.4,36.3 55.4,29 L55.4,15 L61.6,15 L61.6,44.1 L55.4,44.1 L55.4,39.7 L55.3,39.7 C53.9,42.6 51.1,45.1 46.4,45.1 C40.6,45 35.7,41.5 35.7,32.6 L35.7,32.6 Z"
            id="Path"
          ></path>
          <path
            d="M66.3,41.9 L69.1,36.4 C71.8,38 74.9,39.2 78.4,39.2 C81.4,39.2 82.9,38.3 82.9,36.5 C82.9,35.3 82.3,34.4 79.8,33.2 L74.5,30.6 C70.1,28.5 68.4,25.6 68.4,22.2 C68.4,16.8 72.4,13.9 78.7,13.9 C82,13.9 85.7,14.8 88.8,16.2 L86.3,21.6 C84.1,20.7 81.4,19.7 78.6,19.7 C75.8,19.7 75,20.6 75,21.9 C75,23.3 75.8,24.2 78.3,25.4 L83.7,28 C87.5,29.8 89.5,32.3 89.5,36.2 C89.5,42.1 85,45 78.2,45 C74,45 69.6,43.9 66.3,41.9 L66.3,41.9 Z"
            id="Path"
          ></path>
          <path
            d="M101.7,8.2 L94.1,8.2 L94.1,0.6 L101.7,0.6 L101.7,8.2 Z M94.8,15 L101,15 L101,44.1 L94.8,44.1 L94.8,15 Z"
            id="Shape"
          ></path>
          <path
            d="M135.6,26.2 L135.6,44.1 L129.4,44.1 L129.4,27.2 C129.4,22.2 126.9,20.1 122.7,20.1 C118.2,20.1 115,22.8 115,30.1 L115,44 L108.8,44 L108.8,15 L115,15 L115,19.4 L115.1,19.4 C117,16.1 120.1,14 124.6,14 C130.9,14 135.6,17.3 135.6,26.2 L135.6,26.2 Z"
            id="Path"
          ></path>
          <path
            d="M169.8,29.5 L169.8,30.5 L147.2,30.5 C147.5,35.7 151,39.1 156,39.1 C161,39.1 163.5,35.9 164.9,34 L169.2,38.1 C167.1,41.1 163.2,45 156,45 C146.7,45 140.8,38.8 140.8,29.6 C140.8,20.4 146.3,14 155.7,14 C165.1,14 169.8,20.1 169.8,29.5 L169.8,29.5 Z M147.5,26 L163.1,26 C162.8,22 159.9,19.6 155.5,19.6 C151.3,19.7 148.3,21.8 147.5,26 Z"
            id="Shape"
          ></path>
          <path
            d="M172.6,41.9 L175.4,36.4 C178.1,38 181.2,39.2 184.7,39.2 C187.7,39.2 189.2,38.3 189.2,36.5 C189.2,35.3 188.6,34.4 186.1,33.2 L180.8,30.6 C176.4,28.5 174.7,25.6 174.7,22.2 C174.7,16.8 178.7,13.9 185,13.9 C188.3,13.9 192,14.8 195.1,16.2 L192.6,21.6 C190.4,20.7 187.7,19.7 184.9,19.7 C182.1,19.7 181.3,20.6 181.3,21.9 C181.3,23.3 182.1,24.2 184.6,25.4 L190,28 C193.8,29.8 195.8,32.3 195.8,36.2 C195.8,42.1 191.3,45 184.5,45 C180.3,45 176,43.9 172.6,41.9 L172.6,41.9 Z"
            id="Path"
          ></path>
          <path
            d="M198,41.9 L200.8,36.4 C203.5,38 206.6,39.2 210.1,39.2 C213.1,39.2 214.6,38.3 214.6,36.5 C214.6,35.3 214,34.4 211.5,33.2 L206.2,30.6 C201.8,28.5 200.1,25.6 200.1,22.2 C200.1,16.8 204.1,13.9 210.4,13.9 C213.7,13.9 217.4,14.8 220.5,16.2 L218,21.6 C215.8,20.7 213.1,19.7 210.3,19.7 C207.5,19.7 206.7,20.6 206.7,21.9 C206.7,23.3 207.5,24.2 210,25.4 L215.4,28 C219.2,29.8 221.2,32.3 221.2,36.2 C221.2,42.1 216.7,45 209.9,45 C205.7,45 201.3,43.9 198,41.9 Z"
            id="Path"
          ></path>
        </g>
        <g id="to" transform="translate(243.800000, 68.445000)" fill="#8D764B">
          <path
            d="M17.5,17.2 L17.5,11.9 L11.5,11.9 L11.5,0 L5.2,0 L5.2,11.8 L5.68434189e-14,11.8 L5.68434189e-14,17.1 L5.2,17.1 L5.2,33.8 C5.2,38.7 8,41.4 13.2,41.4 L16.1,41.4 L16.1,35.6 L14.6,35.6 C12.4,35.6 11.5,34.6 11.5,32.5 L11.5,17.1 L17.5,17.2 L17.5,17.2 Z"
            id="Path"
          ></path>
          <path
            d="M36,10 C26.7,10 19.8,16.7 19.8,25.7 C19.8,34.7 26.7,41.4 36,41.4 C45.3,41.4 52.2,34.7 52.2,25.7 C52.2,16.7 45.2,10 36,10 Z M36,35.3 C30.4,35.3 26.5,31.5 26.5,25.7 C26.5,19.9 30.3,16.1 36,16.1 C41.7,16.1 45.5,19.9 45.5,25.7 C45.5,31.5 41.6,35.3 36,35.3 Z"
            id="Shape"
          ></path>
        </g>
        <g id="Group" transform="translate(0.000000, 64.594000)" fill="#8D774B">
          <path
            d="M6.3,39.8 L6.3,39.8 L6.2,44.1 L0,44.1 L0,0 L6.2,0 L6.2,19.3 L6.3,19.3 C8.1,16.1 11.3,14.1 16.1,14.1 C23.8,14.1 30.3,19.9 30.3,29.6 C30.3,39.3 23.8,45.1 16.1,45.1 C11.3,45 8.1,42.9 6.3,39.8 Z M23.8,29.5 C23.8,24 20.3,20 14.9,20 C9.5,20 6,23.9 6,29.5 C6,35.1 9.5,39 14.9,39 C20.3,39 23.8,35.1 23.8,29.5 Z"
            id="Shape"
          ></path>
          <path
            d="M35.7,32.6 L35.7,15 L42,15 L42,32 C42,36.8 44.4,39.1 48.1,39.1 C52.4,39.1 55.4,36.3 55.4,29 L55.4,15 L61.6,15 L61.6,44.1 L55.4,44.1 L55.4,39.7 L55.3,39.7 C53.9,42.6 51.1,45.1 46.4,45.1 C40.6,45 35.7,41.5 35.7,32.6 Z"
            id="Path"
          ></path>
          <path
            d="M66.3,41.9 L69.1,36.4 C71.8,38 74.9,39.2 78.4,39.2 C81.4,39.2 82.9,38.3 82.9,36.5 C82.9,35.3 82.3,34.4 79.8,33.2 L74.5,30.6 C70.1,28.5 68.4,25.6 68.4,22.2 C68.4,16.8 72.4,13.9 78.7,13.9 C82,13.9 85.7,14.8 88.8,16.2 L86.3,21.6 C84.1,20.7 81.4,19.7 78.6,19.7 C75.8,19.7 75,20.6 75,21.9 C75,23.3 75.8,24.2 78.3,25.4 L83.7,28 C87.5,29.8 89.5,32.3 89.5,36.2 C89.5,42.1 85,45 78.2,45 C74,45 69.6,43.9 66.3,41.9 L66.3,41.9 Z"
            id="Path"
          ></path>
          <path
            d="M101.7,8.2 L94.1,8.2 L94.1,0.6 L101.7,0.6 L101.7,8.2 Z M94.8,15 L101,15 L101,44.1 L94.8,44.1 L94.8,15 Z"
            id="Shape"
          ></path>
          <path
            d="M135.6,26.2 L135.6,44.1 L129.4,44.1 L129.4,27.2 C129.4,22.2 126.9,20.1 122.7,20.1 C118.2,20.1 115,22.8 115,30.1 L115,44 L108.8,44 L108.8,15 L115,15 L115,19.4 L115.1,19.4 C117,16.1 120.1,14 124.6,14 C130.9,14 135.6,17.3 135.6,26.2 Z"
            id="Path"
          ></path>
          <path
            d="M169.8,29.5 L169.8,30.5 L147.2,30.5 C147.5,35.7 151,39.1 156,39.1 C161,39.1 163.5,35.9 164.9,34 L169.2,38.1 C167.1,41.1 163.2,45 156,45 C146.7,45 140.8,38.8 140.8,29.6 C140.8,20.4 146.3,14 155.7,14 C165.1,14 169.8,20.1 169.8,29.5 Z M147.5,26 L163.1,26 C162.8,22 159.9,19.6 155.5,19.6 C151.3,19.7 148.3,21.8 147.5,26 Z"
            id="Shape"
          ></path>
          <path
            d="M172.6,41.9 L175.4,36.4 C178.1,38 181.2,39.2 184.7,39.2 C187.7,39.2 189.2,38.3 189.2,36.5 C189.2,35.3 188.6,34.4 186.1,33.2 L180.8,30.6 C176.4,28.5 174.7,25.6 174.7,22.2 C174.7,16.8 178.7,13.9 185,13.9 C188.3,13.9 192,14.8 195.1,16.2 L192.6,21.6 C190.4,20.7 187.7,19.7 184.9,19.7 C182.1,19.7 181.3,20.6 181.3,21.9 C181.3,23.3 182.1,24.2 184.6,25.4 L190,28 C193.8,29.8 195.8,32.3 195.8,36.2 C195.8,42.1 191.3,45 184.5,45 C180.3,45 176,43.9 172.6,41.9 Z"
            id="Path"
          ></path>
          <path
            d="M198,41.9 L200.8,36.4 C203.5,38 206.6,39.2 210.1,39.2 C213.1,39.2 214.6,38.3 214.6,36.5 C214.6,35.3 214,34.4 211.5,33.2 L206.2,30.6 C201.8,28.5 200.1,25.6 200.1,22.2 C200.1,16.8 204.1,13.9 210.4,13.9 C213.7,13.9 217.4,14.8 220.5,16.2 L218,21.6 C215.8,20.7 213.1,19.7 210.3,19.7 C207.5,19.7 206.7,20.6 206.7,21.9 C206.7,23.3 207.5,24.2 210,25.4 L215.4,28 C219.2,29.8 221.2,32.3 221.2,36.2 C221.2,42.1 216.7,45 209.9,45 C205.7,45 201.3,43.9 198,41.9 Z"
            id="Path"
          ></path>
        </g>
        <g
          id="west-elm"
          transform="translate(145.928000, 0.000000)"
          fill="#212121"
        >
          <path
            d="M50.7,28 L66.1,28 C67.2,28.2 68.3,27.6 68.7,26.5 C69.3,24.7 69.4,22.8 69.3,20.9 C68.8,13.3 62.5,11.1 58.5,11.1 C49.8,11.1 44.9,18.4 44.9,28.1 C44.9,33.6 46,36.4 48.2,39.7 C50.5,43.1 54.8,44.3 58.3,44.3 C61.8,44.3 67.6,42.2 68.9,40.8 C69.2,40.1 69.2,39.4 69,38.7 C68.8,37.9 68.5,36.8 68,37 C67,37.4 64.2,39.8 58.9,39.9 C50,39.9 49.8,31.3 49.7,29.6 C49.6,28 49.9,28 50.7,28 M58.2,15.1 C61.1,15.1 65,17.4 65,21.2 C65,23.8 64.7,24.2 64,24.2 L50.8,24.2 C50,24.2 50,23.8 50,23.1 C50.3,18.7 53.8,15.2 58.2,15.1"
            id="Path_171424"
          ></path>
          <path
            d="M92.8,25.9 C90,24.6 86.1,23.8 86.1,20 C86.1,16.7 89.2,15.2 91.3,15.2 C93,15.3 94.7,15.7 96.3,16.4 C98.2,17.2 98.1,16.5 98.8,15.3 C99.3,14.4 99.5,13.6 99.2,13.4 C98,12.4 94.9,11.1 91.2,11.2 C84.6,11.2 81.1,15.4 81.2,20.1 C81.2,24.7 85.1,27.5 88.7,29.2 C92.4,30.9 96.2,32.2 96.2,35.3 C96.1,38.3 93.5,40.5 90.6,40.4 C87.4,40.4 84.2,38.5 83,38 C82.4,37.7 82.1,38.4 81.6,39.4 C81,40.7 80.6,41.6 81,41.9 C83.9,43.6 87.1,44.5 90.4,44.6 C96.3,44.6 100.9,41 100.9,35.2 C101.1,29 95.6,27.2 92.8,25.9"
            id="Path_171425"
          ></path>
          <path
            d="M128.7,38.7 C127.4,39.4 125.9,39.8 124.4,39.8 C119.8,39.8 118.8,36.2 118.8,33.2 L118.8,16.7 C118.8,16 119.1,15.9 119.9,15.9 L128.7,15.9 C129,15.9 129,15.3 129,13.8 C129,12.3 129.1,11.6 128.5,11.6 L119.9,11.6 C119.1,11.6 118.8,11.4 118.8,10.3 L118.8,2.5 C118.8,1.3 118.7,1.3 116.7,1.9 C115.2,2.4 114.5,2.9 114.5,3.4 L114.5,10.2 C114.5,11 114.3,11.6 113.4,11.6 L110,11.6 C109.2,11.6 109.4,12.5 109.5,13.7 C109.5,15 109.5,15.9 110,15.9 L113.4,15.9 C114,16 114.4,16 114.4,16.6 L114.4,30.4 C114.4,38.3 115.5,40 117.3,41.8 C118.9,43.3 121,44.2 123.2,44.1 C125.2,44 127.2,43.5 129.1,42.7 C129.9,42.3 130,42.1 129.7,40.3 C129.4,38.5 129.2,38.5 128.7,38.7"
            id="Path_171426"
          ></path>
          <path
            d="M195.7,1.1 C195.7,0.4 195.6,5.68434189e-14 194.9,5.68434189e-14 L187.9,0.1 C187.4,0.1 187.5,0.5 187.5,2 C187.5,3.1 187.5,3.8 187.9,3.8 C190.6,3.8 191.5,5 191.5,6.8 L191.5,43.2 C191.5,44.2 191.3,44.2 193.5,44.2 C195.7,44.2 195.7,44.3 195.7,43.2 L195.7,1.1 L195.7,1.1 Z"
            id="Path_171427"
          ></path>
          <path
            d="M239.6,12.6 C236.3,12.6 233.2,14 231.1,16.6 C230.4,17.4 230.2,17.7 229.8,17.7 C229.4,17.7 229.4,17.3 229,16.7 C227.8,14.2 225.3,12.6 222.6,12.7 C219.3,12.8 216.2,14.3 214,16.8 C213.7,17.1 213.1,17.3 213.1,16.7 L213.1,14.1 C213.1,13.1 212.8,13.1 211.2,13.1 C209.6,13.1 209.1,13.1 209.1,14.1 L209.1,43.5 C209.1,44.3 209.6,44.3 211.2,44.3 C212.5,44.3 213.3,44.3 213.3,43.5 L213.3,28.8 C213.3,21.6 216.7,16.9 221.1,16.9 C225.5,16.9 226.3,20.8 226.4,23.9 L226.4,43.6 C226.4,44.5 227.2,44.2 228.4,44.2 C229.6,44.2 230.5,44.4 230.5,43.6 L230.5,28.8 C230.5,21.6 233.8,16.9 238.8,16.9 C242.6,16.9 243.7,20 243.7,23.1 L243.7,43.3 C243.7,44.3 244,44.3 245.7,44.3 C247.4,44.3 247.9,44.2 247.9,43.3 L247.9,22 C247.7,16 244.3,12.6 239.6,12.6"
            id="Path_171428"
          ></path>
          <path
            d="M35,12.1 C33.3,12.1 32.8,12 32.5,12.7 C32.4,12.9 28,35.3 28,35.3 C27.8,36 27.7,36.2 27.4,36.2 C27,36.2 26.9,36 26.7,35.4 L20.4,12.9 C20.2,12.2 20,12 18.4,12 C16.8,12 16.5,12.2 16.4,12.9 L10.3,35.4 C10.1,36 10,36.2 9.6,36.2 C9.3,36.2 9.1,35.5 8.9,34.9 L4.3,12.5 C4,11.7 3.6,11.9 2,11.9 C0.4,11.9 0,12 0,12.9 C0,13.3 6.6,41.6 6.9,42.8 C7.2,44.1 7.7,44.5 9.5,44.5 C11.6,44.5 11.9,43.6 12.2,42.8 L17.9,22.5 C18.1,21.9 18.1,21.6 18.5,21.6 C18.9,21.6 18.9,21.9 19.1,22.5 L24.8,42.8 C25.1,43.6 25.4,44.5 27.5,44.5 C29.3,44.5 29.7,44.1 30.1,42.8 C30.4,41.6 36.9,13.1 36.9,12.7 C36.8,12 36.2,12.1 35,12.1"
            id="Path_171429"
          ></path>
          <path
            d="M160,28 L175.4,28 C176.5,28.2 177.6,27.6 178,26.5 C178.6,24.7 178.7,22.8 178.5,20.9 C178,13.3 171.7,11.1 167.7,11.1 C159,11.1 154.1,18.4 154.1,28.1 C154.1,33.6 155.2,36.4 157.4,39.7 C159.7,43.1 164,44.3 167.5,44.3 C171,44.3 176.8,42.2 178.1,40.8 C178.4,40.1 178.4,39.4 178.2,38.7 C178,37.9 177.7,36.8 177.2,37 C176.2,37.4 173.4,39.8 168.1,39.9 C159.2,39.9 159,31.3 158.9,29.6 C158.9,28 159.2,28 160,28 M167.5,15.1 C170.4,15.1 174.3,17.4 174.3,21.2 C174.3,23.8 174,24.2 173.3,24.2 L160.1,24.2 C159.3,24.2 159.3,23.8 159.3,23.1 C159.6,18.7 163.1,15.2 167.5,15.1"
            id="Path_171430"
          ></path>
        </g>
      </g>
    </g>
  </svg>
  <svg
    v-else-if="isWebEnabled"
    width="300px"
    height="46px"
    viewBox="0 0 300 46"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    :id="`logo-web`"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>WE business to business</title>
    <g
      id="PIP-Sticky-content"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="WE-Business_stacked_300x46px" fill-rule="nonzero">
        <g id="business-to-business" transform="translate(0.000000, 21.000000)">
          <g
            id="business"
            transform="translate(177.120536, 0.000000)"
            fill="#8C774B"
          >
            <path
              d="M3.515625,22 L3.515625,22 L3.515625,24.3333333 L0,24.3333333 L0,0 L3.515625,0 L3.515625,10.6666667 L3.515625,10.6666667 C4.52008929,8.83333333 6.36160714,7.83333333 8.87276786,7.83333333 C13.2254464,7.83333333 16.7410714,11 16.7410714,16.3333333 C16.7410714,21.6666667 13.0580357,24.8333333 8.87276786,24.8333333 C6.19419643,25 4.35267857,23.8333333 3.515625,22 Z M13.2254464,16.3333333 C13.2254464,13.3333333 11.2165179,11.1666667 8.37053571,11.1666667 C5.52455357,11.1666667 3.515625,13.3333333 3.515625,16.3333333 C3.515625,19.3333333 5.52455357,21.5 8.37053571,21.5 C11.2165179,21.5 13.2254464,19.5 13.2254464,16.3333333 Z"
              id="Shape"
            ></path>
            <path
              d="M19.7544643,18 L19.7544643,8.33333333 L23.2700893,8.33333333 L23.2700893,17.6666667 C23.2700893,20.3333333 24.609375,21.6666667 26.6183036,21.6666667 C28.9620536,21.6666667 30.6361607,20.1666667 30.6361607,16 L30.6361607,8.33333333 L34.1517857,8.33333333 L34.1517857,24.5 L30.6361607,24.5 L30.6361607,22 L30.6361607,22 C29.7991071,23.6666667 28.2924107,25 25.78125,25 C22.6004464,25 19.7544643,23 19.7544643,18 L19.7544643,18 L19.7544643,18 Z"
              id="Path"
            ></path>
            <path
              d="M36.8303571,23.1666667 L38.3370536,20.1666667 C39.84375,21 41.5178571,21.6666667 43.5267857,21.6666667 C45.2008929,21.6666667 46.0379464,21.1666667 46.0379464,20.1666667 C46.0379464,19.5 45.703125,19 44.3638393,18.3333333 L41.3504464,16.8333333 C38.8392857,15.6666667 38.0022321,14 38.0022321,12.1666667 C38.0022321,9.16666667 40.1785714,7.5 43.6941964,7.5 C45.5357143,7.5 47.5446429,8 49.3861607,8.83333333 L48.046875,11.8333333 C46.875,11.3333333 45.3683036,10.8333333 43.6941964,10.8333333 C42.0200893,10.8333333 41.6852679,11.3333333 41.6852679,12 C41.6852679,12.8333333 42.1875,13.3333333 43.5267857,14 L46.5401786,15.5 C48.7165179,16.5 49.7209821,17.8333333 49.7209821,20 C49.7209821,23.3333333 47.2098214,24.8333333 43.359375,24.8333333 C41.015625,24.8333333 38.671875,24.3333333 36.8303571,23.1666667 L36.8303571,23.1666667 L36.8303571,23.1666667 Z"
              id="Path"
            ></path>
            <path
              d="M56.4174107,4.5 L52.2321429,4.5 L52.2321429,0.333333333 L56.4174107,0.333333333 L56.4174107,4.5 Z M52.734375,8.33333333 L56.25,8.33333333 L56.25,24.5 L52.734375,24.5 L52.734375,8.33333333 Z"
              id="Shape"
            ></path>
            <path
              d="M75.3348214,14.5 L75.3348214,24.3333333 L71.8191964,24.3333333 L71.8191964,15 C71.8191964,12.1666667 70.4799107,11 68.1361607,11 C65.625,11 63.7834821,12.5 63.7834821,16.5 L63.7834821,24.1666667 L60.4352679,24.1666667 L60.4352679,8.16666667 L63.9508929,8.16666667 L63.9508929,10.6666667 L63.9508929,10.6666667 C64.9553571,8.83333333 66.796875,7.66666667 69.140625,7.66666667 C72.65625,7.83333333 75.3348214,9.66666667 75.3348214,14.5 L75.3348214,14.5 L75.3348214,14.5 Z"
              id="Path"
            ></path>
            <path
              d="M94.2522321,16.3333333 L94.2522321,16.8333333 L81.6964286,16.8333333 C81.8638393,19.6666667 83.8727679,21.6666667 86.5513393,21.6666667 C89.2299107,21.6666667 90.7366071,19.8333333 91.40625,18.8333333 L93.75,21.1666667 C92.578125,22.8333333 90.4017857,25 86.3839286,25 C81.1941964,25 77.8459821,21.5 77.8459821,16.5 C77.8459821,11.5 80.859375,7.83333333 86.0491071,7.83333333 C91.2388393,7.83333333 94.2522321,11.1666667 94.2522321,16.3333333 L94.2522321,16.3333333 L94.2522321,16.3333333 Z M82.03125,14.5 L90.7366071,14.5 C90.5691964,12.3333333 88.8950893,11 86.5513393,11 C84.0401786,11 82.3660714,12.1666667 82.03125,14.5 L82.03125,14.5 Z"
              id="Shape"
            ></path>
            <path
              d="M95.9263393,23.1666667 L97.4330357,20.1666667 C98.9397321,21 100.613839,21.6666667 102.622768,21.6666667 C104.296875,21.6666667 105.133929,21.1666667 105.133929,20.1666667 C105.133929,19.5 104.799107,19 103.459821,18.3333333 L100.446429,16.8333333 C97.9352679,15.6666667 97.0982143,14 97.0982143,12.1666667 C97.0982143,9.16666667 99.2745536,7.5 102.790179,7.5 C104.631696,7.5 106.640625,8 108.482143,8.83333333 L107.142857,11.8333333 C105.970982,11.3333333 104.464286,10.8333333 102.790179,10.8333333 C101.116071,10.8333333 100.78125,11.3333333 100.78125,12 C100.78125,12.8333333 101.283482,13.3333333 102.622768,14 L105.636161,15.5 C107.8125,16.5 108.816964,17.8333333 108.816964,20 C108.816964,23.3333333 106.305804,24.8333333 102.455357,24.8333333 C100.111607,24.8333333 97.7678571,24.3333333 95.9263393,23.1666667 L95.9263393,23.1666667 L95.9263393,23.1666667 Z"
              id="Path"
            ></path>
            <path
              d="M109.988839,23.1666667 L111.495536,20.1666667 C113.002232,21 114.676339,21.6666667 116.685268,21.6666667 C118.359375,21.6666667 119.196429,21.1666667 119.196429,20.1666667 C119.196429,19.5 118.861607,19 117.522321,18.3333333 L114.508929,16.8333333 C111.997768,15.6666667 111.160714,14 111.160714,12.1666667 C111.160714,9.16666667 113.337054,7.5 116.852679,7.5 C118.694196,7.5 120.703125,8 122.544643,8.83333333 L121.205357,11.8333333 C120.033482,11.3333333 118.526786,10.8333333 116.852679,10.8333333 C115.178571,10.8333333 114.84375,11.3333333 114.84375,12 C114.84375,12.8333333 115.345982,13.3333333 116.685268,14 L119.698661,15.5 C121.875,16.5 122.879464,17.8333333 122.879464,20 C122.879464,23.3333333 120.368304,24.8333333 116.517857,24.8333333 C114.341518,24.8333333 111.830357,24.3333333 109.988839,23.1666667 L109.988839,23.1666667 Z"
              id="Path"
            ></path>
          </g>
          <g id="to" transform="translate(135.435268, 2.085000)" fill="#8C764B">
            <path
              d="M9.70982143,9.5 L9.70982143,6.5 L6.36160714,6.5 L6.36160714,0 L2.84598214,0 L2.84598214,6.5 L0,6.5 L0,9.5 L2.84598214,9.5 L2.84598214,18.6666667 C2.84598214,21.3333333 4.35267857,22.8333333 7.36607143,22.8333333 L9.04017857,22.8333333 L9.04017857,19.6666667 L8.203125,19.6666667 C7.03125,19.6666667 6.52901786,19.1666667 6.52901786,18 L6.52901786,9.5 L9.70982143,9.5 L9.70982143,9.5 Z"
              id="Path"
            ></path>
            <path
              d="M20.0892857,5.5 C14.8995536,5.5 11.0491071,9.16666667 11.0491071,14.1666667 C11.0491071,19.1666667 14.8995536,22.8333333 20.0892857,22.8333333 C25.2790179,22.8333333 29.1294643,19.1666667 29.1294643,14.1666667 C29.1294643,9.16666667 25.1116071,5.5 20.0892857,5.5 Z M20.0892857,19.5 C16.9084821,19.5 14.7321429,17.3333333 14.7321429,14.1666667 C14.7321429,11 16.9084821,8.83333333 20.0892857,8.83333333 C23.2700893,8.83333333 25.4464286,11 25.4464286,14.1666667 C25.4464286,17.3333333 23.1026786,19.5 20.0892857,19.5 Z"
              id="Shape"
            ></path>
          </g>
          <g id="business" fill="#8C774B">
            <path
              d="M3.515625,22 L3.515625,22 L3.515625,24.3333333 L0,24.3333333 L0,0 L3.515625,0 L3.515625,10.6666667 L3.515625,10.6666667 C4.52008929,8.83333333 6.36160714,7.83333333 9.04017857,7.83333333 C13.3928571,7.83333333 16.9084821,11 16.9084821,16.3333333 C16.9084821,21.6666667 13.2254464,25 8.87276786,25 C6.19419643,25 4.52008929,23.8333333 3.515625,22 L3.515625,22 Z M13.2254464,16.3333333 C13.2254464,13.3333333 11.2165179,11.1666667 8.37053571,11.1666667 C5.52455357,11.1666667 3.34821429,13.3333333 3.34821429,16.3333333 C3.34821429,19.3333333 5.35714286,21.5 8.37053571,21.5 C11.3839286,21.5 13.2254464,19.5 13.2254464,16.3333333 Z"
              id="Shape"
            ></path>
            <path
              d="M19.7544643,18 L19.7544643,8.33333333 L23.2700893,8.33333333 L23.2700893,17.6666667 C23.2700893,20.3333333 24.609375,21.6666667 26.6183036,21.6666667 C28.9620536,21.6666667 30.6361607,20.1666667 30.6361607,16 L30.6361607,8.33333333 L34.1517857,8.33333333 L34.1517857,24.5 L30.6361607,24.5 L30.6361607,22 L30.6361607,22 C29.7991071,23.6666667 28.2924107,25 25.78125,25 C22.6004464,25 19.921875,23 19.7544643,18 L19.7544643,18 L19.7544643,18 Z"
              id="Path"
            ></path>
            <path
              d="M36.8303571,23.1666667 L38.3370536,20.1666667 C39.84375,21 41.5178571,21.6666667 43.5267857,21.6666667 C45.2008929,21.6666667 46.0379464,21.1666667 46.0379464,20.1666667 C46.0379464,19.5 45.703125,19 44.3638393,18.3333333 L41.3504464,16.8333333 C38.8392857,15.6666667 38.0022321,14 38.0022321,12.1666667 C38.0022321,9.16666667 40.1785714,7.5 43.6941964,7.5 C45.5357143,7.5 47.5446429,8 49.3861607,8.83333333 L48.046875,11.8333333 C46.875,11.3333333 45.3683036,10.8333333 43.6941964,10.8333333 C42.0200893,10.8333333 41.6852679,11.3333333 41.6852679,12.1666667 C41.6852679,13 42.1875,13.5 43.5267857,14.1666667 L46.5401786,15.6666667 C48.7165179,16.6666667 49.7209821,18 49.7209821,20.1666667 C49.7209821,23.5 47.2098214,25 43.359375,25 C41.1830357,24.8333333 38.671875,24.3333333 36.8303571,23.1666667 L36.8303571,23.1666667 L36.8303571,23.1666667 Z"
              id="Path"
            ></path>
            <path
              d="M56.5848214,4.5 L52.3995536,4.5 L52.3995536,0.333333333 L56.5848214,0.333333333 L56.5848214,4.5 Z M52.734375,8.33333333 L56.25,8.33333333 L56.25,24.5 L52.734375,24.5 L52.734375,8.33333333 Z"
              id="Shape"
            ></path>
            <path
              d="M75.3348214,14.5 L75.3348214,24.3333333 L71.8191964,24.3333333 L71.8191964,15 C71.8191964,12.1666667 70.4799107,11 68.1361607,11 C65.625,11 63.7834821,12.5 63.7834821,16.5 L63.7834821,24.1666667 L60.2678571,24.1666667 L60.2678571,8.16666667 L63.7834821,8.16666667 L63.7834821,10.6666667 L63.7834821,10.6666667 C64.7879464,8.83333333 66.6294643,7.66666667 69.140625,7.66666667 C72.65625,7.83333333 75.3348214,9.66666667 75.3348214,14.5 L75.3348214,14.5 L75.3348214,14.5 Z"
              id="Path"
            ></path>
            <path
              d="M94.4196429,16.3333333 L94.4196429,16.8333333 L81.8638393,16.8333333 C82.03125,19.6666667 84.0401786,21.6666667 86.71875,21.6666667 C89.3973214,21.6666667 90.9040179,19.8333333 91.7410714,18.8333333 L94.0848214,21.1666667 C92.9129464,22.8333333 90.7366071,25 86.71875,25 C81.5290179,25 78.3482143,21.5 78.3482143,16.5 C78.3482143,11.5 81.3616071,7.83333333 86.71875,7.83333333 C92.0758929,7.83333333 94.4196429,11.1666667 94.4196429,16.3333333 L94.4196429,16.3333333 L94.4196429,16.3333333 Z M82.03125,14.5 L90.7366071,14.5 C90.5691964,12.3333333 88.8950893,11 86.5513393,11 C84.0401786,11 82.3660714,12.1666667 82.03125,14.5 Z"
              id="Shape"
            ></path>
            <path
              d="M95.9263393,23.1666667 L97.4330357,20.1666667 C98.9397321,21 100.613839,21.6666667 102.622768,21.6666667 C104.296875,21.6666667 105.133929,21.1666667 105.133929,20.1666667 C105.133929,19.5 104.799107,19 103.459821,18.3333333 L100.446429,16.8333333 C97.9352679,15.6666667 97.0982143,14 97.0982143,12.1666667 C97.0982143,9.16666667 99.2745536,7.5 102.790179,7.5 C104.631696,7.5 106.640625,8 108.314732,8.83333333 L106.975446,12 C105.803571,11.5 104.296875,11 102.622768,11 C100.948661,11 100.613839,11.5 100.613839,12.1666667 C100.613839,13 101.116071,13.5 102.455357,14.1666667 L105.46875,15.6666667 C107.645089,16.6666667 108.649554,18 108.649554,20.1666667 C108.649554,23.5 106.138393,25 102.287946,25 C100.279018,24.8333333 97.7678571,24.3333333 95.9263393,23.1666667 L95.9263393,23.1666667 L95.9263393,23.1666667 Z"
              id="Path"
            ></path>
            <path
              d="M109.988839,23.1666667 L111.495536,20.1666667 C113.002232,21 114.676339,21.6666667 116.685268,21.6666667 C118.359375,21.6666667 119.196429,21.1666667 119.196429,20.1666667 C119.196429,19.5 118.861607,19 117.522321,18.3333333 L114.508929,16.8333333 C111.997768,15.6666667 111.160714,14 111.160714,12.1666667 C111.160714,9.16666667 113.337054,7.5 116.852679,7.5 C118.694196,7.5 120.703125,8 122.377232,8.83333333 L121.037946,11.8333333 C119.866071,11.3333333 118.359375,10.8333333 116.685268,10.8333333 C115.011161,10.8333333 114.676339,11.3333333 114.676339,12 C114.676339,12.8333333 115.178571,13.3333333 116.517857,14 L119.53125,15.5 C121.707589,16.5 122.712054,17.8333333 122.712054,20 C122.712054,23.3333333 120.200893,24.8333333 116.350446,24.8333333 C114.341518,24.8333333 111.830357,24.3333333 109.988839,23.1666667 L109.988839,23.1666667 L109.988839,23.1666667 Z"
              id="Path"
            ></path>
          </g>
        </g>
        <g id="west-elm" fill="#212120">
          <path
            d="M14.4589305,8.02469136 L18.9078322,8.02469136 C19.2256109,8.02469136 19.5433896,7.86419753 19.7022789,7.54320988 C19.8611683,7.0617284 19.8611683,6.41975309 19.8611683,5.9382716 C19.7022789,3.69135802 17.9544961,3.04938272 16.6833814,3.04938272 C14.1411518,3.04938272 12.7111477,5.13580247 12.7111477,8.02469136 C12.7111477,9.62962963 13.0289264,10.4320988 13.6644838,11.3950617 C14.3000412,12.3580247 15.5711559,12.6790123 16.524492,12.6790123 C17.4778281,12.6790123 19.2256109,12.037037 19.5433896,11.7160494 C19.7022789,11.5555556 19.7022789,11.3950617 19.5433896,11.0740741 C19.5433896,10.9135802 19.3845002,10.5925926 19.2256109,10.5925926 C18.9078322,10.7530864 18.1133855,11.3950617 16.6833814,11.3950617 C14.1411518,11.3950617 14.1411518,8.82716049 13.9822625,8.34567901 C14.1411518,8.02469136 14.1411518,8.02469136 14.4589305,8.02469136 M16.524492,4.33333333 C17.3189387,4.33333333 18.4311642,4.97530864 18.4311642,6.09876543 C18.4311642,6.90123457 18.2722748,6.90123457 18.1133855,6.90123457 L14.3000412,6.90123457 C13.9822625,6.90123457 13.9822625,6.74074074 13.9822625,6.58024691 C14.3000412,5.2962963 15.2533772,4.33333333 16.524492,4.33333333"
            id="Path_171424"
          ></path>
          <path
            d="M26.5345208,7.54320988 C25.7400741,7.22222222 24.6278487,6.90123457 24.6278487,5.77777778 C24.6278487,4.81481481 25.5811847,4.33333333 26.0578528,4.33333333 C26.5345208,4.33333333 27.0111889,4.49382716 27.4878569,4.65432099 C27.9645249,4.81481481 27.9645249,4.65432099 28.2823036,4.33333333 C28.441193,4.01234568 28.441193,3.85185185 28.441193,3.85185185 C28.1234143,3.5308642 27.1700782,3.20987654 26.2167421,3.20987654 C24.31007,3.20987654 23.3567339,4.49382716 23.3567339,5.77777778 C23.3567339,7.0617284 24.4689593,7.86419753 25.5811847,8.50617284 C26.6934102,8.98765432 27.8056356,9.30864198 27.8056356,10.2716049 C27.8056356,11.0740741 27.0111889,11.7160494 26.2167421,11.7160494 C25.263406,11.7160494 24.31007,11.2345679 23.9922913,11.0740741 C23.8334019,11.0740741 23.6745126,11.2345679 23.6745126,11.3950617 C23.5156232,11.7160494 23.3567339,12.037037 23.5156232,12.1975309 C24.31007,12.6790123 25.263406,13 26.2167421,13 C27.9645249,13 29.2356397,12.037037 29.2356397,10.2716049 C28.917861,8.34567901 27.3289676,7.86419753 26.5345208,7.54320988"
            id="Path_171425"
          ></path>
          <path
            d="M36.8623283,11.2345679 C36.5445496,11.3950617 36.0678816,11.5555556 35.5912136,11.5555556 C34.3200988,11.5555556 34.0023201,10.5925926 34.0023201,9.62962963 L34.0023201,4.81481481 C34.0023201,4.65432099 34.0023201,4.49382716 34.3200988,4.49382716 L36.8623283,4.49382716 C36.8623283,4.49382716 36.8623283,4.33333333 36.8623283,3.85185185 C36.8623283,3.37037037 36.8623283,3.20987654 36.703439,3.20987654 L34.1612094,3.20987654 C34.0023201,3.20987654 33.8434307,3.20987654 33.8434307,2.88888889 L33.8434307,0.641975309 C33.8434307,0.320987654 33.8434307,0.320987654 33.2078734,0.481481481 C32.7312053,0.641975309 32.572316,0.802469136 32.572316,0.962962963 L32.572316,2.88888889 C32.572316,3.04938272 32.572316,3.20987654 32.2545373,3.20987654 L31.3012012,3.20987654 C31.1423119,3.20987654 31.1423119,3.5308642 31.1423119,3.85185185 C31.1423119,4.17283951 31.1423119,4.49382716 31.3012012,4.49382716 L32.2545373,4.49382716 C32.4134266,4.49382716 32.572316,4.49382716 32.572316,4.65432099 L32.572316,8.66666667 C32.572316,10.9135802 32.8900947,11.3950617 33.3667627,12.037037 C33.8434307,12.5185185 34.4789881,12.6790123 35.1145455,12.6790123 C35.7501029,12.6790123 36.2267709,12.5185185 36.8623283,12.3580247 C37.0212177,12.1975309 37.180107,12.1975309 37.0212177,11.7160494 C37.180107,11.0740741 37.0212177,11.0740741 36.8623283,11.2345679"
            id="Path_171426"
          ></path>
          <path
            d="M56.2468286,0.320987654 C56.2468286,0.160493827 56.2468286,0 55.9290499,0 L53.8634884,0 C53.704599,0 53.704599,0.160493827 53.704599,0.481481481 C53.704599,0.802469136 53.704599,0.962962963 53.8634884,0.962962963 C54.6579351,0.962962963 54.8168244,1.28395062 54.8168244,1.7654321 L54.8168244,12.3580247 C54.8168244,12.6790123 54.8168244,12.6790123 55.4523818,12.6790123 C56.0879392,12.6790123 56.0879392,12.6790123 56.0879392,12.3580247 L56.2468286,0.320987654 L56.2468286,0.320987654 L56.2468286,0.320987654 Z"
            id="Path_171427"
          ></path>
          <path
            d="M68.9579763,3.5308642 C68.0046402,3.5308642 67.0513041,3.85185185 66.5746361,4.65432099 C66.4157467,4.81481481 66.2568574,4.97530864 66.2568574,4.97530864 C66.2568574,4.97530864 66.097968,4.81481481 65.9390787,4.65432099 C65.6213,4.01234568 64.8268533,3.5308642 64.0324065,3.5308642 C63.0790705,3.5308642 62.1257344,4.01234568 61.490177,4.65432099 C61.3312876,4.65432099 61.1723983,4.81481481 61.1723983,4.65432099 L61.1723983,4.01234568 C61.1723983,3.69135802 61.1723983,3.69135802 60.6957303,3.69135802 C60.2190622,3.69135802 60.0601729,3.69135802 60.0601729,4.01234568 L60.0601729,12.5185185 C60.0601729,12.6790123 60.2190622,12.6790123 60.6957303,12.6790123 C61.0135089,12.6790123 61.3312876,12.6790123 61.3312876,12.5185185 L61.3312876,8.18518519 C61.3312876,6.09876543 62.2846237,4.65432099 63.5557385,4.65432099 C64.8268533,4.65432099 64.9857426,5.77777778 65.144632,6.74074074 L65.144632,12.5185185 C65.144632,12.8395062 65.4624106,12.6790123 65.7801893,12.6790123 C66.097968,12.6790123 66.4157467,12.6790123 66.4157467,12.5185185 L66.4157467,8.18518519 C66.4157467,6.09876543 67.3690828,4.65432099 68.7990869,4.65432099 C69.9113123,4.65432099 70.229091,5.61728395 70.229091,6.41975309 L70.229091,12.3580247 C70.229091,12.6790123 70.3879804,12.6790123 70.8646484,12.6790123 C71.3413165,12.6790123 71.5002058,12.6790123 71.5002058,12.3580247 L71.5002058,6.09876543 C71.1824271,4.65432099 70.229091,3.5308642 68.9579763,3.5308642"
            id="Path_171428"
          ></path>
          <path
            d="M9.85113947,3.5308642 C9.37447143,3.5308642 9.21558208,3.5308642 9.21558208,3.69135802 L7.94446731,10.2716049 C7.94446731,10.4320988 7.94446731,10.5925926 7.78557796,10.5925926 C7.62668862,10.5925926 7.62668862,10.5925926 7.62668862,10.4320988 L5.87890581,3.85185185 C5.87890581,3.69135802 5.72001646,3.5308642 5.24334843,3.5308642 C4.76668039,3.5308642 4.76668039,3.5308642 4.60779104,3.85185185 L2.86000823,10.4320988 C2.86000823,10.5925926 2.70111889,10.5925926 2.70111889,10.5925926 C2.54222954,10.5925926 2.54222954,10.4320988 2.54222954,10.2716049 L1.27111477,3.69135802 C1.11222542,3.5308642 1.11222542,3.5308642 0.635557385,3.5308642 C0.158889346,3.5308642 0,3.5308642 0,3.85185185 C0,4.01234568 1.90667215,12.1975309 1.90667215,12.5185185 C1.90667215,12.8395062 2.0655615,13 2.70111889,13 C3.33667627,13 3.33667627,12.6790123 3.49556562,12.5185185 L5.08445908,6.58024691 C5.08445908,6.41975309 5.08445908,6.25925926 5.24334843,6.25925926 C5.40223777,6.25925926 5.40223777,6.25925926 5.40223777,6.58024691 L6.99113123,12.5185185 C7.15002058,12.6790123 7.15002058,13 7.78557796,13 C8.262246,13 8.42113535,12.8395062 8.5800247,12.5185185 C8.73891404,12.1975309 10.4866969,3.85185185 10.4866969,3.69135802 C10.3278075,3.37037037 10.1689182,3.5308642 9.85113947,3.5308642"
            id="Path_171429"
          ></path>
          <path
            d="M45.9190211,8.02469136 L50.3679228,8.02469136 C50.6857014,8.02469136 51.0034801,7.86419753 51.1623695,7.54320988 C51.3212588,7.0617284 51.3212588,6.41975309 51.3212588,5.9382716 C51.1623695,3.69135802 49.4145867,3.04938272 48.1434719,3.04938272 C45.6012424,3.04938272 44.1712383,5.13580247 44.1712383,8.02469136 C44.1712383,9.62962963 44.4890169,10.4320988 45.1245743,11.3950617 C45.7601317,12.3580247 47.0312465,12.6790123 47.9845826,12.6790123 C48.9379186,12.6790123 50.6857014,12.037037 51.0034801,11.7160494 C51.1623695,11.5555556 51.1623695,11.3950617 51.0034801,11.0740741 C51.0034801,10.9135802 50.8445908,10.5925926 50.6857014,10.5925926 C50.3679228,10.7530864 49.573476,11.3950617 48.1434719,11.3950617 C45.6012424,11.3950617 45.6012424,8.82716049 45.442353,8.34567901 C45.6012424,8.02469136 45.7601317,8.02469136 45.9190211,8.02469136 M48.1434719,4.33333333 C48.9379186,4.33333333 50.0501441,4.97530864 50.0501441,6.09876543 C50.0501441,6.90123457 50.0501441,6.90123457 49.7323654,6.90123457 L45.9190211,6.90123457 C45.7601317,6.90123457 45.7601317,6.74074074 45.7601317,6.58024691 C45.7601317,5.2962963 46.8723571,4.33333333 48.1434719,4.33333333"
            id="Path_171430"
          ></path>
        </g>
      </g>
    </g>
  </svg>
  <svg
    v-else
    width="190px"
    height="34px"
    viewBox="0 0 385 69"
    version="1.1"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    data-component="Global-brand_logo-WELogo"
    :id="`logo-we`"
  >
    <title>West Elm</title>
    <desc>
      West Elm offers modern furniture and home decor featuring inspiring
      designs and colors.
    </desc>
    <defs></defs>
    <g
      class="west-elm__logo-outer"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g class="west-elm__logo" fill="#565458">
        <g class="west-elm__logo-inner">
          <path
            d="M371.8148,19.4978 C364.8178,19.5228 361.0758,22.9968 358.6098,25.6918 C357.5108,26.8938 357.2048,27.4498 356.6688,27.4278 C356.1328,27.4058 356.0068,26.8248 355.5038,25.8128 C354.9618,24.8318 352.6128,19.6708 345.5178,19.6708 C338.6058,19.6708 334.4318,23.7418 332.1598,26.0138 C331.6688,26.5048 330.7378,26.8378 330.7378,25.7858 L330.7378,21.8148 C330.7378,20.2208 330.3158,20.2208 327.7138,20.2308 C325.1838,20.2388 324.3938,20.2338 324.3978,21.8358 L324.3978,67.4848 C324.4038,68.7228 325.1888,68.6938 327.6778,68.6938 C329.6918,68.6938 330.8968,68.7588 330.8968,67.4848 L330.8968,44.7018 C330.8968,33.4868 336.1978,26.2378 342.9718,26.2148 C349.8498,26.1898 351.1028,32.3428 351.1208,37.1348 L351.1208,67.7578 C351.1208,69.1088 352.3128,68.6938 354.2088,68.6938 C356.1428,68.6938 357.4658,69.0668 357.4658,67.7578 L357.4658,44.7018 C357.4658,33.4868 362.5578,26.2438 370.4188,26.2148 C376.3148,26.1938 377.9758,31.0178 377.9938,35.7848 L377.9938,67.1848 C377.9938,68.7588 378.4718,68.6978 381.0588,68.6978 C383.6458,68.6978 384.4228,68.5458 384.4228,67.1848 L384.4228,34.0848 C384.3908,24.8628 379.0888,19.4728 371.8148,19.4978"
            class="west-elm__logo-letter-w"
          ></path>
          <path
            d="M303.7386,1.7078 C303.7386,0.6488 303.5866,-0.0002 302.4886,-0.0002 L291.6456,0.1828 C290.8496,0.1828 290.9726,0.8498 290.9726,3.1158 C290.9726,4.7848 290.9086,5.8518 291.6456,5.8518 C295.8476,5.8518 297.1596,7.7258 297.1596,10.4578 L297.1596,66.8798 C297.1656,68.3618 296.8706,68.4108 300.3226,68.3968 C303.8856,68.3858 303.7446,68.5348 303.7386,66.8548 L303.7386,1.7078 Z"
            class="west-elm__logo-letter-e"
          ></path>
          <path
            d="M90.349,23.4861 C94.786,23.4861 100.9,27.1031 100.919,32.8941 C100.935,36.9661 100.494,37.5201 99.422,37.5201 C99.006,37.5201 80.726,37.5821 78.958,37.5821 C77.768,37.5821 77.674,36.9341 77.674,35.8111 C77.674,30.9221 82.258,23.4861 90.349,23.4861 M78.614,43.4081 L102.541,43.3331 C104.461,43.3261 105.955,42.7391 106.545,41.0641 C107.183,38.7671 107.699,36.8341 107.405,32.3961 C106.657,20.6111 96.79,17.2071 90.635,17.2071 C77.199,17.2071 69.574,28.5071 69.574,43.6601 C69.574,52.1561 71.215,56.4881 74.749,61.7051 C78.313,66.9201 84.96,68.8911 90.411,68.8721 C95.83,68.8521 104.891,65.5941 106.798,63.3681 C107.161,62.9441 107.198,61.5961 106.909,60.1681 C106.661,58.9471 106.18,57.2551 105.303,57.5901 C103.826,58.1541 99.351,62.0121 91.243,62.0411 C77.353,62.0911 77.142,48.7351 77.031,46.0621 C76.924,43.4601 77.368,43.4121 78.614,43.4081"
            class="west-elm__logo-letter-s"
          ></path>
          <path
            d="M199.6927,60.0242 C198.2097,60.6092 196.8737,61.6432 192.9777,61.6562 C185.8687,61.6812 184.2837,56.1052 184.2667,51.4242 L184.2667,25.9182 C184.3067,24.8682 184.6607,24.6582 186.0087,24.6582 L199.6467,24.6192 C200.1847,24.6192 200.0857,23.6852 200.0857,21.3742 C200.0857,19.0642 200.2077,18.0142 199.3467,18.0142 L186.0087,18.0482 C184.8107,18.0482 184.2667,17.6722 184.2667,15.9642 L184.2667,3.9512 C184.2667,2.0892 184.0807,2.0502 180.9797,2.9942 C177.5887,4.0272 177.5887,4.0272 177.5887,5.3622 L177.5887,15.9642 C177.5887,17.2272 177.3037,18.0972 175.8187,18.0972 L170.7137,18.1052 C169.4737,18.1052 169.8577,19.5302 169.8637,21.3742 C169.8717,23.3262 169.8347,24.7152 170.7137,24.7152 L176.0297,24.6582 C177.0217,24.7532 177.5887,24.8002 177.5887,25.8092 L177.5887,47.2522 C177.6327,59.5082 179.2867,62.2122 182.0347,65.0162 C184.7737,67.8662 188.5457,68.6452 191.2337,68.6352 C193.9637,68.6252 197.8447,67.5332 200.3867,66.3942 C201.6877,65.8112 201.8227,65.3942 201.3237,62.6412 C200.8097,59.8102 200.4897,59.7092 199.6927,60.0242"
            class="west-elm__logo-letter-t"
          ></path>
          <path
            d="M144.0462,40.1712 C139.6982,38.1642 133.6222,36.9072 133.6222,30.9542 C133.6222,25.8852 138.4092,23.4322 141.7252,23.4322 C143.8342,23.4322 146.6552,24.0942 149.5152,25.2382 C152.3922,26.4422 152.3032,25.4252 153.4552,23.5242 C154.2782,22.1662 154.5062,20.8762 154.1352,20.5622 C152.3262,19.0352 147.4002,17.0622 141.7422,17.0812 C131.4782,17.1172 126.1382,23.6242 126.1652,30.9542 C126.1902,38.0302 132.1622,42.4902 137.7872,45.0882 C143.5582,47.7092 149.4152,49.6972 149.4322,54.5822 C149.4432,57.8142 146.6322,62.4962 140.7062,62.4962 C135.7602,62.4962 130.7202,59.5652 128.9872,58.7362 C128.0202,58.2742 127.6262,59.2942 126.8862,60.8482 C125.9492,62.8172 125.2812,64.2842 126.0202,64.7342 C129.5712,66.9002 135.6202,69.0152 140.6332,68.9982 C149.8442,68.9652 156.9872,63.3862 156.9562,54.4102 C156.9242,45.0212 148.3602,42.2422 144.0462,40.1712"
            class="west-elm__logo-letter-e"
          ></path>
          <path
            d="M259.9456,23.4861 C264.3826,23.4861 270.4966,27.1031 270.5156,32.8941 C270.5316,36.9661 270.0906,37.5201 269.0186,37.5201 C268.6026,37.5201 250.3226,37.5821 248.5546,37.5821 C247.3646,37.5821 247.2706,36.9341 247.2706,35.8111 C247.2706,30.9221 251.8546,23.4861 259.9456,23.4861 M248.2106,43.4081 L272.1376,43.3331 C274.0576,43.3261 275.5516,42.7391 276.1406,41.0641 C276.7796,38.7671 277.2956,36.8341 277.0006,32.3961 C276.2536,20.6111 266.3866,17.2071 260.2316,17.2071 C246.7956,17.2071 239.1696,28.5071 239.1696,43.6601 C239.1696,52.1561 240.8116,56.4881 244.3456,61.7051 C247.9096,66.9201 254.5566,68.8911 260.0076,68.8721 C265.4266,68.8521 274.4876,65.5941 276.3946,63.3681 C276.7576,62.9441 276.7946,61.5961 276.5056,60.1681 C276.2576,58.9471 275.7766,57.2551 274.8986,57.5901 C273.4226,58.1541 268.9476,62.0121 260.8396,62.0411 C246.9496,62.0911 246.7386,48.7351 246.6266,46.0621 C246.5206,43.4601 246.9646,43.4121 248.2106,43.4081"
            class="west-elm__logo-letter-l"
          ></path>
          <path
            d="M54.2894,18.7673 C51.6144,18.7673 50.8984,18.5543 50.4224,19.6233 C50.2634,19.9803 43.4834,54.7283 43.4834,54.7283 C43.2164,55.7573 43.0184,56.2023 42.4894,56.2023 C41.8964,56.2023 41.6484,55.9193 41.4524,55.0223 L31.7404,20.0703 C31.4934,19.0593 31.0674,18.6863 28.6924,18.6863 C26.3174,18.6863 25.7944,18.9323 25.5164,20.0703 L16.0884,55.0283 C15.8344,55.9113 15.5634,56.2023 14.9724,56.2023 C14.4424,56.2023 14.2104,55.1743 13.9414,54.1453 L6.6394,19.4513 C6.2244,18.1413 5.5714,18.4673 3.1334,18.4673 C0.7234,18.4673 -0.0206,18.5943 0.0004,19.9593 C0.0104,20.5883 10.3164,64.5483 10.7514,66.3863 C11.2744,68.3633 11.9414,69.0073 14.7264,68.9973 C17.9794,68.9863 18.3814,67.5733 18.8624,66.3863 L27.7124,34.9043 C28.0124,34.0203 28.0584,33.5393 28.6924,33.5393 C29.3274,33.5393 29.3734,34.0203 29.6744,34.9043 L38.5234,66.3863 C39.0054,67.5733 39.4064,68.9863 42.6604,68.9973 C45.4444,69.0073 46.1114,68.3633 46.6354,66.3863 C47.0694,64.5483 57.1274,20.2283 57.1114,19.7333 C57.0744,18.5623 56.1194,18.7673 54.2894,18.7673"
            class="west-elm__logo-letter-m"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
import breakPoints from "../../mixins/breakPoints";
import EventBus from "../../util/event-bus";
import { getSubBrand } from "../../util/context";
export default {
  name: "WELogo",
  mixins: [breakPoints],
  data() {
    return {
      subBrand: getSubBrand(this).id,
      isResponsive: this.isMobile,
    };
  },
  computed: {
    subBrandContext() {
      return this.$store.getters.getSubBrandContext || this.subBrand;
    },
    isWebEnabled() {
      return this.subBrandContext === "WEB";
    },
    isWebMobileEnabled() {
      return this.subBrandContext === "WEB" && this.isResponsive;
    },
    isWekEnabled() {
      return this.subBrandContext === "WEK";
    },
  },
  created() {
    if (this.isMediumBreakPoint()) {
      this.isResponsive = true;
    }
  },
  mounted() {
    EventBus.$on("mediumBreakPoint", () => {
      this.isResponsive = true;
    });
    EventBus.$on("largeBreakPoint", () => {
      this.isResponsive = false;
    });
  },
};
</script>

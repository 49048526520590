import axios from "axios";
import { REPONAME } from "../util/constants";
import { logger } from "@js-ecom-mfe/logger";
import { GENERIC_SERVICE_TIME_OUT } from "./const/timeouts";
import { loginStateEndpoint } from "./const/endpoints";

const fileName = "loginState.js";

export const getLoginState = (applicationUri) => {
  return axios
    .get(loginStateEndpoint(applicationUri), {
      timeout: GENERIC_SERVICE_TIME_OUT,
    })
    .then((response) => {
      logger.debug(REPONAME, fileName, "getLoginState", response);
      return response.data;
    })
    .catch((e) => {
      logger.error(
        REPONAME,
        fileName,
        "getLoginState",
        "Service call error:",
        e
      );
      return {};
    });
};

const HOUR_FORMAT = /([0-9]+):([0-9]+) ([AP]M) \- ([0-9]+):([0-9]+) ([AP]M)/i;
const weekdayKeys = [
  { key: "MONDAY_HOURS_FORMATTED", label: "Mon" },
  { key: "TUESDAY_HOURS_FORMATTED", label: "Tues" },
  { key: "WEDNESDAY_HOURS_FORMATTED", label: "Wed" },
  { key: "THURSDAY_HOURS_FORMATTED", label: "Thurs" },
  { key: "FRIDAY_HOURS_FORMATTED", label: "Fri" },
  { key: "SATURDAY_HOURS_FORMATTED", label: "Sat" },
  { key: "SUNDAY_HOURS_FORMATTED", label: "Sun" },
];

Object.freeze(weekdayKeys);

import { STORE_LOCATION_CLOSED_FORMATTED } from "../util/constants";
export default {
  methods: {
    /**
     * Returns the today's store hours for given store.
     * If the store is closed, then tomorrow's store hours will be provided.
     * Uses real time date using Javascript's Date constructor.
     * @param {Object} store store object from stores.json call
     * @returns {Object} object containing store hours for today or tomorrow
     */
    getCurrentStoreHours(store) {
      const date = new Date();
      let dayIndex = (date.getDay() || 7) - 1;
      let dayKey = weekdayKeys[dayIndex].key;
      let tomorrow = false;
      let open = true;
      const hoursString = store[dayKey];
      const formattedHours = hoursString.match(HOUR_FORMAT) || [];

      const openingHour =
        +formattedHours[1] + (formattedHours[3] === "PM" ? 12 : 0);
      const openingMinute = +formattedHours[2];
      const closingHour =
        +formattedHours[4] + (formattedHours[6] === "PM" ? 12 : 0);
      const closingMinute = +formattedHours[5];
      const currentHour = date.getHours();
      const currentMinute = date.getMinutes();

      if (
        !formattedHours.length ||
        currentHour > closingHour ||
        (currentHour === closingHour && currentMinute >= closingMinute)
      ) {
        dayIndex = (dayIndex + 1) % 6 || 6;
        dayKey = weekdayKeys[dayIndex].key;
        tomorrow = true;
        open = false;
      } else if (
        currentHour < openingHour ||
        (currentHour === openingHour && currentMinute < openingMinute)
      ) {
        open = false;
      }

      return { hours: store[dayKey], tomorrow, open };
    },
    /**
     * Returns an object with separate set of lowercased store hours.
     * @param {Object} hour format timedata is passed to calculate time in lowercase format.
     * @returns formattedHours in "h:mm:a - h:mm:a" format.
     */
    getStoreHoursToLowerCase(hoursData) {
      const openHour = hoursData[1];
      const openMinute = hoursData[2] !== "00" ? `:${hoursData[2]}` : "";
      const openMeridiem = hoursData[3].toLowerCase();

      const closeHour = hoursData[4];
      const closeMinute = hoursData[5] !== "00" ? `:${hoursData[5]}` : "";
      const closeMeridiem = hoursData[6].toLowerCase();

      const formattedHours = `${openHour}${openMinute}${openMeridiem} - ${closeHour}${closeMinute}${closeMeridiem}`;

      return formattedHours;
    },
    /**
     * Returns the friendly display version of current store hours, for given store.
     * If the store is currently opened, will return store hours in "h:mm:A - h:mm:A" format
     * If the store is currently closed, will return closedText param
     * @param {Object} store store object from stores.json call
     * @returns {Object} Object containing current or tomorrow's store hours in "h:mm:A - h:mm:A" format,
     *                   or a 'closed: true' property if currently closed and doesn't open tomorrow.
     */
    getFormattedCurrentStoreHours(store) {
      const hoursInfo = this.getCurrentStoreHours(store);
      const hoursString = hoursInfo.hours;
      const timeData = hoursString.match(HOUR_FORMAT);

      if (!timeData || timeData.length < 7) {
        return { closed: true };
      }

      const formattedHours = this.getStoreHoursToLowerCase(timeData);

      return {
        tomorrow: hoursInfo.tomorrow,
        open: hoursInfo.open,
        formattedHours,
      };
    },
    /**
     * Returns the friendly display store hours for given week.
     * @param {Object} store store object from stores.json call
     * @returns {Object} store hours keyed by duration of days, e.g. "Mon - Thurs" || "Fri"
     */
    getFormattedWeeklyStoreHours(store) {
      const storeHours = [];
      weekdayKeys.forEach((weekday) => {
        const timeData = store[weekday.key].match(HOUR_FORMAT);

        storeHours.push({
          day: weekday.label,
          hours:
            store[weekday.key] === STORE_LOCATION_CLOSED_FORMATTED
              ? `${store[weekday.key]}`
              : this.getStoreHoursToLowerCase(timeData),
        });
      });

      const reducedHours = storeHours.reduce((acc, hoursData) => {
        if (acc.length && acc[acc.length - 1].hours === hoursData.hours) {
          acc[acc.length - 1].end = hoursData.day;
        } else {
          acc.push({
            start: hoursData.day,
            end: hoursData.day,
            hours: hoursData.hours,
          });
        }
        return acc;
      }, []);

      return reducedHours.map((hoursData) => ({
        days:
          hoursData.start === hoursData.end
            ? hoursData.start
            : `${hoursData.start} - ${hoursData.end}`,
        hours: hoursData.hours,
      }));
    },
  },
};

<template>
  <div
    data-style="my-store"
    class="my-store-component"
    :class="{ 'cross-brand-bar': myStoreConfig.crossBrandBar }"
  >
    <MyStoreModule
      :hasLoaded="hasLoaded"
      :isResponsive="false"
      :isMobileUtilityNavEnabled="isMobileUtilityNavEnabled"
      :selectedStore="selectedStore"
      @open="openMyStoreDrawer()"
    />
    <span
      v-if="isMobileUtilityNavEnabled"
      data-test-id="mobile-utility-nav-my-store-text"
      >{{ mobileUtilityNavMyStoreText }}</span
    >
    <template v-if="hasLoaded">
      <MyStoreDrawer
        :geoCodeError="geoCodeError"
        :isResponsive="isResponsive"
        :selectedStore="selectedStore"
        :showDrawer="getMyStoreIsFlyoutOpen"
        :zipCode="zipCode"
        :loadingStores="loadingStores"
        @close="setMyStoreIsFlyoutOpen(false)"
        @onStoreSelect="updateSelectedStore"
        @onZipCodeUpdate="updateZipCode"
        :key="componentKey"
      />
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import MyStoreDrawer from "./MyStoreDrawer.vue";
import MyStoreModule from "./MyStoreModule.vue";
import zipCodeMixin from "../../mixins/zipCode";
import {
  MY_STORE_KEY,
  STORE_PICKUP_KEY,
  STORE_LOCATION_KEY,
  LAT_LONG_PROP,
  CURRENT_STORES_LIST_KEY,
  STORES_KEY,
  PICKUP_LATITUDE,
  PICKUP_LONGITUDE,
  PICKUP_ADDRESS,
  LAT_LONG,
  LAT,
  LNG,
  TTL,
  USER_LOCATION_KEY,
  US_MARKET_CODE,
  STORE_UPDATE_EVENT,
} from "../../util/constants";
import {
  getGovernorState,
  getMyStoreState,
  getConfigById,
} from "../../util/context";
import EventBus from "../../util/event-bus";
import {
  browserLocalStorageAvailable,
  getItemFromLocalStorage,
  browserSessionStorageAvailable,
  getJsonItemFromSessionStorage,
  saveJsonItemToSessionStorage,
} from "@js-ecom-mfe/browser-storage";
import { addEvent } from "@js-ecom-tracking/datalayer";
import { getUserZipCode } from "@vue-component-ecom-zip-code/zipcode-utility";
import { windowWsiStateExist } from "../../util/wsiState";

export default {
  name: "my-store",
  mixins: [zipCodeMixin],
  components: {
    MyStoreDrawer,
    MyStoreModule,
  },
  computed: {
    ...mapGetters([
      "getMyStoreIsFlyoutOpen",
      "getMyStoreUserLocation",
      "getMyStoreUserLocationString",
      "getStoreLocation",
      "getStoreLocationString",
    ]),
    /**
     * Checks if Global MFE has user's geolocation information
     * @returns {boolean} True if governorState has geo located user
     */
    hasUserGeolocation() {
      const governorState = getGovernorState(this);
      const postalKey = this.isPostalCodeOptimizationEnabled
        ? STORE_LOCATION_KEY // new one
        : USER_LOCATION_KEY; // old one
      return governorState && governorState[postalKey];
    },
    /**
     * Gets selectedStore from Vuex
     * @returns {Object} Selected store using the model from stores.json call
     */
    selectedStore() {
      const myStoreState = getMyStoreState(this);
      if (myStoreState?.selectedStore !== null) {
        WSI.state.change("myStoreLabelUpdate", myStoreState.selectedStore);
      }
      return myStoreState?.selectedStore ? myStoreState.selectedStore : null;
    },
    /**
     * Gets store list from Vuex
     * @returns {Array} List of stores from stores.json call
     */
    storeList() {
      const myStoreState = getMyStoreState(this);

      if (myStoreState?.storeList) {
        // filter out stores that has bopis eligibility.
        if (this.myStoreConfig.filterBopisEligibleStores) {
          return myStoreState?.storeList.filter(
            (store) => store.IS_PICKUP_ENABLED === "true"
          );
        } else {
          return myStoreState.storeList;
        }
      } else {
        return [];
      }
    },
    /**
     * Gets My Store zip code set by user from Vuex
     * @returns {string} Zip code set by user from Vuex
     */
    zipCode() {
      return this.isPostalCodeOptimizationEnabled
        ? this.getStoreLocation("zipCode")
        : this.getMyStoreUserLocation("zipCode") || "";
    },
  },
  props: {
    isMainInstance: {
      type: Boolean,
      default: false,
    },
    isMobileUtilityNavEnabled: {
      type: Boolean,
      default: false,
    },
    topLinksConfig: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    const myStoreConfig = getConfigById("myStore", this) || {};
    const postalCodeOptConfig =
      getConfigById("postalCodeOptimization", this) || {};
    const bingMapConfig = getConfigById("bingMaps", this);
    const mapConfig = getConfigById("maps", this);
    const postalCodeConfigs = {
      bingMaps: {
        ...bingMapConfig,
      },
      maps: {
        ...mapConfig,
      },
    };
    return {
      geoCodeError: false,
      hasLoaded: false,
      isResponsive: false,
      mobileUtilityNavMyStoreText:
        this.topLinksConfig?.myStore?.mobileUtilityNavText || "My Store",
      subscriptionToMainInstanceLoad: null,
      subscriptionToUserGeolocation: null,
      loadingStores: false,
      myStoreConfig,
      postalCodeConfigs,
      isPostalCodeOptimizationEnabled: postalCodeOptConfig?.enabled || false,
      componentKey: 0,
    };
  },
  methods: {
    ...mapActions([
      "myStoreStoreListFetch",
      "setMyStoreIsFlyoutOpen",
      "setPostalCodeData",
    ]),
    ...mapMutations(["myStoreUpdate"]),
    formatPostalCodeLocation(zipCodeInfo) {
      return `${zipCodeInfo?.country}|${zipCodeInfo?.state}|${zipCodeInfo?.value}|${zipCodeInfo?.lat}|${zipCodeInfo?.lng}`;
    },
    /**
     * Sends a vuex action to fetch stores
     * @returns {Array} list of nearby stores sorted by nearest distance
     */
    async getStores() {
      const storeList = await this.myStoreStoreListFetch();
      /* 
        Updated the store list data to storeListResponse.
      */
      WSI.state.change("storeListResponse", storeList.data);
      return storeList;
    },
    /**
     * Listens for the main instance of the MyStore component to load.
     */
    listenForMainInstanceLoad() {
      this.subscriptionToMainInstanceLoad = this.$store.subscribe(
        (mutation) => {
          if (
            mutation.type === "myStoreUpdate" &&
            mutation.payload &&
            mutation.payload.hasLoaded
          ) {
            setTimeout(() => {
              this.subscriptionToMainInstanceLoad();
              this.onLoaded();
            }, 500);
          }
        }
      );
    },
    /**
     * Gets stores and loads user preferences.
     * @param {Object} userPrefs User preferences object from local storage
     */
    async loadUserPrefs(userPrefs) {
      const newMyStoreData = {};
      const postalKey = this.isPostalCodeOptimizationEnabled
        ? STORE_LOCATION_KEY
        : USER_LOCATION_KEY;
      newMyStoreData[postalKey] = userPrefs[postalKey];
      this.myStoreUpdate(newMyStoreData);

      await this.getStores();

      const hasSelectedStore = userPrefs.selectedStore;
      if (hasSelectedStore) {
        // Attempt to re-select user's My Store
        // Find user's selected store within the latest set of store data and
        // select it if it exists, otherwise start with a blank store.
        const selectedStoreMatch = this.storeList.find(
          (store) =>
            store.PICKUP_LOCATION_CODE ===
            userPrefs.selectedStore.PICKUP_LOCATION_CODE
        );
        this.updateSelectedStore(
          selectedStoreMatch ? selectedStoreMatch : null
        );
      } else {
        this.updateSelectedStore(null);
      }
      this.saveUserPreferencesToSession(hasSelectedStore);
      this.onLoaded();
    },
    /**
     * Has loaded user preferences or has set a geolocated store.
     */
    onLoaded() {
      this.hasLoaded = true;
      this.$emit("hasLoaded", this.hasLoaded);
      //callback for BOPIS/BOSTS to load store in fulfillment widget.
      window.WSI.state.change("updatedStoreList", {});
      // Inform duplicated components that main mount() routine has completed
      if (this.isMainInstance) {
        this.myStoreUpdate({
          hasLoaded: this.hasLoaded,
        });
      }
    },
    /**
     * Saves user selected My Store to the Session Storage
     * @param {Object} userSelectedStore Object contains new user selected My Store details.
     */
    saveUserPreferencesToSession(userSelectedStore) {
      if (browserSessionStorageAvailable() && !!userSelectedStore) {
        // adding searchRadius will default to 200 to keep parity with PIP page
        // this radius will preform the search for physical locations used by MyStore.
        // loosing this key will break the MyStore - PIP Bopis Widget communication.
        let latitude = parseFloat(userSelectedStore?.LATITUDE);
        let longitude = parseFloat(userSelectedStore?.LONGITUDE);
        let currentSession = Date.now();

        const currentSessionUserPrefs = getJsonItemFromSessionStorage(
          STORE_PICKUP_KEY,
          {}
        );

        let storePickup = {
          [CURRENT_STORES_LIST_KEY]: currentSession,
          [PICKUP_LATITUDE]: latitude,
          [PICKUP_LONGITUDE]: longitude,
          [LAT_LONG_PROP]: [
            {
              [PICKUP_ADDRESS]: `${userSelectedStore?.CITY}, ${userSelectedStore?.STATE_PROVINCE}, ${userSelectedStore?.COUNTRY_CODE}`,
              [TTL]: currentSession,
              [LAT_LONG]: {
                [LAT]: latitude,
                [LNG]: longitude,
              },
            },
          ],
          [STORES_KEY]: [
            {
              key: currentSession,
              [PICKUP_LATITUDE]: latitude,
              [PICKUP_LONGITUDE]: longitude,
              searchRadius: 200,
              [STORES_KEY]: this.storeList,
              [TTL]: currentSession,
            },
          ],
        };
        saveJsonItemToSessionStorage(STORE_PICKUP_KEY, {
          ...currentSessionUserPrefs,
          ...storePickup,
        });
      }
    },
    /**
     * Fires an event to the Vuex store get the nearest stores,
     * then picks the first store on the list if it exists.
     */
    async setNearestStore() {
      await this.getStores();
      if (this.storeList && this.storeList.length) {
        this.updateSelectedStore(this.storeList[0]);
      } else {
        this.updateSelectedStore(null);
      }
      this.onLoaded();
    },

    /**
     * Sets stores based on user's geolocation from initial load.
     * Checks to see if governorState already has user geolocation loaded, if not
     * use the VueX subscribe pattern to listen for the mutation to occur.
     */
    async setOptimizedGovernorState() {
      if (!this.isPostalCodeOptimizationEnabled) {
        return;
      }

      const useCanadaPostalCodeFormat = this.marketCode !== US_MARKET_CODE;
      const zipCodeInfo = await getUserZipCode(
        this.zipCodeConfigs,
        useCanadaPostalCodeFormat
      );

      window.WSI &&
        window.WSI.state &&
        window.WSI.state.change(
          USER_LOCATION_KEY,
          this.formatPostalCodeLocation(zipCodeInfo)
        );

      const storeZipCodeInfo = await getUserZipCode(
        this.zipCodeConfigs,
        useCanadaPostalCodeFormat,
        "mystore"
      );

      window.WSI &&
        window.WSI.state &&
        window.WSI.state.change(
          STORE_LOCATION_KEY,
          this.formatPostalCodeLocation(storeZipCodeInfo)
        );
    },
    /**
     * Sets stores based on user's geolocation from initial load.
     * Checks to see if governorState already has user geolocation loaded, if not
     * use the Vuex subscribe pattern to listen for the mutation to occur.
     */
    setStoresBasedOnUserGeolocation() {
      const _set = async () => {
        this.setNearestStore();
      };

      if (!this.hasUserGeolocation) {
        this.subscriptionToUserGeolocation = this.$store.subscribe(
          async (mutation) => {
            if (mutation.type === "setGovernorState" && mutation.payload) {
              // Copy over the governorState mutation to our myLocation vuex store,
              // $store.state.header.myStore should now be source of truth for this component.
              if (mutation.payload[STORE_LOCATION_KEY]) {
                const storeLocationUpdate = {
                  [STORE_LOCATION_KEY]: mutation.payload[STORE_LOCATION_KEY],
                };
                this.myStoreUpdate(storeLocationUpdate);
                _set();
                this.subscriptionToUserGeolocation();
                this.saveUserPreferences(storeLocationUpdate);
              } else if (mutation.payload[USER_LOCATION_KEY]) {
                const userLocationUpdate = {
                  [USER_LOCATION_KEY]: mutation.payload[USER_LOCATION_KEY],
                };
                this.myStoreUpdate(userLocationUpdate);
                _set();
                this.subscriptionToUserGeolocation();
                this.saveUserPreferences(userLocationUpdate);
              }
            }
          }
        );
      } else {
        _set();
      }
    },
    /**
     * Saves user preferences for given selectedStore.
     * Also retrieves myStore user location string ex. "US|CA|95677|38.7907|-121.2358"
     * from the Vuex store and saves it under the currentZipCodeInfo key.
     * @param {Object} selectedStore Selected Store object from the MyStoreDrawer.vue component
     */
    updateSelectedStore(selectedStore) {
      this.myStoreUpdate({
        selectedStore,
      });
      this.saveUserPreferences({
        selectedStore,
        currentZipCodeInfo: this.getMyStoreUserLocationString,
        ...(this.isPostalCodeOptimizationEnabled
          ? { storeZipCodeInfo: this.getStoreLocationString ?? "" }
          : {}),
      });
      if (this.isMainInstance) {
        this.saveUserPreferencesToSession(selectedStore);
      }

      // ASSUMPTION: If this.hasLoaded is true, we can assume the updateSelectedStore method was called
      //             by a deliberate user action (click). Inform other MFE components about this event through `WSI.state`.
      //
      //             If this.hasLoaded is false, we can assume that one of the two mount() events:
      //               1) Geolocating a first time user, or
      //               2) Loading user preferences from local storage
      //             has occured. We communicate this "onLoad" event to other MFE components downstream under the prop "isLoadEvent".
      window.WSI.state.change("onMyStoreUpdate", {
        zipCode: this.zipCode,
        selectedStore,
        isLoadEvent: !this.hasLoaded,
        source: "global",
      });
    },
    /**
     * Sends an event to the zipCode mixin when the zip code is updated from a child component.
     * The mixin will convert the zip code into lat/lng which is then stored in the myStore object in vuex.
     * Finally, a vuex event is fired to fetch stores based on the new lat/lng.
     * @param {string} newZipCode new zip code from user triggered event
     * @param {string} oldZipCode currently selected zip code, if there is one
     */
    async updateZipCode(newZipCode, oldZipCode, newZipCodeInfo = {}) {
      if (newZipCode !== oldZipCode) {
        this.loadingStores = true;
        //this method is from the zipCode mixin
        try {
          this.geoCodeError = false;
          if (this.isPostalCodeOptimizationEnabled) {
            this.propagateOptimizedLocation(newZipCodeInfo);
          } else {
            await this.propagateUserLocation(newZipCode);
          }
          await this.myStoreStoreListFetch();
          this.loadingStores = false;
        } catch (error) {
          this.loadingStores = false;
          this.geoCodeError = true;
        }
        addEvent({
          category: "zip edit",
          item: "global submit",
          zipCode: newZipCode || "",
        });
      }
    },
    openMyStoreDrawer() {
      addEvent({
        category: "zip edit",
        item: "global overlay initiation",
        zipCode: this.zipCode || "",
      });
      this.setMyStoreIsFlyoutOpen(true);
    },
  },
  async mounted() {
    if (this.isPostalCodeOptimizationEnabled) {
      await this.setOptimizedGovernorState();
    }
    if (this.isMainInstance) {
      // this check also sees if this is client side processing
      if (browserLocalStorageAvailable()) {
        let userPrefs = getItemFromLocalStorage(MY_STORE_KEY);
        if (userPrefs?.selectedStore) {
          this.loadUserPrefs(userPrefs);
        } else {
          this.setStoresBasedOnUserGeolocation();
        }
      } else {
        this.setStoresBasedOnUserGeolocation();
      }
    } else {
      this.listenForMainInstanceLoad();
    }

    EventBus.$on("mediumBreakPoint", () => {
      this.isResponsive = true;
    });
    EventBus.$on("largeBreakPoint", () => {
      this.isResponsive = false;
    });
    this.setPostalCodeData(this.myStoreConfig);

    if (this.isPostalCodeOptimizationEnabled && windowWsiStateExist()) {
      window.WSI.state.onChange(STORE_UPDATE_EVENT, async (event) => {
        if (event.source !== "global") {
          this.myStoreUpdate({
            selectedStore: event.selectedStore,
          });
          await this.getStores();
          this.saveUserPreferences({
            selectedStore: event.selectedStore,
            currentZipCodeInfo: this.getMyStoreUserLocationString,
            ...(this.isPostalCodeOptimizationEnabled
              ? { storeZipCodeInfo: this.getStoreLocationString }
              : {}),
          });
          if (this.isMainInstance) {
            this.saveUserPreferencesToSession(event.selectedStore);
          }
          this.componentKey = this.componentKey + 1;
        }
      });
    }
  },
  destroyed() {
    // Unsubscribe to all vuex mutations
    if (this.subscriptionToUserGeolocation) {
      this.subscriptionToUserGeolocation();
    }
    if (this.subscriptionToMainInstanceLoad) {
      this.subscriptionToMainInstanceLoad();
    }
  },
};
</script>

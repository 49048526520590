import axios from "axios";
import {
  formatBalanceWithCommas,
  roundDownBalance,
} from "../helpers/keyRewardsHelper";
import { axiosResponseErrorLogger } from "../util/errorLogger";
import { REPONAME } from "../util/constants";
import { logger } from "@js-ecom-mfe/logger";

const fileName = "keyRewards.js";
/**
 * Makes a call to a specified Loyalty API endpoint to retrieve Key Rewards data and parses data
 * @param {String} url - the Loyalty API endpoint
 * @param {Number} timeout - the amount of time the method should wait before failing
 * @returns {Object} - the axios response from the Loyalty API call
 */
export const getKeyRewards = async (url, timeout) => {
  const methodName = "getKeyRewards";

  try {
    const response = await axios.get(
      url,
      {
        validateStatus: (status) => status === 200,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      },
      { timeout: timeout }
    );

    const parsedLRCBalance = formatBalanceWithCommas(
      roundDownBalance(response.data.totalCertificateBalance)
    );

    const timestamp = new Date();

    const keyRewardsData = {
      createdOn: String(timestamp),
      availableLRCBalance: parsedLRCBalance,
      totalCertificateBalance: response.data.totalCertificateBalance,
    };

    axiosResponseErrorLogger(fileName, methodName, response);

    return keyRewardsData;
  } catch (error) {
    logger.warn(
      REPONAME,
      fileName,
      methodName,
      `Error fetching ${url}:`,
      error
    );
  }
};

// Will return date in mm-dd-yy format.
export const getPopularSearchDateFormat = (date) => {
  const currentDate = date ? date : new Date();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  const day = currentDate.getDate().toString().padStart(2, "0");
  const year = currentDate.getFullYear().toString().slice(-2);
  return `${month}-${day}-${year}`;
};

// 86400000 (24 * 60 * 60 * 1000) is equivalent to one day.
export const getPreviousDate = (noOfDays) => {
  const previousDate = new Date(Date.now() - 86400000 * noOfDays);
  return getPopularSearchDateFormat(previousDate);
};

import { REPONAME } from "../util/constants";
import { logger } from "@js-ecom-mfe/logger";
const fileName = "global/common/linkUtilities.js";
const LINK_CLICK_INFO_KEY = "linkClickInfo";

/**
 * Adds link click information to local storage.
 *
 * @param {object} clickEvent click DOM event object
 * @param {Object} linkClickInfo Optional object to store.
 *  If originPage, destinationPage or parameters props are missing , then they will be added.
 * @returns the linkClickInfo object that was created and added to the local storage.
 */
const storeLinkClickInfo = (clickEvent = {}, linkClickInfo = {}) => {
  if (Object.getOwnPropertyNames(clickEvent).length === 0) {
    return;
  }
  if (!linkClickInfo?.destinationPage) {
    if (clickEvent?.target?.tagName.toLowerCase() === "a") {
      linkClickInfo.destinationPage = clickEvent?.target?.href;
    } else {
      linkClickInfo.destinationPage = clickEvent?.currentTarget?.href;
    }
  }
  try {
    const destHost = new URL(linkClickInfo.destinationPage).hostname;
    const originHost = clickEvent?.target?.ownerDocument?.location?.hostname;
    if (destHost !== originHost) {
      return;
    }
    if (
      typeof linkClickInfo?.parameters === "undefined" ||
      linkClickInfo?.parameters.length === 0
    ) {
      const dataParamsElement =
        clickEvent?.target?.tagName.toLowerCase() === "a" &&
        clickEvent.target.dataset?.params
          ? clickEvent.target
          : clickEvent.currentTarget;
      linkClickInfo.parameters = linkParamsFromClickEvent(dataParamsElement);
    }
  } catch (Err) {
    logger.error(REPONAME, fileName, "storeLinkClickInfo", Err);
  }
  if (!linkClickInfo?.originPage) {
    linkClickInfo.originPage =
      clickEvent?.target?.ownerDocument?.location?.href;
  }
  const now = new Date();
  linkClickInfo.timestamp = now;
  localStorage.setItem(LINK_CLICK_INFO_KEY, JSON.stringify(linkClickInfo));
  return linkClickInfo;
};

/**
 * Create parameters object from DOM element by looking at 'data-params' attribute.
 * @param element DOM element on which data-params attribute is set and where the @click handler binding is present.
 *  Usually this may also be the target of the click event (<a> element), but in some cases actul event target may
 *  be different - such as another content nested inside \<a>..\</a>.
 * @returns 'parameters' prop to set in linkClickInfo object that is to be added to localStorage.
 */
const linkParamsFromClickEvent = (element) => {
  let parameters = [];
  const params = element?.dataset?.params ?? "{}";
  const paramsObj = JSON.parse(params);
  const paramNames = Object.keys(paramsObj);
  let paramName;
  for (paramName of paramNames) {
    parameters.push({ name: paramName, value: paramsObj[paramName] });
  }
  return parameters;
};

/**
 * Returns boolean to indicate whether a parameter should be added to data-params.
 * It depends on whether the param name is listed for removal from url string and/or adding to local storage.
 *
 * @param parameterName Parameter name
 * @param linkParametersConfig linkParameters brand config.
 * @returns {boolean} true if parameter (name,value) should be added to data-params attribute.
 */
const isStoreParameterToBrowserStorageObject = (
  parameterName,
  linkParametersConfig = {}
) => {
  const paramNamesRemovedFromUrl = linkParametersConfig?.parametersToRemove;
  const paramNamesToBeAddedToStorage = linkParametersConfig?.parametersToReport;
  return (
    // parameter is set to be removed from query string
    (paramNamesRemovedFromUrl &&
      paramNamesRemovedFromUrl.includes(parameterName)) ||
    // If parameter is listed as to be stored into object
    (paramNamesToBeAddedToStorage &&
      paramNamesToBeAddedToStorage.length > 0 &&
      paramNamesToBeAddedToStorage.includes(parameterName))
  );
};
/**
 * Creates the value of "data-params" attribute of <a> tag.
 * The value is created by adding multiple ("name":"value") pairs to an object. Each pair represents a query string parameter.
 *
 * @param {Object} linkParametersConfig Object containing 'linkParameters' brand config.
 * @param {Object} paramNameValueMap Input (name,value) pairs. Only some of these are included in return value.
 * @returns {string} Object literal , such as { "cm_type": "lnav", "originsc": "supercat" }
 */
const createDataParamsAttr = (paramNameValueMap, linkParametersConfig = {}) => {
  const paramKeyVals = Object.entries(paramNameValueMap)
    .filter(([key]) =>
      isStoreParameterToBrowserStorageObject(key, linkParametersConfig)
    )
    .map(([key, value]) => `\"${key}\": \"${value}\"`)
    .join(",");
  return `{${paramKeyVals}}`;
};
/**
 * Filters the values of the link parameters based on the values of the parameters
 * configured to be removed and returns a new object.
 *
 * @param {Array<string>} parametersToRemove - An array of strings representing the keys of the parameters to remove from the list.
 * @param {Object} paramNameValueMap - An object containing key-value pairs.
 * @returns {Object} An object containing key-value pairs
 */
const parametersToAddToUrl = (parametersToRemove, paramNameValueMap) => {
  if (
    typeof paramNameValueMap !== "object" ||
    paramNameValueMap === null ||
    Array.isArray(paramNameValueMap)
  ) {
    return {};
  }
  const parametersToAdd = {};

  for (const paramName in paramNameValueMap) {
    if (
      paramNameValueMap.hasOwnProperty(paramName) &&
      !parametersToRemove.includes(paramName)
    ) {
      parametersToAdd[paramName] = paramNameValueMap[paramName];
    }
  }
  return parametersToAdd;
};
/**
 * Concatenates a string with the values filtered by parametersToAddToUrl
 *
 * @param {Array<string>} parametersToRemove - An array of strings representing the keys of the parameters to remove from the list.
 * @param {Object} paramNameValueMap - An object containing key-value pairs.
 * @returns {string} A string template if `parameterToString` is found in the filtered parameters, otherwise an empty string.
 */
const queryStringFromUrlParameters = (
  parametersToRemove,
  paramNameValueMap
) => {
  if (
    typeof paramNameValueMap !== "object" ||
    paramNameValueMap === null ||
    Array.isArray(paramNameValueMap)
  ) {
    return "";
  }
  const urlParameterTypeToName = parametersToAddToUrl(
    parametersToRemove,
    paramNameValueMap
  );
  const queryString = Object.entries(urlParameterTypeToName)
    .map(
      ([parameterName, parameterValue]) =>
        `${encodeURIComponent(parameterName)}=${encodeURIComponent(
          parameterValue
        )}`
    )
    .join("&");
  return queryString.length ? `${queryString}` : "";
};

module.exports = {
  isStoreParameterToBrowserStorageObject,
  createDataParamsAttr,
  storeLinkClickInfo,
  linkParamsFromClickEvent,
  parametersToAddToUrl,
  queryStringFromUrlParameters,
};

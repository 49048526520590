<template>
  <div
    data-style="positioned-header-icon"
    :class="['positioned-header-icon', `icon-position-${position}`]"
    data-test-id="positioned-header-icon"
  >
    <div v-if="icon === 'account'" class="icon-container icon-account">
      <a
        :href="`${
          isSignedIn
            ? '/m/account/?cm_type=gnav&cm_sp=HeaderLinks-_-Account-_-Mobile'
            : '/m/account/login.html?cm_type=gnav&cm_sp=HeaderLinks-_-Account-_-Mobile'
        }`"
        aria-label="account"
        class="header-icon account"
      >
        <span
          v-html="mobileHeaderConfig.icons.accountSVG"
          aria-hidden="true"
          data-test-id="positioned-header-icon-account-span"
        />
      </a>
    </div>
    <div v-if="icon === 'favorites'" class="icon-container icon-favorites">
      <a
        :href="'/favorites/?cm_type=gnav&cm_sp=HeaderLinks-_-AccountFavorites-_-Mobile'"
        aria-label="favorites"
        class="header-icon favorites"
      >
        <span
          v-html="mobileHeaderConfig.icons.favoritesSVG"
          aria-hidden="true"
          data-test-id="positioned-header-icon-favorites-span"
        />
      </a>
      <span
        v-if="favoriteCount > 0"
        aria-hidden="true"
        class="favorite-count"
        data-test-id="positioned-header-icon-favorite-count-span"
        >{{ favoriteCount }}</span
      >
    </div>
    <div
      v-if="icon === 'search'"
      @click="setSearchFocus"
      @keyup.enter="setSearchFocus"
      aria-label="search"
      class="icon-container icon-search"
      role="button"
      tabindex="0"
    >
      <span
        v-html="mobileHeaderConfig.icons.searchSVG"
        aria-hidden="true"
        data-test-id="positioned-header-icon-search-span"
      />
    </div>
    <div v-if="icon === 'stores'" class="icon-container icon-stores">
      <a
        :href="'/stores/?cm_type=gnav&cm_sp=HeaderLinks-_-Stores-_-Mobile'"
        aria-label="stores"
        class="header-icon stores"
      >
        <span
          v-html="mobileHeaderConfig.icons.storesSVG"
          aria-hidden="true"
          data-test-id="positioned-header-icon-stores-span"
        />
      </a>
    </div>
  </div>
</template>
<script>
export default {
  name: "PositionedHeaderIcon",
  props: {
    favoriteCount: {
      type: Number,
      default: 0,
    },
    icon: {
      type: String,
      required: true,
    },
    isSignedIn: {
      type: Boolean,
      default: false,
    },
    mobileHeaderConfig: {
      type: Object,
      required: true,
    },
    position: {
      type: Number,
      required: true,
    },
    setSearchFocus: {
      type: Function,
      default: () => ({}),
    },
  },
};
</script>

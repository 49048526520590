import axios from "axios";
import jsonp from "../util/jsonp";
import { getCookie } from "../util/cookies";
import { REPONAME } from "../util/constants";
import { logger } from "@js-ecom-mfe/logger";
import { GENERIC_SERVICE_TIME_OUT } from "./const/timeouts";

const fileName = "personalization.js";

let config = {};

export const setConfig = (personalizationConfig) => {
  config = personalizationConfig;
};

const generatePersonalizedContentUrl = (applicationUri, placement) => {
  const campaign = placement.campaign.toLowerCase().replace(/[\/\\]/g, "-");
  const treatment = placement.treatment.toLowerCase().replace(/[\/\\]/g, "-");
  let ids = "";
  if (placement.indexed_vars && placement.indexed_vars.length) {
    // FUTURE: Sort by t_index
    ids = placement.indexed_vars.reduce((acc, indexedVar, index) => {
      return acc.concat(`${index !== 0 ? "|" : ""}${indexedVar.t_productId}`);
    }, "ids=");
  }
  return `${applicationUri}${config.P13N_CONTENT_URL}/${campaign}/${treatment}/?${ids}`;
};

/**
 * Creates the Fingerprint service URL to get information on placements
 * @param {object} nodeList list of nodes where personalization content can be placed
 */
const generatePlacementsUrl = (concept, nodeList) => {
  const creModeCookie = getCookie(config.CRE_MODE_COOKIE);
  const creStateCookie = getCookie(config.CRE_STATE_COOKIE);
  const identifierParams = config.PROFILE_COOKIE_LIST.reduce(
    (acc, cookieName) => {
      acc = `${acc}&${cookieName}=${getCookie(cookieName)}`;
      return acc;
    },
    ""
  );
  const nodeArray = Array.from(nodeList);
  const personalizationNodes = new Set(
    nodeArray.map((p13nNode) => p13nNode.getAttribute("id"))
  );
  const serviceUrl = `${window.location.protocol}${config.CRE_SERVER}${
    config.LOOKUP_URL
  }${concept.toLowerCase()}`;
  return `${serviceUrl}/?${config.CRE_MODE_COOKIE}=${creModeCookie}&${
    config.CRE_STATE_COOKIE
  }=${creStateCookie}&${identifierParams}&${config.CRE_PLACEMENTS}=${[
    ...personalizationNodes,
  ].join("%2C")}`;
};

/**
 * Gets personalized content from DP based on the placement object
 * @param {object} placement placement object returned from Fingerprint/CRE
 */
export const getPersonalizedContent = async (applicationUri, placement) => {
  try {
    const p13nContentResponse = await axios.get(
      generatePersonalizedContentUrl(applicationUri, placement),
      { timeout: GENERIC_SERVICE_TIME_OUT }
    );
    return p13nContentResponse;
  } catch (error) {
    logger.error(REPONAME, fileName, "getPersonalizedContent", error);
    return Promise.reject(error);
  }
};

/**
 * Gets the Fingerprint placements response for a given list of personalization content nodes
 * @param {object} nodeList list of nodes where personalization content can be placed
 */
export const getPlacements = async (concept, nodeList) => {
  try {
    const placementsResponse = await jsonp.get(
      generatePlacementsUrl(concept, nodeList)
    );
    return placementsResponse;
  } catch (error) {
    logger.error(REPONAME, fileName, "getPlacements", error);
    return Promise.reject(error);
  }
};

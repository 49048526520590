<template>
  <div
    data-style="personalized-placement"
    v-if="placementId && personalizationContent"
    v-html="personalizationContent"
    class="personalized-placement"
    :id="placementId"
    data-component="Global-PersonalizedPlacement"
  ></div>
</template>

<script>
import {
  getBrandConcept,
  getApplicationUri,
  getPlacements,
  getConfigById,
} from "../util/context";
import { getPersonalizedContent, setConfig } from "../services/personalization";
import { logger } from "@js-ecom-mfe/logger";
import { REPONAME } from "../util/constants";
const fileName = "PersonalizedPlacement.vue";
let configLoaded = false;
export default {
  name: "PersonalizedPlacement",
  computed: {
    placement() {
      return getPlacements(this)[this.placementId];
    },
  },
  data() {
    return {
      concept: "",
      hasRendered: false,
      applicationUri: "",
      personalizationContent: "",
    };
  },
  props: {
    placementId: {
      type: String,
      default: "",
    },
  },
  beforeCreate() {
    if (!configLoaded) {
      setConfig(getConfigById("personalization", this));
      configLoaded = true;
    }
  },
  methods: {
    fetchPersonalizedContent: async function (placement) {
      const promoCode = placement.t_promocode;
      let p13nResponse;
      try {
        p13nResponse = await getPersonalizedContent(
          this.applicationUri,
          placement
        );
      } catch (e) {
        logger.error(REPONAME, fileName, "fetchPersonalizedContent", e);
        p13nResponse = null;
      }

      if (
        p13nResponse &&
        p13nResponse.data &&
        p13nResponse.data.trim() !== ""
      ) {
        let p13nContent = p13nResponse.data;
        if (promoCode) {
          p13nContent = this.renderPromo(p13nContent, promoCode);
        }
        this.personalizationContent = p13nContent;
        this.hasRendered = true;
      }
    },
    isEligibleForPersonalizedContent: function (placement) {
      return (
        typeof placement !== "undefined" &&
        placement.campaign &&
        placement.campaign.trim() !== "" &&
        placement.treatment &&
        placement.treatment.trim() !== "" &&
        placement.population &&
        placement.population !== "CONTROL"
      );
    },
    renderPromo: function (content, promoCode) {
      const promoRegExp = /\[\%\=( t_promocode )\%\]/g;
      return content.replace(promoRegExp, promoCode);
    },
  },
  watch: {
    placement(newVal) {
      // Subscribe to store and watch for changes pertaining to this specific placement Id
      if (
        !this.hasRendered &&
        newVal &&
        this.isEligibleForPersonalizedContent(newVal)
      ) {
        this.fetchPersonalizedContent(newVal);
      }
    },
  },
  mounted() {
    this.concept = getBrandConcept(this);
    this.applicationUri = getApplicationUri(this);
  },
};
</script>

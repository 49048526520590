<template>
  <Drawer
    data-style="my-store-drawer"
    ref="drawerComponent"
    :showDrawer="showDrawer"
    :customClass="'my-store-flyout'"
    :direction="myStoreConfig.drawerDirection"
    :lockScreen="true"
    :sitsInlineWith="sitsInlineWith"
    :focusElement="overlayTitleFocus"
    v-on:close="toggleMyStoreDrawer"
    v-on:ready="hasLoaded"
  >
    <template v-slot:header>
      <!-- Header here -->
      <div class="my-selected-store">
        <div class="my-store-title" ref="overlayTitle" tabindex="0">
          My Store,
        </div>
        <template v-if="hasSelectedStore">
          <div class="selected-store-name">
            <template v-if="!isResponsive"
              ><a :href="getStoreLocatorLink(selectedStore)">{{
                selectedStore.STORE_NAME
              }}</a>
            </template>
            <template v-else-if="enableMobileStoreNameLink"
              ><a
                id="mobile-selected-store-name"
                :href="getStoreLocatorLink(selectedStore)"
                >{{ selectedStore.STORE_NAME }}</a
              ></template
            >
            <template v-else>{{ selectedStore.STORE_NAME }}</template>
          </div>
          <MyStoreCurrentStoreHours
            :isResponsive="false"
            :store="selectedStore"
          />
        </template>
      </div>
      <div v-if="myStoreConfig.message" class="my-store-message">
        <p>{{ myStoreConfig.message }}</p>
      </div>
      <div class="wrap-my-store-search">
        <div class="my-store-search-label">
          {{ myStoreConfig.searchTitle }}
        </div>
        <div class="my-store-search">
          <GlobalLocationEdit
            :geoCodeError="geoCodeError"
            :showClose="false"
            :updateLabel="'Search'"
            :zipCode="zipCode"
            :zipErrorMessage="myStoreConfig.zipErrorMessage"
            @updateZipCode="updateZipCode"
            ref="globalLocationEdit"
          />
        </div>
      </div>
    </template>
    <template v-slot:content>
      <!-- Content here -->
      <template v-if="loadingStores">
        <div class="loading-spinner"></div>
      </template>
      <template v-else-if="storeList && storeList.length">
        <ul class="store-listing">
          <template v-if="floatedStore && floatedStore !== null">
            <MyStoreStoreListing
              :isResponsive="isResponsive"
              :selectedStore="selectedStore"
              :store="floatedStore"
              @onSelectStore="selectStore"
            />
          </template>
          <template v-for="(store, index) of storeList">
            <MyStoreStoreListing
              v-if="
                index <
                (floatedStore && floatedStore !== null
                  ? myStoreConfig.maxStoreListing - 1
                  : myStoreConfig.maxStoreListing)
              "
              :key="'store-listing-' + store.PICKUP_LOCATION_CODE"
              :isResponsive="isResponsive"
              :selectedStore="selectedStore"
              :store="store"
              @onSelectStore="selectStore"
            />
          </template>
        </ul>
      </template>
      <template v-else>
        <div class="no-stores-found">
          <h1>{{ myStoreConfig.titleNoStoreFound }}</h1>
          <div class="no-store-message-alignment">
            <p>{{ myStoreConfig.textNoStoreFound }}</p>
          </div>
          <a
            :href="
              myStoreConfig.findStoreLink ? myStoreConfig.findStoreLink : ''
            "
            >STORE LOCATOR</a
          >
        </div>
      </template>
    </template>
    <template v-slot:footer>
      <!-- Footer Content here -->
    </template>
  </Drawer>
</template>

<script>
import {
  getApplicationUri,
  getConfigById,
  getMyStoreState,
} from "../../util/context";
import { getStoreLocatorPath } from "../../util/stores";
import storeHours from "../../mixins/storeHours";
import { mapMutations } from "vuex";
import Drawer from "../drawer/Drawer.vue";
import GlobalLocationEdit from "../header/GlobalLocationEdit.vue";
import MyStoreStoreListing from "./MyStoreStoreListing.vue";
import MyStoreCurrentStoreHours from "./MyStoreCurrentStoreHours.vue";

export default {
  name: "my-store-drawer",
  components: {
    Drawer,
    GlobalLocationEdit,
    MyStoreStoreListing,
    MyStoreCurrentStoreHours,
  },
  mixins: [storeHours],
  props: {
    geoCodeError: {
      type: Boolean,
      default: false,
    },
    isResponsive: {
      type: Boolean,
      default: false,
    },
    loadingStores: {
      type: Boolean,
      default: false,
    },
    selectedStore: {
      type: Object,
      default: null,
    },
    showDrawer: {
      type: Boolean,
      default: false,
    },
    zipCode: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: Number | String,
      default: "",
    },
  },
  data() {
    const myStoreConfig = getConfigById("myStore", this) || {};
    return {
      floatedStore: null,
      myStoreConfig,
      hasLoaded: false,
      showMyStoreDrawer: false,
      subscriptionToStoreListFetch: null,
      sitsInlineWith: myStoreConfig.sitsInlineWith
        ? myStoreConfig.sitsInlineWith
        : "#nav-main",
      enableMobileStoreNameLink:
        myStoreConfig?.enableMobileStoreNameLink ?? false,
    };
  },
  computed: {
    /**
     * Checks if there is a selected store
     * @returns {boolean} True if user has a selected store
     */
    hasSelectedStore() {
      return this.selectedStore && this.selectedStore !== null;
    },
    /**
     * Gets store list from Vuex
     * @returns {Array} List of stores from stores.json call
     */
    storeList() {
      const myStoreState = getMyStoreState(this);
      if (myStoreState?.storeList) {
        if (this.floatedStore) {
          return myStoreState.storeList.filter(
            (store) =>
              store.PICKUP_LOCATION_CODE !==
              this.floatedStore.PICKUP_LOCATION_CODE
          );
        } else if (this.myStoreConfig.filterBopisEligibleStores) {
          return myStoreState?.storeList.filter(
            (store) => store.IS_PICKUP_ENABLED === "true"
          );
        } else {
          return myStoreState.storeList;
        }
      } else {
        return [];
      }
    },
  },
  methods: {
    ...mapMutations(["myStoreUpdate"]),
    /**
     * For given storeList, if user has selected a store, see if the selected store should be floated.
     * @param {Array} storeList storeList returned by the Vuex store
     */
    checkIfSelectedStoreShouldBeFloated(storeList) {
      if (this.hasSelectedStore) {
        const selectedStoreInStoreList =
          storeList &&
          storeList.length &&
          storeList.filter(
            (store) =>
              store.PICKUP_LOCATION_CODE ===
              this.selectedStore.PICKUP_LOCATION_CODE
          );
        if (selectedStoreInStoreList && selectedStoreInStoreList.length) {
          // Selected store is in payload
          this.floatedStore = selectedStoreInStoreList[0];
        } else {
          // De-select the store if it doesn't appear on the list
          this.floatedStore = null;
          this.selectStore(null);
        }
      }
    },
    /**
     * Returns the store locator link for given store object
     * @param {object} store Store object
     * @returns {string} URL for store locator page for given store object
     */
    getStoreLocatorLink(store) {
      const storePageNewLinksFormat =
        this.myStoreConfig?.linksNewFormatEnabled ?? false;
      return `${getApplicationUri(this)}${getStoreLocatorPath(
        store,
        storePageNewLinksFormat
      )}`;
    },
    /**
     * Scroll event handler. Currently used to trigger a blur() event
     * on the zip code input to hide the Google Maps Autocomplete dropdown.
     */
    handleScroll() {
      this.$refs.globalLocationEdit.$refs.zipInput.blur();
    },
    /**
     * Sets the selectedStore from the My Store listing.
     * @param {object} store Store to set
     */
    selectStore(store) {
      this.$emit("onStoreSelect", store);

      // Closes the drawer when a store changes.
      setTimeout(() => {
        // Artificial timeout to provide Shop MFE a head start on its async calls.
        // FUTURE: Wrap this around a WSI.state event listener once more MFEs rely on MyStore.
        this.$refs.drawerComponent.closeDrawer();
      }, 500);
    },
    /**
     * Closes the MyStore Drawer
     */
    toggleMyStoreDrawer() {
      this.$emit("close");
    },
    /**
     * Sends an event to parent component with the new zip code on zip code update.
     * @param {string} newZipCode new zip code
     */
    updateZipCode(newZipCode, oldZipCode, newZipCodeInfo = {}) {
      this.$emit("onZipCodeUpdate", newZipCode, oldZipCode, newZipCodeInfo);
    },
    // For Accessibility with keyboard users
    overlayTitleFocus() {
      this.$refs.overlayTitle.focus();
    },
  },
  mounted() {
    if (this.myStoreConfig.floatLogicEnabled) {
      this.checkIfSelectedStoreShouldBeFloated(this.storeList);
      // Listen to changes in storelist fetch for floated store logic
      this.subscriptionToStoreListFetch = this.$store.subscribe((mutation) => {
        if (
          mutation.type === "myStoreUpdate" &&
          mutation.payload &&
          mutation.payload.storeList
        ) {
          this.checkIfSelectedStoreShouldBeFloated(mutation.payload.storeList);
        }
      });
    }
    if (this.hasLoaded) {
      this.$refs.drawerComponent.$refs.containerRef.addEventListener(
        "scroll",
        this.handleScroll
      );
    }
  },
  beforeDestroy() {
    if (this.hasLoaded) {
      this.$refs.drawerComponent.$refs.containerRef.removeEventListener(
        "scroll",
        this.handleScroll
      );
    }
  },
  destroyed() {
    // Unsubscribe to all vuex mutations
    if (this.subscriptionToStoreListFetch) {
      this.subscriptionToStoreListFetch();
    }
  },
};
</script>

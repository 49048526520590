/**
 * Takes a store object from the stores.json endpoint and
 * generates a store locator path for the store based on its properties.
 * @param {object} store Store object
 * @param {boolean} storePageNewLinksFormat Config to toggle store page new link format
 * @returns {string} Partial URL path for the store locator page for given store object
 */
export const getStoreLocatorPath = (store, storePageNewLinksFormat) => {
  const formattedCityName = store.CITY.replace(/ /g, "-");
  const formattedStoreName = store.STORE_NAME.replace(/ /g, "-");
  // [brand site]/stores/[country]/[state]/[city-store-name-separated-by-dashes]
  let url =
    `stores/${store.COUNTRY_CODE}/${store.STATE_PROVINCE}/${formattedCityName}-${formattedStoreName}/`.toLowerCase();
  if (storePageNewLinksFormat) {
    /** some stores names are same as city name
     * for e.g formattedCityName = 'birmingham' & formattedStoreName = 'birmingham'
     * we need the if-else to make sure the city name is skipped in such scenario otherwise the url
     * https://wwww.westelm.com/stores/us-mi-birmingham-birmingham/ redirects to Store Locator
     *
     */
    if (formattedCityName.toLowerCase() === formattedStoreName.toLowerCase()) {
      url =
        `stores/${store.COUNTRY_CODE}-${store.STATE_PROVINCE}-${formattedStoreName}/`.toLowerCase();
    } else {
      url =
        `stores/${store.COUNTRY_CODE}-${store.STATE_PROVINCE}-${formattedCityName}-${formattedStoreName}/`.toLowerCase();
    }
  }
  return url;
};

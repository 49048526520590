<template>
  <ul>
    <li
      data-style="my-store-store-listing"
      v-if="store && store !== null"
      class="wrap-store-list"
    >
      <div class="my-store-detail">
        <div class="store-name">
          <template v-if="!isResponsive"
            ><a :href="getStoreLocatorLink(store)">{{
              store.STORE_NAME
            }}</a></template
          >
          <template v-else-if="enableMobileStoreNameLink"
            ><a :href="getStoreLocatorLink(store)" class="mobile-store-name">{{
              store.STORE_NAME
            }}</a></template
          >
          <template v-else>{{ store.STORE_NAME }}</template>
        </div>
        <div class="store-address">
          <p>{{ store.ADDRESS_LINE_1 }},</p>
          <p>{{ store.ADDRESS_LINE_2 ? store.ADDRESS_LINE_2 + "," : "" }}</p>
          <p>
            {{ store.CITY }}, {{ store.STATE_PROVINCE }} {{ store.POSTAL_CODE }}
          </p>
        </div>
        <div class="store-contact">
          {{ store.PHONE_NUMBER_FORMATTED }}
        </div>
        <WeeklyStoreHours :store="store" />
      </div>
      <input
        class="store-listing-button"
        type="submit"
        :class="[
          selectedStore &&
          store.PICKUP_LOCATION_CODE === selectedStore.PICKUP_LOCATION_CODE
            ? 'button-selected-store'
            : 'button-unSelected-store',
        ]"
        :value="
          selectedStore &&
          store.PICKUP_LOCATION_CODE === selectedStore.PICKUP_LOCATION_CODE
            ? myStoreConfig.flyoutButtonTextMyStore
            : myStoreConfig.flyoutButtonTextSetAsMyStore
        "
        @click="selectStore(store)"
      />
    </li>
  </ul>
</template>

<script>
import { getApplicationUri, getConfigById } from "../../util/context";
import { getStoreLocatorPath } from "../../util/stores";
import WeeklyStoreHours from "./MyStoreWeeklyStoreHours.vue";
import { addEvent } from "@js-ecom-tracking/datalayer";

export default {
  name: "my-store-store-listing",
  data() {
    const myStoreConfig = getConfigById("myStore", this) || {};
    return {
      myStoreConfig,
      enableMobileStoreNameLink:
        myStoreConfig?.enableMobileStoreNameLink ?? false,
    };
  },
  components: {
    WeeklyStoreHours,
  },
  props: {
    isResponsive: {
      type: Boolean,
      default: false,
    },
    selectedStore: {
      type: Object,
      default: null,
    },
    store: {
      type: Object,
      default: null,
    },
  },
  methods: {
    /**
     * Returns the store locator link for given store object
     * @param {object} store Store object
     * @returns {string} URL for store locator page for given store object
     */
    getStoreLocatorLink(store) {
      const storePageNewLinksFormat =
        this.myStoreConfig?.linksNewFormatEnabled ?? false;
      return `${getApplicationUri(this)}${getStoreLocatorPath(
        store,
        storePageNewLinksFormat
      )}`;
    },
    /**
     * Sends an event to parent component about the store selected
     */
    selectStore(store) {
      addEvent({
        category: "my store",
        item: "change store",
        storeId: store?.PICKUP_LOCATION_CODE ?? "", // ID of the store selected by the user
      });
      this.$emit("onSelectStore", store);
    },
  },
};
</script>

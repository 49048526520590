<template>
  <img
    v-if="flyoutHeaderIcon"
    :src="flyoutHeaderIcon"
    alt="flyout-header-icon"
    data-style="search-flyout-header-icon"
  />
</template>

<script>
import { getApplicationUri, getConfigById } from "../../../util/context";
import { GLOBAL_NETSTORAGE_PATH } from "../../../util/constants";
export default {
  name: "SearchFlyoutLogo",
  data() {
    const flyoutconfig = getConfigById("searchFlyout", this) ?? {};
    return {
      flyoutconfig,
      flyoutHeaderIcon: false,
    };
  },
  methods: {
    //get Search Header Image
    flyoutHeaderImage() {
      let headerImage = this.flyoutconfig?.searchHeaderImage ?? false;
      if (headerImage && this.flyoutconfig?.loadFromNetStorage) {
        const netStoragePath =
          this.flyoutconfig?.netStoragePath ?? GLOBAL_NETSTORAGE_PATH;
        const applicationUri = getApplicationUri(this) || "/";
        headerImage = `${applicationUri}${netStoragePath}${headerImage}`;
      }
      return headerImage;
    },
  },
  mounted() {
    this.flyoutHeaderIcon = this.flyoutHeaderImage();
  },
};
</script>

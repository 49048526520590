<template>
  <div
    data-style="ecm-content-slot"
    class="ecm-content-slot"
    :class="this.contentLocation.replace(/[^\w\s_\-]/gi, ' ')"
  >
    <div data-component="Global-EcmPromo" class="promo">
      <div class="promo-content">
        <Content
          data-component="Global-EcmPromo"
          class="promo"
          :callback="this.callback"
          :contentLocation="setContentPath(this.contentLocation)"
          :contentArray="contentArray"
          :refreshOnClient="refreshOnClient"
          :applicationUri="this.applicationUri"
          :assetsUrl="this.assetsUrl"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Content from "@vue-component-ecom-content/content";

export default {
  name: "ecmContentSlot",
  components: {
    Content,
  },
  props: {
    contentLocation: {
      type: String,
    },
    callback: {
      type: Function,
    },
    contentArray: {
      type: Array,
    },
    refreshOnClient: {
      type: Boolean,
    },
  },
  data() {
    const assetUrl = this.$store.state.header.config.assetsUrl ?? "";
    return {
      applicationUri: this.$store.state.header.clientApplicationUri,
      assetsUrl: this.$store.state.header.assetUris.ecmImages || assetUrl,
    };
  },
  methods: {
    /**
     * Remove the mobile indication from the path for the content location.
     * @param {String} contentSlot the content path
     * @returns {String} the content path to use
     */
    setContentPath(contentSlot) {
      if (this.$store.state.header?.url?.match(/\/m\//)) {
        return contentSlot.replace("/m", "");
      } else {
        return contentSlot;
      }
    },
  },
};
</script>

<template>
  <div
    data-style="recently-viewed-items-pop-out"
    class="pop-out-menu recently-viewed-items-pop-out"
    ref="recentlyViewedItemsPopout"
    data-component="Global-RecentlyViewedItemsPopOut"
    :class="{
      'loading-placeholder': handleLoader,
    }"
    @focusout="handleDropdownFocusOut($event)"
  >
    <div v-if="!isMobile && loadChildDataOnHover" class="popout-wrapper">
      <RecommendationWrapper
        @favoriteAnalytics="addFavoriteAnalyticsEvent"
        @onSeeMoreClickToRVIPopOut="openRecommendations"
        @placementsFetched="handleRviRecsLoader"
        @productFavoriteTogglerOperationsRecommendation="
          updateFavoritedRecommendationItem
        "
        @rviAnalyticsToRVIPopOut="rviLoaded"
        repoName="global"
        :applicationUri="applicationUri"
        :concept="concept"
        :currencyData="currencyData"
        :fallbackPlacementMessage="getFallbackPlacementMessage"
        :isMobileView="isMobile"
        :marketCode="getMarketCode"
        :pageTypeName="getPageId"
        :pricing="getPricingConfig"
        :productCarousel="getProductCarousel"
        :promoVizConfig="getPromoVizConfig"
        :recommendationConfig="getRecommendationConfiguration"
        :resetCarousel="resetCarousel"
        :rviList="[]"
        :showSeeMoreLink="showSeeMoreLink"
        :isUiUserNameCookieEnabled="isUserNameCookieEnabled"
        data-test-id="recommendation-wrapper"
      />
      <p class="api-error-message" v-html="getApiErrorMessage"></p>
    </div>
  </div>
</template>
<script>
import { addEvent } from "@js-ecom-tracking/datalayer";
import { getConfigById } from "../../util/context";
import EventBus from "../../util/event-bus";
import recommendedProducts from "../../mixins/recommendedProducts";
import {
  FLYOUT_CONFIG,
  INLINE_RECOMMENDATIONS_BREAKPOINTS,
  MIN_DESKTOP_WIDTH,
  PRICING_CONFIG,
  PRODUCT_CAROUSAL_CONFIG,
  PROMOVIZ_CONFIG,
  PRICE_TYPE_BW_RANGE,
  RECOMMENDATIONS_CONFIG,
  RECOMMENDATION_VARIANT,
} from "../../util/constants";
import { mapActions, mapGetters } from "vuex";
import { getItemFromLocalStorage } from "@js-ecom-mfe/browser-storage";
import dropdownMenu from "../../mixins/dropdownMenu";

export default {
  name: "recently-viewed-items-pop-out",
  components: {
    RecommendationWrapper: () =>
      import(
        /* webpackChunkName: "header-lazy-components" */ "@vue-component-ecom-product-recommendations/product-recommendations"
      ).then((wrapper) => {
        return wrapper.default;
      }),
  },
  mixins: [recommendedProducts, dropdownMenu],
  props: {
    resetCarousel: {
      type: Boolean,
      default: false,
    },
    loadChildDataOnHover: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const recommendationsConfiguration =
      getConfigById("recommendation", this) || RECOMMENDATIONS_CONFIG;
    const profileConfig = getConfigById("profile", this) ?? {};
    return {
      product: {},
      // eslint-disable-next-line no-empty-function
      addEvent: () => {},
      applicationUri: this.$store?.state?.header?.applicationUri,
      concept: this.$store?.state?.header?.concept,
      currencyData: this.$store?.state?.header?.currencyData || {},
      flyoutConfig:
        getConfigById("viewMoreRecommendations", this) || FLYOUT_CONFIG,
      pricing: getConfigById("pricing", this) || PRICING_CONFIG,
      productCarousel:
        getConfigById("productCarousel", this) || PRODUCT_CAROUSAL_CONFIG,
      promoVizConfig:
        getConfigById("promotionVisibility", this) || PROMOVIZ_CONFIG,
      recommendationsConfiguration,
      recommendationFavoriteFlyoutConfig:
        recommendationsConfiguration?.wsiRecs?.flyout ?? {},
      showSeeMoreLink: true,
      showRVILoader: true,
      recsApiError: false,
      isUserNameCookieEnabled: profileConfig?.isUiUserNameCookieEnabled ?? true,
    };
  },
  methods: {
    ...mapActions(["currencyDataFetch", "setFavoritedRecommendationItem"]),
    addFavoriteAnalyticsEvent(favoriteAnalyticsPayload) {
      if (this.addEvent) {
        this.addEvent(favoriteAnalyticsPayload);
      }
    },
    openRecommendations(product, analyticsParam) {
      delete product["recommendationDetails"];
      product.priceSet = {
        lowSellingPrice: product?.sale_price_range[0],
        highSellingPrice: product?.sale_price_range[1],
        lowRegularPrice: product?.price_range[0],
        highRegularPrice: product?.price_range[1],
      };
      product.seedLink = `${
        product.productLink.split("?")[0]
      }?cm_src=RVI-MoreLikeThis-Seed&${analyticsParam}`;
      const emitObject = {
        config: this.flyoutConfig,
        product: product.pid,
        location: "global",
        state: this.showSeeMore,
        index: this.productIndex,
        pageType: this.getPageType(analyticsParam.split("=")[1]),
        analyticsParam: analyticsParam,
      };
      this.product = product;
      emitObject.seedProduct = product;
      EventBus.$emit("LoadFlyoutRecommendations", emitObject);
      this.inlineRecommendationsCarouselAnalytics();
      const popout = this.$refs["recentlyViewedItemsPopout"];
      if (popout?.style) {
        popout.style.display = "none";
      }
    },
    inlineRecommendationsCarouselAnalytics() {
      this.addEvent({
        category: "recently-viewed",
        item: "see-more",
        groupId: this.product.pid,
      });
    },
    rviLoaded(data) {
      // eslint-disable-next-line eqeqeq
      if (data.slideFrom != null) {
        this.addEvent({
          category: "recently-viewed",
          item: "scroll",
        });
      }
    },
    // Take country and currency input from events and updates country ad currency based on cookie
    fetchCurrencyData(country, currency) {
      this.currencyDataFetch({
        selectedCountry: country,
        selectedCurrency: currency,
      });
    },
    handleRviRecsLoader(data) {
      this.recsApiError = !data.state;
      this.showRVILoader = false;
    },
    /**
     * Passed to Favorite Flyout, after emitted by Recommendation Wrapper
     * @param {Object} recommendationFavoriteItem -- Favorite Item comes from recommendation wrapper
     */
    updateFavoritedRecommendationItem(favoritedRecommendationItem) {
      this.setFavoritedRecommendationItem(favoritedRecommendationItem);
    },
  },
  computed: {
    ...mapGetters(["getMarketCode"]),
    /**
     * Returns true if it is mobile view.
     * @returns {Boolean}
     */
    isMobile() {
      return (
        this.$store?.state?.header?.activeProperties?.isMobile === "1" ||
        this.windowWidth < MIN_DESKTOP_WIDTH
      );
    },
    /**
     * Returns API error message if the API call fails.
     * @returns {String}
     */
    getApiErrorMessage() {
      return this.recsApiError
        ? this.flyoutConfig?.apiErrorMessage || FLYOUT_CONFIG?.apiErrorMessage
        : "";
    },
    /**
     * Create fallback object for "RecommendationWrapper".
     * Returns fallback message object from config if the pageId is bestsellers.
     * @returns {Object}
     */
    getFallbackPlacementMessage() {
      const fallbackPlacementMessageObject = {
        enabled: false,
        message: "",
      };
      return this.getPageId === "GLOBALRVIBESTSELLERS"
        ? this.flyoutConfig?.fallbackPlacementMessage ||
            FLYOUT_CONFIG?.fallbackPlacementMessage
        : fallbackPlacementMessageObject;
    },
    /**
     * Returns recently viewed or bestsellers based on pagetype.
     * @returns {String}
     */
    getPageId() {
      return getItemFromLocalStorage("recentlyViewed")
        ? (this.pageType = "GLOBALRVI")
        : (this.pageType = "GLOBALRVIBESTSELLERS");
    },
    /**
     * Create pricing object for "RecommendationWrapper".
     * Returns pricing object from brand config.
     * @returns {Object}
     */
    getPricingConfig() {
      const {
        mdmLabels,
        defaultLabels,
        showPriceTypeBetweenPriceRanges = PRICE_TYPE_BW_RANGE,
      } = this.pricing;
      const { showDiscountPercentage, showMdmPriceLabels } = this.pricing;
      this.getPricing = {
        mdmLabels,
        defaultLabels,
        showDiscountPercentage,
        showMdmPriceLabels,
        showPriceTypeBetweenPriceRanges,
      };
      return this.getPricing;
    },
    /**
     * Create product carousel object for "RecommendationWrapper".
     * Returns product carousel object from brand config.
     * @returns {Object}
     */
    getProductCarousel() {
      return this.productCarousel;
    },
    /**
     * Create promo visibility object for "RecommendationWrapper".
     * Returns promo visibility object from brand config.
     * @returns {Object}
     */
    getPromoVizConfig() {
      return this.promoVizConfig;
    },
    /**
     * Create recommendation object for "RecommendationWrapper".
     * Override recsHostUrl with maskUrl if switch is enabled.
     * Returns recommendation object from brand config.
     * @returns {Object}
     */
    getRecommendationConfiguration() {
      let hostUrl = "";
      const getMaskUri = this.handleSeeMoreLink;
      getMaskUri.maskUrlEnabled && getMaskUri.maskUrl
        ? (hostUrl = getMaskUri.maskUrl)
        : (hostUrl =
            this.recommendationsConfiguration?.wsiRecs?.recsHostUrl ||
            `${this.applicationUri}${RECOMMENDATIONS_CONFIG?.wsiRecs?.recsHostUrl}`);
      const {
        isRecsEnabled,
        recommendationSource,
        variant = RECOMMENDATION_VARIANT,
      } = this.recommendationsConfiguration;
      const favorite =
        this.recommendationsConfiguration?.wsiRecs?.favorite || {};
      const breakpoints =
        this.flyoutConfig?.inlineRecommendations?.breakpoints ||
        INLINE_RECOMMENDATIONS_BREAKPOINTS;
      this.getRecommendationsFlyout = {
        isRecsEnabled,
        recommendationSource,
        wsiRecs: { breakpoints, favorite },
        variant,
      };
      this.getRecommendationsFlyout.wsiRecs.recsHostUrl = hostUrl;
      return this.getRecommendationsFlyout;
    },
    handleLoader() {
      return this.showRVILoader;
    },
  },
  mounted() {
    EventBus.$on(
      "enableRVIPopout",
      () => {
        this.$refs["recentlyViewedItemsPopout"].style.display = "block";
      },
      this
    );
    this.addEvent = addEvent;
    WSI.state.onChange("currencyUpdate", (event) => {
      this.fetchCurrencyData(event.selectedCountry, event.selectedCurrency);
    });
  },
};
</script>

import { mapGetters, mapMutations } from "vuex";
import { fetchBingAddressFromZipCode, loadBingMapLib } from "../util/bing-maps";
import {
  MY_STORE_KEY,
  STORE_LOCATION_KEY,
  USER_LOCATION_KEY,
} from "../util/constants";
import { getConfigById } from "../util/context";
import {
  fetchAddressFromLatLng,
  fetchAddressFromZipCode,
  loadGoogleMapLib,
} from "../util/google-maps";
import {
  getValidPostalCode,
  isPostalCodeUpdateRequired,
} from "../util/postalCode";
import { checkValidZipCode } from "../util/string";
import {
  browserLocalStorageAvailable,
  getItemFromLocalStorage,
  saveItemToLocalStorage,
} from "@js-ecom-mfe/browser-storage";

const DEFAULT_ZIP_CODE = "94103";

export default {
  data() {
    const bingMapConfig = getConfigById("bingMaps", this);
    const myStoreConfig = getConfigById("myStore", this) || {};
    return {
      bingMapConfig,
      myStoreConfig,
    };
  },
  methods: {
    ...mapMutations(["myStoreUpdate", "setPostalCodeFromLatLng"]),
    /**
     * Updates the zipcode in Vuex store
     *
     * * @param {String | Object} newLookup -- ZipCode, or city name, or an object containing the lat lng
     *
     */
    async propagateUserLocation(newLookup) {
      let locationStr = "";
      let addressDetails;
      if (typeof newLookup === "string" && checkValidZipCode(newLookup)) {
        // Zip lookup
        addressDetails = await this.getAddressFromZipCode(newLookup);
      } else {
        addressDetails = await this.getAddressFromLatLng(newLookup);
      }
      const currentPostalCode = addressDetails.zipCode;
      if (
        addressDetails &&
        isPostalCodeUpdateRequired(currentPostalCode, this.myStoreConfig)
      ) {
        const newPostalCode = await getValidPostalCode(
          addressDetails.lat,
          addressDetails.lng,
          this.$store.state.header.config
        );
        this.$store.commit("setPostalCodeFromLatLng", newPostalCode);
        addressDetails.zipCode = newPostalCode;
      }
      const locationArr =
        this.$store.state.header.myStore[USER_LOCATION_KEY].split("|");
      //state is at the second position
      locationArr[1] = addressDetails.state;
      //zipcode is at the third position
      locationArr[2] = addressDetails.zipCode;
      //latitude is at the fourth position
      locationArr[3] = addressDetails.lat;
      //longitude is at the fifth position
      locationArr[4] = addressDetails.lng;
      locationStr = locationArr.join("|");

      const newMyStoreData = {};
      newMyStoreData[USER_LOCATION_KEY] = locationStr;
      this.myStoreUpdate(newMyStoreData);

      return locationStr;
    },

    propagateOptimizedLocation(zipCodeInfo, zipType = "mystore") {
      let govenorStateLocation;
      // if (!isNullOrEmptyObject(zipCodeInfo)) {
      govenorStateLocation = `${zipCodeInfo?.country}|${zipCodeInfo?.state}|${zipCodeInfo?.value}|${zipCodeInfo?.lat}|${zipCodeInfo?.lng}`;
      // }
      if (zipType === "shipto") {
        window.WSI &&
          window.WSI.state &&
          window.WSI.state.change(USER_LOCATION_KEY, govenorStateLocation);
        const newMyStoreData = {};
        newMyStoreData[USER_LOCATION_KEY] = govenorStateLocation;
        this.myStoreUpdate(newMyStoreData);
        this.saveUserPreferences({
          [USER_LOCATION_KEY]: govenorStateLocation,
        });
      } else if (zipType === "mystore") {
        window.WSI &&
          window.WSI.state &&
          window.WSI.state.change(STORE_LOCATION_KEY, govenorStateLocation);
        const newMyStoreData = {};
        newMyStoreData[STORE_LOCATION_KEY] = govenorStateLocation;
        this.myStoreUpdate(newMyStoreData);
        this.saveUserPreferences({
          [STORE_LOCATION_KEY]: govenorStateLocation,
        });
      }
    },

    /**
     * Loads the Google/Bing Maps API if not loaded. Returns latitude & longitude based on the zipcode
     *
     * * @param {String} newZipCode -- ZipCode that needs to be set in the local storage
     *
     * * @returns {Promise} -- On success the resolve will be called with the coordinates, on failure the reject will be called with the error
     */
    async getAddressFromZipCode(zipCode) {
      //maps.provider could either be "Google" or "Bing" - from JSON configs
      if (getConfigById("maps", this)) {
        const maps = getConfigById("maps", this);
        window.mapsProvider = maps.provider;
      }
      if (window.mapsProvider === "Bing") {
        const bingMaps = getConfigById("bingMaps", this);
        loadBingMapLib(bingMaps);
        const cords = await fetchBingAddressFromZipCode(
          zipCode || this.currentZipCode,
          this.bingMapConfig
        );
        return cords;
      } else {
        const googleMaps = getConfigById("googleMaps", this);
        loadGoogleMapLib(googleMaps);
        const cords = await fetchAddressFromZipCode(
          zipCode || this.currentZipCode
        );
        return cords;
      }
    },
    /**
     * Loads the Google/Bing Maps API if not loaded. Returns and address object based on lat & lng
     *
     * * @param {Object} latlng -- An object containing lat & lng properties
     *
     * * @returns {Promise} -- On success the resolve will be called with the coordinates, on failure the reject will be called with the error
     */
    async getAddressFromLatLng(latlng) {
      //maps.provider could either be "Google" or "Bing" - from JSON configs
      if (getConfigById("maps", this)) {
        const maps = getConfigById("maps", this);
        window.mapsProvider = maps.provider;
      }
      if (window.mapsProvider === "Bing") {
        const bingMaps = getConfigById("bingMaps", this);
        loadBingMapLib(bingMaps);
        const cords = await fetchBingAddressFromZipCode(
          latlng,
          this.bingMapConfig
        );
        return cords;
      } else {
        const googleMaps = getConfigById("googleMaps", this);
        loadGoogleMapLib(googleMaps);
        const cords = await fetchAddressFromLatLng(latlng);
        return cords;
      }
    },
    /**
     * Get's the zipcode from a given position. Currently Akamai returns a list of zipcode separated by "+"
     *
     * @param {String} zipCode -- A string containing zipCode separated by "+"
     * @param {Number} pos -- Position of the zipCode that needs to be extracted
     *
     * @returns {String} -- Extracted zipCode
     *
     */
    getZipCodeAtPosition(zipCodes, pos = 0) {
      const ZIP_CODE_DELIMITER = "+";
      const ZIP_CODE_SPLITTER = "-";
      const zipCodeArray = zipCodes.split(ZIP_CODE_DELIMITER);
      const zipCode = zipCodeArray[pos];
      return zipCode.split(ZIP_CODE_SPLITTER)[0];
    },
    /**
     * Updates the postal code only for CAN market if WSI state has invalid format code
     * Only executes in CAN market and depends on config
     */
    initializePostalCode() {
      const userLocation =
        this.$store?.state?.header?.governorState[USER_LOCATION_KEY] ?? "";
      const myStoreConfigs = this.$store?.state?.header?.config?.myStore;
      const postalCodeFromLatLng =
        this.$store?.state?.header?.postalCodeFromLatLng;
      const locationDetails = userLocation.split("|");
      if (
        !userLocation ||
        !postalCodeFromLatLng ||
        locationDetails[2] === postalCodeFromLatLng ||
        !myStoreConfigs?.enablePostalCodeOverrideFromLatLng
      ) {
        return;
      }
      locationDetails[2] = postalCodeFromLatLng;
      const govenorStateLocation = locationDetails.join("|");
      // Update new details with updated postal code into WSI state
      window.WSI &&
        window.WSI.state &&
        window.WSI.state.change(USER_LOCATION_KEY, govenorStateLocation);
    },
    /**
     * Saves user preferences to the Local Storage
     * @param {Object} newUserPrefs Object partial containing new user prefs
     */
    saveUserPreferences(newUserPrefs) {
      // FUTURE: User messaging if browser storage is no available (incognito)
      // Makes Search and MFE work
      if (browserLocalStorageAvailable()) {
        const currentLocalUserPrefs = getItemFromLocalStorage(MY_STORE_KEY, {});
        saveItemToLocalStorage(MY_STORE_KEY, {
          ...currentLocalUserPrefs,
          ...newUserPrefs,
        });
      }
    },
  },
  mounted() {
    // Initial load check if postal code needs to be updated
    if (!this.isPostalCodeOptimizationEnabled) {
      this.initializePostalCode();
    }
  },
  computed: {
    ...mapGetters({
      marketCode: "getMarketCode",
      zipCodeConfigs: "getZipCodeConfigs",
    }),
    locationKey() {
      return this.isPostalCodeOptimizationEnabled
        ? STORE_LOCATION_KEY
        : USER_LOCATION_KEY;
    },
    /**
     * Compute the currentZipCode based on user location in the store
     *
     */
    currentZipCode: function () {
      const userLocation =
        this.$store.state.header.governorState[this.locationKey];
      if (userLocation) {
        const zipCode = this.getZipCodeAtPosition(userLocation.split("|")[2]);
        return zipCode;
      } else {
        return DEFAULT_ZIP_CODE;
      }
    },
  },
};

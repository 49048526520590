/**
 * Get count of path to match segments if it matches with the current path
 * according to the following cases
 *
 * CASE 1 ->  if current path segments are less than path to match segments (inclusion/exclusion).
 *            No need to check, return 0;
 *            eg:  currentPath -> "/shop/cookware"
 *                 pathToMatch -> "/shop/cookware/cookset1"
 *                 return 0 because the current path will not match entirely to the pathToMatch
 *
 * CASE 2 ->  Check if pathToMatch segments not matching with current Path Segments, return 0;
 *            eg:  currentPath -> "/shop/furniture/sofa",
 *                 pathToMatch -> "/shop/cookware"
 *                 return 0 because the current path segments do not match to the pathToMatch upto its length
 *            eg:  currentPath -> "/shop/furniture/sofa",
 *                 pathToMatch -> "/shop/furniture"
 *                 do not return 0 because the current path segments match to the pathToMatch upto its length
 *            eg:  currentPath -> "/shop/furniture/sofa",
 *                 pathToMatch -> "/shop/* /sofa"
 *                 '*' means it can be any value and it must exists for that segment of currentPath
 *                 hence do not return 0 because the current path segments match to the pathToMatch
 *
 * CASE 3 ->  if above cases true, then get count the pathToMatch segments which matched to determine which inclusion/exclusion
 *            item matches more with the current path;
 *            eg:  currentPath -> "/shop/furniture/sofa",
 *                 inclusion example path -> pathToMatch -> "/shop/furniture"
 *                 exclusion example path -> pathToMatch -> "/shop/furniture/sofa"
 *                 both the inclusion and exclusion segments matches but exclusion segments matches more via counting,
 *                 so priority to be given to more counts.
 */
const getPathCountWithMatchingSegments = (currentPath, pathToMatch) => {
  const currentPathSegments = currentPath.split("/").filter(Boolean);
  const pathToMatchSegments = pathToMatch.split("/").filter(Boolean);

  return (
    (currentPath === "/" && pathToMatch === "/") ||
    (pathToMatchSegments.length <= currentPathSegments.length &&
      pathToMatchSegments.every(
        (segmentToMatch, i) =>
          segmentToMatch === currentPathSegments[i] || segmentToMatch === "*"
      ) &&
      pathToMatchSegments.length)
  );
};

/**
 * get the Max count of current path and all inclusion/exclusion list items that are matching to determine
 * if the current path meets the matching requirement of path to match
 */
const getMaxPathCountWithMatchingSegments = (currentPath, list) =>
  Math.max(
    ...list.map((path) => getPathCountWithMatchingSegments(currentPath, path))
  );

/**
 * check if the current path meets the matching requirement of both inclusion/exclusion items
 * inclusion count should be greater than 0 or exclusion list max count matched
 * exclusion count should be 0 or less than incclusion list max count matched
 */
const isCurrentPathIncluded = (currentPath, inclusionList, exclusionList) => {
  const hasInclusions = inclusionList.length;
  const hasExclusions = exclusionList.length;

  if (!hasInclusions && !hasExclusions) {
    return true;
  }

  if (hasInclusions && !hasExclusions) {
    return !!getMaxPathCountWithMatchingSegments(currentPath, inclusionList);
  }

  if (!hasInclusions && hasExclusions) {
    return !getMaxPathCountWithMatchingSegments(currentPath, exclusionList);
  }

  const maxInclusionCount = getMaxPathCountWithMatchingSegments(
    currentPath,
    inclusionList
  );
  const maxExclusionCount = getMaxPathCountWithMatchingSegments(
    currentPath,
    exclusionList
  );

  return (
    (maxInclusionCount || maxExclusionCount) &&
    maxInclusionCount >= maxExclusionCount
  );
};

/**
 * Determine the visbility of global design chat based on
 * device using the provided configs enablements and url rules
 */
export const getGlobalDesignChatVisibility = (
  currentPath,
  config,
  isMobile
) => {
  const deviceUrlRulesForEnablements =
    (isMobile &&
      config?.isMobileEnabled &&
      config?.urlRulesForEnablements?.mobile) ||
    (!isMobile &&
      config?.isDesktopEnabled &&
      config?.urlRulesForEnablements?.desktop);

  if (deviceUrlRulesForEnablements) {
    const allUrlRulesForEnablements = config.urlRulesForEnablements.all;
    const inclusionList = [
      ...allUrlRulesForEnablements.inclusionList,
      ...deviceUrlRulesForEnablements.inclusionList,
    ];
    const exclusionList = [
      ...allUrlRulesForEnablements.exclusionList,
      ...deviceUrlRulesForEnablements.exclusionList,
    ];
    return isCurrentPathIncluded(currentPath, inclusionList, exclusionList);
  }
  return (
    (config?.isMobileEnabled || config?.isDesktopEnabled) &&
    !config.urlRulesForEnablements
  );
};

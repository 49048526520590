<template>
  <ul
    data-style="brands-list"
    class="brands-list"
    v-if="brandsList && brandsList.length > 0"
    data-component="Global-header-BrandsList"
  >
    <li
      v-for="(brand, index) in brandsList"
      :key="index"
      :class="[
        brand.class ? brand.class : '',
        brand.isActive ? 'active' : '',
        brand.id && brand.id === currentSubBrand ? 'selected' : '',
      ]"
    >
      <a
        v-if="brand.link"
        :href="brand.link"
        :target="brand.target || '_blank'"
        referrerpolicy="no-referrer-when-downgrade"
      >
        {{ brand.brand_name }}
        <span v-if="brand.sub_name" :class="brand.sub_name">{{
          brand.sub_name
        }}</span>
      </a>
      <span v-else-if="brand.brand_name">{{ brand.brand_name }}</span>
      <PrimaryNavPopOutMenu
        v-if="brand.pop_out"
        :customData="[{ items: brand.links }]"
        :hasSpotlight="false"
        :isBrandList="true"
      />
    </li>
  </ul>
</template>

<script>
import PrimaryNavPopOutMenu from "./PrimaryNavPopOutMenu.vue";
import {
  getConfigById,
  getBrandConcept,
  getSubBrand,
} from "../../util/context";
import { logger } from "@js-ecom-mfe/logger";
import EventBus from "../../util/event-bus";
import breakPoints from "../../mixins/breakPoints";
import { REPONAME } from "../../util/constants";

const fileName = "BrandsList.vue";

export default {
  name: "brands-list",
  mixins: [breakPoints],
  components: {
    PrimaryNavPopOutMenu,
  },
  data() {
    return {
      isResponsive: this.isMobile,
      brandsList: [],
      currentSubBrand: "",
    };
  },
  methods: {
    getConfig() {
      const config = getConfigById("crossBrandBar", this);
      if (!config || !config.brandBarOrder || !config.brandBarOrder.length) {
        logger.warn(
          REPONAME,
          fileName,
          "getConfig",
          `Parsing error in cross_brand_bar.json for concept ${getBrandConcept(
            this
          )}`
        );
        return [];
      }
      return this.isResponsive && config?.mobileBrandBarOrder
        ? config.mobileBrandBarOrder
        : config.brandBarOrder;
    },
  },
  created() {
    this.brandsList = this.getConfig();
    this.currentSubBrand = getSubBrand(this).brand_name;
    this.$emit("subBrandSelection", this.currentSubBrand);
  },
  mounted() {
    EventBus.$on("mediumBreakPoint", () => {
      this.isResponsive = true;
      this.brandsList = this.getConfig();
    });
    EventBus.$on("largeBreakPoint", () => {
      this.isResponsive = false;
      this.brandsList = this.getConfig();
    });
  },
};
</script>

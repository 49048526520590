/* eslint-disable camelcase */
/**
 * Add backslash to path if needed.
 * @param {String} path - the path to process
 * @returns {String} the formed path
 */
const addBackslashIfNeeded = (path = "") => {
  if (!path.length) {
    // if no path just return the empty path
    return path;
  }

  if (path.endsWith("/")) {
    // If the path ends with a backslash we are done
    return path;
  }

  let query = "";
  const queryIndex = path.indexOf("?");
  // Split path from query (if present)
  if (queryIndex >= 0) {
    query = path.substring(queryIndex);
    path = path.substring(0, queryIndex);
  }

  // if no backslash add it
  if (!path.endsWith("/")) {
    path = path + "/";
  }

  return path + query;
};

/**
 * Take the response from Constructor and transform it into
 * a response in BR format.
 * @param {Object} res - the response to transform
 * @param {String} keyword - the search term
 * @param {Object} constructorConfig - The constructor config object
 * @returns {Object} the transformed response
 */
export const transformConstructorResponse = (
  res,
  keyword,
  constructorConfig
) => {
  const output = {
    data: {
      response: {},
      responseHeader: {},
    },
  };
  const isSuggestionTrackingEnabled =
    constructorConfig?.suggestionTrackingEnabled || false;
  const cProducts = res.sections["Products"];

  const products = cProducts.map((cProduct) => {
    const url = !isSuggestionTrackingEnabled
      ? addBackslashIfNeeded(cProduct.data.url)
      : `${addBackslashIfNeeded(cProduct.data.url)}?_cn_psugg_q=${keyword}`;
    return {
      pid: cProduct.data.id,
      sale_price: cProduct.data.price,
      title: cProduct.value,
      thumb_image: cProduct.data.image_url,
      url: url,
      variants: [],
    };
  });
  let cSuggestions = res.sections["Search Suggestions"];
  const suggestions = cSuggestions.map((suggestion) => {
    return {
      q: suggestion.value,
      dq: suggestion.value,
    };
  });

  output.data.response = {
    products: products,
    suggestions: suggestions,
    numFound: res.total_num_results_per_section["Products"],
    q: res.request.term,
  };
  return output;
};

<template>
  <div data-style="flyout" class="grid-recommendations-wrapper">
    <Drawer
      ref="drawerComponent"
      :showDrawer="showDrawer"
      :customClass="['custom-flyout', flyoutClass]"
      direction="right"
      :lockScreen="true"
      :sitsInlineWith="sitsInlineWith"
      :isResponsive="isResponsive"
      v-on:close="toggleDrawer('close')"
      :showCloseIcon="true"
      v-on:ready="hasLoaded"
    >
      <template v-slot:content>
        <slot name="flyout-content"></slot>
      </template>
      <template v-slot:footer> </template>
    </Drawer>
  </div>
</template>
<script>
import Drawer from "../drawer/Drawer.vue";
import EventBus from "../../util/event-bus";

export default {
  name: "flyout-component",
  components: {
    Drawer,
  },
  props: {
    flyoutClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showDrawer: false,
      hasLoaded: false,
      sitsInlineWith: "",
      isResponsive: false,
    };
  },
  computed: {},
  methods: {
    // toggles drawer
    toggleDrawer(type) {
      this.showDrawer = !this.showDrawer;
      if (type === "close") {
        this.$emit("handleFlyoutClose");
      }
    },
  },
  mounted() {
    // toggles Flyout view
    EventBus.$on("toggleFlyout", () => {
      this.showDrawer = !this.showDrawer;
    });
    EventBus.$on("mediumBreakPoint", () => {
      this.isResponsive = true;
    });
    EventBus.$on("largeBreakPoint", () => {
      this.isResponsive = false;
    });
  },
};
</script>

<template>
  <div
    data-style="search-flyout-type-ahead"
    :class="{ hide: hide, show: !hide }"
  >
    <div class="visual-type-ahead type-ahead-search-results">
      <div class="c-horizontal-line"></div>
      <ul
        id="typeAhead"
        class="type-ahead"
        :class="
          this.showingRecentSearches ? 'recent-searches' : 'search-suggestions'
        "
      >
        <li v-if="!suggestions.length">
          <a
            :href="searchResultsUrl(query)"
            @click="onRawInputHandler(query, $event)"
          >
            <span :data-keyword="query" class="queryKeyword query-keyword">{{
              query
            }}</span>
          </a>
        </li>
        <li
          :key="suggestion.q + '-suggestion'"
          v-for="(suggestion, index) in suggestions"
          :class="{ 'active-suggestion': index === currentSuggestion }"
        >
          <a
            :href="searchResultsUrl(suggestion.dq)"
            v-html="renderQueryKeyword(suggestion.dq)"
            @click="onSearchSuggestionHandler(suggestion.dq, $event)"
          />
        </li>
      </ul>
    </div>
    <div
      id="vtaScrollerContainer"
      v-if="productsSubset && productsSubset.length"
    >
      <div class="top-products-title">{{ topProductsTitle }}</div>
      <div id="vtaScroller" class="vta-scroller">
        <ul id="vtaScrollerProducts" class="container">
          <li
            :key="product.pid + '-scroller'"
            v-for="product in productsSubset"
            class="box"
          >
            <div class="vta-scroller-product-thumb">
              <a
                :href="product.url"
                @click="submitSearchAnalytics(product.title, $event)"
              >
                <img
                  :src="setThumbImage(product)"
                  class="product-thumb"
                  :alt="product.title"
                />
              </a>
            </div>
            <div class="vta-scroller-product-info">
              <a
                class="product-name"
                :href="product.url"
                v-html="product.title"
                @click="submitSearchAnalytics(product.title, $event)"
              ></a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import breakPoints from "../../../mixins/breakPoints";
import subBrandSearch from "../../../mixins/subBrandSearch";
import typeAhead from "../../../mixins/typeAhead";

export default {
  name: "SearchFlyoutVisualTypeAhead",
  mixins: [breakPoints, subBrandSearch, typeAhead],

  data() {
    const searchFlyoutConfig = this.$store.state.header.config.searchFlyout;
    const vtaConfig = this.$store.state.header.config.vta;
    const { topSearchVta = {} } = vtaConfig;
    return {
      maxProductsVisible: searchFlyoutConfig?.maxProductsVisible ?? 4,
      maxSuggestionsVisible: searchFlyoutConfig?.maxSuggestionsVisible ?? 4,
      topSearchVta,
    };
  },
  props: {
    query: {
      type: String,
      default: "",
    },
  },
  methods: {},
};
</script>

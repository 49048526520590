import { logger } from "@js-ecom-mfe/logger";
import { REPONAME } from "./constants";

const fileName = "createDataLayerImpressionObserver.js";

// FUTURE: This implements only the 'isIntersecting' property of the IntersectionObserverEntry object
// refactoring is required to handle other intersection changes
/**
 * Detects the intersection of a component with the viewport and emits events on intersection changes
 * @param {Execution Context} component - The component to be observed
 * @param {Number} root - Ancestor element used to as the viewport
 * @param {Number} threshold - The ratio of the component that should be visible to trigger the detectIntersect callback
 * @returns IntersectionObserver
 */
export default function createDataLayerImpressionObserver(
  component,
  root = null,
  threshold = 1.0
) {
  const methodName = "createDataLayerImpressionObserver";

  if (typeof IntersectionObserver !== "function" || !component) {
    const warningMessage = !component
      ? "No component reference passed to function"
      : "Intersection Observer not present in page";
    logger.warn(REPONAME, fileName, methodName, warningMessage);
    return null;
  }

  /**
   * Emits an event whenever the intersection observer detects a change
   * to whether the component is visible within the viewport
   * @param {Array<Object>} IntersectionObserverEntry Array of objects pertaining to the Intersection Observer API
   */
  const detectIntersect = ([{ isIntersecting }]) => {
    const eventName = isIntersecting ? "onIntersect" : "onNonIntersect";
    component.$emit(eventName, { component: component.$options.name });
  };

  return new IntersectionObserver(detectIntersect, {
    root: root,
    threshold: threshold,
  });
}

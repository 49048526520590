const axios = require("axios");

const HttpsAgent = require("agentkeepalive").HttpsAgent;
const Agent = require("agentkeepalive");

const keepaliveAgentHttps = new HttpsAgent({
  maxSockets: 100,
  maxFreeSockets: 10,
  timeout: 15000, // active socket keepalive for 60 seconds
  freeSocketTimeout: 30000, // free socket keepalive for 30 seconds
});

const keepaliveAgent = new Agent({
  maxSockets: 100,
  maxFreeSockets: 10,
  timeout: 15000, // active socket keepalive for 60 seconds
  freeSocketTimeout: 30000, // free socket keepalive for 30 seconds
});

// module.exports = axios;
module.exports = axios.create({
  //10 sec timeout
  timeout: 15000,

  //keepAlive pools and reuses TCP connections, so it's faster
  httpAgent: keepaliveAgent,
  httpsAgent: keepaliveAgentHttps,
  //   httpAgent: new http.Agent({ keepAlive: true }),
  //   httpsAgent: new https.Agent({ keepAlive: true }),

  //follow up to 10 HTTP 3xx redirects
  maxRedirects: 2,

  //cap the maximum content length we'll accept to 50MBs, just in case
  maxContentLength: 1024 * 1024,
});

<template>
  <button
    data-style="my-store-module"
    @click="openDrawer"
    data-component="mobile-my-store"
    class="my-store-module"
    ref="myStoreFocus"
  >
    <div
      v-show="myStoreConfig.displayLocationPin"
      data-component="location-pin"
      class="module-location-pin"
      role="button"
    ></div>
    <div data-component="my-store-details" :class="myStoreDetailsClassName">
      <template v-if="hasLoaded">
        <div class="store-name">
          <label class="my-store-header-title">{{
            myStoreConfig.headerTitle
          }}</label>
          <span class="store-name-span">
            {{ hasSelectedStore ? selectedStore.STORE_NAME : "Choose Store" }}
          </span>
        </div>
        <MyStoreCurrentStoreHours
          :isResponsive="isResponsive"
          :store="selectedStore"
        />
      </template>
      <template v-else> ... Loading My Store </template>
    </div>
  </button>
</template>

<script>
import { getConfigById } from "../../util/context";
import MyStoreCurrentStoreHours from "./MyStoreCurrentStoreHours.vue";
import EventBus from "../../util/event-bus";
import { addEvent } from "@js-ecom-tracking/datalayer";

export default {
  name: "my-store-module",
  components: {
    MyStoreCurrentStoreHours,
  },
  props: {
    hasLoaded: {
      type: Boolean,
      default: false,
    },
    isResponsive: {
      type: Boolean,
      default: false,
    },
    selectedStore: {
      type: Object,
      default: null,
    },
    isMobileUtilityNavEnabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const myStoreConfig = getConfigById("myStore", this) || {};
    return {
      myStoreConfig,
    };
  },
  computed: {
    hasSelectedStore() {
      return this.selectedStore && this.selectedStore !== null;
    },
    myStoreDetailsClassName() {
      return this.isMobileUtilityNavEnabled ? "hide" : "module-details";
    },
  },
  mounted() {
    EventBus.$on("focusReturnToOrigin", this.focusReturnToOrigin);
  },
  methods: {
    openDrawer() {
      addEvent({
        category: "my store",
        item: "open flyout",
      });
      this.$emit("open");
      if (window.Microsoft && window.Microsoft.Maps) {
        window.WSI.state.change("bingAPILoader", true);
      }
      if (window.google && window.google.maps) {
        window.WSI.state.change("googleAPILoader", true);
      }
    },
    // For Accessibility with keyboard users
    focusReturnToOrigin() {
      this.$refs.myStoreFocus.focus();
    },
  },
};
</script>

import axios from "axios";
import { logger } from "@js-ecom-mfe/logger";
import { REPONAME } from "../util/constants";

const fileName = "profileSummary.js";

/**
 * Makes a call to the specified profile summary API endpoint to retrieve profile summary data and returns profile summary data
 * @param {String} url - the profile summary API endpoint
 * @param {Number} timeout - the amount of time in ms, the method should wait before failing
 * @returns {Object} - response returned from the profile summary API call
 */
export const getProfileSummary = async (url, timeout) => {
  const methodName = "getProfileSummary";
  try {
    const response = await axios.get(
      url,
      {
        validateStatus: (status) => status < 500,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      },
      { timeout: timeout }
    );
    return response.status !== 200 ? null : response.data;
  } catch (error) {
    logger.warn(
      REPONAME,
      fileName,
      methodName,
      `Error fetching ${url}: ${error}`
    );
  }
};

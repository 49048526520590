import { logger } from "@js-ecom-mfe/logger";
import axios from "axios";
import { REPONAME } from "../util/constants";
import {
  getPopularSearchDateFormat,
  getPreviousDate,
} from "../util/dateFormat";
import { getPopularSearchesFromResponse } from "../util/popularSearches";
import { popularSearchesEndpoint } from "./const/endpoints";
import { GENERIC_SERVICE_TIME_OUT } from "./const/timeouts";

const fileName = "popularSearch.js";

export const getPopularSearches = async (configuredPath, maxRetries) => {
  const formatedUrl = popularSearchesEndpoint(
    configuredPath,
    getPopularSearchDateFormat()
  );
  const fetchPopularSearches = async (url, attempt) => {
    try {
      const response = await axios.get(url, {
        timeout: GENERIC_SERVICE_TIME_OUT,
      });
      return [...getPopularSearchesFromResponse(response)];
    } catch (error) {
      if (attempt < maxRetries) {
        const urlWithPreviousDate = popularSearchesEndpoint(
          configuredPath,
          getPreviousDate(attempt)
        );
        return fetchPopularSearches(urlWithPreviousDate, attempt + 1);
      } else {
        logger.error(REPONAME, fileName, "fetchPopularSearches", error);
        return Promise.reject(error);
      }
    }
  };
  //First attemt of API service call
  return fetchPopularSearches(formatedUrl, 1);
};

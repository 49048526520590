/**
 * Mixin to attach noScroll class to body when Modal or Overlay is opened
 */
export default {
  methods: {
    /**
     * Gets called when user focus on search box or open hamburger menu
     */
    toggleNoScroll(overlayVisible) {
      if (overlayVisible) {
        document.body.classList.add("no-scroll");
      } else {
        document.body.classList.remove("no-scroll");
      }
    },
  },
};

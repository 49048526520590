import axios from "axios";
import { REPONAME } from "../util/constants";
import { logger } from "@js-ecom-mfe/logger";
import { GENERIC_SERVICE_TIME_OUT } from "./const/timeouts";
import { quickCartEndpoint } from "./const/endpoints";

const fileName = "quickcart.js";
export const getCartItems = async () => {
  let cartItems;
  try {
    cartItems = await axios({
      method: "get",
      url: quickCartEndpoint(),
      timeout: GENERIC_SERVICE_TIME_OUT,
    });
    return cartItems;
  } catch (e) {
    logger.error(REPONAME, fileName, "getCartItems", e);
    return Promise.reject(e);
  }
};

<template>
  <section
    class="design-platform"
    id="mobile-notification-badge"
    data-test-id="Global-mobile-notification-badge"
  >
    <span data-style="Global-pzn-notification-icon">
      <SVGIcon
        icon="pzn-notification-icon"
        data-style="Global-pzn-notification-icon-svg-mobile"
      ></SVGIcon>
    </span>
  </section>
</template>

<script>
const SVGIcon = () =>
  import(/* webpackChunkName: "header-footer-svg-icon" */ "../SVGIcon.vue");
export default {
  name: "mobile-notification-badge",
  components: {
    SVGIcon,
  },
};
</script>

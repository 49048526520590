<template>
  <div
    data-style="registry-pop-out"
    class="pop-out-menu registry"
    data-component="Global-header-RegistryPopOut"
    @focusout="handleDropdownFocusOut($event)"
  >
    <div class="col">
      <h3>{{ config.findRegistryHeading }}</h3>
      <p class="col-required">*Required</p>
      <form
        id="findRegistryFormNav"
        action="/registry/index.html"
        method="post"
        novalidate="novalidate"
        autocomplete="off"
      >
        <fieldset>
          <label for="findRegistry.firstNameNav" class="required">
            First Name *</label
          >
          <input
            type="text"
            id="findRegistry.firstNameNav"
            name="firstName"
            v-model="firstName"
            pattern="^(?!.*[\[\]{}|~]).*$"
          />
          <div id="findRegistry.firstNameNav.error" class="validation-msg">
            <span
              class="error hide"
              id="error.findRegistry.firstNameNav.required"
              >Please enter a first name.</span
            >
            <span
              class="error hide"
              id="error.findRegistry.firstNameNav.invalid"
              >First name should only contain letters, numbers and spaces.</span
            >
          </div>
          <label for="findRegistry.lastNameNav" class="required-label">
            Last Name *</label
          >
          <input
            type="text"
            id="findRegistry.lastNameNav"
            name="lastName"
            v-model="lastName"
            value=""
            class="ilv"
            maxlength="20"
            pattern="^(?!.*[\[\]{}|~]).*$"
            required=""
            data-ilv="{'order':['required','invalid','length'],trim:'none'}"
          />
          <div id="findRegistry.lastNameNav.error" class="validation-msg">
            <span
              class="error hide"
              id="error.findRegistry.lastNameNav.required"
              >Please enter a last name.</span
            >
            <span class="error hide" id="error.findRegistry.lastNameNav.invalid"
              >Last name should only contain letters, numbers and spaces.</span
            >
            <span class="error hide" id="error.findRegistry.lastNameNav.length"
              >Please enter at least the first two letters of the last
              name.</span
            >
          </div>
        </fieldset>
        <button @click="handleFindRegistryClick">
          {{ config.findRegistryButtonText }}
        </button>
      </form>
    </div>
    <div class="col">
      <h3>{{ config.manageMyRegistryHeading }}</h3>
      <div
        class="ifLoggedOut ifIdentified ifNoRegistries"
        data-test-id="wedding-registry-signin"
        v-if="!isSignedIn"
      >
        <p>{{ config.signInText }}</p>
        <form
          action="/authenticate.html"
          method="post"
          id="loginForm"
          class="wsgc-form signin-to-account-form"
          novalidate="novalidate"
          autocomplete="off"
        >
          <input type="hidden" name="failureUrl" value="/registry/" />
          <input
            type="hidden"
            name="targetUrl"
            value="/registry/manage-registry.html?regLogin=true&amp;fromRegLandingPage=true"
          />
          <label for="login.email" class="required-label"> Email</label>
          <input
            type="email"
            id="login.email"
            name="email"
            value=""
            class="ilv"
            maxlength="75"
            required=""
            data-ilv="{'order':['required','invalid'],trim:'both'}"
          />
          <div id="login.email.error" class="validation-msg">
            <span class="error hide" id="error.login.email.required"
              >Please enter an email address.</span
            >
            <span class="error hide" id="error.login.email.invalid"
              >Please enter a valid email address.</span
            >
          </div>
          <label for="login.password" class="required-label"> Password</label>
          <input
            type="password"
            id="login.password"
            name="password"
            value=""
            class="ilv"
            maxlength="25"
            required=""
            pattern="(?=^.{8,25}$)^(?=.*\d)(?=.*[A-Za-z])(?!.*\s)(?!.*[\[\]{}|~]).*$"
            data-ilv="{'order':['required','length'],trim:'none'}"
          />
          <div id="login.password.error" class="validation-msg">
            <span class="error hide" id="error.login.password.required"
              >Your password must be between 8 and 25 characters.</span
            >
            <span class="error hide" id="error.login.password.length"
              >The password must be between 8 and 25 characters.</span
            >
          </div>

          <div
            class="persistent-login"
            v-if="config.persistentLogin && config.persistentLogin.enabled"
          >
            <div class="persistent-login-checkbox-wrapper">
              <input
                v-model="persistentLogin"
                :value="persistentLogin"
                type="checkbox"
                id="persistent-login-header"
                name="keepMeSignedIn"
              />
              <label
                class="persistent-login-label"
                for="persistent-login-header"
              >
                <span class="keep-me-sign-in-label">{{
                  config.persistentLogin.checkboxLabel
                }}</span>
              </label>
            </div>
            <div class="persistent-login-text-security-text">
              <p class="persistent-login-text">
                {{ config.persistentLogin.securityText }}
              </p>
            </div>
          </div>

          <button>Sign In</button>
          <div class="forgot-password hint-link">
            <a href="/account/forgotpassword.html?path=registry"
              >Forgot your Password?</a
            >
          </div>
        </form>
      </div>
      <ul class="manage-links" v-if="isSignedIn">
        <li v-if="showManageLinksTopPics">
          <a href="/shop/registry/registry-favorites/">{{
            config.manageLinksTopPics
          }}</a>
        </li>
        <li>
          <a href="/registry/manage-registry.html">{{
            config.manageLinksManageMyRegistry
          }}</a>
        </li>
        <li v-if="showManageLinksLinkYourStore">
          <a href="/registry/search-registry.html">{{
            config.manageLinksLinkYourStore
          }}</a>
        </li>
      </ul>
    </div>
    <div class="col">
      <h3>Create a Registry</h3>
      <ul class="create-registry">
        <li class="create-baby-registry"></li>
        <li class="ifLoggedOut ifIdentified get-started">
          <a href="/registry/create-registry-sign-in.html">
            {{ config.getStartedButtonText }}
          </a>
        </li>
        <li class="get-started">
          <a href="/pages/virtual-registry/?cm_type=gnav">Virtual Registry</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {
  browserLocalStorageAvailable,
  getItemFromLocalStorage,
  saveItemToLocalStorage,
  saveJsonItemToSessionStorage,
} from "@js-ecom-mfe/browser-storage";
import {
  PERSISTENT_LOGIN_KEY,
  FIND_REGISTRY_HEADING,
  FIND_REGISTRY_BUTTON_TEXT,
  MANAGE_MY_REGISTRY_HEADING,
  MANAGE_LINKS_TOP_PICS,
  MANAGE_LINKS_MANAGE_MY_REGISTRY,
  MANAGE_LINKS_LINK_YOUR_STORE,
  SIGN_IN_TEXT,
  GET_STARTED_BUTTON_TEXT,
  PROFILE_SUMMARY_LOCAL_STORAGE_KEY,
  UI_USERNAME_COOKIE,
} from "../../../util/constants";
import { getConfigById } from "../../../util/context";
import Cookies from "js-cookie";
import dropdownMenu from "../../../mixins/dropdownMenu";

export default {
  name: "RegistryPopOut",
  mixins: [dropdownMenu],
  data() {
    const registryPopoutConfig = getConfigById("registryPopOut", this) || {};
    const profileConfig = getConfigById("profile", this) ?? {};
    const {
      findRegistryHeading = FIND_REGISTRY_HEADING,
      findRegistryButtonText = FIND_REGISTRY_BUTTON_TEXT,
      manageMyRegistryHeading = MANAGE_MY_REGISTRY_HEADING,
      manageLinksTopPics = MANAGE_LINKS_TOP_PICS,
      manageLinksManageMyRegistry = MANAGE_LINKS_MANAGE_MY_REGISTRY,
      manageLinksLinkYourStore = MANAGE_LINKS_LINK_YOUR_STORE,
      signInText = SIGN_IN_TEXT,
      getStartedButtonText = GET_STARTED_BUTTON_TEXT,
    } = registryPopoutConfig;
    const config = {
      ...registryPopoutConfig,
      findRegistryHeading,
      findRegistryButtonText,
      manageMyRegistryHeading,
      manageLinksTopPics,
      manageLinksManageMyRegistry,
      manageLinksLinkYourStore,
      signInText,
      getStartedButtonText,
    };
    return {
      config,
      persistentLogin: false,
      firstName: "",
      lastName: "",
      isUserNameCookieEnabled: profileConfig?.isUiUserNameCookieEnabled ?? true,
      isSignedIn: false,
      showManageLinksTopPics:
        registryPopoutConfig?.isManageLinksTopPicsEnabled ?? true,
      showManageLinksLinkYourStore:
        registryPopoutConfig?.isManageLinksLinkYourStoreEnabled ?? true,
    };
  },
  methods: {
    /**
     * Updates the registry search criteria in Local Storage .
     * @param {Object} Updated value for registryData to save in local storage
     */
    handleFindRegistryClick() {
      saveJsonItemToSessionStorage("registryData", {
        firstName: this.firstName,
        lastName: this.lastName,
      });
    },
    /**
     * Attempt to load persistent login preferences based on Local Storage key if it exists.
     */
    loadPersistentLoginPreferences() {
      if (browserLocalStorageAvailable()) {
        const persistentLoginPref =
          getItemFromLocalStorage(PERSISTENT_LOGIN_KEY);
        if (
          persistentLoginPref !== null &&
          typeof persistentLoginPref === "boolean"
        ) {
          this.persistentLogin = persistentLoginPref;
        }
      }
    },
    /**
     * Updates the persistent login preferences in Local Storage based on new value.
     * @param {Boolean} updatePersistentLoginVal Updated value for persistent login to save in local storage
     */
    updatePersistentLoginPreferences(updatedPersistentLoginVal) {
      if (browserLocalStorageAvailable()) {
        saveItemToLocalStorage(PERSISTENT_LOGIN_KEY, updatedPersistentLoginVal);
      }
    },
  },
  mounted() {
    const userNameCookie = Cookies.get(UI_USERNAME_COOKIE);
    const profileSummaryForLoggedInUser = getItemFromLocalStorage(
      PROFILE_SUMMARY_LOCAL_STORAGE_KEY
    );
    if (this.isUserNameCookieEnabled) {
      this.isSignedIn = Boolean(userNameCookie);
    } else {
      this.isSignedIn = Boolean(profileSummaryForLoggedInUser);
    }
    if (this.config?.persistentLogin?.enabled) {
      this.loadPersistentLoginPreferences();
    }
  },
  watch: {
    persistentLogin(updatedPersistentLoginVal) {
      this.updatePersistentLoginPreferences(updatedPersistentLoginVal);
    },
  },
};
</script>

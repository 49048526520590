/**
 * A method to deep clone an object
 *
 * @param {Object} originalObject
 * @returns {Object} - deepClonedObject
 */
export const deepCloneOfObject = (originalObject) => {
  if (typeof structuredClone === "function") {
    return structuredClone(originalObject);
  }
  return JSON.parse(JSON.stringify(originalObject));
};

<template>
  <form
    data-style="email-sms-capture-form"
    id="email-sms-sign-up-form"
    data-test-id="email-sms-sign-up-form"
    v-show="show"
    @submit.prevent="handleSubmit"
  >
    <div class="pt-2">
      <div
        :class="{ 'input-field': true, invalid: $v.emailSignupField.$error }"
      >
        <input
          type="text"
          placeholder="getEmailLabel"
          id="email-signup-field"
          name="email-signup-field"
          aria-label="Email address"
          v-model="emailSignupField"
        />
        <label for="email-signup-field">{{ getEmailLabel }}</label>
        <p
          :class="{ 'input-error': true, visible: $v.emailSignupField.$error }"
        >
          <span v-if="!$v.emailSignupField.email">{{
            getInvalidEmailText
          }}</span>
          <span v-if="!$v.emailSignupField.required">{{
            getEmailRequiredText
          }}</span>
        </p>
      </div>
    </div>
    <div class="email-opt-out-checkbox" v-if="emailOptOutEnabled">
      <div class="email-opt-out-wrapper">
        <input
          type="checkbox"
          class="email-opt-out"
          id="emailCampaignOptOut"
          name="optOut"
          v-model="emailOptOutField"
        />
        <label
          class="emailCampaignOptOut-updates-label"
          for="emailCampaignOptOut"
        ></label>
      </div>
      <div v-html="emailOptOutLegaleseMessage" class="notification-text"></div>
    </div>
    <div
      v-if="isPhoneNumberFieldEnabled"
      :class="{ 'input-field': true, invalid: $v.phoneSignupField.$error }"
    >
      <input
        type="text"
        placeholder="getPhoneNoLabel"
        class="phone-signup-field"
        id="phone-signup-field"
        name="phone-signup-field"
        aria-label="Phone number optional"
        @input="acceptPhoneNumber"
        v-model="phoneSignupField"
        maxlength="14"
      />
      <label for="phone-signup-field">{{ getPhoneNoLabel }}</label>
      <p :class="{ 'input-error': true, visible: $v.phoneSignupField.$error }">
        <span v-if="!$v.phoneSignupField.isPhone">{{
          getInvalidPhoneNoText
        }}</span>
      </p>
    </div>
    <div>
      <EcmContentSlot contentLocation="mobile-email-overlay-middle" />
    </div>
    <div class="pt-2">
      <button type="submit" class="btn-primary">{{ getSignUpNowText }}</button>
    </div>
  </form>
</template>

<script>
import { required, email, helpers } from "vuelidate/lib/validators";
import EcmContentSlot from "../EcmContentSlot.vue";
import { mapGetters } from "vuex";
import { PHONE_NO_REGEXP } from "../../util/constants.js";

const isPhone = (value) => !helpers.req(value) || PHONE_NO_REGEXP.test(value);

export default {
  name: "email-sms-capture-form",
  components: {
    EcmContentSlot,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      emailSignupField: "",
      emailOptOutField: false,
      phoneSignupField: "",
      emailOptOutEnabled: false,
      emailOptOutLegaleseMessage: "",
    };
  },
  validations: {
    emailSignupField: {
      required,
      email,
    },
    phoneSignupField: {
      isPhone,
    },
  },
  computed: {
    ...mapGetters([
      "getEmailLabel",
      "getPhoneNoLabel",
      "getPhoneNoRegex",
      "getSignUpNowText",
      "getInvalidEmailText",
      "getEmailRequiredText",
      "getInvalidPhoneNoText",
      "isPhoneNumberFieldEnabled",
    ]),
  },
  mounted() {
    const emailOptOutConsentConfig =
      this.$store.state.header.config?.marketingOverlay?.emailOptOutConsent ||
      {};
    this.emailOptOutEnabled = emailOptOutConsentConfig?.enable || false;
    this.emailOptOutLegaleseMessage =
      emailOptOutConsentConfig?.legaleseMessage || "";
  },
  methods: {
    /**
     * Removes white space and hyphens from phone number field.
     */
    acceptPhoneNumber() {
      this.phoneSignupField = this.phoneNumberFormatter(this.phoneSignupField);
    },
    /**
     * Submits the form.
     */
    handleSubmit() {
      let formData = this.getFormData();
      this.$v.$touch();
      if (!this.$v.$error) {
        this.$emit("submit", formData);
      }
    },
    /**
     * phoneNumberFormatter().
     * Format the eneterd phone number.
     * @returns {string}
     */
    phoneNumberFormatter(value) {
      const phoneRegExp = new RegExp(this.getPhoneNoRegex);
      var phoneExpression = value.replace(/\D/g, "").match(phoneRegExp);
      return !phoneExpression[2]
        ? phoneExpression[1]
        : `(${phoneExpression[1]}) ${phoneExpression[2]}${
            phoneExpression[3] ? `-${phoneExpression[3]}` : ``
          }`;
    },
    getFormData() {
      const formData = {
        emailAddress: this.emailSignupField,
        ...(this.isPhoneNumberFieldEnabled && {
          phoneNumber: this.phoneSignupField,
        }),
        ...(this.emailOptOutEnabled && { optOut: !this.emailOptOutField }),
      };
      return formData;
    },
  },
};
</script>

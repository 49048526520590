import axios from "axios";
import { getConfigById } from "../util/context.js";
import {
  saveItemToLocalStorage,
  getItemFromLocalStorage,
} from "@js-ecom-mfe/browser-storage";
import { logger } from "@js-ecom-mfe/logger";
import { GENERIC_SERVICE_TIME_OUT } from "./const/timeouts";
import { brConfigEndpoint } from "./const/endpoints";
import { REPONAME } from "../util/constants";

const fileName = "brConfig.js";

export const getBrConfigFromLocalStorage = (genId, scope) => {
  const brLocalStorageKey = getConfigById("brLocalStorageKey", scope);
  const lsGenId = getItemFromLocalStorage(brLocalStorageKey, {});
  if (lsGenId[genId]) {
    return lsGenId[genId][brLocalStorageKey];
  }
  return;
};

export const updateBrConfigInLocalStorage = (genId, brConfig, scope) => {
  let obj = {};
  const brLocalStorageKey = getConfigById("brLocalStorageKey", scope);
  obj[genId] = { [brLocalStorageKey]: brConfig };
  saveItemToLocalStorage(brLocalStorageKey, obj);
};
let isGetBrConfigRequestPending = false;
export const getBrConfig = async (host, genId, scope) => {
  let brConfig = getBrConfigFromLocalStorage(genId, scope);

  if (brConfig) {
    return brConfig;
  }
  if (!isGetBrConfigRequestPending) {
    try {
      isGetBrConfigRequestPending = true;
      const response = await axios.get(brConfigEndpoint(host), {
        timeout: GENERIC_SERVICE_TIME_OUT,
      });
      const { data } = response;
      brConfig = {
        host,
        searchUrl: data.searchUrl,
        accountId: data.accountId,
        domainKey: data.domainKey,
        typeAheadUrl: data.typeAheadUrl,
        constructorKey: data.accountKey,
      };
      updateBrConfigInLocalStorage(genId, brConfig, scope);
      isGetBrConfigRequestPending = false;
      return brConfig;
    } catch (error) {
      logger.error(REPONAME, fileName, "getBrConfig", error);
      isGetBrConfigRequestPending = false;
      return Promise.reject(error);
    }
  } else {
    return Promise.reject();
  }
};

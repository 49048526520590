<template>
  <div data-component="sign-in-prompt-container" class="design-platform">
    <div
      data-style="sign-in-prompt-container"
      id="sign-in-prompt-container"
      class="w-96 absolute flex flex-col items-center bg-light z-30"
      :class="{ 'mt-2': isMobile }"
      ref="styledContainer"
      :style="[containerStyle]"
    >
      <div
        data-style="sign-in-prompt-indicator"
        class="sign-in-prompt-indicator border border-shade-dark bg-light z-10"
        :style="[indicatorStyle]"
      ></div>
      <SignInPrompt
        :isMobile="isMobile"
        :config="config"
        @signInPromptClose="closeSignInPrompt"
      ></SignInPrompt>
    </div>
  </div>
</template>
<script>
import { saveItemToSessionStorage } from "@js-ecom-mfe/browser-storage";
import { getConfigById } from "../../util/context";
import { mapMutations } from "vuex";
import { PAGE_SESSION_DATA_KEY, REPONAME } from "../../util/constants";
import SignInPrompt from "@vue-component-ecom-account/sign-in-prompt";
import { logger } from "@js-ecom-mfe/logger";

const INTERACTION_EVENTS = ["mousedown", "click"];
const REPOSITION_EVENTS = ["scroll", "resize"];
const fileName = "SignInPromptContainer.vue";

export default {
  name: "sign-in-prompt-container",
  components: {
    SignInPrompt,
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      config: getConfigById("signInPrompt", this) || {},
      containerStyle: {},
      indicatorStyle: {},
      overlayDuration:
        getConfigById("signInPrompt", this)?.overlayDuration || 8,
    };
  },
  mounted() {
    // must wait for tick after mounted so that $el has been appended to DOM and has dimension
    this.$nextTick(() => {
      this.repositionPrompt();
    });
    // Disable additional prompts for the remainder of the session
    saveItemToSessionStorage(
      PAGE_SESSION_DATA_KEY,
      "isSignInPromptDisabled",
      true
    );
    INTERACTION_EVENTS.forEach((event) => {
      window.addEventListener(event, this.closeSignInPrompt);
    });
    REPOSITION_EVENTS.forEach((event) => {
      window.addEventListener(event, this.repositionPrompt);
    });
    // Close the prompt after the configured duration
    setTimeout(() => {
      this.setDisplaySignInPrompt(false);
    }, this.overlayDuration * 1000);
  },
  beforeDestroy() {
    INTERACTION_EVENTS.forEach((event) => {
      window.removeEventListener(event, this.closeSignInPrompt);
    });
    REPOSITION_EVENTS.forEach((event) => {
      window.removeEventListener(event, this.repositionPrompt);
    });
  },
  methods: {
    ...mapMutations(["setDisplaySignInPrompt"]),
    /**
     * Closes the sign in prompt via user action that takes place outside
     * of the sign in prompt.
     * @param event User interaction event
     */
    closeSignInPrompt(event = {}) {
      if (!this.$el.contains(event.target)) {
        this.setDisplaySignInPrompt(false);
      }
    },
    /**
     * This component needs to display under the account icon if present (or hamburger on mobile)
     * and display a caret-esque indicator for where the user should click. In order to support the
     * different brands and their individual styles we need to set the precise locations using javascript
     * as CSS is not stable enough for something that breaks DOM flow but needs to respond to exact positions
     * of elements in the DOM.
     */
    repositionPrompt() {
      const containerRect = this.$refs.styledContainer.getBoundingClientRect();
      const spacer = 10;
      const indicatorSize = 18;
      let accountIconRect,
        hamburgerIconRect,
        containerLeft,
        containerRight,
        containerTop,
        indicatorLeft,
        indicatorRight;
      if (this.isMobile) {
        accountIconRect = document
          .querySelector("header .icon-account")
          ?.getBoundingClientRect();
        hamburgerIconRect = document
          .querySelector("header #menu-icon")
          ?.getBoundingClientRect();
        if (accountIconRect) {
          containerRight = "8";
          containerTop = accountIconRect.bottom + window.scrollY + spacer;
          indicatorRight =
            window.innerWidth -
            this.findElementMidX(accountIconRect) -
            indicatorSize / 2 -
            containerRight;
        } else if (hamburgerIconRect) {
          containerLeft = "8";
          containerTop = hamburgerIconRect.bottom + window.scrollY + spacer;
          indicatorLeft =
            this.findElementMidX(hamburgerIconRect) -
            indicatorSize / 2 -
            containerLeft;
        } else {
          logger.error(
            REPONAME,
            fileName,
            "repositionPrompt",
            "No icon found to anchor prompt"
          );
          return;
        }
        this.indicatorStyle = {
          position: "absolute",
          left: `${indicatorLeft}px`,
          right: `${indicatorRight}px`,
          top: `-${indicatorSize / 2}px`,
        };
      } else {
        accountIconRect = document
          .querySelector("#nav-user-links #my-account")
          ?.getBoundingClientRect();
        if (!accountIconRect) {
          logger.error(
            REPONAME,
            fileName,
            "repositionPrompt",
            "No icon found to anchor prompt"
          );
          return;
        }
        containerLeft =
          this.findElementMidX(accountIconRect) +
          window.scrollX -
          containerRect.width / 2;
        containerTop = accountIconRect.bottom + window.scrollY + spacer;
      }
      this.containerStyle = {
        left: `${containerLeft}px`,
        right: `${containerRight}px`,
        top: `${containerTop}px`,
      };
    },
    findElementMidX(clientRect) {
      return clientRect.left + window.scrollX + clientRect.width / 2;
    },
  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"data-style":"secondary-nav"},on:{"mouseenter":_vm.mouseEnterSecondaryNav,"mouseleave":_vm.mouseLeaveSecondaryNav}},[(_vm.secondaryNavEnabled)?_c('section',{attrs:{"id":"nav-secondary"}},[_c('ul',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeMenu),expression:"closeMenu"}]},[_vm._l((_vm.secondary_nav_categories),function(category,index){return [(!(category.hideIfInternational && _vm.isInternational))?_c('li',{key:category.id},[_c('a',{attrs:{"href":category.url || 'javascript:void(0)'},domProps:{"innerHTML":_vm._s(category.name)},on:{"click":function($event){_vm.isIpad && _vm.isIpadDeviceCheckEnabled && !_vm.isResponsive
                ? category.popOut ||
                  (category.categories && category.categories.length)
                  ? _vm.toggleTopNav($event, index, category.url)
                  : null
                : null}}}),_vm._v(" "),(
              _vm.topNavItemByPath(category.path) ||
              category.custom ||
              (category.customComponent && category.popOut)
            )?_c('button',{ref:"menuIcon",refInFor:true,staticClass:"drop-down-icon",attrs:{"type":"button","aria-label":_vm.getNavItemText({ text: category.name }),"aria-expanded":"false"},on:{"click":function($event){return _vm.showDropdownMenu($event, '#nav-secondary')},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.closeDropdownMenu.apply(null, arguments)}}},[_c('SVGIcon',{attrs:{"icon":'icon-dropdown'}})],1):_vm._e(),_vm._v(" "),(category.customComponent)?_c(category.customComponent,{tag:"component",attrs:{"ecmPromos":category.ecmPromos}}):_vm._e(),_vm._v(" "),(
              (category.path && category.type === 'supercat') ||
              category.custom
            )?[(_vm.topNavItemByPath(category.path) || category.custom)?_c('PrimaryNavPopOutMenu',{attrs:{"catData":_vm.topNavItemByPath(category.path),"navLinkCatId":category.id,"customData":category.custom ? category.categories : null,"hasSpotlight":category.hasSpotlight,"ecmPromos":category.ecmPromos,"showLeftNavHeaders":_vm.config.showLeftNavHeaders || false,"showLinksIfHasGlobalOrNavLink":_vm.config.showLinksIfHasGlobalOrNavLink || true}}):_vm._e()]:_vm._e()],2):_vm._e()]})],2),_vm._v(" "),_c('EcmContentSlot',{attrs:{"contentLocation":"secondary-nav-banner-1"}}),_vm._v(" "),_c('EcmContentSlot',{attrs:{"contentLocation":"secondary-nav-banner-b2b"}}),_vm._v(" "),_c('EcmContentSlot',{attrs:{"contentLocation":"kids-secondary-nav-banner-1"}})],1):_vm._e()])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
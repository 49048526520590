/* eslint-disable no-console */
/**
 * Logs an error to the console, either server-side or client-side
 *
 * @param {String} fileName - Originating file name
 * @param {String} methodName - Originating method name
 * @param {String} message - Message from the error, descriptive text
 */
export const logError = (fileName, methodName, message) => {
  // eslint-disable-next-line
  console.error(`ERROR globalMFE-${fileName}-${methodName} ${message}`);
};

/**
 * Logs a warning to the console, either server-side or client-side
 *
 * @param {String} fileName - Originating file name
 * @param {String} methodName - Originating method name
 * @param {String} message - Message from the error, descriptive text
 */
export const logWarning = (fileName, methodName, message) => {
  // eslint-disable-next-line
  console.warn(`WARNING globalMFE-${fileName}-${methodName} ${message}`);
};

/**
 * Logs a debug to the console, either server-side or client-side
 *
 * @param {String} fileName - Originating file name
 * @param {String} methodName - Originating method name
 * @param {String} message - Message from the debug, descriptive text
 */
export const logDebug = (fileName, methodName, message) => {
  console.debug(`DEBUG globalMFE-${fileName}-${methodName} ${message}`);
};

/**
 * Tests an Axios response for status codes, status text and logs appropriate messages in the console
 *
 * @param {String} fileName - Originating file name
 * @param {String} methodName - Originating method name
 * @param {Object} response - Axios response object
 */
export const axiosResponseErrorLogger = (fileName, methodName, response) => {
  //First use statusCode
  if (response.status && Number.isInteger) {
    if (response.status > 399) {
      logError(
        fileName,
        methodName,
        `Error: received statusCode ${response.status} statusText ${response.statusText}`
      );
    } else if (response.status > 299) {
      logWarning(
        fileName,
        methodName,
        `Warning: received statusCode ${response.status} statusText ${response.statusText}`
      );
    }
  } else {
    //Then go off of statusTest
    if (response.statusText !== "OK") {
      logError(
        fileName,
        methodName,
        `Error: received statusCode ${response.status} statusText ${response.statusText}`
      );
    }
  }
};

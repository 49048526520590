import { logger } from "@js-ecom-mfe/logger";
import axios from "axios";
import { REPONAME } from "../util/constants";
import { categoryDataEndpoint } from "./const/endpoints";
import { GENERIC_SERVICE_TIME_OUT } from "./const/timeouts";

const fileName = "categoryDataService.js";

export const categoryDataFetch = async (isCatalogService, store) => {
  let response;
  let url = isCatalogService
    ? categoryDataEndpoint()
    : `/categorytree/shop/data.json`;
  try {
    response = await axios({
      method: "get",
      url,
      timeout: GENERIC_SERVICE_TIME_OUT,
    });
  } catch (e) {
    logger.error(REPONAME, fileName, "categoryDataFetch", e);
    response = isCatalogService
      ? { data: { categories: [] } }
      : { data: { categoryData: { categories: [] } } };
  }
  store.dispatch("setTabsData", response.data?.tabsData);
  store.dispatch("setTopLevelTabs", response.data?.tabs);
  return isCatalogService
    ? response.data.categories
    : response.data.categoryData.categories;
};

<template>
  <div
    data-style="quick-cart"
    :class="['quickcart', showInFlyout ? 'quickcart-flyout' : '']"
    @click="getCartItemsData"
    @keyup.esc="closeDropdownMenu"
    @focusout="handleDropdownFocusOut($event)"
  >
    <div v-if="showInFlyout" class="cart-flyout-header">
      <div class="heading">cart</div>
      <div class="logo">
        <SearchFlyoutLogo />
      </div>
    </div>
    <div id="quickcart-container" v-html="cartDetails"></div>
  </div>
</template>

<script>
import { getCartItems } from "../../../../src/services/quickcart";
import { logger } from "@js-ecom-mfe/logger";
import { REPONAME } from "../../../util/constants";
import SearchFlyoutLogo from "../SearchFlyout/SearchFlyoutLogo.vue";
import dropdownMenu from "../../../mixins/dropdownMenu";

const fileName = "QuickCart.vue";

export default {
  name: "quick-cart",
  components: {
    SearchFlyoutLogo,
  },
  data() {
    return {
      cartDetails: `<div id="quickcart"><div class="quickcart-loading-placeholder"></div></div>`,
    };
  },
  props: {
    isDataReloadRequired: {
      type: Boolean,
      default: false,
    },
    showInFlyout: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [dropdownMenu],
  methods: {
    /**
     * Gets called on click of a cart icon in tablet devices
     */
    async getCartItemsData() {
      await this.fetchCartItems();
    },
    async fetchCartItems() {
      const self = this;
      try {
        const { data } = await getCartItems();
        self.cartDetails = data;
        const count = self.getCartCount(data);
        // wait for the DOM
        await this.$nextTick();
        if (count) {
          self.$emit("updateCartCount", count[1]);
        } else {
          // todo: need to get this class in the quick cart api response
          this.$el.querySelector(".cart").classList.add("cart-no-items");
        }
      } catch (e) {
        logger.error(REPONAME, fileName, "fetchCartItems", e);
      }
    },
    /**
     * Gets called on updateCartCount event on page load
     * @param {String} data
     * @returns {cart count}
     */
    getCartCount(data) {
      return data.match(/class="quickcart-header">([0-9]+) item/i);
    },
  },
  watch: {
    /**
     * Gets called when isDataReloadRequired prop is changed from parent and calling the api for fetch cart items when new prop value is true
     */
    async isDataReloadRequired(newValue) {
      if (newValue) {
        this.fetchCartItems().then(() => {
          logger.debug(
            REPONAME,
            fileName,
            "EventFetchCartItems",
            "fetching cart items"
          );
        });
      }
    },
  },
};
</script>

/* eslint-disable no-underscore-dangle */
import "./polyfill";
import { createApp } from "./app-header";
import { retrieveCategoryInformation } from "./util/categoryData";

const { app, store } = createApp();

let resolveCatPromise;
window.catInfoLoaded = new Promise((res) => {
  resolveCatPromise = res;
});

window.addEventListener("DOMContentLoaded", () => {
  if (window.__HEADER__INITIAL__STATE__) {
    store.replaceState(window.__HEADER__INITIAL__STATE__);
  }

  retrieveCategoryInformation(store).then((data) => {
    resolveCatPromise(data);
  });

  app.$mount("header");
});

if (process.env.NODE_ENV !== "production") {
  // App served from port 4000
  // Webpack dev server on port 9000
  window.VUE_DEVTOOLS_CONFIG = {
    openInEditorHost: "https://localhost:9997/",
  };
}

/* eslint-disable global-require */
if (module.hot) {
  const api = require("vue-hot-reload-api");
  const Vue = require("vue");

  api.install(Vue);
  if (!api.compatible) {
    throw new Error(
      "vue-hot-reload-api is not compatible with the version of Vue you are using."
    );
  }

  module.hot.accept();
}

<template>
  <div
    data-style="global-location-edit"
    class="global-location-edit"
    data-component="Header-GlobalLocationEdit"
  >
    <span v-if="showClose" class="edit-close"> × </span>

    <div class="capture-zipcode">
      <div class="zipcode-search">
        <div
          v-if="isPostalCodeOptimizationEnabled"
          data-style="postal-code-optimization"
        >
          <zipCode
            :key="componentKey"
            :shippingZip="false"
            :storeZip="true"
            :updateZipCodeValue="updatePostalCodeValue"
            :useCanadaPostalCodeFormat="false"
            :zipCodeConfigs="postalCodeConfigs"
            :labelMessages="postalCodeLabel"
            @resetUpdateZipCodeValue="resetUpdatePostalCodeValue"
          />
        </div>
        <div v-else>
          <slot name="message"></slot>
          <div v-if="showZipError || geoCodeError" class="error">
            {{ zipErrorMsg }}
          </div>
          <div
            v-if="bingInputBoxGlobal"
            class="wrap-floating-label"
            id="inputContainerGlobal"
          >
            <input
              id="postalCode"
              type="text"
              class="zipcode-search-input"
              v-model="value"
              ref="zipInput"
              @keyup.down="onArrowDown"
              @keyup.up="onArrowUp"
              placeholder="Enter City, State, or ZIP"
              v-click-outside="closeSuggestionsBox"
            />
            <ul
              id="autocomplete-results"
              v-show="isOpen"
              class="autocomplete-results"
            >
              <li
                v-for="(result, i) in autoSuggestions"
                :key="i"
                @click="setResult(result)"
                class="autocomplete-result"
                :class="{ 'is-active': i === arrowCounter }"
              >
                <div class="maps_icon"></div>
                <div class="suggestions">
                  <div>{{ result.name }}</div>
                  <div>{{ result.address }}</div>
                </div>
              </li>
              <span class="bingLogoLight"></span>
            </ul>
          </div>
          <div v-else class="wrap-floating-label">
            <input
              type="tel"
              class="zipcode-search-input"
              v-model="value"
              autocomplete="off"
              pattern="[0-9]*"
              max="99999"
              ref="zipInput"
              placeholder="placeHolderMsg"
              required
            />
          </div>
        </div>
        <div class="wrap-zip-search-button">
          <button :button-class="'update-zip-code'" @click="updateZipCode">
            {{ updateLabel }}
          </button>
        </div>
        <div v-if="bingInputBoxGlobal" id="bingInputGlobal"></div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
/* global Microsoft */
import {
  loadGoogleMapLib,
  latLngFromAddComp,
  zipCodeFromAddComp,
} from "../../util/google-maps.js";
import { fetchBingAddressFromLatLng } from "../../util/bing-maps.js";
import { getConfigById } from "../../util/context";
import bingAutoSuggestMethods from "../../mixins/bingAutoSuggestMethods";
import clickOutside from "../../directives/click-outside";
import Vue from "vue";
import { mapGetters } from "vuex";
import { CUSTOM_POSTAL_CODE_FORMAT_REGEX } from "../../util/constants";
import zipCode from "@vue-component-ecom-zip-code/zip-code";
Vue.use(clickOutside);

const ZIP_ERROR_MESSAGE =
  "The location you specified seems to be incorrect. Please enter a valid input.";
const ZIP_PLACEHOLDER = "Enter City, State, or ZIP";

export default {
  name: "global-location-edit",
  mixins: [bingAutoSuggestMethods],
  props: {
    zipCode: {
      type: [String, Number],
      default: "",
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    enableAutoComplete: {
      type: Boolean,
      default: true,
    },
    geoCodeError: {
      type: Boolean,
      default: false,
    },
    updateLabel: {
      type: String,
      default: "Update",
    },
    zipErrorMessage: {
      type: String,
      default: null,
    },
  },
  data() {
    const bingMapConfig = getConfigById("bingMaps", this);
    const mapConfig = getConfigById("maps", this);
    const myStoreConfig = getConfigById("myStore", this);
    const postalCodeOptConfig =
      getConfigById("postalCodeOptimization", this) || {};
    const customPostalCodeFormatRegex = new RegExp(
      myStoreConfig?.customPostalCodeFormatRegex ||
        CUSTOM_POSTAL_CODE_FORMAT_REGEX
    );
    const placeHolderMsg =
      postalCodeOptConfig?.placeHolderMsg || ZIP_PLACEHOLDER;
    const zipErrorMsg = this.zipErrorMessage || ZIP_ERROR_MESSAGE;

    const postalCodeLabelObject = {
      placeHolderMsg: placeHolderMsg,
      zipErrorMessage: zipErrorMsg,
    };
    const postalCodeConfigs = {
      bingMaps: {
        ...bingMapConfig,
      },
      maps: {
        ...mapConfig,
      },
    };
    return {
      value: this.zipCode,
      autoComplete: "",
      autoCompleteListener: "",
      autoCompleteOptions: {
        types: ["(regions)"],
        componentRestrictions: { country: ["us", "pr"] },
      },
      oldZipCode: this.zipCode,
      showZipError: false,
      bingInputBoxGlobal: false,
      zipErrorMsg,
      placeHolderMsg,
      latlng: null,
      googleLoader: false,
      bingLoader: false,
      bingMapConfig,
      useCanadaPostalCodeFormat:
        myStoreConfig?.useCanadaPostalCodeFormat || false,
      bingReturn: false,
      updatePostalCodeValue: false,
      postalCodeLabel: postalCodeLabelObject,
      postalCodeConfigs,
      componentKey: 0,
      customPostalCodeFormatRegex,
    };
  },
  computed: {
    ...mapGetters(["getMyStoreUserLocation"]),
    ...mapGetters({
      isPostalCodeOptimizationEnabled: "getPostalCodeOptimizationEnabled",
    }),
    /**
     * Gets My Store latitude value set by user from Vuex
     * @returns {string} latitude set by user from Vuex
     */
    latitude() {
      return this.getMyStoreUserLocation("lat") || "";
    },
  },
  components: {
    zipCode,
  },
  mounted() {
    WSI.state.onChange("updateUserPrefs", (userPrefs) => {
      this.userGeoLocation = userPrefs;
    });
    //maps.provider could either be "Google" or "Bing" - from JSON configs
    if (getConfigById("maps", this)) {
      const maps = getConfigById("maps", this);
      window.mapsProvider = maps.provider;
    }
    if (window.mapsProvider === "Bing") {
      this.bingInputBoxGlobal = true;
    }
    if (this.enableAutoComplete && window.mapsProvider === "Google") {
      const googleMaps = getConfigById("googleMaps", this);
      loadGoogleMapLib(googleMaps).then(this.enableAutoCompletion);
    }
  },
  watch: {
    zipCode(newVal) {
      this.value = newVal;
      this.componentKey = this.componentKey + 1;
    },
    latitude() {
      this.value = this.zipCode;
    },
  },
  methods: {
    /**
     * Triggers updateZipCode passing in the updated zipcode value and the old value with which the component was initialized,
     * then saves the zip code value as oldZipCode for change detection.
     */
    async updateZipCode() {
      if (this.isPostalCodeOptimizationEnabled) {
        // trigger the zip code component to save the new zip code.
        if (this.updatePostalCodeValue) {
          // if the flag is already true then there was an invalid zip code error
          // clear the flag and try again.
          this.updatePostalCodeValue = false;
          await this.$nextTick();
        }
        this.updatePostalCodeValue = true;
      } else if (this.isValidInput(this.value) || this.bingReturn) {
        this.showZipError = false;
        this.$emit("updateZipCode", this.latlng || this.value, this.oldZipCode);
        this.oldZipCode = { ...this.latlng } || this.value;
        // clear latlng
        this.latlng = null;
        this.bingReturn = false;
      } else {
        this.showZipError = true;
      }
    },
    /**
     * Postal Code Optimization code to deal with the zip code being updated.
     * @param {String} newZipCode - the updated zip code
     * @param {String} oldZipCode - the previous zip code
     */
    resetUpdatePostalCodeValue(newZipCode, oldZipCode, newZipCodeInfo) {
      this.oldZipCode = newZipCode;
      this.updatePostalCodeValue = false;
      this.$emit("updateZipCode", newZipCode, oldZipCode, newZipCodeInfo);
    },
    /**
     * Saved ZipCode or LatLong based on provided data from Autosuggestion.
     */
    async updateZipCodeOrLatLong(value) {
      if (value.lat && value.lng) {
        let res = await fetchBingAddressFromLatLng(value, this.bingMapConfig);
        this.value = res.zipCode;
      } else {
        this.value = value;
      }

      this.$emit("updateZipCode", this.value, this.oldZipCode);
    },
    /**
     * Validates the entered zip/postal code value
     * @param {String} value - user entered value in zipcode field
     * Regex test results:
     * 'A' can be any character, likewise '1' can be any digit for the below explaination
     * For CAN region when useCanadaPostalCodeFormat is enabled, regex is true for following codes: A1A, A1A 1A1 and A1A1A1 (Includes uppercase and lowercase characters)
     * For US region, regex is true for 5 digits: 11111
     * @return {Boolean} - returns true if entered zip/postal code is valid
     */
    isValidInput(value) {
      return !value
        ? false
        : this.useCanadaPostalCodeFormat
          ? /^([A-Za-z]\d[A-Za-z]|[A-Za-z]\d[A-Za-z]\s?\d[A-Za-z]\d)$/.test(
              value.trim()
            )
          : value.trim().length > 0;
    },
    /**
     * This method is called as a callback everytime user selects an option from autocomplete
     * It calls google libs `getPlace` method, which returns the selected options details.
     * We then retrieve the zipcode from the object and set's as the value. This is because in this component we only need the zipcode
     *
     */
    onPlaceSelection() {
      const selectedPlace = this.autoComplete?.getPlace();
      const { address_components } = selectedPlace;
      if (address_components) {
        const zipCode = zipCodeFromAddComp(address_components);

        /**
         * If user has not entered the zipcode in the search box, google doesn't return zipcode
         * in that scenario get lat lng.
         */
        if (!zipCode) {
          this.latlng = latLngFromAddComp(selectedPlace);
        } else {
          this.value = zipCode;
          // When user presses enter on the dropdown option
          this.getPlacePredictions(this.value);
        }
      } else {
        //when user presses enter instead of selecting from the dropdown
        const { name } = selectedPlace;
        this.getPlacePredictions(name);
      }
    },
    /**
     * This method is called as a callback when google returns the predictions
     * @param {Array} predictions - Arrays of predictions returned by google services
     * @param {String} status - Status value returned by google services
     *
     */
    onPlacePredictions(predictions, status) {
      // *Callback from async google places call
      if (status !== "OK") {
        // show that this address is an error
        this.showZipError = true;
        return;
      }
      const [firstPrediction] = predictions;
      const { terms } = firstPrediction;

      let zipCode = "";
      if (terms.length < 4) {
        // ASSUMPTION: `Terms` array came back with City, State, Country.
        //             Concat the result terms together and let the geocoder
        //             service handle the rest.
        zipCode = terms.reduce((acc, term, index) => {
          acc = acc.concat(`${index === 0 ? "" : ", "}${term.value}`);
          return acc;
        }, "");
      } else {
        // ASSUMPTION: `Terms` array came back with City, State, Zip, Country.
        //             2nd position of term has the zipCode.
        zipCode = terms[2].value;
      }

      this.value = zipCode;
      this.updateZipCode();
    },
    /**
     * When presses enter, we need to get the predictions from google service
     * @param {String} input - User entered value in the text box
     *
     */
    getPlacePredictions(input) {
      // eslint-disable-next-line no-undef
      const service = new google.maps.places.AutocompleteService();
      service.getPlacePredictions(
        {
          input,
          ...this.autoCompleteOptions,
        },
        this.onPlacePredictions
      );
    },
    /**
     * Enables google's autocomplete widget
     *
     */
    enableAutoCompletion() {
      if (window.mapsProvider === "Google") {
        window.WSI.state.onChange("googleAPILoader", (checkGoogleLoader) => {
          this.googleLoader = checkGoogleLoader;
          if (this.googleLoader) {
            // eslint-disable-next-line no-undef
            this.autoComplete = new google.maps.places.Autocomplete(
              this.$refs.zipInput,
              this.autoCompleteOptions
            );
            // eslint-disable-next-line no-undef
            this.autoCompleteListener = google.maps.event.addListener(
              this.autoComplete,
              "place_changed",
              this.onPlaceSelection
            );
          }
        });
      }
    },
  },
};
</script>

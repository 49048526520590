<template>
  <div data-style="mobile-header-icon" class="header-icons">
    <BrandLogo prefix="/m" />
    <button
      type="button"
      v-if="mobileHeaderConfig.icons.close"
      @click.prevent="close"
      @keyup.esc="close"
      ref="closeIconRef"
      aria-label="close site navigation"
      class="icon-container close link"
      data-test-id="icon-container-close-div"
    >
      <SVGIcon
        v-if="mobileHeaderConfig.icons.closeSvg"
        :icon="mobileHeaderConfig.icons.closeSvg"
      />
      <SVGIcon v-else :icon="'mobile-header-close'" />
    </button>
    <!--
      @FUTURE remove `<template>` wrapper here once `firstHeaderIcon` and `secondHeaderIcon` configs make it into 
      production, and after following `<template>` fallback is removed. See comments marked "@FUTURE" elsewhere in this file.
    -->
    <template
      v-if="
        mobileHeaderConfig.firstHeaderIcon &&
        mobileHeaderConfig.secondHeaderIcon
      "
    >
      <PositionedHeaderIcon
        :favoriteCount="favoriteCount"
        :icon="mobileHeaderConfig.firstHeaderIcon"
        :isSignedIn="isSignedIn"
        :mobileHeaderConfig="mobileHeaderConfig"
        :position="1"
        :setSearchFocus="setSearchFocus"
      />
      <PositionedHeaderIcon
        :favoriteCount="favoriteCount"
        :icon="mobileHeaderConfig.secondHeaderIcon"
        :isSignedIn="isSignedIn"
        :mobileHeaderConfig="mobileHeaderConfig"
        :position="2"
        :setSearchFocus="setSearchFocus"
      />
    </template>
    <!--
      @FUTURE remove this entire template fallback once `firstHeaderIcon` and `secondHeaderIcon` configs make it into
      production. Logic for PositionedHeaderIcon above is simpler and more explicit.
    -->
    <template v-else>
      <div class="icon-container">
        <a
          v-if="favoritesHeaderIconEnabled"
          aria-label="favorites"
          class="header-icon favorites"
          :href="
            mobileHeaderConfig.icons.favorites
              ? '/favorites/?cm_type=gnav&cm_sp=HeaderLinks-_-AccountFavorites-_-Mobile'
              : '/stores/?cm_type=gnav&cm_sp=HeaderLinks-_-Stores-_-Mobile'
          "
        >
          <span
            v-if="mobileHeaderConfig.icons.favorites"
            v-html="mobileHeaderConfig.icons.favoritesSVG"
          />
          <span v-else v-html="mobileHeaderConfig.icons.findStoreSVG" />
        </a>
        <span
          v-if="mobileHeaderConfig.icons.favorites && favoriteCount > 0"
          class="favorite-count"
          >{{ favoriteCount }}</span
        >
      </div>

      <div
        v-if="mobileHeaderConfig.icons.stores"
        class="icon-stores"
        @click="setSearchFocus"
      >
        <a
          class="header-icon stores"
          href="/stores/?cm_type=gnav&amp;cm_sp=HeaderLinks-_-Stores-_-Mobile"
          aria-label="stores"
          v-html="mobileHeaderConfig.icons.storesSVG"
        >
        </a>
      </div>
      <div v-if="showSearchFlyout" class="icon-search">
        <button aria-label="search" @click="handleSearchFocus()">
          <span
            v-if="this.searchHeaderIconEnabled"
            v-html="mobileHeaderConfig.icons.searchSVG"
          />
        </button>
        <SearchFlyout :flyoutConfig="searchFlyoutConfig" />
      </div>
      <div v-else class="icon-search" @click="setSearchFocus">
        <span
          v-if="this.searchHeaderIconEnabled"
          v-html="mobileHeaderConfig.icons.searchSVG"
        />
      </div>
    </template>
    <div class="icon-cart">
      <a
        v-if="mobileHeaderConfig.icons.cartIcon"
        :href="mobileHeaderConfig.icons.cartUrl"
        aria-label="shopping cart"
        class="header-icon cart-with-wrapper"
        data-test-id="header-icon-cart-with-wrapper"
      >
        <div
          v-html="mobileHeaderConfig.icons.cartWithWrapperSVG"
          aria-hidden="true"
          class="header-icon-wrapper"
          data-test-id="header-icon-cart-div-with-icon"
        />
      </a>

      <a
        v-else-if="mobileHeaderConfig.icons.cartFlyout"
        @click="handleFlyout()"
        href="javascript:void(0)"
        v-html="mobileHeaderConfig.icons.cartFlyoutSVG"
        aria-label="shopping cart"
        class="header-icon cart-with-flyout"
        data-test-id="header-icon-cart-with-flyout"
      >
      </a>

      <a
        v-else
        v-html="mobileHeaderConfig.icons.cartSVG"
        aria-label="shopping cart"
        href="/shoppingcart/"
        class="header-icon cart"
        data-test-id="header-icon-cart-link-with-icon"
      />
      <span
        v-if="!hideCartCount && getCartQuantity > 0"
        aria-hidden="true"
        class="cart-count"
        data-test-id="header-icon-cart-count"
        >{{ getCartQuantity }}</span
      >
      <template
        v-if="
          mobileHeaderConfig.icons.cartFlyout && hasLoaded && !isShoppingCart
        "
      >
        <Flyout
          @handleFlyoutClose="handleFlyoutClose"
          flyoutClass="mobile-cart-flyout"
        >
          <template v-slot:flyout-content>
            <QuickCart
              :isDataReloadRequired="reloadCartItems"
              :showInFlyout="mobileHeaderConfig.icons.cartFlyout"
            />
          </template>
        </Flyout>
      </template>
    </div>
  </div>
</template>

<script>
import BrandLogo from "./BrandLogo.vue";
import EventBus from "../../util/event-bus";
import { GOVERNOR_USER_STATE_KEY } from "../../../src/util/constants.js";
import { getConfigById } from "../../util/context";
import { mapGetters } from "vuex";
import PositionedHeaderIcon from "./PositionedHeaderIcon.vue";
const SVGIcon = () =>
  import(/* webpackChunkName: "header-footer-svg-icon" */ "../SVGIcon.vue");
import SearchFlyout from "./SearchFlyout/SearchFlyout.vue";
import QuickCart from "./ActionLinkList/QuickCart.vue";
import Flyout from "./Flyout.vue";

export default {
  name: "mobile-header-icon",
  components: {
    BrandLogo,
    PositionedHeaderIcon,
    SVGIcon,
    SearchFlyout,
    QuickCart,
    Flyout,
  },
  props: {
    favoriteCount: {
      type: Number,
      default: 0,
    },
    mobileHeaderConfig: {
      type: Object,
      required: true,
    },
    showSearchFlyout: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    const searchFlyoutConfig = getConfigById("searchFlyout", this) ?? {};
    const hideCartCount =
      this.mobileHeaderConfig?.icons?.hideCartCount ?? false;
    return {
      /*
        @FUTURE remove `favoritesHeaderIconEnabled` and `searchHeaderIconEnabled` once `firstHeaderIcon` and `secondHeaderIcon`
        configs make it into production. These configs only only exist in WE global.json. `firstHeaderIcon` and `secondHeaderIcon`
        places icons explicitly without excess logic, configuration, or conditional fallbacks
      */
      favoritesHeaderIconEnabled: true,
      searchHeaderIconEnabled: true,
      isSignedIn: false,
      hasLoaded: false,
      searchFlyoutConfig,
      reloadCartItems: false,
      hideCartCount,
    };
  },
  computed: {
    ...mapGetters(["getCartQuantity"]),
    isShoppingCart() {
      const bodyId = document.body.id;
      return bodyId === "shopping-cart";
    },
  },
  /*
    @FUTURE remove `favoritesHeaderIconEnabled` and `searchHeaderIconEnabled` once `firstHeaderIcon` and `secondHeaderIcon`
    configs make it into production. These configs only only exist in WE global.json. `firstHeaderIcon` and `secondHeaderIcon`
    places icons explicitly without excess logic, configuration, or conditional fallbacks
  */
  created() {
    this.favoritesHeaderIconEnabled =
      this.mobileHeaderConfig.icons.hasOwnProperty("favoritesHeaderIconEnabled")
        ? this.mobileHeaderConfig.icons.favoritesHeaderIconEnabled
        : true;
    this.searchHeaderIconEnabled = this.mobileHeaderConfig.icons.hasOwnProperty(
      "searchHeaderIconEnabled"
    )
      ? this.mobileHeaderConfig.icons.searchHeaderIconEnabled
      : true;
  },
  methods: {
    close() {
      this.$emit("close");
      EventBus.$emit("setHamburgerMenuFocus");
    },
    setSearchFocus(event) {
      event?.stopPropagation();
      EventBus.$emit("setSearchFocusMobile");
    },
    handleSearchFocus() {
      EventBus.$emit("toggleSearchFlyout");
    },
    // toggles cart flyout
    handleFlyout() {
      this.reloadCartItems = !this.reloadCartItems;
      EventBus.$emit("toggleFlyout");
    },
    // closes the flyout container
    handleFlyoutClose() {
      this.reloadCartItems = !this.reloadCartItems;
    },
  },
  mounted() {
    this.hasLoaded = true;
    this.$emit("hasLoaded", this.hasLoaded);
    window.WSI.state.onChange(GOVERNOR_USER_STATE_KEY, (displayName) => {
      this.isSignedIn = !!displayName;
    });
  },
};
</script>

import {
  FLYOUT_CONFIG,
  PRICE_TYPE_BW_RANGE,
  PRICING_CONFIG,
  PRODUCT_CAROUSAL_CONFIG,
  PROMOVIZ_CONFIG,
  RECOMMENDATIONS_CONFIG,
  RECOMMENDATION_VARIANT,
  VTA_RVI_BREAKPOINTS,
} from "../util/constants";
import { getConfigById } from "../util/context.js";

export default {
  data() {
    const flyoutConfig = getConfigById("viewMoreRecommendations", this);
    const applicationUri = this.$store?.state?.header?.applicationUri;
    const concept = this.$store?.state?.header?.concept;
    const promoVizConfig =
      getConfigById("promotionVisibility", this) || PROMOVIZ_CONFIG;
    const productCarousel =
      getConfigById("productCarousel", this) || PRODUCT_CAROUSAL_CONFIG;
    const pricing = getConfigById("pricing", this) || PRICING_CONFIG;
    const currencyData = this.$store?.state?.header?.currencyData || {};

    FLYOUT_CONFIG.inlineRecommendations.maskUrl.hostUrl = `${applicationUri}${FLYOUT_CONFIG.inlineRecommendations.maskUrl.hostUrl}`;
    let config = { ...flyoutConfig };
    if (!flyoutConfig.inlineRecommendations) {
      config = { ...flyoutConfig, ...FLYOUT_CONFIG };
    }

    return {
      config,
      applicationUri,
      concept,
      currencyData,
      promoVizConfig,
      productCarousel,
      pricing,
    };
  },
  computed: {
    /**
     * Controls the visibility of "See More Like This" link on RVI carousal
     * Returns true/false based on config
     * @returns {Boolean}
     */
    handleSeeMoreLink() {
      let inlineRecommendations = {
        master: this.config?.enable || false,
        config: this.config?.inlineRecommendations || {},
        desktop: this.config?.inlineRecommendations?.desktop || false,
        mobile: this.config?.inlineRecommendations?.mobile || false,
        maskUrlEnabled:
          this.config?.inlineRecommendations?.maskUrl?.enable || false,
        maskUrl: this.config?.inlineRecommendations?.maskUrl?.hostUrl || "",
      };
      return inlineRecommendations;
    },
    /**
     * Create pricing object for "RecommendationWrapper".
     * Returns pricing object from brand config.
     * @returns {Object}
     */
    getPricingConfig() {
      const {
        mdmLabels,
        defaultLabels,
        showPriceTypeBetweenPriceRanges = PRICE_TYPE_BW_RANGE,
      } = this.pricing;
      const { showDiscountPercentage, showMdmPriceLabels } = this.pricing;
      return {
        mdmLabels,
        defaultLabels,
        showDiscountPercentage,
        showMdmPriceLabels,
        showPriceTypeBetweenPriceRanges,
      };
    },
    /**
     * Create recommendation object for "RecommendationWrapper".
     * Override recsHostUrl with maskUrl if switch is enabled.
     * Returns recommendation object from brand config.
     * @returns {Object}
     */
    getRecommendationConfiguration() {
      const recommendationsConfiguration =
        getConfigById("recommendation", this) || RECOMMENDATIONS_CONFIG;
      let hostUrl = "";
      const getMaskUri = this.handleSeeMoreLink;
      getMaskUri.maskUrlEnabled && getMaskUri.maskUrl
        ? (hostUrl = getMaskUri.maskUrl)
        : (hostUrl =
            recommendationsConfiguration?.wsiRecs?.recsHostUrl ||
            `${this.applicationUri}${RECOMMENDATIONS_CONFIG?.wsiRecs?.recsHostUrl}`);
      const {
        isRecsEnabled,
        recommendationSource,
        variant = RECOMMENDATION_VARIANT,
      } = recommendationsConfiguration;
      const favorite = recommendationsConfiguration?.wsiRecs?.favorite || {};

      const breakpoints = VTA_RVI_BREAKPOINTS;
      const getRecommendationsFlyout = {
        isRecsEnabled,
        recommendationSource,
        wsiRecs: { breakpoints, favorite },
        variant,
      };
      getRecommendationsFlyout.wsiRecs.recsHostUrl = hostUrl;

      return getRecommendationsFlyout;
    },
  },
  methods: {
    /**
     * Takes page type as input.
     * Returns page name based on the type.
     * @returns {String}
     */
    getPageType(type) {
      if (type === "RVI") {
        return `GLOBALRECOMMENDATIONSFLYOUTRVI`;
      }
      return `GLOBALRECOMMENDATIONSFLYOUT`;
    },
  },
};

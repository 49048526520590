/**
 * Converts a character (A-Z) to its 0-index position in the alphabet.
 * e.g. A = 0, a = 0, B = 1, b = 2 ...
 * If a string is provided, only the first character of the string is considered
 * @param { string } char
 * @returns { number } - converted c
 */
export const convertFirstCharToInt = (char) => {
  const alphabet = /^[A-Za-z]/;
  if (typeof char !== "string" || !char.charAt(0).match(alphabet)) {
    return null;
  }
  return char.toLowerCase().charCodeAt(0) - 97;
};

/**
 * Checks if string provided follows the 9 char zip code format ("10001-1234")
 * or the 5 char zip code format ("10001").
 * @param {string} zipCodeString zip code string to check
 * @returns {boolean} returns true if string provided follows the 9 or 5 char zip code format
 */
export const checkValidZipCode = (zipCodeString) => {
  if (typeof zipCodeString !== "string") {
    return false;
  }

  if (zipCodeString.length === 10) {
    // User provided a 9 char zip, e.g. "10001-1234"
    const splitZip = zipCodeString.split("-");
    return (
      splitZip.length === 2 &&
      splitZip[0].length === 5 &&
      isAllInt(splitZip[0]) &&
      splitZip[1].length === 4 &&
      isAllInt(splitZip[1])
    );
  } else if (zipCodeString.length === 5) {
    return isAllInt(zipCodeString);
  } else {
    return false;
  }
};

/**
 * Checks if string provided only consists of integers
 * @param {string} str string to check if it only consists of integers
 * @returns {boolean} true if string provided consists only of integers
 */
export const isAllInt = (str) => {
  if (typeof str !== "string") {
    return false;
  }

  return str.split("").every((char) => Number.isInteger(parseFloat(char)));
};

/**
 * Removes trailing slash from string passed in
 * @param {string} str string to remove trailing slash from
 * @returns {string} string with its trailing slash removed
 */
export const removeTrailingSlash = (str) => {
  if (typeof str !== "string") {
    return str;
  } else {
    return str.endsWith("/") ? str.substring(0, str.length - 1) : str;
  }
};

/**
 * Removes excess whitespace, tabs and newlines inclusive, from a string.
 * For example: " h   e ll   o  " becomes "h e ll o".
 * @param {string} str string to remove excess whitespace from
 * @returns {string} string with excess whitespace removed
 */
export const removeExcessWhiteSpace = (str) => {
  if (typeof str !== "string") {
    return "";
  } else {
    return str.trim().replace(/\s\s+/g, " ");
  }
};

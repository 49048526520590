/* eslint-disable camelcase */

import {
  getProducts,
  getPznTriggeredCampaigns,
} from "../services/pznTriggeredCampaigns.js";
import { loadScript } from "../util/scriptHandler";
import { REPONAME } from "../util/constants";
import { logger } from "@js-ecom-mfe/logger";

const fileName = "pznTriggeredCampaignsHelper.js";

export const defaultCampaign = {
  id: null,
  name: null,
  products: [],
};

export const defaultConfig = {
  campaigns: [],
  enabled: false,
  mobileEnabled: false,
  api: {
    hostName: "",
    bearerToken: "",
    logLevel: 1,
    mockUserToken: "",
    timeoutSeconds: 60,
    useMockData: true,
    useEmptyMockData: false,
    loadSpotjsWithNpm: false,
  },
  ABTest: {
    name: "",
    activeVariant: "",
    enabled: false,
  },
  mobileABTest: {
    name: "",
    activeVariant: "",
    enabled: false,
  },
  img: {
    imgUrlBase: "",
    imgSuffix: "t.jpg",
  },
};

/**
 * Get campaigns with product details
 *
 * @param {Object} config the pzn triggered campaigns configuration
 * @param {String} brandConcept the current brand code (MG|PB|PK|PT|WE|WS)
 * @param {Object} store the global vuex store
 * @returns {Promise<Object>} Campaign object with product details
 */
export const getCampaignsWithProductDetails = async (
  config,
  brandConcept,
  store
) => {
  const methodName = "getCampaignsWithProductDetails";
  const { id, name, products, hasFetched, hasFetchedWithUserToken } =
    store.getters.getPznTriggeredCampaign;
  const userToken = getUserToken();

  try {
    if (!shouldMakeApiCall(hasFetched, hasFetchedWithUserToken, userToken)) {
      return {
        id,
        name,
        products,
      };
    }

    store.dispatch("setPznTriggeredCampaign", {
      hasFetched: true,
      hasFetchedWithUserToken: !!userToken,
    });

    if (!config.api.useMockData) {
      await configureDecisionCampaigns(config.api);
    }

    const campaignTypes = getCampaignTypes(config.campaigns);
    const deviceToken = getDeviceToken();
    const campaign = await getPznTriggeredCampaigns(
      config.api,
      brandConcept,
      deviceToken,
      userToken,
      campaignTypes
    );

    if (!campaign) {
      return defaultCampaign;
    }

    campaign.products = await getCampaignProducts(campaign, config.img);

    if (!campaign.products.length) {
      return defaultCampaign;
    }

    const campaignConfig = getCampaignConfigById(config.campaigns, campaign.id);

    campaign.name = campaignConfig.name;

    store.dispatch("setPznTriggeredCampaign", campaign);

    return campaign;
  } catch (error) {
    logger.error(REPONAME, fileName, methodName, error);
    return defaultCampaign;
  }
};

/**
 * Get each product's detail for the campaign
 *
 * @param {Object} campaign the campaign with products
 * @param {Object} imgConfig
 * @returns list of detailed products
 */
export const getCampaignProducts = async (campaign, imgConfig) => {
  const methodName = "getCampaignProducts";
  const groupIds = getUniqueGroupIds(campaign.products);

  if (!groupIds.length) {
    logger.warn(
      REPONAME,
      fileName,
      methodName,
      `no group IDs found in campaign ${campaign?.campaignId}`
    );
    return [];
  }

  const productPromises = await getProducts(groupIds);
  const products = productPromises
    .filter((productPromise) => productPromise.status === "fulfilled")
    .map((productPromise) =>
      convertCatalogProductToBloomreachFormat(
        productPromise.value,
        imgConfig.imgUrlBase,
        imgConfig.imgSuffix
      )
    )
    .filter((product) => !isNoLongerAvailableProduct(product))
    .slice(0, 10);
  return products;
};

/**
 * @typedef CatalogProduct
 * @property {String} name name of the product
 * @property {String} groupId group ID of the product
 * @property {String} imageUrl partial image URL of the product
 * @property {String} priceType type of current price (e.g. REGULAR, SPECIAL, etc.)
 * @property {Number} originalPrice original price of product
 * @property {String} originalPriceRange original price range of product
 * @property {Number} currentPrice current price of product
 * @property {String} priceRange current price range of product
 */
/**
 * Check if a catalog product is no longer available based on price = 0
 *
 * @param {CatalogProduct} catalogProduct - catalog formatted product
 * @returns {Boolean} - true if the catalog product is not available, false if it is available
 */
const isNoLongerAvailableProduct = (catalogProduct) => {
  return catalogProduct.sale_price === 0 && catalogProduct.price === 0;
};

/**
 * Get a unique list of group ids
 *
 * @param {Array} products list of products from a campaign
 * @returns unique list of group ids
 */
export const getUniqueGroupIds = (products) => {
  return [...new Set(products)];
};

/**
 * Converts an catalog productWidgetInformation-formatted product object to a Bloomreach-formatted object
 * from https://github.wsgc.com/eCommerce-Bedrock/ecom-app-shop/blob/release/src/client/components/RecentlyViewedItems.vue
 *
 * @param {CatalogProduct} catalogProduct -- catalog formatted product to convert
 * @param {String} [imgUrlBase=https://qark-images.pkimgs.com/pkimgs/qark/images/dp/] -- base url for the image. e.g.
 * @param {String} [imgSuffix=t.jpg] -- image suffix. see https://confluence.wsgc.com/display/EC/Image+Size+Suffix+-+Quick+Lookup
 *
 * @returns {Object} -- Bloomreach formatted product
 */
export const convertCatalogProductToBloomreachFormat = (
  catalogProduct,
  imgUrlBase = "https://qark-images.pkimgs.com/pkimgs/qark/images/dp/",
  imgSuffix = "t.jpg"
) => {
  let priceRange = [];
  let salePriceRange = [];

  if (catalogProduct.priceRange) {
    const splitPriceRange = catalogProduct.priceRange.split(" - ");
    if (
      splitPriceRange[0] ===
      Number.parseFloat(catalogProduct.currentPrice).toFixed(2)
    ) {
      if (catalogProduct.originalPriceRange) {
        priceRange = catalogProduct.originalPriceRange
          ?.split(" - ")
          .map(Number);
      } else {
        priceRange = [
          catalogProduct.originalPrice,
          catalogProduct.originalPrice,
        ];
      }

      salePriceRange = catalogProduct.priceRange.split(" - ").map(Number);
    } else {
      priceRange = splitPriceRange.map(Number);
    }
  } else {
    //if no price range, BR object expects one, populate ranges w/ orig & current prices
    priceRange = [catalogProduct.originalPrice, catalogProduct.originalPrice];
    salePriceRange = [catalogProduct.currentPrice, catalogProduct.currentPrice];
  }

  /* eslint-disable camelcase */
  return {
    name: catalogProduct.name,
    pid: catalogProduct.id,
    thumb_image: `${imgUrlBase}${catalogProduct.imageUrl}${imgSuffix}`,
    title: catalogProduct.name,
    priceType:
      catalogProduct.priceType.charAt(0).toUpperCase() +
      catalogProduct.priceType.slice(1).toLowerCase(),
    price: catalogProduct.originalPrice,
    sale_price: catalogProduct.currentPrice,
    price_range: priceRange,
    sale_price_range: salePriceRange,
    productLink: `/products/${catalogProduct.id}`,
  };
  /* eslint-enable camelcase */
};

/**
 * Loads the spot.js file and setups the API configuration for the decision event
 *
 * @param {String} apiConfig the config to call decision event API
 */
export const configureDecisionCampaigns = async (apiConfig) => {
  const methodName = "configureDecisionCampaigns";

  if (!apiConfig.hostName || !apiConfig.bearerToken) {
    logger.error(
      REPONAME,
      fileName,
      methodName,
      "Unable to load script as host name and/or bearer token are null"
    );
    return;
  }

  if (apiConfig.loadSpotjsWithNpm) {
    try {
      const spotLoader = await import(
        /* webpackChunkName: "cheetah-spot" */ "@js-ecom-third-party/cheetah-spot"
      );
      await spotLoader.initialize();
    } catch (error) {
      logger.error(REPONAME, fileName, "created", error);
    }
  } else {
    const { assetUris, staticPrefix } = WSI.appGlobal;
    await loadScript(
      `${assetUris.scripts}${staticPrefix}/js/thirdParty/spot.js`,
      false
    );
  }

  if (window["spot_data"]) {
    window["spot_data"].push({
      config: {
        apiHost: apiConfig.hostName,
        apiAuth: apiConfig.bearerToken,
        logLevel: apiConfig.logLevel,
      },
    });
  }
};

/**
 * Checks whether the feature is enabled by configuration or AB Test
 * @param {Object} config the pzn triggered campaigns configuration
 * @param {Object} store the global vuex store
 * @param {Boolean} isMobile Whether the feature is Mobile
 * @returns {Boolean}
 */
export const isEnabledByConfig = (config, store, isMobile = false) => {
  const ABTest = isMobile ? config.mobileABTest : config.ABTest;
  const enabled = isMobile ? config.mobileEnabled : config.enabled;

  if (!enabled) {
    return false;
  }

  if (!ABTest.enabled) {
    return true;
  }

  const activeAbTest = store.getters.getActiveTestById(ABTest.name);

  return !!activeAbTest && activeAbTest.variation === ABTest.activeVariant;
};

/**
 * return campaign types
 * @param {Object} campaignConfig the pzn triggered campaigns configuration
 * @returns {Array} list of configured campaign types
 */
export const getCampaignTypes = (campaignConfig) => {
  return campaignConfig.map(({ campaignId }) => campaignId);
};

/* eslint-disable camelcase */
/**
 * Gets the user token from digitalData
 * @returns {String} the user token
 */
export const getUserToken = () => {
  return (
    window.digitalData?.x_user?.profile?.profileEmail ||
    window.digitalData?.page?.attributes?.emailAddress ||
    window.digitalData?.x_transaction?.customer?.email
  );
};
/* eslint-enable camelcase */

/**
 * Checks whether the feature is enabled by configuration or AB Test
 * @param {Object} config the pzn triggered campaigns configuration
 * @param {Object} id the campaign id to find configuration settings for
 * @returns {Array} list of configuration settings per campaign
 */
export const getCampaignConfigById = (campaigns, id) => {
  return campaigns.find(({ campaignId }) => campaignId === id);
};

/**
 * Gets the adobe id of the user
 * @returns {String} the adobe id
 */
export const getDeviceToken = () => {
  return window.visitor?.getMarketingCloudVisitorID();
};

export const shouldMakeApiCall = (
  hasFetched,
  hasFetchedWithUserToken,
  userToken
) => {
  return (
    shouldMakeAdditionalFetchWithUserToken(
      hasFetched,
      hasFetchedWithUserToken,
      userToken
    ) || !hasFetched
  );
};

export const shouldMakeAdditionalFetchWithUserToken = (
  hasFetched,
  hasFetchedWithUserToken,
  userToken
) => {
  return hasFetched && !hasFetchedWithUserToken && !!userToken;
};
